import { Col, Image, Table, notification } from "antd";
import deleteImage from "../../../assets/images/delete.png";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import pdfPlaceholderImage from "../../../assets/images/pdfPlaceHolder.png";
import xlsxPlaceholderImage from "../../../assets/images/xlsx_thumbnail.png";
import docxPlaceholderImage from "../../../assets/images/docximage.png";
require("./EditAttachments.scss");

const EditPOAttachments = (props: { images: any }) => {
  const token = getToken();

  const notifyMessage = (
    message: string,
    description?: string,
    color?: string
  ) => {
    notification.open({
      message,
      description,
      style: { color },
    });
  };

  const deleteHandler = (record: any) => {
    AxiosConfig.delete(`purchaseorders/attachments/${record}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.status.code === 200) {
          notifyMessage(res.data.status.message);
        }
      })
      .catch((error) => {
        notifyMessage("", error.response.data.status.message, "red");
      });
  };

const getAttachmentContent = (record: any) => {
  const fileType = record?.image_id.split(".").pop();
  const fileName = record?.image_id.split("^").pop().split(".")[0];

  if (
    fileType === "pdf" ||
    fileType === "jpg" ||
    fileType === "jpeg" ||
    fileType === "png" ||
    fileType === "xlsx" ||
    fileType === "docx"
  ) {
    let content;

    if (fileType === "pdf") {
      content = (
        <div className="pdf-placeholder">
          <img
            src={pdfPlaceholderImage}
            alt="PDF Placeholder"
            className="pdf-placeholder-image"
          />
        </div>
      );
    } else if (fileType === "xlsx") {
      content = (
        <div className="xlsx-placeholder">
          <img
            src={xlsxPlaceholderImage}
            alt="XLSX Placeholder"
            className="xlsx-placeholder-image"
          />
        </div>
      );
    } else if (fileType === "docx") {
      content = (
        <div className="docx-placeholder">
          <img
            src={docxPlaceholderImage}
            alt="DOCX Placeholder"
            className="docx-placeholder-image"
          />
        </div>
      );
    } else {
      content = (
        <Col span={12}>
          <Image
            className="attachment-image"
            width={62}
            height={62}
            src={record.image_url}
            alt="Attachment"
          />
        </Col>
      );
    }

    return (
      <div className="attachment">
        {content}
        <text>{fileName}</text>
      </div>
    );
  } else {
    return (
      <div className="attachment" key={record.key}>
        <Image
          className="attachment-image"
          width={62}
          height={62}
          src={record.image_url}
          alt="Attachment"
        />
      </div>
    );
  }
};

  const columns = [
    {
      title: "Attachments",
      dataIndex: "image_url",
      key: "image_url",
      render: (record: any, row: any) => getAttachmentContent(row),
    },
    {
      title: "Action",
      dataIndex: "image_id",
      key: "image_id",
      render: (record: any) => (
        <div className="actionBtnAttachment">
          <span>
            <img src={deleteImage} onClick={() => deleteHandler(record)} />
          </span>
        </div>
      ),
    },
  ];

  const POImageData = props.images.map((row: { id: number; url: string }) => ({
    image_id: row.id,
    image_url: row.url,
    key: row.id,
  }));

  return (
    <Table
      className="AttachmentsTable"
      columns={columns}
      dataSource={POImageData}
      pagination={{ pageSize: 2 }}
    />
  );
};

export default EditPOAttachments;
