//import Action Types
import { GET_BANDS, GET_BANDS_ERROR } from "../../constants";

//Write down the initial state of the reducer
interface BandsState {
    bands: Array<object>;
    error: string;
}

const INITIAL_STATE:BandsState = {
    bands: [],
    error: "",
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_BANDS:
            return { ...state, bands: action.payload };
        case GET_BANDS_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};
