import React, { useState, useEffect } from "react";
import { Table, Button, Row, Col, Input, Select } from "antd";
import { enumForAudit } from "../../../utils/enums";
import { AxiosConfig } from "../../../ApiConfig";
import { errorNotification } from "../../../utils/util";
import { getToken } from "../../../auth";
import SessionAudit from "../../../components/AuditModal/SessionAudit";
import { format, startOfMonth, endOfMonth, getMonth } from "date-fns";
import { useParams, useLocation, useHistory } from "react-router-dom";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "antd/dist/antd.css";
require("./Audits.scss");
import arrow from "../../../assets/images/arrow.png";
import { app } from "../../../utils/enums";
import Loader from "../../../components/Loader/Loader";
import { CSVLink } from "react-csv";
import { ExportOutlined } from "@ant-design/icons";
// Monthly Audit
const AuditsPSAT = () => {
  let history = useHistory();
  const { id }: any = useParams();
  const location = useLocation();
  const passedData = location.state;
  const { entity }: any = passedData;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [monthName, setMonthName] = useState();
  const [project_id, setProjectId] = useState();
  const [customer_id, setCustomerId] = useState();
  const [customer_name, setCustomerName] = useState();
  const [response_group_id, setResGroupId] = useState();
  const [func_type, setFuncType] = useState();

  const token = getToken();
  const currentMonth = getMonth(new Date()) + 1;
  const { Option } = Select;
  const fixedColumns = ["project_code", "id", "customer.name", "name"];
  const optionsMonths = [
    { label: "January", value: "month1" },
    { label: "February", value: "month2" },
    { label: "March", value: "month3" },
    { label: "April", value: "month4" },
    { label: "May", value: "month5" },
    { label: "June", value: "month6" },
    { label: "July", value: "month7" },
    { label: "August", value: "month8" },
    { label: "September", value: "month9" },
    { label: "October", value: "month10" },
    { label: "November", value: "month11" },
    { label: "December", value: "month12" },
  ];

  const fixedMonthColumns = [
    "month1",
    "month2",
    "month3",
    "month4",
    "month5",
    "month6",
    "month7",
    "month8",
    "month9",
    "month10",
    "month11",
    "month12",
  ];
  const [filterMonth, setFilterMonth] = useState("");
  const [filteredColumns, setFilteredColumns]: any = useState([
    ...fixedMonthColumns,
    ...fixedColumns,
  ]);
  const [tableColumns, setTableColumns]: any = useState([]);
  const [allColumns, setAllColumns]: any = useState([]);

  const [nameSearch, setNameSearch] = useState("");
  const [userId, setUserId] = useState();
  const [auditPsatData, setAuditPsatData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterTotalCount, setFilterTotalCount] = useState(0);
  const [financialYears, setFinancialYears] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectCode, setProjectCode] = useState("");
  const [customerNames, setCustomerNames]: any = useState([]);
  const currentYear = new Date().getFullYear();
  const [isFilter, setIsFilter] = useState(false); // eslint-disable-line
  const [accessToAll, setAccesToAll] = useState(0);
  const [loading, setLoading] = useState(true);
  const [customerNameFilter, setCustomerNameFilter] = useState([]);

  function getMonthRangeDates(monthNumber, year) {
    const startDate = startOfMonth(new Date(year, monthNumber - 1));
    const endDate = endOfMonth(new Date(year, monthNumber - 1));

    return {
      startDate: format(startDate, "dd MMM"),
      endDate: format(endDate, "dd MMM"),
    };
  }

  //Total count change according to data
  useEffect(() => {
    setFilterTotalCount(filterData?.length);
  }, [filterData]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const generateColumns = () => {
    const commonColumns = [
      ...Array.from({ length: 12 }) // Assuming there are 12 months in a year
        .map((_, index) => {
          const monthNumber = index + 1;
          const monthName = monthNames[index];
          const { startDate, endDate } = getMonthRangeDates(monthNumber, year);
          return {
            title: (
              <div className="header-div">
                <div className="header-div-child">{`${monthName}`}</div>
                <div className="header-week-range">{`${startDate} - ${endDate}`}</div>
              </div>
            ),
            dataIndex: `month${monthNumber}`,
            key: `month${monthNumber}`,
            width: "200px",
            onCell: (record) => ({
              onClick: () => {
                const monthData = record.monthly_data.find(
                  (option) =>
                    option.month === monthNumber && option.year === year
                );

                monthData
                  ? handleCellClick(
                      monthData.month,
                      record.id,
                      monthData.year,
                      "update",
                      record.name,
                      monthData.group_id,
                      monthName,
                      record.project_code,
                      record.id,
                      record.name,
                      monthData.recorded_by.firstName,
                      monthData.recorded_by.lastName
                    )
                  : handleCellClick(
                      monthNumber,
                      record.id,
                      year,
                      "add",
                      record.name,
                      null,
                      monthName,
                      record.project_code,
                      record.id,
                      record.name,
                      null,
                      "Not yet Recorded"
                    );
              },
            }),
            render: (_, record) => {
              const monthData = record.monthly_data.find(
                (option) => option.month === monthNumber && option.year === year
              );
              if (monthData) {
                const colorData =
                  monthData.color_code != null
                    ? monthData.color_code
                    : "#B0B0B0";
                const textColor =
                  monthData.color_code != null ? "#000000" : "#000000";
                return monthData ? (
                  <Button
                    style={{
                      backgroundColor: `${colorData}`,
                      width: "100%",
                      borderRadius: "20px",
                      height: "40px",
                      color: `${textColor}`,
                    }}
                  >
                    Total Score :{" "}
                    {monthData
                      ? !isNaN(monthData.average_score)
                        ? monthData.average_score.toFixed(2)
                        : monthData.average_score
                      : null}
                  </Button>
                ) : null;
              }
            },
          };
        }),
    ];

    if (entity === "Project") {
      return [
        {
          title: (
            <div className="project-code-column-header">
              <div style={{ fontSize: "16px" }}>
                {enumForAudit.labelForProjectCode}
              </div>
            </div>
          ),
          dataIndex: "project_code",
          key: "project_code",
          width: "200px",
          className: "project-code-column",
          sorter: (a: any, b: any) =>
            a.project_code.replace("STP_", "") -
            b.project_code.replace("STP_", ""),
        },
        {
          title: (
            <div className="project-code-column-header">
              <div style={{ fontSize: "16px" }}>
                {enumForAudit.labelForProjectName}
              </div>
            </div>
          ),
          dataIndex: "name",
          key: "id",
          width: "400px",
          className: "project-code-column",
          sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        },
        {
          title: (
            <div className="project-code-column-header">
              <div style={{ fontSize: "16px" }}>
                {enumForAudit.labelForCustomerName}
              </div>
            </div>
          ),
          dataIndex: "customer.name",
          key: "customer.name",
          width: "400px",
          className: "project-code-column",
          sorter: (a: any, b: any) =>
            a.customer.name.localeCompare(b.customer.name),
          render: (text, record) => {
            return record.customer ? record.customer.name : null;
          },
        },
        ...commonColumns,
      ];
    } else if (entity === "Customer") {
      return [
        {
          title: (
            <div className="project-code-column-header">
              <div style={{ fontSize: "16px" }}>
                {enumForAudit.labelForCustomerName}
              </div>
            </div>
          ),
          dataIndex: "name",
          key: "name",
          width: "400px",
          className: "project-code-column",
          sorter: (a: any, b: any) => a.name.localeCompare(b.name),
          render: (text, record) => {
            return record ? record.name : null;
          },
        },
        ...commonColumns,
      ];
    } else {
      return commonColumns;
    }
  };

  useEffect(() => {
    getUser();
    getFinancialYearData();
  }, [userId]);

  useEffect(() => {
    if (auditPsatData) {
      const results: any = auditPsatData.filter(
        (item: any) =>
          item?.name?.toLowerCase()?.includes(nameSearch.toLowerCase()) ||
          item?.project_code?.toLowerCase()?.includes(nameSearch.toLowerCase())
      );

      setFilterData(results);
      setIsFilter(false);
      setFilterTotalCount(results?.length);
    }
  }, [nameSearch, auditPsatData]);
  const getUser = async () => {
    try {
      const response = await AxiosConfig.get("users/current", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.data && response.data.data.user) {
        const userId = response.data.data.user.id;
        setUserId(userId);
        getPsatAuditData(userId);
      } else {
        console.error("User data not found in the API response");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const getFinancialYearData = async () => {
    try {
      const response = await AxiosConfig.get("/financialYears", {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data && response.data.data && response.data.data.years) {
        const finYearsData = response.data.data.years.map((finyears: any) =>
          new Date(finyears.start_date).getFullYear()
        );

        setFinancialYears(finYearsData);
      } else {
        console.error("User data not found in the API response");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const [description, setDescription] = useState("");
  useEffect(() => {
    getPsatAuditData(userId);
  }, []);

  const getPsatAuditData = async (userId: any) => {
    try {
      setLoading(true);
      const res = await AxiosConfig.get(
        `audit/${userId}?group_id=${id}&entity=${entity}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const tableData = res.data.data.projects;
      // We need unique Customer Names to be used in Customer Name filter as there can be multiple projects with same customer name
      if (entity === "Customer") {
        const arrayUniqueByKey = [
          ...new Map(
            res?.data?.data?.projects?.map((item) => [item.name, item])
          ).values(),
        ];
        const allCustomerName = arrayUniqueByKey?.map((proj: any) => {
          return { value: proj.id, label: proj.name };
        });
        setCustomerNames(allCustomerName);
      } else {
        const arrayUniqueByKey = [
          ...new Map(
            res?.data?.data?.projects?.map((item) => [item.customer.name, item])
          ).values(),
        ];
        const allCustomerName = arrayUniqueByKey?.map((proj: any) => {
          return { value: proj.id, label: proj.customer.name };
        });
        setCustomerNames(allCustomerName);
      }
      setAuditPsatData(tableData);
      if (customerNameFilter.length) {
        handleCustomerSelect(customerNameFilter);
      } else {
        setFilterData(tableData);
        setFilterTotalCount(tableData?.length);
      }
      const reviewObj: any = await AxiosConfig.get(`reviewSettings/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const reviewDataArray = reviewObj.data.data.reviewData;
      reviewDataArray != null && reviewDataArray != undefined
        ? setAccesToAll(reviewDataArray[0].access_to_all == true ? 1 : 0)
        : 0;
      const description = reviewDataArray[0].description;
      setDescription(description);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error fetching Audit WSR data:", error);
    }
  };

  const [recordedByInfo, setRecordedByInfo] = useState<{
    recorded_by_firstName: string | null;
    recorded_by_lastName: string | null;
  }>({ recorded_by_firstName: null, recorded_by_lastName: null });

  const handleCellClick = (
    month_no: any,
    project_id: any,
    year: any,
    func_type: any,
    project_name: any,
    group_id: any,
    monthName: any,
    project_code: any,
    customer_id: any,
    customer_name: any,
    recorded_by_firstName: any,
    recorded_by_lastName: any
  ) => {
    setYear(year);
    setMonth(month_no);
    setProjectId(project_id);
    setResGroupId(group_id);
    setMonthName(monthName);
    if (entity == "Customer") {
      setCustomerId(customer_id);
      setCustomerName(customer_name);
    }

    if (
      (year === currentYear && month_no > currentMonth) ||
      year > currentYear
    ) {
      errorNotification(
        "Sorry, data entry for the upcoming month is not allowed"
      );
    } else {
      setIsModalOpen(true);
    }
    setFuncType(func_type);
    setProjectName(project_name);
    setProjectCode(project_code);

    setRecordedByInfo({
      recorded_by_firstName,
      recorded_by_lastName,
    });
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    getPsatAuditData(userId);
  };

  const handleOnChange = (event) => {
    setNameSearch(event.target.value);
  };

  const handleCustomerSelect = (customer: any) => {
    if (customer?.length > 0) {
      setCustomerNameFilter(customer);
      setIsFilter(true);
      const selectedCustomerName = customer?.map((cust) => {
        return cust.label;
      });
      if (entity === "Customer") {
        const data = auditPsatData?.filter((checkData: any) =>
          selectedCustomerName.includes(checkData.name)
        );
        setFilterData(data);
      } else {
        const data = auditPsatData?.filter((checkData: any) =>
          selectedCustomerName.includes(checkData.customer.name)
        );
        setFilterData(data);
      }
    } else {
      setCustomerNameFilter([]);
      setFilterData(auditPsatData);
      setIsFilter(false);
    }
  };

  const monthLabels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const selectedYear = year.toString();

  const csvData = filterData.map((entity: any) => {
    const rowData: any = {};

    if ("project_code" in entity) {
      rowData.project_code = entity.project_code;
      rowData["Project name"] = `"${entity.name}"`;
      rowData["Customer name"] = `"${entity.customer.name}"`;
    } else {
      rowData["Customer name"] = `"${entity.name}"`;
    }

    monthLabels.forEach((monthLabel) => {
      rowData[monthLabel] = "";
    });

    if (entity.monthly_data) {
      entity.monthly_data.forEach((monthData: any) => {
        if (monthData.year.toString() === selectedYear) {
          const monthLabel = monthLabels[monthData.month - 1];
          rowData[monthLabel] = monthData.average_score;
        }
      });
    }

    return rowData;
  });

  let csvHeader: string[] = [];

  if (filterData.length > 0) {
    const entity = filterData[0] as { monthly_data?: any[] };

    if ("project_code" in entity) {
      csvHeader = [
        "project_code",
        "Project name",
        "Customer name",
        ...monthLabels,
      ];
    } else {
      csvHeader = ["Customer name", ...monthLabels];
    }
  }

  let csvContent = csvHeader.join(",") + "\n";
  csvData.forEach((row) => {
    const values = csvHeader.map((header) => {
      return row[header] !== undefined
        ? row[header]
        : row[header] === 0
        ? 0
        : "";
    });
    csvContent += values.join(",") + "\n";
  });

  const handleMonthSelect = (month) => {
    if (month) {
      setFilteredColumns([month, ...fixedColumns]);
    } else {
      setFilteredColumns([...fixedMonthColumns, ...fixedColumns]);
    }
  };

  //It will filter out the month columns from all the columns
  useEffect(() => {
    if (filteredColumns) {
      if (filteredColumns.length && financialYears.length > 0) {
        const temp = allColumns.filter((f) => filteredColumns.includes(f.key));
        if (financialYears.length > 0) {
          setTableColumns(temp);
        }
      } else {
        if (financialYears.length > 0) {
          setTableColumns(generateColumns() || []);
        }
      }
    }
    return () => {};
  }, [filteredColumns]);

  useEffect(() => {
    //Condition 1 : If month filter is not enabled and we have changed the year
    //Condition 2 (else condition): If month filter is enabled and we have changed the year so we need to filter out the month's data from the new selected year
    if (!filterMonth && financialYears.length > 0) {
      setTableColumns(generateColumns() || []);
      setAllColumns(generateColumns() || []);
    } else {
      const newYearColumns = financialYears.length > 0 ? generateColumns() : [];
      const temp =
        (newYearColumns as { key: string }[])?.filter((f) =>
          filteredColumns.includes(f.key)
        ) || [];
      setTableColumns(temp);
      setAllColumns(generateColumns() || []);
    }
  }, [year, financialYears]);

  return (
    <div>
      <Row gutter={24}>
        <Col xl={6} xs={24}>
          <span className="back-button" onClick={() => history.push("/audits")}>
            <img src={arrow} />
            {app.AuditstextForBack}
          </span>
        </Col>
      </Row>
      <Row className="audit-wsr-search">
        <Col>
          <div className="searchForm" style={{ width: "200px" }}>
            <Input.Search
              allowClear
              onChange={handleOnChange}
              placeholder="Search"
            />
          </div>
        </Col>
        <Col className="column2-style z-11 filter-checkbox-width">
          <ReactMultiSelectCheckboxes
            options={customerNames}
            onChange={(e: any) => {
              handleCustomerSelect(e);
            }}
            placeholderButtonLabel="Customer Name"
            value={customerNameFilter}
          />
        </Col>
        <Col>
          <div>
            <span className="div-padding">Year : </span>
            <Select
              value={year}
              className="year-dropdown"
              onChange={(value: any) => {
                setYear(value);
              }}
            >
              {financialYears.map((year: any, index: any) => (
                <Option key={index} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col>
          <div>
            <span className="div-padding1">Month : </span>
            <Select
              className="week-dropdown"
              onChange={(value) => {
                setFilterMonth(value);
                handleMonthSelect(value);
              }}
              options={optionsMonths}
              allowClear
              value={filterMonth}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="description">
            <label>Description: {description}</label>
          </div>
        </Col>
      </Row>
      <Row className="mt-audit-12 mb-10">
        <Col xs={20} className="pos-relative">
          <div className="total-project-container-psat-csat">
            <label>
              {entity == "Project"
                ? enumForAudit?.labelForPojectCount
                : enumForAudit?.labelForCustomersCount}{" "}
              : {filterTotalCount}
            </label>
          </div>
        </Col>
        <Col xs={4}>
          <div className="float-right">
            <CSVLink
              data={csvContent}
              filename="audit_monthly_data.csv"
              className="exportTableButton-audit"
              title="Export Table"
            >
              <ExportOutlined className="exportOultineIcon" />
            </CSVLink>
          </div>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : (
        <Table
          className={"custom-table fixed-header"}
          dataSource={filterData.sort((a: any, b: any) =>
            a.project_code.localeCompare(b.project_code)
          )}
          columns={tableColumns}
          loading={loading}
          bordered
          pagination={false}
          scroll={{ y: `calc(100vh - 135px)` }}
          style={{ tableLayout: "fixed", overflow: "auto" }}
        />
      )}
      <div>
        {isModalOpen && (
          <SessionAudit
            response_group_id={response_group_id}
            group_id={id}
            project_id={project_id}
            is_month_report={true}
            week_no={null}
            month_no={month}
            year={year}
            func_type={func_type}
            project_name={projectName}
            project_code={projectCode}
            recorded_by={`${recordedByInfo.recorded_by_firstName ?? ""} ${
              recordedByInfo.recorded_by_lastName ?? ""
            }`}
            onClose={handleModalCancel}
            accessToAll={accessToAll}
            month_name={monthName}
            is_quarterly_report={false}
            quarterly_type={null}
            customer_id={customer_id}
            entity={entity}
            customer_name={customer_name}
          />
        )}
      </div>
    </div>
  );
};
export default AuditsPSAT;
