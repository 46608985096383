import { Tabs } from "antd";

import { SupersetEmbedComponent } from "../../../components";

const Reports = () => {
  const { TabPane } = Tabs;

  return (
    <Tabs className="BoldStyling" defaultActiveKey="1">
      <TabPane tab="Audits Dashboard" key="1">
        <SupersetEmbedComponent
          pageType="audits"
          isDepartmentEnabled={false}
          isSingleProject={false}
          projectId= {'null'}
        />
      </TabPane>
    </Tabs>
  );
};

export default Reports;
