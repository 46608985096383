import React from "react";
import { Drawer, Row, Col, Input, Modal, Radio, Button } from "antd";
import { Formik } from "formik";
import { useState, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import * as yup from "yup";
import ButtonReuse from "../../../components/Buttons/Button";
import { getToken } from "../../../auth";
import { notification } from "antd";
import { CurrencyInput } from "../../../components/Currency/MasterCurrency";
require("./getMonthlySlots.scss");
require("./viewslots.scss");
import "./editMonthData.scss";
import { useSelector } from "react-redux";
import { editMonthsData } from "../../../utils/enums";

const EditOverlay = (props: {
  visible?: boolean;
  onClose: () => void;
  recordHandler?: any;
  rateDetails?: any;
  id?: number;
}) => {
  let ratesID: any = {};
  let currID: any = {};
  let initialFormState = {};
  let cuError = {};

  props.rateDetails.map((value: any) => {
    const key = `rate_${value.currency_code}`.toLowerCase();
    ratesID[key] = value.currencyrates.id;
    currID[key] = value.id;
    initialFormState[key] = value?.currencyrates?.rate;
    cuError[key] = yup
      .number()
      .required()
      .typeError("Please enter a valid amount");
  });

  const costId = props.recordHandler?.id;

  var token = getToken();

  const costUpdate = (values: any) => {
    AxiosConfig.patch("financialmonths/" + costId, values, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.status == 200) {
          {
            costNotification();
            props.onClose();
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }
        }
      })
      .catch(function (error) {
        console.log(error.response.data);
        const notify = () => {
          notification.open({
            message: error.response.data.status.message,
          });
        };
        {
          notify();
        }
      });
  };

  const initialCost = {
    cost_per_stu: "",
  };
  const [newitems, setNewItems] = useState(initialFormState);
  const [newcost, setCostItem] = useState(initialCost);
  const [editmonthlymodal, setEditMonthlyModal] = useState(false);
  const [value, setValue] = useState(1);
  const [finalPayload, setFinalPayload] = useState(null);
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );

  const openEditMonthlyModal = (values: any) => {
    setFinalPayload(values);
    setEditMonthlyModal(true);
  };

  useEffect(() => {
    const initialCost = {
      cost_per_stu: props.recordHandler?.cost.replace("$", ""),
    };
    setCostItem(initialCost);

    setNewItems(initialFormState);
  }, [props]);

  const costNotification = () => {
    notification.open({
      message: "Cost Edited Successfully",
    });
  };

  const schema_cost = yup.object().shape({
    ...cuError,
    cost_per_stu: yup.number().typeError("Please enter a valid amount"),
  });

  function updateCurrentMonth(values) {
    const valuesKey: any = Object.keys(values);
    for (let key of valuesKey) {
      const currenecyID = ratesID[key];
      AxiosConfig.patch(
        "rates/" + currenecyID,
        { rate: values[key], isFutureMonths: value },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      ).then((response) => {
        if (response.status == 200) {
          {
            props.onClose();
          }
        }
      });
    }
    AxiosConfig.put(
      "purchaseorders/forexValue",
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((response) => {
        notification.open({
          message: response.data.status.message,
        });
        if (response.status == 200) {
          {
            props.onClose();
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }
        }
      })
      .catch(function (error) {
        notification.open({
          message: error.response.data.status.message,
        });
      });
  }

  return (
    <>
      <Drawer
        title="Edit Monthly Slots"
        placement="right"
        onClose={props.onClose}
        visible={props.visible}
        width="500"
        className="monthlySlotsDrawer"
      >
        <Formik
          enableReinitialize={true}
          initialValues={{ cost_per_stu: newcost.cost_per_stu }}
          validationSchema={schema_cost}
          onSubmit={(values) => {
            costUpdate(values);
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={12} className="viewSlotsCol">
                  <h4>{editMonthsData.headingForMonthName}</h4>
                  <Input value={props.recordHandler?.month} disabled />
                </Col>
                <Col span={12} className="viewSlotsCol">
                  <h4>{editMonthsData.headingForMonthsYear}</h4>
                  <Input value={props.recordHandler?.year} disabled />
                </Col>
              </Row>
            </form>
          )}
        </Formik>
        {Object.keys(ratesID).length! > 0 ? (
          <>
            <Formik
              enableReinitialize={true}
              initialValues={newitems}
              validationSchema={schema_cost}
              onSubmit={async (values: any) => {
                openEditMonthlyModal(values);
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col span={24}>
                      <h3 className="slots-title mt-20">
                        {editMonthsData.headingForBaseCurrency}
                        {currencySymb?.currency_code} (
                        {currencySymb?.currency_symbol})
                      </h3>
                    </Col>
                    <Col span={24}>
                      <h4 className="view">
                        {editMonthsData.headingForForexRates}
                      </h4>
                    </Col>

                    <CurrencyInput
                      monthId={costId}
                      type="edit"
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                    />
                    <Col span={24} className="viewSlotsCol">
                      <ButtonReuse
                        type="primary"
                        className="primary-btn submitBtn"
                        htmlType="submit"
                        value="Save"
                      ></ButtonReuse>
                      <ButtonReuse
                        onClick={props.onClose}
                        value="Cancel"
                        className="closeSlotBtn"
                      ></ButtonReuse>
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          </>
        ) : (
          ""
        )}
        {Object.keys(ratesID).length! < 1 ? (
          <Formik
            enableReinitialize={true}
            initialValues={newitems}
            validationSchema={schema_cost}
            onSubmit={async (values: any) => {
              const valuesKey: any = Object.keys(values);
              for (let key of valuesKey) {
                AxiosConfig.post(
                  "rates/",
                  {
                    month_id: costId,
                    currency_id: currID[key],
                    rate: values[key],
                  },
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  }
                )
                  .then((response) => {
                    if (response.status == 200) {
                      {
                        props.onClose();
                        setTimeout(function () {
                          window.location.reload();
                        }, 1000);
                      }
                    }
                  })
                  .catch(function (error) {
                    console.log(error.response.data);
                    const notify = () => {
                      notification.open({
                        message: error.response.data.status.message,
                      });
                    };
                    {
                      notify();
                    }
                  });
              }
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col span={24}>
                    <h4 className="slots-title mt-20">
                      {editMonthsData.headingForAddForexRate}
                    </h4>
                  </Col>

                  <CurrencyInput
                    monthId={costId}
                    type="edit"
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                  />
                  <Col span={24} className="viewSlotsCol">
                    <ButtonReuse
                      type="primary"
                      className="primary-btn submitBtn"
                      onClick={openEditMonthlyModal}
                      htmlType="submit"
                      value="Save"
                    ></ButtonReuse>
                    <ButtonReuse
                      onClick={props.onClose}
                      value="Cancel"
                      className="closeSlotBtn"
                    ></ButtonReuse>
                  </Col>
                </Row>
              </form>
            )}
          </Formik>
        ) : (
          ""
        )}
      </Drawer>
      <Modal
        className="forexRateModal"
        title=""
        visible={editmonthlymodal}
        onCancel={() => setEditMonthlyModal(false)}
        footer={null}
      >
        <h1>{editMonthsData.warningMsgToSave}</h1>
        <Radio.Group
          onChange={(e) => {
            setValue(e.target.value);
          }}
          value={value}
        >
          <Radio className="rate-radio-btn" value={1}>
            {editMonthsData.radioButtonFuture}
          </Radio>
          <Radio className="rate-radio-btn2" value={0}>
            {editMonthsData.radioButton}
          </Radio>
        </Radio.Group>
        <Button
          className="rate-save-btn"
          type="primary"
          onClick={() => {
            setEditMonthlyModal(false);
            updateCurrentMonth(finalPayload);
          }}
        >
          {editMonthsData.buttonForSave}
        </Button>
        <Button
          className="rate-cancel-btn"
          onClick={() => setEditMonthlyModal(false)}
        >
          {editMonthsData.buttonForCancel}
        </Button>
      </Modal>
    </>
  );
};

export default EditOverlay;
