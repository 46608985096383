import { GET_CUSTOMER_CATEGORY, GET_CUSTOMER_CATEGORY_ERROR } from "../../constants";
import { Axios } from "../../utils/axios";

export const getCustomersCategory = () => {
  return async (dispatch) => {
    try {
      const response = await Axios.get(`customerTypes`);
      if (response.status == 200) {
        dispatch({
          type: GET_CUSTOMER_CATEGORY,
          payload: response.data.data.customerTypes,
        });
      } else {
        dispatch({
          type: GET_CUSTOMER_CATEGORY_ERROR,
          payload: "Error: Data not found.",
        });
      }
    } catch (error: any) {
      dispatch({
        type: GET_CUSTOMER_CATEGORY_ERROR,
        payload: error,
      });
    }
  };
};
