import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import reducers from './reducers'


const middlewares = [ReduxThunk];
const store = createStore(reducers, {},composeWithDevTools(applyMiddleware(...middlewares)));

export type AppDispatch = typeof store.dispatch;
export default store;

