//import Action Types
import { GET_PRACTICES, GET_PRACTICES_ERROR } from "../../constants";

//Write down the initial state of the reducer
interface PracticeState {
    practices: Array<object>;
    error: string;
}

const INITIAL_STATE: PracticeState = {
    practices: [],
    error: "",
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_PRACTICES:
            return { ...state, practices: action.payload };
        case GET_PRACTICES_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};
