//import Action Types
import { GET_CURRENCY_RATES, GET_CURRENCY_RATES_ERROR } from "../../constants";

//Write down the initial state of the reducer
interface CurrencyState {
  data: Array<object>;
  error: string;
}

const INITIAL_STATE: CurrencyState = {
  data: [],
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CURRENCY_RATES:
      return { ...state, data: action.payload };
    case GET_CURRENCY_RATES_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
