import { Col, Input } from "antd";
import { useEffect, useState } from "react";
import { Axios } from "../../utils/axios";

export const CurrencyInput = (props: {
  monthId: any;
  type?: string;
  rateDetails?: any;
  handleChange?: any;
  errors?: any;
  values?: any;
}) => {
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    (async function () {
      try {
        setCurrencies([]);
        const res = await Axios.get(`rates/${props.monthId}?isBase=true`);

        if (res?.data && res?.data?.monthRates?.mastercurrencies.length > 0) {
          setCurrencies(res?.data?.monthRates?.mastercurrencies);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [props.monthId]);

  const getCurrency = () => {
    if (currencies && currencies.length > 0) {
      return currencies.map((value: any, key) => {
        return (
          <>
            <Col span={12} className="viewSlotsCol" key={key}>
              <h4>{value.currency_code}</h4>
              <Input value={value?.currencyrates?.rate} />
            </Col>
          </>
        );
      });
    }
    return <></>;
  };

  const editView = () => {
    if (currencies && currencies.length > 0) {
      return currencies.map((value: any, key) => {
        const code = value.currency_code.toLowerCase();
        const name = `rate_${code}`;
        return (
          <>
            <Col span={12} className="viewSlotsCol" key={key}>
              <h4>{value.currency_code}</h4>
              <Input
                value={props.values[name]}
                onChange={props.handleChange}
                name={name}
                type="text"
              />
              <p className="display_error">{props.errors[name]}</p>
            </Col>
          </>
        );
      });
    }
    return <></>;
  };

  return <>{props.type === "edit" ? editView() : getCurrency()}</>;
};
