import { Table, Row, Typography, Popover } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  convertCurrency,
  convertNumber
} from "../../../utils/util";
import "./RevenueTable.scss";
import { useSelector } from "react-redux";

const RevenueTable = (props: {
  revenueTableData?: any;
  idArray?: any;
  poTypes?: any;
  isLoading: boolean;
  setIsLoading: any;

}) => {
  let initialEarningsInfo = {
    month: "",
    fin_month_year: "",
    departments_id_array: [],
    po_type_id_array: [],
  };

  const { Text } = Typography;
  const { isLoading, setIsLoading } = props;
  const [revenueData, setRevenueData] = useState([]);
  const [getTotalRev, setTotalRev] = useState(0);
  const [getTotalStu, setTotalStu] = useState(0);
  const [getTotalMonthStu, setTotalMonthStu] = useState(0);
  const [getTotalEarned, SetTotalEarned] = useState(0);
  const [getTotalUtilized, SetTotalUtilized] = useState(0);
  const [getTotalPlannedUtilization, setTotalPlannedUtilization] = useState(0);
  const [getTotalPlannedEfficiency, setTotalPlannedEfficiency] = useState(0);
  const [getTotalRevenuePerStu, setTotalRevenuePerStu] = useState(0);
  const [getTotalMargin, SettotalMargin] = useState(0);
  const [getTotalMarginPer, SetTotalMarginPer] = useState(0);
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);

  const history = useHistory();

  useEffect(() => {
    getData();
  }, [props.revenueTableData, currencyRate]);

  let po_id: any = [];

  props.poTypes.forEach((po: any) => {
    po_id.push(po.id);
  });

  initialEarningsInfo.po_type_id_array = po_id;
  const getData = async () => {
    setIsLoading(true);
    let obj: any = [];
    var totalRev = 0;
    var totalStu = 0;
    var totalAlloc = 0;
    var totalPlanned = 0;
    var totalEarned = 0;
    var totalExecutionPer = 0;
    var totalUtilized = 0;
    var totalUtilizedper = 0;
    var totalMargin = 0;
    var totalMarginPer = 0;
    var totalPlannedUtilization = 0;
    var TotalPlannedEfficiency = 0;
    var TotalRevenuePerStu = 0;

    props.revenueTableData.map((data: any) => {
      obj.push({
        id: data.id,
        Month_Year: `${data.month.substr(0, 3)} ${data.year}`,
        Month: data.month,
        Year: data.year,
        Month_Status: data.month_status,
        Earned: convertCurrency(data?.earned,currencyRate),
        Stus_allocated: convertNumber(data.stus_allocated, 2),
        Stus_earned: convertNumber(data.stus_earned, 2),
        Stus_utilized: convertNumber(data.stus_utilized, 2),
        Monthallocated_Stu: convertNumber(data.Monthallocated_Stu, 2),
        plannedUtilizationPercentage: `${convertNumber(
          (data.Monthallocated_Stu / data.stus_allocated) * 100,
          2
        )} % `,
        plannedEfficiency:
          convertNumber((data.stus_earned / data.stus_allocated) * 100, 2) +
          "%",
        revenuePerStus: convertCurrency(data.earned / data.stus_allocated,currencyRate),
        planned_efficiency:
          convertNumber(
            (data.Monthallocated_Stu / data.stus_allocated) * 100,
            2
          ) + "%",
        Execution_efficiency:
          convertNumber((data.stus_earned / data.stus_allocated) * 100, 2) +
          "%",
        Utilization_efficiency:
          convertNumber((data.stus_utilized / data.stus_allocated) * 100, 2) +
          "%",
        Margin: convertCurrency(data?.earned - data?.stus_allocated * data?.cost_per_stu,currencyRate),
        Margin_percentage:
          data.earned == 0
            ? 0 + "%"
            : convertNumber(
                ((data.earned - data.stus_allocated * data.cost_per_stu) /
                  data.earned) *
                  100,
                2
              ) + "%",
      });
       if (props.revenueTableData.length >= 0) {
      setIsLoading(false);
    }
      totalRev = totalRev + data.earned;
      totalStu = totalStu + data.stus_allocated;
      totalAlloc = totalAlloc + data.Monthallocated_Stu;
      totalPlanned =
        totalPlanned + (data.Monthallocated_Stu / data.stus_allocated) * 100;
      totalEarned = totalEarned + data.stus_earned;
      totalExecutionPer =
        totalExecutionPer + (data.stus_earned / data.stus_allocated) * 100;
      totalUtilized = totalUtilized + data.stus_utilized;
      totalUtilizedper =
        totalUtilizedper + data.stus_utilized / data.stus_allocated;
      totalMargin =
        totalMargin + data.earned - data.stus_allocated * data.cost_per_stu;
      totalMarginPer = (totalMargin / totalRev) * 100;
      totalPlannedUtilization = (totalAlloc / totalStu) * 100;
      TotalPlannedEfficiency = (totalEarned / totalStu) * 100;
      TotalRevenuePerStu = totalRev / totalStu;
    });

    const plannedUtilization = Number(totalPlannedUtilization);
    setRevenueData(obj);
    setTotalRev(totalRev);
    setTotalStu(totalStu);
    setTotalMonthStu(totalAlloc);
    SetTotalEarned(totalEarned);
    SetTotalUtilized(totalUtilized);
    setTotalPlannedUtilization(plannedUtilization);
    setTotalPlannedEfficiency(TotalPlannedEfficiency);
    setTotalRevenuePerStu(TotalRevenuePerStu);

    SettotalMargin(totalMargin);
    SetTotalMarginPer(totalMarginPer);

  };
  const onClickRow = (month: any, year: any) => {
    initialEarningsInfo.fin_month_year = year;
    initialEarningsInfo.month = month;
    initialEarningsInfo.departments_id_array = props.idArray;
    history.push({ pathname: "/monthlyearnings", state: initialEarningsInfo });
  };
  const columns = [
    {
      title: "MONTH",
      dataIndex: ["Month, Year", "Month_Year", "Month_Status"],
      key: "month",
      render: (text: any, record: any) => (
        <a
          onClick={() => {
            onClickRow(record["Month"], record["Year"]);
          }}
        >
          {record["Month_Year"]}
        </a>
      ),
    },
    {
      title: "REVENUE",
      dataIndex: "Earned",
      key: "earned",
      className: "tableContent",
    },
    {
      title: "EARNED STU's",
      dataIndex: "Stus_earned",
      key: "Stus_earned",
      className: "tableContent",
    },
    {
      title: "STU'S Available",
      dataIndex: "Stus_allocated",
      key: "stus_allocated",
      className: "tableContent",
    },
    {
      title: "PLANNED UTILIZATION",
      dataIndex: "Monthallocated_Stu",
      key: "Monthallocated_Stu",
      className: "tableContent",
    },
    {
      title: "ACTUAL UTILIZATION",
      dataIndex: "Stus_utilized",
      key: "Stus_utilized",
      className: "tableContent",
    },
    {
      title: (
        <Popover
          className="plannedUtilization"
          content="Utilized/Available"
          trigger="hover"
        >
          PLANNED UTILIZATION % (BU){" "}
        </Popover>
      ),
      dataIndex: "plannedUtilizationPercentage",
      key: "plannedUtilizationPercentage",
      className: "tableContent",
    },
    {
      title: (
        <Popover
          className="plannedEffeciency"
          content="Earning/Available"
          trigger="hover"
        >
          PLANNED EFFECIENCY % (BU){" "}
        </Popover>
      ),
      dataIndex: "plannedEfficiency",
      key: "plannedEfficiency",
      className: "tableContent",
    },
    {
      title: `${currencySymb?.currency_symbol} / STU's`,
      dataIndex: "revenuePerStus",
      key: "revenuePerStus",
      className: "tableContent",
    },

    {
      title: "MARGIN",
      dataIndex: "Margin",
      key: "margin",
      className: "tableContent",
    },
    {
      title: "MARGIN %",
      dataIndex: "Margin_percentage",
      key: "margin_percentage",
      className: "tableContent",
    },
  ];

  return (
    <div className="table-card revenue-table">
      {!isLoading &&
          <Row>
              <div className="tableOut noWordBreak" style={{ width: "100%" }}>
                  <Table
                    rowClassName={(record: any) =>
                      record.Month_Status === "0"
                        ? "PresentStatus"
                        : record.Month_Status === "1"
                        ? "FutureStatus"
                        : ""
                    }
                    rowKey="id"
                    columns={columns}
                    dataSource={revenueData}
                    bordered
                    className="talentTable"
                    pagination={false}
                    loading={isLoading}
                    summary={() => (
                      <Table.Summary fixed>
                        <Table.Summary.Row className="table-bold-rows">
                          <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                          <Table.Summary.Cell index={1} className="Total">
                            {" "}
                            <Text>
                              {convertCurrency(getTotalRev,currencyRate)}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={2} className="Total">
                            {" "}
                            <Text>{convertNumber(getTotalEarned, 2)}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={3} className="Total">
                            {" "}
                            <Text>{convertNumber(getTotalStu, 2)}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={4} className="Total">
                            {" "}
                            <Text>{convertNumber(getTotalMonthStu, 2)}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={5} className="Total">
                            {" "}
                            <Text>{convertNumber(getTotalUtilized, 2)}</Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={6} className="Total">
                            {" "}
                            <Text>
                              {convertNumber(getTotalPlannedUtilization, 2) + "%"}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={7} className="Total">
                            {" "}
                            <Text>
                              {convertNumber(getTotalPlannedEfficiency, 2) + "%"}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={8} className="Total">
                            {" "}
                            <Text>
                              {convertCurrency(getTotalRevenuePerStu,currencyRate)}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={9} className="Total">
                            {" "}
                            <Text>
                              {convertCurrency(getTotalMargin,currencyRate)}
                            </Text>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={10} className="Total">
                            {" "}
                            <Text>{convertNumber(getTotalMarginPer, 2) + "%"}</Text>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>
                    )}
                  />            
              </div>
          </Row>
        }
    </div>
  );
};

export default RevenueTable;
