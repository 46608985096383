import { Card, Row, Col, Select } from "antd";
import { useState, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import moment from "moment";
import { Bubble } from "react-chartjs-2";
import { projectByHour } from "../../../utils/enums";
import { randomIntFromInterval, getRandomColor } from "../../../utils/util";

const ProjectByHours = () => {
  const token = getToken();
  const { Option } = Select;

  const [projectHoursData, getProjectHoursData] = useState([]);
  const [projectHoursColor, getProjectHoursColor] = useState([]);
  const [timeSpan, setTimeSpan] = useState(30);
  let talentData: any = localStorage.getItem("talentid");
  talentData = JSON.parse(talentData);



  useEffect(() => {
    AxiosConfig.post(
      "talents/projectbyhours",
      {
        current_date: moment(new Date()).format("YYYY-MM-DD"),
        time_span: timeSpan,
        talent_id: Number(talentData["Talent_id"]),
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        const tempData: any = [];
        const tempColor: any = [];
        res.data.data.projectByHours.forEach((data: any) => {
          tempData.push({
            x: randomIntFromInterval(0, 30),
            y: randomIntFromInterval(0, 30),
            r:
              timeSpan === 30
                ? data.hours_spent
                : timeSpan === 90
                ? data.hours_spent / 3
                : data.hours_spent / 6,
            name: data.name,
            value: data.hours_spent.toFixed(2),
          });
          tempColor.push(getRandomColor());
        });
        getProjectHoursData(tempData);
        getProjectHoursColor(tempColor);
      })
      .catch((err) => console.log(err));
  }, [timeSpan]);
  const projectByHoursData = {
    datasets: [
      {
        label: "Project By Hours",
        data: projectHoursData,
        backgroundColor: projectHoursColor,
      },
    ],
  };
  const config = {
    type: "bubble",
    data: projectByHoursData,
    options: {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context: any) {
              const label = `${
                context.dataset.data[context.dataIndex].name
              } - ${context.dataset.data[context.dataIndex].value}`;
              return label;
            },
          },
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
    },
  };
  return (
    <Card className="ProjectByHoursCard">
      <Row>
        <Col span={12}>
          <h2>{projectByHour.headingForActiveProject}</h2>
        </Col>
        <Col span={12}>
          <Select
            defaultValue="30"
            style={{ width: "100%" }}
            onChange={(event: any) => setTimeSpan(Number(event))}
          >
            <Option value="30">{projectByHour.optionFor30}</Option>
            <Option value="90">{projectByHour.optionFor90}</Option>
            <Option value="365">{projectByHour.optionFor365}</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Bubble data={projectByHoursData} options={config.options} />
        </Col>
      </Row>
    </Card>
  );
};

export default ProjectByHours;
