import PasswordField from "../../components/FormInput/PasswordField";
import ButtonReuse from "../../components/Buttons/Button";
import logo from "../../assets/images/logo.svg";
import { Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { PoweredBy } from "../../viewgroups/index";
import { Row, Col } from "antd";
import Layout from "antd/lib/layout/layout";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Formik, ErrorMessage } from "formik";
import * as yup from "yup";
import { AxiosConfig } from "../../ApiConfig";
require("./Login.scss");
require("../../components/Button/Button.scss");


const ResetPassword = () => {
    const validationSchema = yup.object({
        newpassword: yup.string()
            .min(8, 'Password must be at least 8 characters')
            .required('New password is required'),
        confirmpassword: yup.string()
            .oneOf([yup.ref('newpassword'), null], 'Passwords should be same')
            .required('Confirm password is required'),
    });

    const [userId, setUserId] = useState("");
    let history = useHistory();
    // Inside your component
    useEffect(() => {
        const urlParams = new URLSearchParams(history.location.search);
        // Get specific parameter values
        const paramValue: any = urlParams.get('data');
        let userdata = JSON.parse(paramValue)
        setUserId(userdata.userId)
        localStorage.clear();
    }, []);
    return (
        <Fragment>
            <div className="login-wrapper">
                <Layout style={{ height: "100vh" }}>
                    <Row
                        justify="space-around"
                        align="middle"
                        style={{ height: "100vh", marginRight: "0px" }}
                    >
                        <Col xs={24} md={12}>
                            <div className="login-container">
                                <div className="logo">
                                    <img src={logo} alt="logo" />
                                </div>
                                <h5>Reset Password</h5>
                                <Formik
                                    initialValues={{ newpassword: "", confirmpassword: "" }}
                                    validationSchema={validationSchema}
                                    onSubmit={(values) => {
                                        AxiosConfig.post(`users/password/reset-password?userId=${userId}`, values)
                                            .then((response) => {
                                                console.log("response =>", response);
                                                if (response.status == 200) {
                                                    history.push("/login");
                                                }
                                            })
                                            .catch(function (error) {
                                                console.log(error?.response?.data?.status?.message);
                                            });
                                    }}

                                >
                                    {({ handleSubmit, handleChange, values, }) => (

                                        <form onSubmit={handleSubmit}>
                                            <PasswordField
                                                onChange={handleChange}
                                                value={values.newpassword}
                                                name="newpassword"
                                                placeholder="Enter new password"
                                                className="password--input"
                                                iconRender={(visible: any) =>
                                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                                }
                                            />


                                            <ErrorMessage className="display_error" name="newpassword" component="div" />
                                            <PasswordField
                                                onChange={handleChange}
                                                value={values.confirmpassword}
                                                name="confirmpassword"
                                                placeholder="Confirm new password"
                                                className="password--input"
                                                iconRender={(visible: any) =>
                                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                                }
                                            />

                                            <ErrorMessage className="display_error" name="confirmpassword" component="div" />
                                            <div className="d-flex justify-content-between" >
                                                <div>
                                                    <ButtonReuse
                                                        type="primary"
                                                        className="primary-btn mt20"
                                                        htmlType="submit"
                                                        value="Submit"
                                                    ></ButtonReuse>
                                                </div>

                                            </div>
                                        </form>
                                    )}

                                </Formik>


                            </div>
                        </Col>
                    </Row>
                    <PoweredBy></PoweredBy>
                </Layout>
            </div>

        </Fragment>
    );


};
export default ResetPassword;
