import { Table, Input, Row, Col } from "antd";
import { useState, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import editImage from "../../../assets/images/edit.png";
import { Button } from "antd";
import dayjs from "dayjs";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import Loader from "../../../components/Loader/Loader";
import { Buttons } from "../../../utils/enums";
require("./Users.scss");

const Users = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [userId, setUserId] = useState("");
  var token = getToken();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data) {
      const normalizedSearch = nameSearch.trim().toLowerCase().replace(/\s+/g, " ");
      const results = data.filter((item : any) => {
        const fullName = `${item.First_name.toLowerCase()} ${item.Last_name.toLowerCase()}`;
        return (
          fullName.includes(normalizedSearch) ||
          item.First_name.toLowerCase().includes(normalizedSearch) ||
          item.Last_name.toLowerCase().includes(normalizedSearch) ||
          item.Email_id.toLowerCase().includes(normalizedSearch) ||
          item.Department.toLowerCase().includes(normalizedSearch) ||
          item.Project.toLowerCase().includes(normalizedSearch)
        );
      }
      );
      setFilterData(results);
    }
  }, [nameSearch, data]);

  const getDate = (value: any) => {
    if (value === null) {
      return "Never logged on";
    } else {
      return dayjs(value).format("YYYY/MM/DD h:mm A");
    }
  };

  const getData = async () => {
    await AxiosConfig.get("/users", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      if (res.data.length >= 0) {
        setIsLoading(false);
      }
      setData(
        res.data.map(
          (row: {
            firstName: any;
            lastName: any;
            id: any;
            email: any;
            lastLogin: any;
            departments: any;
            projects: any;
          }) => ({
            First_name: row.firstName,
            Last_name: row.lastName,
            User_id: row.id,
            Name: row.firstName + row.lastName,
            Email_id: row.email,
            Last_login: getDate(row.lastLogin),
            Department:
              row.departments.length > 0
                ? row.departments
                    .map((row: { name: any }) => {
                      return row.name + " ";
                    })
                    .join(", ")
                : "No BU's assigned yet",
            Project:
              row.projects.length > 0
                ? row.projects
                    .map((row: { name: any }) => {
                      return row.name + " ";
                    })
                    .join(", ")
                : "No Projects assigned yet",
            key: row.id,
          })
        )
      );
      setFilterData(
        res.data.map(
          (row: {
            firstName: any;
            lastName: any;
            id: any;
            email: any;
            lastLogin: any;
            departments: any;
            projects: any;
          }) => ({
            First_name: row.firstName,
            Last_name: row.lastName,
            User_id: row.id,
            Email_id: row.email,
            Last_login: getDate(row.lastLogin),
            Department:
              row.departments.length > 0
                ? row.departments
                    .map((row: { name: any }) => {
                      return row.name + " ";
                    })
                    .join(", ")
                : "No BU's assigned yet",
            Project:
              row.projects.length > 0
                ? row.projects
                    .map((row: { name: any }) => {
                      return row.name + " ";
                    })
                    .join(", ")
                : "No Projects assigned yet",
            key: row.id,
          })
        )
      );
    });
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "[First_name, Last_name]",
      key: "name",
      width: 300,
      render: (text: any, record: any) => (
        <div>
          <p className="nameTable">
            {record["First_name"] + " " + record["Last_name"]}
          </p>
        </div>
      ),
      sorter: (a: any, b: any) => a.First_name.localeCompare(b.First_name),
    },
    {
      title: "EMAIL ID",
      dataIndex: "Email_id",
      key: "email",
      sorter: (a: any, b: any) => a.Email_id.localeCompare(b.Email_id),
      width: 200,
    },
    {
      title: "BU",
      dataIndex: "Department",
      key: "bu",
      width: 300,
    },
    {
      title: "PROJECTS",
      dataIndex: "Project",
      key: "projects",
      width: 400,
    },
    {
      title: "LAST LOGIN",
      dataIndex: "Last_login",
      key: "last_login",
      width: 200,
      render: (text: any) => (
        <div className="no-wrap">
          {text}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "User_id",
      key: "user_id",
      width: 100,
      render: (record: any) => (
        <>
          <div className="actionBtns">
            <span onClick={() => handleView(record)}>
              <img className="UserEditimage" src={editImage} />
            </span>
          </div>
        </>
      ),
    },
  ];
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const closeHandler = () => {
    setVisible(false);
  };

  const visibleHandler = () => {
    setVisible(true);
  };

  const handleOnChange = (event: any) => {
    setNameSearch(event.target.value);
  };

  const [visibleEdit, setVisibleEdit] = useState(false);

  const closeHandlerEdit = () => {
    setVisibleEdit(false);
  };

  const handleView = (record: any) => {
    setVisibleEdit(true);
    localStorage.setItem("UserId", record);
    setUserId(record);
  };

  return (
    
    <div className="table-card">
      <Row>
        <Col md={12} xs={24}>
          <div className="searchForm">
              <Input.Search
                allowClear
                onChange={handleOnChange}
                placeholder="Search by name / email / project name/ BU"
                className="searchInput"
              />
          </div>
        </Col>
        <Col md={12} xs={24}>
          <div className="addUserBtn mb15">
            <Button
              type="primary"
              onClick={visibleHandler}
              className="primary-btn"
            >
              {Buttons.labelForAddUserButton}
            </Button>
          </div>
        </Col>
      </Row>
      {visible &&
        <AddUser
          visible={visible}
          onClose={closeHandler}
          onCancelButton={closeHandler}
        />
      }
      <EditUser
        visible={visibleEdit}
        onClose={closeHandlerEdit}
        onCancelButton={closeHandlerEdit}
        userId={userId}
      />
      {/* Loader */}
      {isLoading === true ? (
          <Loader />
        ) : (
          <div className="tableOut noWordBreak">
        <Table
          columns={columns}
          dataSource={filterData}
          bordered
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30"],
          }}
          className="userTable"
        />
      </div>
        )}
      
    </div>
  );
};

export default Users;
