import { GET_PRACTICES, GET_PRACTICES_ERROR } from "../../constants";
import { Axios } from "../../utils/axios";

export const getPractices = () => {
    return async (dispatch) => {
        try {
            const response = await Axios.get(`practice`);

            if (response.status == 200) {
                dispatch({
                    type: GET_PRACTICES,
                    payload: response.data.data.practices,
                });
            } else {
                dispatch({
                    type: GET_PRACTICES_ERROR,
                    payload: "Error: Data not found.",
                });
            }
        } catch (error: any) {
            dispatch({
                type: GET_PRACTICES,
                payload: error,
            });
        }
    };
};
