import { Col, Image, Table, notification } from "antd";
import deleteImage from "../../../assets/images/delete.png";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import pdfPlaceholderImage from "../../../assets/images/pdfPlaceHolder.png";
import xlsxPlaceholderImage from "../../../assets/images/xlsx_thumbnail.png";
import docxPlaceholderImage from "../../../assets/images/docximage.png";
import { useEffect, useRef, useState } from "react";

require("./EditAttachments.scss");

const EditPoAttachments = (props: {
  images: any;
  poId?: any;
  attachmentsId: any;
}) => {
  const fileData: any = useRef({});

  useEffect(() => {
    getEarnings();
  }, []);

  var token = getToken();

  const notify = (message: string, description?: string, color?: string) => {
    notification.open({
      message,
      description,
      style: { color },
    });
  };

  const deleteHandler = (record: any) => {
    AxiosConfig.delete(`poearnings/attachments/${record}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.status.code === 200) {
          notify(res.data.status.message);
        }
      })
      .catch(function (error) {
        notify("", error.response.data.status.message, "red");
      });
  };

  const [image, setImage] = useState<any>([]);
  let image_arr: any = [];

  const getEarnings = () => {
    AxiosConfig.get("poearnings/" + props.poId, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const data = res.data.data.po_earn;
        data.sort(function (a: any, b: any) {
          return a.financialmonth_id - b.financialmonth_id;
        });

        const attachments = data.map((row) => row.po_earn_attachments).flat();
        attachments.forEach((row) => {
          getAttachments(row.attachment_id);
        });
      })
      .catch(() => {});
  };

  const getAttachments = (attachment_id: any) => {
    AxiosConfig.get("poearnings/attachments/" + attachment_id, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    }).then((res) => {
      let image_obj: any = {};
      const url: any = URL.createObjectURL(res.data);
      fileData["current"][url] = { data: res.data, attachment_id };
      image_obj["attachment_id"] = attachment_id;
      image_obj["url"] = url;
      image_arr.push(image_obj);

      const filteredImages = image_arr
        .filter((img) =>
          (props.attachmentsId || []).some(
            (attachmentId: string) => attachmentId === img.attachment_id
          )
        )
        .map((row: { attachment_id: any; url: any }) => ({
          image_id: row.attachment_id,
          image_url: row.url,
          key: row.attachment_id,
        }));

      setImage(filteredImages);
    });
  };
  const renderAttachment = (record: any, row: any) => {
    const fileType = row?.image_id?.split(".").pop();
    const fileName = row?.image_id?.split("^").pop().split(".")[0];

    let attachmentContent;

    if (fileType === "pdf") {
      attachmentContent = (
        <div className="pdf-placeholder">
          <img
            src={pdfPlaceholderImage}
            alt="PDF Placeholder"
            className="pdf-placeholder-image"
          />
        </div>
      );
    } else if (fileType === "xlsx") {
      attachmentContent = (
        <div className="xlsx-placeholder">
          <img
            src={xlsxPlaceholderImage}
            alt="XLSX Placeholder"
            className="xlsx-placeholder-image"
          />
        </div>
      );
    } else if (fileType === "docx") {
      attachmentContent = (
        <div className="docx-placeholder">
          <img
            src={docxPlaceholderImage}
            alt="DOCX Placeholder"
            className="docx-placeholder-image"
          />
        </div>
      );
    } else {
      attachmentContent = (
        <Col key={record} span={12}>
          <Image
            className="attachment-image"
            width={62}
            height={62}
            src={record}
            alt="Attachment"
          />
        </Col>
      );
    }

    return (
      <div className="attachment">
        {attachmentContent}
        <text>{fileName}</text>
      </div>
    );
  };

  const columns = [
    {
      title: "Attachments",
      dataIndex: "image_url",
      key: "image_url",
      render: renderAttachment,
    },
    {
      title: "Action",
      dataIndex: "image_id",
      key: "image_id",
      render: (record: any) => (
        <div className="actionBtnAttachment">
          <span>
            <img src={deleteImage} onClick={() => deleteHandler(record)} />
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        className="AttachmentsTable"
        columns={columns}
        dataSource={image}
        pagination={{ pageSize: 2 }}
      />
    </>
  );
};

export default EditPoAttachments;
