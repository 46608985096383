import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Drawer, Tabs} from "antd";
import { AxiosConfig } from "../../../ApiConfig";
import { FormikProps } from "formik";
import { getToken } from "../../../auth";
import { CreditNoteProps } from "../Customer/CreditNote";
import { EditCreditNoteSection } from "./EditCreditNoteSection";
import { EditCreditNoteApplication } from "./EditCreditNoteApplication";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
require("./AddCustomer.scss");

export interface CreditNotePO {
  id: number;
  name: string;
  number: string;
}

const EditOverlay = (props: {
  visible?: boolean;
  onClose: () => void;
  onCancelButton: () => void;
  creditNoteDetail: CreditNoteProps;
}) => {
  const token = getToken();
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState<string>("1");
  function callback(key: any) {
    setActiveKey(key);
  }
  const initialFormState = {
    currency_id: "",
    customer_name: "",
    credit_amount: 0,
    description: "",
    currency_symbol: "",
    issue_date:"",
  };

  type MyFormValues = {
    customer_id: number;
    credit_note: string;
    credit_amount: number;
    description: string;
    currency_id: number;
    files: [];
    currency_symbol: string;
    issue_date: moment.Moment | null;
  };

  const [creditNoteDetails, setCreditNoteDetails] = useState(initialFormState);
  const formikRef = React.useRef<FormikProps<MyFormValues>>(null);
  const [creditNotePos, setCreditNotePos] = useState<any[]>([]);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [editable, setEditable] = useState<boolean[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const getCreditDetailsApiCall = async () => {
    const res = await AxiosConfig.get(
      `creditnote/${props.creditNoteDetail?.id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (res.status === 200) {
      const initialFormState = {
        currency_id: res.data.data.creditNote.currency_id,
        customer_name: res.data.data.creditNote.customer.name ?? "",
        description: res.data.data.creditNote?.description ?? "",
        credit_amount: res.data.data.creditNote.credit_amount ?? 0,
        currency_symbol:
          res.data.data.creditNote?.mastercurrency?.currency_symbol ?? "",
        issue_date: res.data.data.creditNote?.issue_date ?? "",
      };

      setCreditNoteDetails(initialFormState);
      setCreditNotePos(res.data.data?.creditNote?.creditnote_pos || []);
      if (res.data.data.creditNote.creditnote_attachments?.length) {
        res.data.data.creditNote.creditnote_attachments.forEach(function (
          row: any
        ) {
          const attachment_id = row.attachment_id;
          getAttachments(attachment_id);
        });
      }
      setIsLoading(false)
    }
  };

  const onCancelHandler = () => {
    props.onCancelButton();
  };

  const handleDrawerClose = () => {
    if (formikRef.current) {
      formikRef.current?.resetForm();
    }
    props.onClose();
  };

  const showEditModal = () => {
    setIsModalVisibleEdit(true);
  };

  const [editImage, setEditImage] = useState<any>([]);
  let image_arr: any = [];
  let edit_image_arr: any = [];

  const getAttachments = async (attachment_id: any) => {
    try {
      const res = await AxiosConfig.get("creditnote/attachment/" + attachment_id, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob",
      });
      let image_obj: any = {};
      const url = URL.createObjectURL(res.data);
      image_obj["id"] = attachment_id;
      image_obj["url"] = url;
      edit_image_arr.push(image_obj);
      image_arr.push(url);
      setEditImage(edit_image_arr);
    } catch (error) {
      console.error("Error fetching attachments:", error);
    }
  };

  useEffect(() => {
    getCreditDetailsApiCall();
  }, []);

  return (
    <>
      <Drawer
        title="Credit Note"
        width={1300}
        visible={props.visible}
        onClose={handleDrawerClose}
        bodyStyle={{ paddingBottom: 80 }}
        className="add_creditNote"
      >
        <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={callback}>
          <TabPane tab="Credit Note Application" key="1">
            {
              (creditNoteDetails.currency_id && !isLoading) ?              
              <EditCreditNoteApplication
              creditNoteDetails={creditNoteDetails}
              onCancelHandler={onCancelHandler}
              creditNotePos={creditNotePos}
              setCreditNotePos={setCreditNotePos}
              editable={editable}
              setEditable={setEditable}
              creditNoteDetail={props.creditNoteDetail}
            />:<Loader/>
            }
           
          </TabPane>
          <TabPane tab="Edit credit Note" key="2">
            <EditCreditNoteSection
              creditNoteDetails={creditNoteDetails}
              showEditModal={showEditModal}
              setCreditNoteDetails={setCreditNoteDetails}
              isModalVisibleEdit={isModalVisibleEdit}
              setIsModalVisibleEdit={setIsModalVisibleEdit}
              editImage={editImage}
              onCancelHandler={onCancelHandler}
              creditNoteDetail={props.creditNoteDetail}
              onClose={props.onClose}
            />
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default EditOverlay;
