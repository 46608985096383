import { GET_INVOICE, GET_INVOICE_ERROR } from "../../constants";
import { Axios } from "../../utils/axios";

export const getAllInvoice = (poId: number) => {
  return async (dispatch) => {
    try {
      const response = await Axios.get(`/invoice/${poId}`);
      if (response.status == 200) {
        dispatch({
          type: GET_INVOICE,
          payload: response.data,
        });
      } else {
        dispatch({
          type: GET_INVOICE_ERROR,
          payload: "Error: Data not found.",
        });
      }
    } catch (error: any) {
      dispatch({
        type: GET_INVOICE_ERROR,
        payload: error,
      });
    }
  };
};
