import { Row } from "antd";
import "antd/dist/antd.css";

require("./CustomerClassification.scss");
import { useState } from "react";

import DragList from "../../../components/DragDropTable/dragList";

const CustomerClassification = () => {
  const [visible, setVisible] = useState(false);

  const index = 1;
  const closeHandler = () => {
    setVisible(false);
  };

  return (
    <>
      <Row className="customer-board">
        {<DragList index={index} visible={visible} onClose={closeHandler} />}
      </Row>
    </>
  );
};
export default CustomerClassification;
