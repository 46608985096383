import "antd/dist/antd.css";
import { notification, Row, Modal, Input, DatePicker} from "antd";
import { AxiosConfig } from "../../../ApiConfig";
import { InputField } from "../../../components";
import ButtonReuse from "../../../components/Buttons/Button";
import { EditCreditNote } from "../../../utils/enums";
import { Formik } from "formik";
import { getToken } from "../../../auth";
import UploadCreditAttachment from "./UploadAttachmentEditCredit";
import DeleteCreditAttachment from "./DeleteAttachmentCreditNote";
import * as yup from "yup";
import TextArea from "antd/lib/input/TextArea";
import { preventMinus } from "../../../utils/util";
import moment from "moment";
require("./AddCreditNote.scss");

export interface CreditNotePO {
  id: number;
  name: string;
  number: string;
}
const EditCreditNoteSection = (props) => {
  const {
    creditNoteDetails,
    showEditModal,
    isModalVisibleEdit,
    setIsModalVisibleEdit,
    onCancelHandler,
    editImage,
    creditNoteDetail,
    onClose,
  } = props;

  const token = getToken();

  const validationSchema = yup.object({
    credit_amount: yup.number().required("Please enter a valid amount"),
    description: yup.string().trim().required("Please enter the description"),
    issue_date: yup.date().nullable().required("Issue date is required"),
  });

  const openNotification = () => {
    notification.open({
      message: "Customer Edited Successfully",
    });
  };

 
  const handleCancelModalEdit = () => {
    setIsModalVisibleEdit(false);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        customer_name: creditNoteDetails.customer_name,
        credit_amount: creditNoteDetails.credit_amount,
        description: creditNoteDetails.description,
        issue_date: moment(creditNoteDetails.issue_date),
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (values?.credit_amount < creditNoteDetail?.adjusted_amount) {
          notification.error({
            message: "Error",
            description:
              "Credit amount is lesser than the total adjusted amount.",
            style: { color: "red" },
          });
        } else {
          const payload = {
            customer_name: values?.customer_name,
            credit_amount: values?.credit_amount,
            description: values?.description,
            issue_date: values?.issue_date,
          };

          try {
            const response = await AxiosConfig.put(
              `creditnote/${creditNoteDetail.id}`,
              payload,
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            if (response.status === 200) {
              openNotification();
              onClose();
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          } catch (error) {
            const errorMessage =
              (
                error as {
                  response?: { data?: { status?: { message?: string } } };
                }
              )?.response?.data?.status?.message || "Unknown error occurred";
            notification.error({
              message: "Error",
              description: errorMessage,
              style: { color: "red" },
            });
          }
        }
      }}
    >
      {({ handleSubmit, handleChange, values, errors,setFieldValue }) => {
        return (
          <form onSubmit={handleSubmit} className="add_project_form">
            <label>{EditCreditNote.labelForCustomerName}</label>
            <InputField
              placeholder="Enter the Customer Name"
              name="customer_name"
              onChange={handleChange}
              value={values.customer_name}
              disabled={true}
            ></InputField>
            <p className="display_error">{errors.customer_name}</p>

            <label>{EditCreditNote.labelForCreditAmount}</label>
            <Input
              placeholder="Enter the Credit Amount"
              name="credit_amount"
              onChange={(event) => {
                const inputValue = event.target.value;
                const twoDecimalRegex = /^\d+(\.\d{0,2})?$/;
                if (
                  twoDecimalRegex.test(inputValue) ||
                  inputValue === ""
                ) {
                  setFieldValue("credit_amount", inputValue);
                }
              }}
              onKeyPress={preventMinus}
              min={0}
              value={values.credit_amount}
              addonBefore={creditNoteDetails.currency_symbol}
              className="date-picker-input"
            ></Input>
            <p className="display_error">{errors.credit_amount}</p>

            <label>{EditCreditNote.labelForIssueDate}</label>
            <DatePicker
              className="date-picker-input"
              value={values.issue_date}
              onChange={(date) => {
                setFieldValue("issue_date",date);
              }}
            />
            <p className="display_error">{errors.issue_date}</p>

            <label>{EditCreditNote.labelForDescription} </label>
            <TextArea
              placeholder="Enter the Description"
              rows={5}
              name="description"
              onChange={handleChange}
              value={values.description}
              className="textarea-input"
            />
            <p className="display_error">{errors.description}</p>

            <label>{EditCreditNote.labelForAttachment} </label>
            <Row>
              <ButtonReuse
                value="Edit"
                type="submit"
                className="edit-image-button-credit"
                onClick={showEditModal}
              />
              <Modal
                title="Attachments"
                className="AttachmentsModal"
                visible={isModalVisibleEdit}
                onCancel={handleCancelModalEdit}
              >
                <UploadCreditAttachment CreditNoteID={creditNoteDetail?.id} />
                <DeleteCreditAttachment images={editImage} />
              </Modal>
            </Row>

            <div className="button-container">
              <ButtonReuse
                type="primary"
                className="primary-btn"
                htmlType="submit"
                value="Save"
              ></ButtonReuse>
              <ButtonReuse
                type="primary"
                className="primary-btn cancel--btn"
                value="Cancel"
                onClick={onCancelHandler}
              ></ButtonReuse>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export { EditCreditNoteSection };
