export const MONTH_OPTIONS = Object.freeze({
  APRIL: { value: "April", label: "April" },
  MAY: { value: "May", label: "May" },
  JUNE: { value: "June", label: "June" },
  JULY: { value: "July", label: "July" },
  AUGUST: { value: "August", label: "August" },
  SEPTEMBER: { value: "September", label: "September" },
  OCTOBER: { value: "October", label: "October" },
  NOVEMBER: { value: "November", label: "November" },
  DECEMBER: { value: "December", label: "December" },
  JANUARY: { value: "January", label: "January" },
  FEBRUARY: { value: "February", label: "February" },
  MARCH: { value: "March", label: "March" },
});
