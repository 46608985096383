import { Menu } from "antd";
import {
  AppstoreOutlined,
  BookOutlined,
  SolutionOutlined,
  ContactsOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  isAdmin,
  isBuHead,
  isProjectAccess,
  isDepartmentAccess,
} from "../../utils/util";
import { enumForSideMenu } from "../../utils/enums";
import PoweredBy from "../PoweredBy/PoweredBy";
import logo from "../../assets/images/logoNew.png";
require("./SideMenu.scss");

function SideMenu(props: {
  showAdmin?: any;
  showDash?: any;
  collapsed: boolean;
}) {
  const { SubMenu } = Menu;
  let key: any = localStorage.getItem("key");

  const { collapsed } = props;

  return (
    <>
      <Menu mode="inline" className="side-menu" defaultSelectedKeys={[key]}>
        {props.showDash && (
          <SubMenu key="sub" icon={<AppstoreOutlined />} title="Dashboard">
            <Menu.Item key="1-1">
              {enumForSideMenu.menuItemSales}
              <Link
                to={"/buDashboard"}
                onClick={() => localStorage.setItem("key", "1-1")}
              ></Link>
            </Menu.Item>
            <Menu.Item key="1-2">
              {enumForSideMenu.menuItemDelivery}
              <Link
                to={"/buDashboardDelivery"}
                onClick={() => localStorage.setItem("key", "1-2")}
              ></Link>
            </Menu.Item>
            <Menu.Item key="1-3">
              {enumForSideMenu.menuItemInvoice}
              <Link
                to={"/buDashboardInvoice"}
                onClick={() => localStorage.setItem("key", "1-3")}
              ></Link>
            </Menu.Item>
          </SubMenu>
        )}
        <SubMenu key="sub1" icon={<BookOutlined />} title="Projects">
          {(isAdmin() || isDepartmentAccess() || isProjectAccess()) && (
            <Menu.Item key="2-1">
              {enumForSideMenu.menuItemSnapshot}
              <Link
                to={"/snapshotproject"}
                onClick={() => localStorage.setItem("key", "2-1")}
              ></Link>
            </Menu.Item>
          )}

          <Menu.Item key="2">
            {enumForSideMenu.menuItemProjects}
            <Link
              to={"/projects"}
              onClick={() => localStorage.setItem("key", "2")}
            ></Link>
          </Menu.Item>
          <Menu.Item key="2-2">
            {enumForSideMenu.menuItemPurchaseOrder}
            <Link
              to={"/purchaseorders"}
              onClick={() => localStorage.setItem("key", "2-2")}
            ></Link>
          </Menu.Item>
          <Menu.Item key="2-3">
            {enumForSideMenu.menuItemExpenses}
            <Link
              to={"/expenses"}
              onClick={() => localStorage.setItem("key", "2-3")}
            ></Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu key="sub5" icon={<BookOutlined />} title="Audits">
          {props.showAdmin && (
            <Menu.Item key="5-1">
              {enumForSideMenu.menuItemSettings}
              <Link
                to={"/auditreview"}
                onClick={() => localStorage.setItem("key", "5-1")}
              ></Link>
            </Menu.Item>
          )}
          <Menu.Item key="5-2">
            {enumForSideMenu.menuItemReviews}
            <Link
              to={"/audits"}
              onClick={() => localStorage.setItem("key", "5-2")}
            ></Link>
          </Menu.Item>
          <Menu.Item key="5-3">
            {enumForSideMenu.menuItemReports}
            <Link
              to={"/reports"}
              onClick={() => localStorage.setItem("key", "5-3")}
            ></Link>
          </Menu.Item>
        </SubMenu>

        <SubMenu key="sub3" icon={<UsergroupAddOutlined />} title="Allocations">
          <Menu.Item
            key="8-1"
            title={enumForSideMenu.menuItemProjectAllocation}
          >
            {enumForSideMenu.menuItemProjectAllocation}
            <Link
              to={"/allocations"}
              onClick={() => localStorage.setItem("key", "8-1")}
            ></Link>
          </Menu.Item>
          <Menu.Item key="8-2" title={enumForSideMenu.menuItemTalentAllocation}>
            {enumForSideMenu.menuItemTalentAllocation}
            <Link
              to={"/TalentAllocation"}
              onClick={() => localStorage.setItem("key", "8-2")}
            ></Link>
          </Menu.Item>
        </SubMenu>

        {(isAdmin() || isBuHead()) && (
          <>
            <SubMenu
              key="sub4"
              icon={<UsergroupAddOutlined />}
              title="Customers"
            >
              <Menu.Item key="3">
                {enumForSideMenu.menuItemSummary}
                <Link
                  to={"/customers"}
                  onClick={() => localStorage.setItem("key", "3")}
                ></Link>
              </Menu.Item>
              <Menu.Item key="3.1">
                {enumForSideMenu.menuItemClassification}
                <Link
                  to={"/customerClassification"}
                  onClick={() => localStorage.setItem("key", "3.1")}
                ></Link>
              </Menu.Item>
              {isAdmin() && (
                <Menu.Item key="3.2">
                  {enumForSideMenu.menuItemCreditNote}
                  <Link
                    to={"/creditNote"}
                    onClick={() => localStorage.setItem("key", "3.2")}
                  ></Link>
                </Menu.Item>
              )}
            </SubMenu>
            <SubMenu key="sub2" icon={<SolutionOutlined />} title="Talent">
              <Menu.Item key="4.1">
                {enumForSideMenu.menuItemSnapshot}
                <Link
                  to={"/snapshot"}
                  onClick={() => localStorage.setItem("key", "4.1")}
                ></Link>
              </Menu.Item>
              <Menu.Item key="4.2">{enumForSideMenu.menuItemTrends}</Menu.Item>
              <Menu.Item key="4">
                {enumForSideMenu.menuItemTalentList}
                <Link
                  to={"/talent"}
                  onClick={() => localStorage.setItem("key", "4")}
                ></Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="5" icon={<ContactsOutlined />}>
              {enumForSideMenu.menuItemUsers}
              <Link
                to={"/users"}
                onClick={() => localStorage.setItem("key", "5")}
              ></Link>
            </Menu.Item>
          </>
        )}
      </Menu>
      {collapsed ? (
        <img src={logo} className="logo" alt="logo" />
      ) : (
        <PoweredBy />
      )}
    </>
  );
}
export default SideMenu;
