import { useState, useEffect } from "react";
import { Drawer, Row, Col, DatePicker, notification } from "antd";
import { AxiosConfig } from "../../../ApiConfig";
import Select from "react-select";
import "./AddTalent.scss";
import { Formik } from "formik";
import { InputField } from "../../../components";
import * as yup from "yup";
import ButtonReuse from "../../../components/Buttons/Button";
import { getToken } from "../../../auth";
import { addTalent } from "../../../utils/enums";
import moment from "moment";
require("./AddTalent.scss");

const AddTalent = (props: {
  visible?: any;
  onClose?: any;
  onCancelButton?: any;
}) => {
  const token = getToken();
  const dateFormat = "YYYY/MM/DD";
  const validationSchema = yup.object({
    firstName: yup.string().required("Please enter the first name"),
    lastName: yup.string().required("Please enter the last name"),
    email: yup
      .string()
      .required("Please enter the email")
      .email("Please enter valid email"),
    emp_id: yup.string().required("Please enter the employee id"),
    date_of_joining: yup
      .mixed()
      .required("Please enter the date of joining")
      .nullable(false),
    band_id: yup.string().required("Please select the band"),
    skill_id: yup.string().required("Please select the skill"),
    department_id: yup.string().required("Please select the department"),
  });

  const [bandOptions, setBandOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  // const [departmentOptions, setDepartmentOptions] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [secondarySkillOptions, setSecondarySkillOptions] = useState([]);
  const [practiceOptions, setPracticeOptions] = useState([]);

  const getBandData = async () => {
    await AxiosConfig.get("bands", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempArr: any = res.data.data.bands.map((band: any) => ({
          value: band.name,
          label: band.name,
          key: band.id,
        }));
        setBandOptions(tempArr);
      })
      .catch((err) => console.log(err));
  };
  const getSkillsData = async () => {
    await AxiosConfig.get("skills", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempArr: any = res.data.data.skills.map((skill: any) => ({
          value: skill.name,
          label: skill.name,
          key: skill.id,
        }));
        const sortedSkillsData = tempArr?.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        setSkillOptions(sortedSkillsData);
      })
      .catch((err) => console.log(err));
  };

  const getSecondarySkillsData = async () => {
    await AxiosConfig.get("skills/secondarySkills", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempArr: any = res.data.data.secondarySkills.map(
          (skill: any) => ({
            value: skill.name,
            label: skill.name,
            key: skill.id,
          })
        );
        setSecondarySkillOptions(tempArr);
      })
      .catch((err) => {
        throw err;
      });
  };

  const getPracticeData = async () => {
    await AxiosConfig.get("practice", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempArr: any = res.data.data.practices.map((practice: any) => ({
          value: practice.name,
          label: practice.name,
          key: practice.id,
        }));
        setPracticeOptions(tempArr);
      })
      .catch((err) => {
        throw err;
      });
  };

  const getDepatments = async () => {
    await AxiosConfig.get("departments", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      const tempDept: any = [];
      res.data.data.departments.map(
        (row: { name: any; id: any; flag: any }) => {
          if (row.flag == 1)
            tempDept.push({
              value: row.id,
              label: row.name,
            });
        }
      );
      const sortedDepartmentData = tempDept.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setDepartments(sortedDepartmentData);
    });
  };
  const onCancelHandler = () => {
    props.onCancelButton();
  };

  useEffect(() => {
    getBandData();
    getSkillsData();
    getSecondarySkillsData();
    getDepatments();
    getPracticeData();
  }, []);

  return (
    <>
      <Drawer
        title="Add Talent"
        width={700}
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="add_talent"
        closable={false}
        maskClosable={false}
      >
        <Formik
          initialValues={{
            emp_id: "",
            firstName: "",
            lastName: "",
            email: "",
            date_of_joining: "",
            band_id: "",
            skill_id: "",
            department_id: "",
            secondary_skills: [],
          }}
          validationSchema={validationSchema}
          onSubmit={(values: any) => {
            values = {
              ...values,
              date_of_joining: moment(values.date_of_joining).format(
                dateFormat
              ),
              secondary_skills: values.secondary_skills
                ? values.secondary_skills.map((skill: any) => ({
                    id: skill.value,
                    name: skill.label,
                  }))
                : [],
            };
            AxiosConfig.post("talents", values, {
              headers: { Authorization: `Bearer ${token}` },
            })
              .then((response) => {
                if (response.status == 200) {
                  const notify = () => {
                    notification.open({
                      message: response.data.status.message,
                    });
                  };
                  {
                    notify();
                  }
                  setTimeout(function () {
                    window.location.reload();
                  }, 1000);
                }
              })
              .catch(function (error) {
                const notify = () => {
                  notification.open({
                    message: "",
                    description: error.response.data.status.message,
                    style: { color: "red" },
                  });
                };
                {
                  notify();
                  for (const key in values) {
                    delete values[key];
                  }
                }
              });
          }}
        >
          {({
            handleSubmit,
            handleChange,
            errors,
            values,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col md={12} xs={24}>
                  <label>{addTalent.labelForFirstName}</label>
                  <InputField
                    placeholder="Enter First Name"
                    name="firstName"
                    onChange={handleChange}
                    value={values.firstName}
                    className="input--field"
                  ></InputField>
                  <p className="display_error">{errors.firstName}</p>
                </Col>
                <Col md={12} xs={24}>
                  <label>{addTalent.labelForLastName}</label>
                  <InputField
                    placeholder="Enter Last Name"
                    name="lastName"
                    onChange={handleChange}
                    value={values.lastName}
                    className="input--field"
                  ></InputField>
                  <p className="display_error">{errors.lastName}</p>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={24}>
                  <label>{addTalent.labelForEmailId}</label>
                  <InputField
                    placeholder="Enter Email ID"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    className="input--field"
                  ></InputField>
                  <p className="display_error">{errors.email}</p>
                </Col>
                <Col md={12} xs={24}>
                  <label>{addTalent.labelForEmployeeId}</label>
                  <InputField
                    placeholder="Enter Employee ID"
                    name="emp_id"
                    onChange={handleChange}
                    value={values.emp_id}
                    className="input--field"
                  ></InputField>
                  <p className="display_error">{errors.emp_id}</p>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={24}>
                  <label>{addTalent.labelForDateofJoining}</label>
                  <DatePicker
                    defaultValue={undefined}
                    format={dateFormat}
                    placeholder="Enter the Date of Joining"
                    name="date_of_joining"
                    onChange={(val) => {
                      setFieldValue("date_of_joining", val);
                    }}
                    className="input--field"
                  />
                  <p className="display_error">{errors.date_of_joining}</p>
                </Col>
                <Col md={12} xs={24}>
                  <div className="commonDivWidth">
                    <label>{addTalent.labelForBand}</label>
                    <Select
                      options={bandOptions}
                      onChange={(value: { key: any }) =>
                        setFieldValue("band_id", value.key)
                      }
                      name="band_id"
                      placeholder="Select the Band"
                      className="commonSelectdropdown"
                    />
                  </div>
                  <p className="display_error">{errors.band_id}</p>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={24}>
                  <div className="commonDivWidth">
                    <label>{addTalent.labelForPrimarySkill}</label>
                    <Select
                      options={skillOptions}
                      onChange={(value: { key: any }) =>
                        setFieldValue("skill_id", value.key)
                      }
                      name="skill_id"
                      placeholder="Select the Skill"
                      className="commonSelectdropdown"
                    />
                  </div>
                  <p className="display_error">{errors.skill_id}</p>
                </Col>
                <Col md={12} xs={24}>
                  <div className="commonDivWidth">
                    <label>{addTalent.labelForDepartment}</label>
                    <Select
                      options={departments}
                      onChange={(data) => {
                        setFieldValue("department_id", data.value);
                      }}
                      name="department_id"
                      placeholder="Select the Department"
                      className="commonSelectdropdown"
                    />
                  </div>
                  <p className="display_error">{errors.department_id}</p>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={24}>
                  <div className="commonDivWidth">
                    <label>Secondary Skills</label>
                    <Select
                      options={secondarySkillOptions}
                      isMulti
                      isClearable
                      onChange={(selectedOptions: any) =>
                        setFieldValue(
                          "secondary_skills",
                          selectedOptions.map((option: any) => ({
                            value: option.key,
                            label: option.label,
                          }))
                        )
                      }
                      name="secondary_skills"
                      placeholder="Select Secondary Skills"
                      className="secondarySkillsSelect"
                    />
                  </div>
                  <p className="display_error">{errors.secondary_skills}</p>
                </Col>
                <Col md={12} xs={24}>
                  <div className="commonDivWidth">
                    <label>Practice</label>
                    <Select
                      options={practiceOptions}
                      isClearable
                      onChange={(value) => {
                        setFieldValue("practice_id", value.key);
                      }}
                      name="practice"
                      placeholder="Select the Practice"
                      className="secondarySkillsSelect"
                    />
                  </div>
                </Col>
              </Row>
              <div className="button-container">
                <ButtonReuse
                  type="primary"
                  className="primary-btn"
                  htmlType="submit"
                  value="Add"
                ></ButtonReuse>
                <ButtonReuse
                  type="primary"
                  className="primary-btn cancel--btn"
                  value="Cancel"
                  onClick={() => {
                    resetForm();
                    onCancelHandler();
                  }}
                ></ButtonReuse>
              </div>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export default AddTalent;
