//import Action Types
import {
    GET_CUSTOMERS,
    GET_CUSTOMERS_ERROR,
  } from "../../constants";
  
  //Write down the initial state of the reducer
  interface CurrencyState {
    allCustomers: Array<object>;
    error: string;
  }
  
  const INITIAL_STATE: CurrencyState = {
    allCustomers: [],
    error: "",
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_CUSTOMERS:
        return { ...state, allCustomers: action.payload };
      case GET_CUSTOMERS_ERROR:
        return { ...state, error: action.payload };
  
      default:
        return state;
    }
  };
  