import React, { useState, useEffect } from "react";
require("./Audits.scss");
import { Table, Button } from "antd";
import { EditTwoTone } from "@ant-design/icons";
import AddReviewOverlay from "../Project/AddReviews";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import Loader from "../../../components/Loader/Loader";


const token = getToken();

const AuditsReview = () => {
    const [visible, setVisible] = useState<boolean>(false);
    const [auditData, setAuditData] = useState([]);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [editItem, setEditItem] = useState(null);
    const [loading, setLoading] = useState(true); 
  const columns = [
    {
      title: "Review Title",
      dataIndex: "review_type",
      key: "review_type",
    },
    {
      title: "Entity",
      dataIndex: "entity",
      key: "entity",
    },
    {
      title: "Interval",
      dataIndex: "interval",
      key: "interval",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (_, record) => (
        <EditTwoTone onClick={() => handleEdit(record)} />
      ),
    },
  ];

  useEffect(() => {
    const getAuditReview = async () => {
      try {
        setLoading(true);
        const res = await AxiosConfig.get("reviewSettings", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAuditData(res.data.data.reviewData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching audit data:", error);
        setLoading(false);
      }
    };

    getAuditReview();
  }, []);


  const handleEdit = (record) => {
    setEditItem(record);
    setEditMode(true);
    setVisible(true);
  };



  return (
    <div className="audits-review">
      <div style={{ marginBottom: "25px", textAlign: "right" }}>
        <Button
          type="primary"
          onClick={() => setVisible(true)}
          style={{
            backgroundColor: "#5C67E5",
            fontSize: "16px",
            height: "40px",
            width: "201px",
            borderRadius: "4px",
          }}
        >
          Add Review Form
        </Button>
        {visible && 
          <AddReviewOverlay
            visible={visible}
            onClose={() => {
              setEditItem(null);
              setEditMode(false);
              setVisible(false);
            }}
            onCancelButton={() => {
              setEditItem(null);
              setEditMode(false);
              setVisible(false);
            }}
            editMode={editMode}
            editItem={editItem}
            auditData={auditData}
            
          />
        }
      </div>

      {loading ? ( 
        <Loader/>
      ) :   (
      <Table
        className="custom-table audit-review-table"
        dataSource={auditData}
        columns={columns}
        bordered
        pagination={false}
        style={{ tableLayout: "fixed" }}
      />
      )}
    </div>
  );
};

export default AuditsReview;
