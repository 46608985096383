import { Button } from "antd";
require("../Button/Button.scss");
const ButtonReuse = (props: {
  value?: string;
  type?: any;
  htmlType?: any;
  className?: string;
  children?: any;
  iconRender?: any;
  onClick?: any;
  disabled?: any;
  size?: any;
  style?: any;
}) => {
  return (
    <Button
      type={props.type}
      htmlType={props.htmlType}
      className={props.className}
      onClick={props.onClick}
      disabled={props.disabled}
      size={props.size ?? "default"}
      style={props.style}
    >
      {props.value}
    </Button>
  );
};

export default ButtonReuse;
