import "antd/dist/antd.css";
import {
  Divider,
  Drawer,
  notification,
  Upload,
  Modal,
} from "antd";
import Select from "react-select";
import { AddProject } from "../../../utils/enums";
import { Formik } from "formik";
import * as yup from "yup";
import { AxiosConfig } from "../../../ApiConfig";
import { InputField } from "../../../components";
import ButtonReuse from "../../../components/Buttons/Button";
import { useState, useEffect, useRef } from "react";
import { getToken } from "../../../auth";
import { PlusOutlined } from "@ant-design/icons";
import { getLocation } from "../../../stores/actions/getLocationAction";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
require("./AddProject.scss");

const FormData = require("form-data");

const data = new FormData();

const AddOverlay = (props: {
  visible?: any;
  onClose?: any;
  onCancelButton?: any;
}) => {
  const validationSchema = yup.object({
    customer_id: yup
      .string()
      .trim()
      .required("Please select the customer name"),
    project_name: yup.string().trim().required("Please enter the project name"),
    associated_bu_id: yup.string().required("Please select the associated bu"),
    billable_option: yup.string().required("Please select the Billable Value"),
    geo_id: yup.string().required("please select the geo location"),
    billable_id: yup.string().required("please select the billable Option"),
  });

  const onCancelHandler = () => {
    props.onCancelButton();
  };
  const token = getToken();

  const dispatch: any = useDispatch();
  const geoLocationData = useSelector((store: any) => store.locations.location);
  const [customers, setCustomers] = useState([]);
  const [billableGeoOption, setBillableGeoOption] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const singleProjectOwnerActive = useRef(true);

  useEffect(() => {
    getCustomers();
    getLocation();
  }, []);

  useEffect(() => {
    dispatch(getLocation());
  }, [dispatch]);

  useEffect(() => {
    if (geoLocationData) {
      setLocationData(geoLocationData);
    }
  }, [geoLocationData]);

  useEffect(() => {
    const getBillableOption = async () => {
      try {
        const response = await AxiosConfig.get("billableentity", {
          headers: { Authorization: `Bearer ${token}` },
        });

        const billableOptions =
          response?.data?.data?.billableEntity?.map((billable_option) => ({
            value: billable_option.entity_name,
            label: billable_option.entity_name,
            key: billable_option.id,
          })) ?? [];

        setBillableGeoOption(billableOptions);
      } catch (error) {
        console.error("Error fetching billable options:", error);
      }
    };
    getBillableOption();
  }, []);

  const getCustomers = async () => {
    await AxiosConfig.get("customers", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setCustomers(res.data.data.customers);
    });
  };

  let obj: any = localStorage.getItem("Departments");
  let departments = JSON.parse(obj);
  let filteredBu = [];
  if (departments === null) {
    filteredBu = [];
  } else {
    filteredBu = departments;
  }

  const customerOptions = customers.map(function (customer: {
    name: any;
    label: any;
    id: any;
  }) {
    return {
      value: customer.name,
      label: customer.name,
      key: customer.id,
    };
  });

  const locationOptions = locationData.map(function (Locations: {
    location: any;
    id: any;
  }) {
    return {
      value: Locations.id,
      label: Locations.location,
    };
  });

  const billableValues = [
    { key: 1, name: "Billable" },
    { key: 0, name: "Non-Billable" },
  ];

  const billableOptions = billableValues.map(function (billableBU: {
    name: any;
    key: any;
  }) {
    return {
      value: billableBU.name,
      label: billableBU.name,
      key: billableBU.key,
    };
  });
  const associatedBuOptions = filteredBu.map(function (associatedBus: {
    name: any;
    label: any;
    id: any;
  }) {
    return {
      value: associatedBus.name,
      label: associatedBus.name,
      key: associatedBus.id,
    };
  });

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const [previewTitle, setPreviewTitle] = useState();
  const [files, setFiles] = useState<any>([]);

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handlePreview = async (file: any) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleAttachmentChange = ({ fileList }: any) => {
    setFiles(fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{AddProject.ButtonForUpload}</div>
    </div>
  );

  return (
    <>
      <Drawer
        title="Add Project"
        width={500}
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="add_project"
        closable={false}
        maskClosable={false}
      >
        <Formik
          initialValues={{
            customer_id: "",
            geo_id: "",
            project_name: "",
            associated_bu_id: "",
            billable_option: "",
            billable_id: "",
            files: [],
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            if (!singleProjectOwnerActive.current) {
              notification.open({
                message: "",
                description: "One project owner must be in active state",
                style: { color: "red" },
              });
            } else {
              for (let i = 0; i < files.length; i++) {
                data.append("files", files[i].originFileObj);
              }
              data.append("customer_id", values.customer_id);
              data.append("geo_id", values.geo_id);
              data.append("project_name", values.project_name);
              data.append("associated_bu_id", values.associated_bu_id);
              data.append("Location_option", values.associated_bu_id);
              data.append("billable_id", values.billable_id);
              data.append("billable_option", values.billable_option);
              AxiosConfig.post("projects", data, {
                headers: { Authorization: `Bearer ${token}` },
              })
                .then((response) => {
                  if (response.status == 200) {
                    const notify = () => {
                      notification.open({
                        message:
                          response.data?.status?.message ??
                          "Unknown error occurred",
                      });
                    };
                    {
                      notify();
                    }
                    setTimeout(function () {
                      window.location.reload();
                    }, 1000);
                  }
                })
                .catch(function (error) {
                  const notify = () => {
                    notification.open({
                      message: "",
                      description: error.response?.data?.status?.message
                        ? typeof error.response.data.status.message === "string"
                          ? error.response.data.status.message
                          : error.response.data.status.message.details[0].message.replaceAll(
                              '"',
                              ""
                            )
                        : "Unknown error occurred",
                      style: { color: "red" },
                    });
                  };
                  {
                    notify();
                    for (const key in values) {
                      data.delete(key);
                    }
                  }
                });
            }
          }}
        >
          {({
            handleSubmit,
            handleChange,
            errors,
            values,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit} className="add_project_form">
              <label>{AddProject.labelForCustomerName}</label>
              <Select
                options={customerOptions}
                onChange={(value: { key: any }) =>
                  setFieldValue("customer_id", value.key)
                }
                name="customer_id"
                placeholder="Select customer name"
              />

              <p className="display_error">{errors.customer_id}</p>

              <label>{AddProject.labelForProjectName} </label>

              <InputField
                placeholder="Enter Project Name"
                name="project_name"
                onChange={handleChange}
                value={values.project_name}
                className="input--field"
              ></InputField>
              <p className="display_error">{errors.project_name}</p>

              <label>{AddProject.labelForAssociatedBU} </label>
              <Select
                placeholder="Select Associated BU"
                options={associatedBuOptions}
                onChange={(value: { key: any }) =>
                  setFieldValue("associated_bu_id", value.key)
                }
                name="associated_bu_id"
              />
              <p className="display_error">{errors.associated_bu_id}</p>

              <label>{AddProject.labelForBillableorNonBillable}</label>
              <Select
                placeholder="Billable/Non-Billable"
                options={billableOptions}
                onChange={(value: { key: any }) =>
                  setFieldValue("billable_option", value.key)
                }
                name="billable_option"
              />
              <p className="display_error">{errors.billable_option}</p>
              <label>{AddProject.labelForBillableOption}</label>
              <Select
                placeholder="Billing Entity"
                options={billableGeoOption}
                name="billable_id"
                onChange={(value: { key: number }) => {
                  setFieldValue("billable_id", value.key);
                }}
              />
              <p className="display_error">{errors.billable_id}</p>

              <label>{AddProject.labelForGeography}</label>
              <Select
                placeholder="Select Location"
                options={locationOptions}
                onChange={(value: { value: number }) => {
                  setFieldValue("geo_id", value.value);
                }}
                name="Location_option"
              />
              <p className="display_error">{errors.geo_id}</p>
              <Upload
                listType="picture-card"
                fileList={files}
                onPreview={handlePreview}
                onChange={handleAttachmentChange}
                beforeUpload={() => false}
              >
                {uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
              <Divider />
              <div className="button-container">
                <ButtonReuse
                  type="primary"
                  className="primary-btn"
                  htmlType="submit"
                  value="Add"
                ></ButtonReuse>
                <ButtonReuse
                  type="primary"
                  className="primary-btn cancel--btn"
                  value="Cancel"
                  onClick={() => {
                    onCancelHandler();
                    resetForm();
                  }}
                ></ButtonReuse>
              </div>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export default AddOverlay;
