import React, { useState } from "react";
import { CustomerClassification  } from "../../utils/enums";
import "./ClassificationPopup.scss";
const ClassificationPopup = (props) => {
  const [reloadComponent, setReloadComponent] = useState(false);
  const handleYesClick = () => {
    props.onYesClick();
  };
  const handleNoClick = () => {
    props.onNoClick();
    setReloadComponent(true);
  };
  if (reloadComponent) {
    return null;
  }
  return (
    <div className="popup-modal">
      <div className="popup">
        <div className="popup-content">
          <h3 className="title">
            {CustomerClassification.confirmationPopupTitle}
          </h3>
          <p className="note">{CustomerClassification.editConfirmationNote}</p>
          <div className="buttons">
            <button className="yes" onClick={handleYesClick}>
              Yes
            </button>
            <button className="no" onClick={handleNoClick}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ClassificationPopup;
