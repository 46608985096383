//import Action Types
import { GET_FIN_YEAR, GET_FIN_YEAR_ERROR } from "../../constants";

//Write down the initial state of the reducer
interface CurrencyState {
  finYear: Array<object>;
  error: string;
}

const INITIAL_STATE: CurrencyState = {
  finYear: [],
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_FIN_YEAR:
      return { ...state, finYear: action.payload };
    case GET_FIN_YEAR_ERROR:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
