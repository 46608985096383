import { Input } from "antd";
import { Fragment } from "react";
import "./InputField.scss";

const InputField = (props: {
  name?: string;
  onChange?: any;
  value?: any;
  placeholder?: string;
  onBlur?: any;
  onKeyDown?: any;
  type?: string;
  prefix?: any;
  suffix?: any;
  className?: any;
  disabled?: any;
  addonBefore?: any;
}) => {
  const classes = "form-group__input " + props.className;

  return (
    <Fragment>
      <Input
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onKeyDown={props.onKeyDown}
        type={props.type}
        prefix={props.prefix}
        suffix={props.suffix}
        className={classes}
        disabled={props.disabled}
        addonBefore={props.addonBefore}
      />
    </Fragment>
  );
};
export default InputField;
