import { notification } from "antd";
import { getAdmin, getHr, getPm, getDepts, getProjectsAccess, getDepatmentAccess} from "./localStore";
import moment from "moment";
import Crypto from "crypto";
const defaultYear = process.env.REACT_APP_DEFAULT_START_YEAR as string;
export const defaultStartYear = defaultYear ? defaultYear : "2019";

export const decimalValue = 2;

export const randomIntFromInterval = (min, max) => {
  if (typeof max !== "number" || max <= min) {
    throw new Error("Invalid range for randomIntFromInterval");
  }

  const range = max - min + 1;
  const byteArray = new Uint32Array(1);
  let randomInt;

  do {
    crypto.getRandomValues(byteArray);
    randomInt = byteArray[0] >>> 0;
  } while (randomInt >= Math.pow(2, 32) - (Math.pow(2, 32) % range));

  return min + (randomInt % range);
};

export const getRandomColor = () => {
  const cryptoArray = new Uint8Array(3);
  crypto.getRandomValues(cryptoArray);

  const red = cryptoArray[0];
  const green = cryptoArray[1];
  const blue = cryptoArray[2];

  const randomColor = `rgb(${red}, ${green}, ${blue})`;
  return randomColor;
};

export const allowOnlyNumbers = (e) => {
  const re = /^[0-9]*(\.[0-9]{0,2})?$/;
  if (!e.target.value) {
    return "";
  }
  if (re.test(e.target.value)) {
    return e.target.value;
  }
  return false;
};

export const getTotalAmount = (amount, decimal, sign = true, symbol = "") => {
  symbol = getSymbol(symbol);
  sign = sign === false ? false : true;
  return sign === true
    ? symbol +
        amount
          .toFixed(decimal)
          .toString()
          .replace(/\B(?=(\d{3})+\b)/g, ",")
    : amount
        .toFixed(decimal)
        .toString()
        .replace(/\B(?=(\d{3})+\b)/g, ",");
};

export const replaceString = (val) => {
  return val.replace(/[,₹]/g, "");
};

export function removeCurrencySymbols(inputString) {
  const currencyRegex = /[₹$€£¥₽CA$SGDAEDMYR]/g;
  const stringWithoutCurrency = inputString.replace(currencyRegex, '');
  return parseFloat(stringWithoutCurrency || 0);
}

export const getTotal = (amount, decimal, sign = true) => {
  amount = typeof amount === "string" ? parseInt(amount) : amount;

  const formattedAmount = amount
    .toFixed(decimal)
    .toString()
    .replace(/\B(?=(\d{3})+\b)/g, ",");

  return sign
    ? formattedAmount
    : amount
        .toFixed(decimal)
        .toString()
        .replace(/\B(?=(\d{3})+\b)/g, ",");
};

export const getSymbol = (symbol: any) => {
  return symbol ? symbol : "₹";
};

export const formatCurrencyValue = (value: any, amount: any, symbol = "") => {
  let checkAmount =
    amount && typeof amount == "string" ? parseFloat(amount) : amount;
  symbol = getSymbol(symbol);
  if (isEmptyNumber(checkAmount)) {
    checkAmount = 0;
  }
  if (typeof value !== "undefined" && value !== null) {
    return value.toUpperCase() !== "INR" && value.toUpperCase() !== "₹"
      ? decimalCheck(amount, 2)
      : symbol + Intl.NumberFormat("en-IN").format(checkAmount.toFixed(0));
  } else {
    return symbol + Intl.NumberFormat("en-IN").format(checkAmount.toFixed(0));
  }
};

export const formatCurrencyValueinPO = (
  value: any,
  amount: any,
  symbol = ""
) => {
  let checkAmount =
    amount && typeof amount == "string" ? parseFloat(amount) : amount;
  symbol = getSymbol(symbol);
  if (isEmptyNumber(checkAmount)) {
    checkAmount = 0;
  }
  if (typeof value !== "undefined" && value !== null) {
    if (symbol === "₹") {
      return symbol + Intl.NumberFormat("en-IN").format(checkAmount.toFixed(0));
    } else {
      return symbol + Intl.NumberFormat("en-US").format(checkAmount.toFixed(2));
    }
  } else {
    return symbol + Intl.NumberFormat("en-US").format(checkAmount.toFixed(2));
  }
};

export const formatCurrencyValueDecimal = (
  value: any,
  amount: any,
  symbol = ""
) => {
  let checkAmount =
    amount && typeof amount == "string" ? parseFloat(amount) : amount;
  symbol = getSymbol(symbol);
  if (isEmptyNumber(checkAmount)) {
    checkAmount = 0;
  }
  if (value) {
    return value.toUpperCase() != "INR"
      ? decimalCheck(amount, 2)
      : symbol + Intl.NumberFormat("en-IN").format(checkAmount.toFixed(2));
  }
};

export function parseValue(value) {
  if (
    typeof value == "string" &&
    value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/)
  ) {
    return value; // Return the date-time string as is
  } else {
    const parsedValue = parseFloat(value);
    if (isNaN(parsedValue)) {
      return value; // Return the original string as a string type
    } else {
      return parsedValue; // Return the parsed value as a number type
    }
  }
}

export const checkBase = (value: any, amount: any) => {
  let checkAmount =
    amount && typeof amount == "string" ? parseInt(amount) : amount;
  if (isEmptyNumber(checkAmount)) {
    checkAmount = 0;
  }
  if (value) {
    return value.toUpperCase() != "INR"
      ? decimalCheck(amount, 2)
      : Intl.NumberFormat("en-IN").format(checkAmount.toFixed(2));
  }
};

export const decimalCheck = (amount, decimal, symbol = "") => {
  symbol = getSymbol(symbol);
  if (isEmptyNumber(amount)) {
    return `${symbol} 0`;
  } else {
    return getTotalAmount(amount, decimal);
  }
};

export const checkNull = (number) => {
  return number == Infinity || !number ? 0 : number;
};

export const checkNumber = (amount, decimal) => {
  if (isEmptyNumber(amount)) {
    return "$0.00";
  } else {
    return getTotalAmount(amount, decimal);
  }
};

export const convertNumberToAmounts = (amount, decimal) => {
  if (isEmptyNumber(amount)) {
    return 0;
  } else {
    return amount.toFixed(decimal);
  }
};

export const checkColor = (allocatedSTU, recognizeSTU, currentMonth) => {
  if (currentMonth === "Planned STU's") {
    const allocated = convertAmountToNumber(allocatedSTU);
    const recognized = convertAmountToNumber(recognizeSTU);

    return allocated === recognized
      ? "black"
      : "" || allocated < recognized - (20 * recognized) / 100
      ? "orange"
      : "" || allocated > recognized + (20 * recognized) / 100
      ? "red"
      : "black";
  }
};

export const convertNumberToAmount = (amount, decimal) => {
  if (isEmptyNumber(amount)) {
    return "$ 0.00";
  } else {
    return getTotalAmount(amount, decimal);
  }
};

export const convertNumber = (amount, decimal) => {
  if (isEmptyNumber(amount)) {
    return "0";
  } else {
    return getTotal(amount, decimal, false);
  }
};
export const removeCommas = (amount) => {
  if (isEmptyNumber(amount)) {
    return "0";
  } else {
    return amount.replace(/,/g, '');
  }
};
export const isEmptyNumber = (value) => {
  if (!value || value === Infinity || value == "-Infinity") {
    return true;
  }
  return false;
};
export const infinityCheck = (value) => {
  value = parseFloat(value);
  if (!value || value === Infinity || value == -Infinity) {
    return "0.00";
  }
  return value;
};

export const isInt = (value) => {
  return Number(value) === value && value % 1 === 0;
};

export const isFloat = (value) => {
  return Number(value) === value && value % 1 !== 0;
};

export const allocationCheck = (executed) => {
  const allocation = process.env.REACT_APP_ALLOCATION_PER as string;
  if (executed > allocation) {
    return false;
  }
  return true;
};

export const isAdmin = () => {
  if (getAdmin()) {
    return true;
  }
  return false;
};

export const isHr = () => {
  if (getHr()) {
    return true;
  }
  return false;
};

export const isPm = () => {
  if (getPm()) {
    return true;
  }
  return false;
};

export const isProjectAccess = () => {
  if (getProjectsAccess()) {
    return true;
  }
  return false;
};
export const isDepartmentAccess = () => {
  if (getDepatmentAccess()) {
    return true;
  }
  return false;
};

export const isBuHead = () => {
  const deptHead = getDepts();
  if (getPm() && deptHead && deptHead.length > 0) {
    return true;
  }
  return false;
};

export const errorNotification = (msg: string) => {
  notification.error({
    message: "Notification",
    description: msg,
    style: { color: "red" },
  });
};

export const successNotification = (msg: string) => {
  notification.success({
    message: "Notification",
    description: msg,
    style: { color: "green" },
  });
};

export const openNotification = (msg: string) => {
  notification.open({
    message: msg,
  });
};

export const convertNumberToPer = (val1, val2) => {
  if (!infinityCheck(val1 || val2)) {
    return "0.00";
  } else {
    return ((val1 / val2) * 100).toFixed(2);
  }
};

export const convertPerToNumber = (val1, val2) => {
  if (!infinityCheck(val1 || val2)) {
    return "0.00";
  } else {
    return ((val1 * val2) / 100).toFixed(2);
  }
};

export const convertAmountToNumber = (amount) => {
  if (amount) {
    let number = Number(String(amount).replace(/[^0-9.-]+/g, ""));
    return number;
  }
  return 0;
};

export const checkTalentStatus = (dateOfSep: any) => {
  let currentDate = new Date();
  let today = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const dateOfSeparation = new Date(dateOfSep);
  const lastDayOfMonth = new Date(
    dateOfSeparation.getFullYear(),
    dateOfSeparation.getMonth() + 2,
    0
  );
 

  if (dateOfSep) {
    if (Date.parse(dateOfSep) >= Date.parse(today.toString())) {
     return "Serving Notice period";
    } else if (
      Date.parse(dateOfSep) <= Date.parse(today.toString()) &&
      Date.parse(today.toString()) <= Date.parse(lastDayOfMonth.toString()) &&
      new Date(dateOfSep).getMonth() === new Date().getMonth() &&
      new Date(dateOfSep).getFullYear() === new Date().getFullYear()
    ) {
      return "Resigned";
    } else {
      return "Inactive";
    }
  } else {
    return "Active";
  }
};

export const checkVarience = (val1, val2, flag = true) => {
  return convertAmountToNumber(val1) === convertAmountToNumber(val2)
    ? "black"
    : "" ||
      convertAmountToNumber(val1) <
        convertAmountToNumber(val2) - (20 * convertAmountToNumber(val2)) / 100
    ? flag === true
      ? "red"
      : "orange"
    : "" ||
      convertAmountToNumber(val1) >
        convertAmountToNumber(val2) + (20 * convertAmountToNumber(val2)) / 100
    ? flag === true
      ? "orange"
      : "red"
    : "black";
};

export const getFinancialYear = () => {
  const cYear = moment().format("YYYY");
  const nextYear = parseInt(cYear) + 1;
  const years: any = [];
  for (let i: any = defaultStartYear; i <= nextYear; i++) {
    years.push(`${i - 1}-${i}`);
  }
  return years;
};

export const currentFinancialYear = () => {
  const year: any = new Date();
  return year.getMonth() < 3 ? year.getFullYear() - 1 : year.getFullYear();
};

export const currentFinancialYears = () => {
  const year: any = new Date();
  if (year.getMonth() < 3) {
    return `${year.getFullYear() - 1}-${year.getFullYear()}`;
  } else {
    return `${year.getFullYear()}-${year.getFullYear() + 1}`;
  }
};

export const currentFyYear = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1;
  const FinYear = currentMonth < 4 ? currentYear - 1 : currentYear;
  return `FY-${FinYear}-${(FinYear + 1).toString().substr(-2)}`;
};

export const convertFinancialYearToStartAndEnd = (years: string) => {
  if (years) {
    return [years.split("-")[0], years.split("-")[1]];
  }
  return [0, 0];
};

export const convertAmountToNumberForVariance = (val1: any, val2: any) => {
  if (
    ((convertAmountToNumber(val1) - convertAmountToNumber(val2)) /
      convertAmountToNumber(val2)) *
      100 ===
    Infinity
  )
    return "100";
  else
    return (
      ((convertAmountToNumber(val1) - convertAmountToNumber(val2)) /
        convertAmountToNumber(val2)) *
      100
    ).toFixed(2);
};

export const convertUnavailableHours = (val: any, val2: any) => {
  return val2 - val;
};

export const preventMinus = (e) => {
  if (e.code === "Minus") {
    e.preventDefault();
  }
};

export const getRandomColorForTeam = () => {
  const cryptoArray = new Uint8Array(3);
  window.crypto.getRandomValues(cryptoArray);

  const randomColor =
    "#" +
    Array.from(cryptoArray)
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");

  return randomColor;
};

export const getRandomColorForTeamSkill = () => {
  const randomBytes = Crypto.randomBytes(3);
  const randomColor = "#" + randomBytes.toString("hex");
  return randomColor;
};

export const randomIntFromIntervalEffort = () => {
  const randomBytes = Crypto.randomBytes(4);
  return Math.floor(
    (randomBytes.readUInt32BE(0) / 0xffffffff) * (500 - 0 + 1) + 0
  );
};

export const getRandomColorEffort = () => {
  const cryptoArray = new Uint8Array(3);
  crypto.getRandomValues(cryptoArray);

  const randomColor =
    "#" +
    Array.from(cryptoArray)
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");

  return randomColor;
};

export const calculateSoldPerStu = (valueSold, expense, stusSold) => {
  const valueSoldNum = parseFloat(valueSold);
  const directExpenseNum = expense ? parseFloat(expense) : 0.0;
  const stusSoldNum = parseFloat(stusSold);
  const soldPerStu = (valueSoldNum - directExpenseNum) / stusSoldNum;
  return isNaN(soldPerStu) ? "N/A" : soldPerStu.toFixed(2);
};

export const getRandomColorRevenue = () => {
  const randomNum = Crypto.randomInt(0, 16777215);
  const randomColor = "#" + randomNum.toString(16).padStart(6, "0");
  return randomColor;
};
/**
 *
 * @param token - JWT Token
 * below function is used to decode the token
 */
export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token?.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const DECIMAL_PLACES = 2;

export const calculateAdjustedAmount = (creditNotePos, credit_amount) => {
  const adjustedAmountSum = creditNotePos.reduce((total, item) => {
    const amount = !item.amount ? 0 : item.amount;
    return total + amount;
  }, 0);

  const formattedAdjustedAmount = adjustedAmountSum.toFixed(DECIMAL_PLACES);

  if (!credit_amount || credit_amount === 0) {
    return {
      totalAdjustedAmount: "0.00",
      adjustedAmount: formattedAdjustedAmount,
      pendingAmount: formattedAdjustedAmount,
    };
  }

  const totalAdjusted = (
    (formattedAdjustedAmount / credit_amount) *
    100
  ).toFixed(DECIMAL_PLACES);
  const pendingAmount = (credit_amount - formattedAdjustedAmount).toFixed(
    DECIMAL_PLACES
  );

  return {
    totalAdjustedAmount: totalAdjusted,
    adjustedAmount: formattedAdjustedAmount,
    pendingAmount: pendingAmount,
  };
};

export const calculateExpenseAdjustedAmount = (expensePos, amount) => {
  const adjustedAmountSum = expensePos.reduce((total, item) => {
    const amount = !item.amount ? 0 : item.amount;
    return total + amount;
  }, 0);

  const formattedAdjustedAmount = adjustedAmountSum.toFixed(DECIMAL_PLACES);

  if (!amount || amount === 0) {
    return {
      totalAdjustedAmount: "0.00",
      adjustedAmount: formattedAdjustedAmount,
      pendingAmount: formattedAdjustedAmount,
    };
  }

  const totalAdjusted = ((formattedAdjustedAmount / amount) * 100).toFixed(
    DECIMAL_PLACES
  );
  const pendingAmount = (amount - formattedAdjustedAmount).toFixed(
    DECIMAL_PLACES
  );

  return {
    totalAdjustedAmount: totalAdjusted,
    adjustedAmount: formattedAdjustedAmount,
    pendingAmount: pendingAmount,
  };
};
export const convertCurrency = (amount:number, rate:number) => {
  
  let convertedAmount:number = amount/rate;
  convertedAmount = isNaN(convertedAmount) || !isFinite(convertedAmount) ? 0:convertedAmount
  
  let cur_type = localStorage.getItem("cur_type");
  let isEditable = localStorage.getItem("isEditable");
  let currencyType = cur_type
      if(isEditable == "true"){
        currencyType = "INR"
      }
  
  return formatCurrency(convertedAmount,currencyType||'INR')
};

export const formatCurrency = (value: number, currencyCode: string): string => {
  let options: Intl.NumberFormatOptions = {
      style: "currency",
      currency: currencyCode
  };

  // In India, the numbering system is different, so we set minimumFractionDigits to 2
  if (currencyCode === "INR" || currencyCode === "₹") {
      options.minimumFractionDigits = 2;
  }

  // Create a NumberFormat object with the options
  const formatter = new Intl.NumberFormat(undefined, options);

  // Format the value using the NumberFormat object
  return formatter.format(value);
};

export const replaceCurrencyString = (val) => {
  return val.replace(/[^\d.]/g, "");
};

export const getCurrentFiscalYear=()=>{

// Get the current year
const currentYear = new Date().getFullYear();

// determine the fiscal year range
let fiscalYearStart = currentYear;
let fiscalYearEnd = currentYear + 1;

//  If current month is before October, adjust the fiscal year
if (new Date().getMonth() < 9) {  // Months are zero-based, so 9 is October
    fiscalYearStart = currentYear - 1;
    fiscalYearEnd = currentYear;
}
// Format the fiscal year as "2024-2025"
 return `${fiscalYearStart}-${fiscalYearEnd}`;
 
}