import { Table, Row, Col, Input, Typography, Tooltip } from "antd";
import { CSVLink } from "react-csv";
import { ExportOutlined } from "@ant-design/icons";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import warning from "../../../assets/images/WarningOutlined.png";
import {
  checkNull,
  decimalCheck,
  convertCurrency,
  replaceCurrencyString
} from "../../../utils/util";
import { useSelector } from "react-redux";
require("./PurchaseOrderList.scss");
import Loader from "../../../components/Loader/Loader";
import { purchaseOrderList } from "../../../utils/enums";
import Switch from "react-switch";
import UnarchiveImage_t from "../../../assets/images/unarchive_t.png";
import archiveImage_t from "../../../assets/images/archive_t.png";

const PurchaseOrderList = () => {
  const [departmentNames, setDepartmentNames] = useState([]); 
  const [poDetails, setPoDetails] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [poType, setPoType] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [csvData, setCsvData] = useState([]);
  const [filterTotalCount, setFilterTotalCount] = useState(0);
  const [userid, getuserid] = useState();
  const [totalSold, setTotalSold] = useState(0);
  const [totalStu, setTotalStu] = useState(0);
  const [unaccountedTotal, setUnaccountedTotal] = useState(0);
  const [unaccountedInvoiceTotal, setUnaccountedInvoiceTotal] = useState(0);
  const [leadLagTotal, setLeadLagTotal] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const currentTableData = useRef<any>([]);
  const { Column } = Table;
  const { Text } = Typography;
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
 
  const [filter, setFilters] = useState({});
  /* eslint-disable no-unused-vars */
  let TotalSold = 0;
  let TotalUnaccounted = 0;
  let TotalPlannedInvoice = 0;
  let TotalInvoiced = 0;
  let TotalUnaccountedInvoice = 0;
  let TotalSTU = 0;
  let TotalSoldStu = 0;
  let TotalDirectExpense = 0;

  let departmentArray: any = [];
  let obj: any = localStorage.getItem("Departments");
  let departments = JSON.parse(obj);
  let filteredBu = [];
  if (departments === null) {
    filteredBu = [];
  } else {
    filteredBu = departments;
  }
  const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);

  filteredBu.forEach(function (row: { id: any }) {
    return departmentArray.push(row.id);
  });
  const token = getToken();
  let history = useHistory();

  const inputDeptArray: any = {
    departments_id_array: departmentArray,
  };

  // const departmentOptions = filteredBu.map((row: { id: any; name: any }) => {
  //   return {
  //     text: row.name,
  //     value: row.name,
  //   };
  // });
  const getUser = async () => {
    try {
      const response = await AxiosConfig.get("users/current", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.data && response.data.data.user) {
        const user_id = response.data.data.user.id;
        getuserid(user_id);
      } else {
        console.error("User data not found in the API response");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  useEffect(() => {
    if (currencySymb?.currency_code && userid  && poDetails.length === 0 ){
      getData();
      getPoType();
    }
  }, [currencySymb?.currency_code, userid, poDetails.length]);
  useEffect(() => {
    getUser();
  }, []);
 
  useEffect(() => {
   getData()
  }, [currencyRate]);
  const onClickRow = (record: any) => {
    history.push("/projects/" + record);
    localStorage.setItem("projectsId", record);
  };

  const getPoType = async () => {
    await AxiosConfig.get("potypes", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setPoType(res.data.data.poTypes);
    });
  };
  
  const poTypeOptions = poType.map(function (poType: {
    type: any;
    text: any;
    id: any;
  }) {
    return {
      value: poType.type,
      text: poType.type,
    };
  });
 
 const calculateSum = (filteredData?) => {
    let totalValueInBase = 0;
    let totalLeadLag = 0;
    let totalUnaccounted = 0;
    let totalUnaccountedInvoice = 0;
    let totalSoldStu = 0;
    const data = filteredData ? filteredData : poDetails;
    data.forEach((item:any) => {
      totalValueInBase += parseFloat(item.sold_filter); 
      totalLeadLag += parseFloat(item.leadLag_filter) ; 
      totalSoldStu += parseFloat(replaceCurrencyString(item.sold_stu));
      totalUnaccounted += parseFloat(item.TotalUnaccounted);
      totalUnaccountedInvoice += parseFloat(item.TotalUnaccountedInvoice);
    })
    setTotalSold(totalValueInBase || 0);
    setLeadLagTotal(totalLeadLag || 0);
    setUnaccountedTotal(totalUnaccounted || 0);
    setUnaccountedInvoiceTotal(totalUnaccountedInvoice || 0);
    filteredData && setTotalStu(totalSoldStu || 0);
  }

  useEffect(() => {
    calculateSum();
  }, [poDetails]);

    function getUnaccountedExpenseTotal(dataSource)
      {
        const unaccountedExpTotal =  dataSource.reduce((acc, record) => {
            const value = parseFloat(record.totalExpenseDifference.replace(/[,₹]/g, '')) || 0;
            return acc + value;
    }, 0);
    return unaccountedExpTotal;
      }

  const getData = async () => {
    if(userid ){
      setIsLoading(true);
    await AxiosConfig.post("purchaseorders/polisting", inputDeptArray, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        user_id: userid,
      },
    }).then((res) => {
      let data = res.data.data.poListing;

      const departmentNames = new Set();

      
      data.forEach((value) => {
        if (value.project && value.project.department) {
          departmentNames.add(value.project.department.name);
        }
      });

      const formattedDepartments: any = Array.from(departmentNames).map((name) => ({
        text: name,
        value: name,
      }));

      setDepartmentNames(formattedDepartments)

      if (data.length >= 0) {
        setIsLoading(false);
      }
      let tempArray: any = [];
      let projectArray: any = [];
      data.map((value: any, index: any) => {
        TotalSold += checkNull(value.value_in_base);

        TotalSoldStu += checkNull(value.stus_sold);

        TotalDirectExpense =
          TotalDirectExpense + checkNull(value.direct_expense);
        value.value_in_base = value.value_in_base
          ? checkNull(value.value_in_base)
          : 0;
        TotalUnaccounted =
          TotalUnaccounted + checkNull(value.RevUnaccounted_base);
        TotalPlannedInvoice =
          TotalPlannedInvoice + checkNull(value.planned_invoice_base);
        TotalInvoiced = TotalInvoiced + checkNull(value.total_final_invoice);
        TotalUnaccountedInvoice =
          TotalUnaccountedInvoice + checkNull(value.InvUnaccounted_base);
        let tempData: any = {
          id: value.id,
          key: index,
          sale_date: dayjs(value.sale_date).format("YYYY/MM/DD"),
          project_name: value.project.name,
          po_number: value.number,
          po_name: value.name,
          expense: value.expense,
          projected_expenses: value.projected_expenses,
          po_month_earnings: value.po_month_earnings,
          total_final_invoice: decimalCheck(
            value.total_final_invoice,
            2,
            currencySymb?.currency_symbol
          ),
          po_type: value.potype == null ? "NO Type" : value.potype.type,
          department: value.project.department.name,
          stus_sold: value.stus_sold,
          direct_expense: value.direct_expense,
          RevForex: value.revForex,
          InvForex: value.InvForex,
          RevForexTilldate: value.RevForexTilldate,
          earned_tillDate_base: decimalCheck(
            value.earned_tillDate_base,
            2,
            currencySymb?.currency_symbol
          ),
          sold_filter: value.value_in_base,
          TotalUnaccounted :value.RevUnaccounted_base,
          TotalUnaccountedInvoice: value.InvUnaccounted_base,
          sold: convertCurrency(
            value.value_in_base, currencyRate
          ),
          leadLag_filter: checkNull(value.lead_lag),
          Lead_lag:convertCurrency(
            value.lead_lag,
            currencyRate
          ),
         
          sold_stu: convertCurrency(
           
            (value.value_in_base - value.direct_expense) / value.stus_sold,
            currencyRate
          ),
          poServices: value.poServices ? value.poServices.length : 0,
          unaccounted_filter: value.RevUnaccounted_base,
          unaccounted_rev: convertCurrency(
         
            value.RevUnaccounted_base,
            currencyRate
          ),
          status: value.po_status == null ? "Not Applicable" : value.po_status,
          Accounted_base: decimalCheck(
            value.Accounted_base,
            2,
            currencySymb?.currency_symbol
          ),
          stu_executed: checkNull(
            value.stu_expended == null ? 0 : value.stu_expended
          ),
          stu_pending: checkNull(
            checkNull(value.stus_sold) - checkNull(value.stu_expended)
          ),
          project_id: value.project.id,
          plannedInvoice_filter: value.planned_invoice_base,
          planned_invoice_base: decimalCheck(
            value.planned_invoice_base,
            2,
            currencySymb?.currency_symbol
          ),
          finalInvoice_filter: value.total_final_invoice,
          Final_invoice: decimalCheck(
            value.total_final_invoice,
            2,
            currencySymb?.currency_symbol
          ),
          Po_type: value.projectType,
          unaccInvoice_filter: value.InvUnaccounted_base,
          unaccounted_invoice:convertCurrency(
            value.InvUnaccounted_base,
            currencyRate
          ),
          invoice: value.invoiced,
          isActiveMonth: value.financial_month_status,
          gained: value.gained,
          value: value.value,
          dropped: value.dropped,
          isArchived: value.project.archive_status
        };    
        let projectData: any = {
          project_name: value.project.name,
          project_id: value.project.id,
        };
        tempArray.push(tempData);
        projectArray.push(projectData);
      });
      data = data.sort((a: any, b: any) => {
        var a1 = a.name.toLowerCase();
        var b1 = b.project.name.toLowerCase();
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });
      var filterArray = projectArray.reduce(
        (accumalator: any, current: any) => {
          if (
            !accumalator.some(
              (item: any) => item.project_name === current.project_name
            )
          ) {
            accumalator.push(current);
          }
          return accumalator;
        },
        []
      );
      filterArray = filterArray.sort((a: any, b: any) => {
        var a1 = a.project_name.toLowerCase();
        var b1 = b.project_name.toLowerCase();
        return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
      });

        tempArray.forEach((element) => {
          if (element.po_month_earnings) {
            let totalExpense = 0;
            element.po_month_earnings.forEach((pme) => {
              if (pme.financialmonth.status >= 0) {
                totalExpense = totalExpense + pme.projected_expenses;
              } else {
                totalExpense = totalExpense + pme.direct_expense;
              }
            });
            element.totalExpenseDifference = convertCurrency(
              element.projected_expenses - totalExpense,
              currencyRate
            );
          } else {
            element.totalExpenseDifference = convertCurrency(
              element.projected_expenses,
              currencyRate
            );
          }
        });

      setPoDetails(tempArray);
      setFilterData(tempArray);
      setProjectList(filterArray);
      // setTotalStu((TotalSold - TotalDirectExpense) / TotalSoldStu);      
    });
   }
  };
  const projectOptions: any = projectList.map(
    (row: { project_name: any; project_id: any }) => {
      return {
        text: row.project_name,
        value: row.project_name,
      };
    }
  );

  const handleOnChange = (event: any) => {
    setNameSearch(event.target.value);
  };

  useEffect(() => {
    if (poDetails) {
      const results: any = poDetails.filter(
        (item: any) =>
          item.po_name.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.po_number.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.project_name.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item.po_type.toLowerCase().includes(nameSearch.toLowerCase())
      );
      setFilterData(results);
      setFilterTotalCount(results.length);
      calculateSum(results)
    }
  }, [nameSearch]);

  const poHeader = [
    { label: "PO NUMBER", key: "po_number" },
    { label: "PO NAME", key: "po_name" },
    { label: "PO TYPE", key: "po_type" },
    { label: "PROJECT NAME", key: "project_name" },
    { label: "DEPARTMENT", key: "department" },
    { label: "SOLD", key: "sold" },
    { label: "SOLD_STU", key: "sold_stu" },
    { label: "UNACCOUNTED", key: "unaccounted_rev" },
    { label: "UNACCOUNTED INVOICE", key: "unaccounted_invoice" },
    { label: "UNACCOUNTED EXP", key: "totalExpenseDifference" },
    { label: "LEAD/TRAIL", key: "Lead_lag" },
    { label: "SALE DATE", key: "sale_date" },
  ];
  const sortedCSVData = csvData.sort((a: any, b: any) => {
      return a?.sold?.replace(/[,₹]/g, "") - b?.sold?.replace(/[,₹]/g, "")
  });


  function handleSwitchChange(): void {
    setIsActive(prevState => !prevState);
  }

  const applyFilters = (data, filters) => {
    let filteredData = [...data];
  
    Object.keys(filters).forEach((filterKey) => {
      const filterValues = filters[filterKey] || [];
      if (filterValues.length > 0) {
        filteredData = filteredData.filter((item: any) =>
          filterValues.includes(item[filterKey])
        );
      }
    });
  
    return filteredData;
  };



const roundToTwoDecimals = (num: number) => {
  return Math.round(num * 100) / 100;
};

const calculateTotals = (item: any) => {
  const gained = isNaN(parseFloat(item.gained)) ? 0 : parseFloat(item.gained);
  const value = isNaN(parseFloat(item.value)) ? 0 : parseFloat(item.value);
  const dropped = isNaN(parseFloat(item.dropped))
    ? 0
    : parseFloat(item.dropped);
  const invoice = isNaN(parseFloat(item.invoice))
    ? 0
    : parseFloat(item.invoice);
  const total = roundToTwoDecimals(gained + value - dropped);
  const roundedInvoice = roundToTwoDecimals(invoice);
  return { total, roundedInvoice };
};

const filteredPoRevenueData = filterData.filter((item: any) => {
  const { total, roundedInvoice } = calculateTotals(item);

  if (item.isArchived) {
    return !isActive;
  }

  if (isActive) {

    return !(item.isActiveMonth === false && total === roundedInvoice);
  } else {
   
    return item.isActiveMonth === false && total === roundedInvoice;
  }
});

const filteredDataWithAppliedFilters = applyFilters(filteredPoRevenueData, filter);

useEffect(() => {
  calculateSum(filteredDataWithAppliedFilters);
  setFilterTotalCount(filteredDataWithAppliedFilters.length);
}, [isActive, filterData, filter]);
  return (
    <>
      <div className="table-card">
      <Row className="purchaseOrderRow">

      <Col md={16} xs={24}>
  <div className="searchFormWrapper specificSearch">
    <div className="searchForm">
      <form>
        <Input.Search
          allowClear
          className="searchInput"
          onChange={handleOnChange}
          placeholder="Search by Po Name / Po Number / Project / PoType"
        />
      </form>
    </div>
    <label className="totalLabel">
      {purchaseOrderList.textForTotal} {filterTotalCount}
    </label>
  </div>
</Col>
  <Col md={8} xs={12} className="switchWrapper">
    <Tooltip title={isActive ? "Toggle to show inactive PO's" : "Toggle to show active PO's"}>
      <div>
        <Switch
          onChange={handleSwitchChange}
          checked={!isActive}
          checkedIcon={
          <img
            src={UnarchiveImage_t}
            className="switch_icon"
          />
        }
          onColor="#d0021b"
          uncheckedIcon={
          <img
            src={archiveImage_t}
            className="switch_icon"
          />
        }
          offColor="#1b9558"
        />
      </div>
    </Tooltip>

    <CSVLink
      data={sortedCSVData }
      filename="polisting.csv"
      className="exportTableButton"
      title="Export Table"
      headers={poHeader}
    >
      <ExportOutlined
        onClick={() => {
          const arrayWithoutArchieve = currentTableData.current.map(
            (obj) => ({
            po_number: obj.po_number,
            po_name: obj.po_name.replace(/,/g, ''),
            po_type: obj.po_type,
            project_name: obj.project_name,
            department: obj.department,
            sold: obj.sold,
            sold_stu: obj.sold_stu,
            unaccounted_rev: obj.unaccounted_rev,
            unaccounted_invoice: obj.unaccounted_invoice,
            Lead_lag: obj.Lead_lag,
            sale_date: obj.sale_date,
            totalExpenseDifference: obj.totalExpenseDifference,
          })
        );
          setCsvData(arrayWithoutArchieve);
        }}
        className="exportOultineIcon"
      />
    </CSVLink>
  </Col>
</Row>
        {isLoading === true ? (
          <Loader />
        ) : (
          <div className="mb20">
            <div className="tab-contents">
              <Table
                bordered
                dataSource={filteredPoRevenueData}
                className="poListingTable"
                scroll={{ x: 1500, y: 680 }}
                pagination={false}
                onChange={(pagination, filters, sorter, extra) => {
                 let filteredData: any = applyFilters(extra.currentDataSource, filters);
                    setFilters(filters);

                  let direct_expense: number = 0;
                  filteredData.map((value) => {
                    let unaccounted_rev = +value.unaccounted_filter;
                    direct_expense = direct_expense + value.direct_expense;
                    let PlannedInvoice = +value.plannedInvoice_filter;
                    let Final_invoice = +value.finalInvoice_filter;
                    let unaccounted_invoice = +value.unaccInvoice_filter;

                    TotalSold += checkNull(value.value_in_base);
                    TotalSoldStu +=checkNull(value.stus_sold)

                    TotalUnaccounted = unaccounted_rev;
                    TotalPlannedInvoice = TotalPlannedInvoice + PlannedInvoice;
                    TotalInvoiced = TotalInvoiced + Final_invoice;
                    TotalUnaccountedInvoice = unaccounted_invoice;
                  });
                  //setTotalStu((TotalSold - direct_expense) / TotalSTU);
                  setTotalStu(TotalSTU || 0);

                  const filteredCount = filteredData.length;
                  setFilterTotalCount(filteredCount);
                  calculateSum(filteredData);
                }}
                
                summary={(currentData) => {
                  currentTableData.current = currentData;
                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} className="totalPO">
                          {purchaseOrderList.labelForTotal}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} className="poSummary"></Table.Summary.Cell>
                        <Table.Summary.Cell index={2} className="poSummary"></Table.Summary.Cell>

                        <Table.Summary.Cell index={3} className="poSummary1">
                          <Text>{convertCurrency(totalSold, currencyRate)}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4} className="poSummary1">
                          <Text>{convertCurrency(totalStu, currencyRate)}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5} className="poSummary1">
                          <Text>{convertCurrency(unaccountedTotal, currencyRate)}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6} className="poSummary1">
                          <Text>{convertCurrency(unaccountedInvoiceTotal, currencyRate)}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={7} className="poSummary1">
                        <Text>{convertCurrency(getUnaccountedExpenseTotal(currentData), currencyRate)}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={8} className="poSummary1">
                          <Text>{convertCurrency(leadLagTotal, currencyRate)}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              >
                <Column
                  title="PURCHASE ORDER"
                  dataIndex={["po_number", "po_name", "po_type"]}
                  key="po_type"
                  align="left"
                  className="Purchase"
                  render={(text: any, record: any) => (
                    <div
                      style={{
                        width: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "100px",
                        }}
                      >
                        <p className="poNumberLink">{record["po_number"]}</p>

                        <p>{record["po_name"]}</p>
                        <p>{record["po_type"]}</p>
                      </div>
                      {record.poServices == 0 ? (
                        <div style={{ position: "relative" }}>
                          <img
                            src={warning}
                            className="warning-img"
                            style={{ cursor: "pointer" }}
                          />
                          <span className="warning-text">
                            {purchaseOrderList.warningTextForPoServices}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                  sorter={(a: any, b: any) =>
                    a.po_name.localeCompare(b.po_name)
                  }
                  filters={poTypeOptions}
                  onFilter={(value: any, record: any) =>
                    record.po_type.includes(value)
                  }
                />
                <Column
                  title="PROJECT NAME"
                  dataIndex={["project_name", "project_id"]}
                  key="project_name"
                  align="left"
                  sorter={(a: any, b: any) =>
                    a.project_name.localeCompare(b.project_name)
                  }
                  filters={projectOptions}
                  onFilter={(value: any, record: any) => record.project_name === value}
                  render={(text: any, record: any) => (
                    <a
                      onClick={() => {
                        onClickRow(record["project_id"]);
                      }}
                    >
                      {record["project_name"]}
                    </a>
                  )}
                />
                <Column
                  title="DEPARTMENT"
                  dataIndex="department"
                  key="department"
                  align="left"
                  sorter={(a: any, b: any) =>
                    a.department.localeCompare(b.department)
                  }
                  filters={departmentNames}
                  onFilter={(value: any, record: any) =>
                    record.department.includes(value)
                  }
                />
                <Column
                  title="SOLD"
                  dataIndex="sold"
                  key="sold"
                  align="left"
                  sorter={(a: any, b: any) =>
                    a.sold.replace(/[,₹]/g, "") - b.sold.replace(/[,₹]/g, "")
                  }
                />
                <Column
                  title="SOLD/STU's"
                  dataIndex="sold_stu"
                  key="sold_stu"
                  align="left"
                  sorter={(a: any, b: any) =>
                    a.sold_stu.localeCompare(b.sold_stu)
                  }
                />
                <Column
                  title="UNACCOUNTED REV"
                  dataIndex="unaccounted_rev"
                  key="unaccounted_rev"
                  align="left"
                  sorter={(a: any, b: any) =>
                    a.unaccounted_rev.replace(/[,₹]/g, "") -
                    b.unaccounted_rev.replace(/[,₹]/g, "")
                  }
                />

                <Column
                  title="UNACCOUNTED INVOICE"
                  dataIndex="unaccounted_invoice"
                  className="unaccounted"
                  key="unaccounted_invoice"
                  align="left"
                  sorter={(a: any, b: any) =>
                    a.unaccounted_invoice.replace(/[,₹]/g, "") -
                    b.unaccounted_invoice.replace(/[,₹]/g, "")
                  }
                />
                      <Column
                  title="UNACCOUNTED EXPENSES"
                  dataIndex="totalExpenseDifference"
                  className="unaccounted"
                  key="right"
                  align="left"
                  sorter={(a: any, b: any) =>
                    a.totalExpenseDifference.toString().replace(/[,₹]/g, "") -
                    b.totalExpenseDifference.toString().replace(/[,₹]/g, "")
                  }
                />
                <Column
                  title="LEAD/TRAIL"
                  dataIndex="Lead_lag"
                  align="left"
                  key="Lead_lag"
                  sorter={(a: any, b: any) =>
                    a.Lead_lag.replace(/[,₹]/g, "") -
                    b.Lead_lag.replace(/[,₹]/g, "")
                  }
                />
                <Column
                  title="SALE DATE"
                  dataIndex="sale_date"
                  key="sale_date"
                  sorter={(a: any, b: any) =>
                    dayjs(a.sale_date, "YYYY/MM/DD").unix() -
                    dayjs(b.sale_date, "YYYY/MM/DD").unix()
                  }
                />
              </Table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PurchaseOrderList;