import axios from "axios";
// import { getToken } from "../src/auth";
const AxiosConfig = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

});


// Add a response interceptor
AxiosConfig.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    // Handle token expiration
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      localStorage.removeItem('token');
      window.location.href = "/login"
      
      // // Use refresh token to obtain a new access token
      // const newAccessToken = await refreshAccessToken();

      // // Update the original request with the new token
      // originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
      // // Retry the original request
      // return AxiosConfig(originalRequest);
    }

    return Promise.reject(error);
  }
);

// Function to refresh access token
// const refreshAccessToken = async () => {
//   const token = getToken()

//   // Make a request to your server to refresh the token
//   const response = await AxiosConfig.post('auth/refresh-token', { token });

//   const newAccessToken = response.data.token;
//   // Update the stored access token
//   localStorage.setItem('token', newAccessToken);

//   return newAccessToken;
// };

export { AxiosConfig };
