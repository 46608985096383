const saveUser = (user:any) => {
    localStorage.setItem(
        "user",
        JSON.stringify({
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            superAdmin: user.superAdmin,
            lastLogin: user.lastLogin,
            projects:user.projects,
        })
    );
}

const saveFirstName = (user:any) => {
    localStorage.setItem(
        "firstName",
        user.firstName
    );
}

const saveToken = (user:any) => {
    localStorage.setItem(
        "token",
        user.token
    );
}

const saveIsLoggedIn = () => {
    localStorage.setItem(
        "isLoggedIn",
        "1"
    );
}

const clearStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
}

const getUser = () => {
    const user:any = localStorage.getItem('user');
    return JSON.parse(user);
}

const getAdmin = () => {
    const user:any = getUser();
    if ( user && (user.superAdmin == true) ) {
        return true;
    }
    return false;
}

const getPm = () => {
    const user:any = getUser();
    if ( user && (user.superAdmin == false) && (user.role == 'user')&& (user.projects) && user.projects.length > 0) {
        return true;
    }
    return false;
}

const getHr = () => {
    const user:any = getUser();
    if ( user && (user.superAdmin == false) && (user.role == 'hr') ) {
        return true;
    }
    return false;
}

const getDepts = () =>{
    const dept:any = localStorage.getItem('Departments');
    return JSON.parse(dept);
}

const getProjectsAccess = () =>{
    const proj:any = localStorage.getItem('projects');
    const projects = JSON.parse(proj);
    return projects?.length > 0;
}

const getDepatmentAccess = () =>{
    const Department:any = localStorage.getItem('Departments');
    const Departments = JSON.parse(Department);
    return Departments?.length > 0;
}


const getAssociatedBus = () =>{
    const dept:any = localStorage.getItem('associatedBus');
    return JSON.parse(dept);
}

const getAssociatedBusIds = () => {
    let deprt: any = [];
    let associatedBuList: any = getAssociatedBus();
    if (associatedBuList) {
      for (const bu of associatedBuList) {
        deprt.push(bu.id);
      }
    }
    return deprt;
}

export {
    saveUser,
    saveFirstName, 
    saveToken, 
    saveIsLoggedIn, 
    clearStorage, 
    getUser, 
    getAdmin,
    getPm,
    getHr,
    getDepts,
    getAssociatedBus,
    getAssociatedBusIds,
    getProjectsAccess,
    getDepatmentAccess
};