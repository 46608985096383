import React, { useEffect, useState } from "react";
import { Heatmap, HeatmapConfig } from "@ant-design/plots";

const HeatMapComponent = (props) => {
  {
    const [data, setData] = useState([]);
    const { handleCalendarAuditRecordClick } = props;
    const [plotDetails, setPlotDetails] = useState();
    let config: HeatmapConfig;

    useEffect(() => {
      setData(props.data);
    }, [props.data]);

    const updateColors = (plot) => {
      const geometries = plot.chart.geometries[0]?.elements;

      if (!geometries) {
        console.warn('No geometries or elements found in the plot');
        return;
      }

      geometries.forEach((element: any) => {
        const { week_no, year, month, quarterly } = element.data;
        const cellData: any = props.data.find(
          (d: any) =>
            d.week_no === week_no &&
            d.year === year &&
            d.month === month &&
            d.quarterly === quarterly
        );
        if (cellData && element.shape && element.shape.attr) {
          element.shape.attr("fill", cellData.color_code); // Assigning the color
        }
      });
    };

    useEffect(() => {
      if (plotDetails) updateColors(plotDetails);
    });

    config = {
      onReady: async (plot: any) => {
        // Click Functionality
        setPlotDetails(plot);
        await plot.on("element:click", (...args) => {
          handleCalendarAuditRecordClick(args[0]?.data?.data);
        });
        // updating colors from each data
        updateColors(plot);
      },
      data: data,
      xField: props.xField,
      yField: "year",
      colorField: "floor_score",
      heatmapStyle: {
        lineHeight: 2,
        lineWidth: 3, // Width of the border stroke
        stroke: "#fff", // Color of the border stroke
      },
 
      tooltip: {
        fields: [props.xField, "average_score"],
        showTitle: false,
      },
    };

    return <Heatmap {...config} />;
  }
};

export default HeatMapComponent;
