import { Row, Col, Card, Select } from "antd";
import { useState, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import moment from "moment";
import { Doughnut } from "react-chartjs-2";
import { approvedHours } from "../../../utils/enums";

const ApprovedHours = () => {
  const token = getToken();
  const { Option } = Select;
  const [timeSpan, setTimeSpan] = useState(30);
  let talentData: any = localStorage.getItem("talentid");
  talentData = JSON.parse(talentData);
  const [labels, setLabels]: any = useState([]);
  const [dataArr, setDataArr]: any = useState([]);
  const colors: any = ["#5C67E5", "#F8C053", "#FF7A37"];

  useEffect(() => {
    AxiosConfig.post(
      "talents/approvedhours",
      {
        current_date: moment(new Date()).format("YYYY-MM-DD"),
        time_span: timeSpan,
        talent_id: Number(talentData["Talent_id"]),
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setLabels(Object.keys(res.data.data.approvedHoursData));
        setDataArr(Object.values(res.data.data.approvedHoursData));
      })
      .catch((err) => console.log(err));
  }, [timeSpan]);

  const approvalHoursDatasets: any = {
    labels: labels,
    datasets: [
      {
        label: "Activity Hours",
        data: dataArr,
        backgroundColor: colors,
      },
    ],
  };

  const config = {
    type: "doughnut",
    data: approvalHoursDatasets,
    options: {
      radius: "80%",
      cutout: "60%",
      // plugins: {
      //   legend: {
      //     position: "right",
      //     labels: {
      //       boxHeight: 15,
      //       boxWidth: 15,
      //       generateLabels: (chart: any) => {
      //         const datasets = chart.data.datasets;
      //         return datasets[0].data.map((data: any, i: any) => ({
      //           text: `${chart.data.labels[i]}- $${checkNull(data)}`,
      //           fillStyle: datasets[0].backgroundColor[i],
      //         }));
      //       },
      //     },
      //   },
      // },
    },
  };

  return (
    <Card className="ActivityHoursCard">
      <Row>
        <Col span={12}>
          <h2>{approvedHours.headingForBillableNonbillableNotApproved}</h2>
        </Col>
        <Col span={12}>
          <Select
            defaultValue="30"
            style={{ width: "100%" }}
            onChange={(event: any) => setTimeSpan(Number(event))}
          >
            <Option value="30">{approvedHours.optionFor30}</Option>
            <Option value="90">{approvedHours.optionFor90}</Option>
            <Option value="365">{approvedHours.optionFor365}</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Doughnut data={approvalHoursDatasets} options={config.options} />
        </Col>
      </Row>
    </Card>
  );
};

export default ApprovedHours;
