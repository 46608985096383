import React, { useState } from "react";
import { CustomerClassification } from "../../utils/enums";
import "./PasswordPopup.scss";
function PasswordPopup({ onConfirm, onCancel }) {
  const [password, setPassword] = useState("");

  const handleConfirm = () => {
    onConfirm(password);
    setPassword("");
  };

  const handleCancel = () => {
    onCancel();
    setTimeout(function () {
      window.location.reload();
    }, 1000);
  }; 
  return (
    <div className="password-popup-modal">
      <div className="password-popup">
        <h3 className="title">
          {CustomerClassification.editConfirmationTitle}
        </h3>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter Password"
        />
        <p className="note">{CustomerClassification.editConfirmationNote} </p>
        <div className="buttons">
          <button className="confirm" onClick={handleConfirm}>
            Confirm
          </button>
          <button className="cancel" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
export default PasswordPopup;
