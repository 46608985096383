import InputField from "../../components/FormInput/InputField";
import PasswordField from "../../components/FormInput/PasswordField";
import ButtonReuse from "../../components/Buttons/Button";
import logo from "../../assets/images/logo.svg";
import { Fragment, useEffect } from "react";
import { useHistory,Link } from "react-router-dom";
import { useState } from "react";
import { PoweredBy } from "../../viewgroups/index";
import { Row, Col } from "antd";
import Layout from "antd/lib/layout/layout";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useFormik  } from "formik";
import * as yup from "yup";
import { AxiosConfig } from "../../ApiConfig";
import  ForgetPasswordModal  from '../Login/ForgetPassword';
import {
  saveFirstName,
  saveIsLoggedIn,
  saveToken,
  saveUser,
} from "../../utils/localStore";
require("./Login.scss");
require("../../components/Button/Button.scss");

const Login = () => {

  const [errorMsg, setErrorMsg] = useState("");
  let history = useHistory();
  const [modalVisible, setModalVisible] = useState(false); 

  const submitLogin = (values) =>{
    AxiosConfig.post("users/login", values)
    .then((response) => {
      if (response.status == 200) {
        saveUser(response.data.data.user);
        saveFirstName(response.data.data.user);
        saveToken(response.data.data.user);
        localStorage.setItem(
          "userId",
          response.data.data.user.id
        );
        saveIsLoggedIn();
        if (response.data.data.user.projects.length > 0) {
          if (!localStorage.getItem("projects")) {
            localStorage.setItem(
              "projects",
              JSON.stringify(
                response.data.data.user.projects
              )
            );
          }
        }
        
        if (response.data.data.user.departments.length > 0) {
          if (!localStorage.getItem("associatedBus")) {
            localStorage.setItem(
              "associatedBus",
              JSON.stringify(
                response.data.data.user.departments.filter(
                  function (associatedBu: { flag: any }) {
                    return associatedBu.flag === true;
                  }
                )
              )
            );
          }
          if (!localStorage.getItem("fixedAssociatedBu")) {
            localStorage.setItem(
              "fixedAssociatedBu",
              JSON.stringify(
                response.data.data.user.departments.filter(
                  function (associatedBu: { flag: any }) {
                    return associatedBu.flag === true;
                  }
                )
              )
            );
          }
          sessionStorage.setItem("isDepartment", "1");
          sessionStorage.setItem("Heading", "/buDashboard");
          localStorage.setItem("key", "1");
          history.push("/buDashboard");
        } else {
          history.push("/projects");
          localStorage.setItem("key", "1");
          sessionStorage.setItem(
            "Heading",
            "/projects"
          );
        }
        window.location.reload();
      }
    })
    .catch(function (error) {
      console.log(error?.response?.data?.status?.message);
      setErrorMsg(error?.response?.data?.status?.message);
    });
  }
  
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup.string().email().required(),
      password: yup.string().min(8).required(),
    }),
    onSubmit: (values, { setSubmitting }) => {
      submitLogin(values);
      setSubmitting(false);
    },
  });


  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        formik.submitForm();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [formik]);

  useEffect(() => {
    localStorage.clear();
  }, []);
  const handleForgetPasswordClick = () => {
    setModalVisible(true); // Open modal when "Forget password ?" link is clicked
  };
  const handleModalOk = () => {
    setModalVisible(false);
  };
  const handleModalCancel = () => {
    setModalVisible(false);
  };


  return (
    <Fragment>
      <div className="login-wrapper">
        <Layout style={{ height: "100vh" }}>
          <Row
            justify="space-around"
            align="middle"
            style={{ height: "100vh", marginRight: "0px" }}
          >
            <Col xs={24} md={12}>
              <div className="login-container">
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
                <h5>Login</h5>
                <form onSubmit={formik.handleSubmit as any}>
                  <InputField
                    type="text"
                    name="email"
                    placeholder="Enter your email address"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    className="email--input"
                  />
                  <p className="display_error">{formik.errors.email}</p>

                  <PasswordField
                    name="password"
                    placeholder="Enter your password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    className="password--input"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                  <p className="display_error">{formik.errors.password}</p>
                  <p className="display_error">{errorMsg}</p>
                  {/* <div className="text-left">
                    <ButtonReuse
                      type="primary"
                      className="primary-btn mt20"
                      htmlType="submit"
                      value="Login"
                    ></ButtonReuse>
                  </div> */}
                  <div className="d-flex justify-content-between" >
                     <div>
                        <ButtonReuse
                            type="primary"
                            className="primary-btn mt20"
                            htmlType="submit"
                            value="Login"
                        ></ButtonReuse>
                      </div>
                      <span style={{ color: '#7788FB' }}> 
                      <Link to="#" onClick={handleForgetPasswordClick}> Forgot password ?</Link>
                      </span>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
          <PoweredBy></PoweredBy>
        </Layout>
      </div>
       {/* Modal for forget password */}
       <ForgetPasswordModal
        visible={modalVisible}
        handleOk={handleModalOk}
        handleCancel={handleModalCancel}
      />
    </Fragment>
  );
};
export default Login;