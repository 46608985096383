import { GET_BANDS, GET_BANDS_ERROR } from "../../constants";
import { Axios } from "../../utils/axios";

export const getBands = () => {
    return async (dispatch) => {
        try {
            const response = await Axios.get(`bands`);

            if (response.status == 200) {
                dispatch({
                    type: GET_BANDS,
                    payload: response.data.data.bands,
                });
            } else {
                dispatch({
                    type: GET_BANDS_ERROR,
                    payload: "Error: Data not found.",
                });
            }
        } catch (error: any) {
            dispatch({
                type: GET_BANDS_ERROR,
                payload: error,
            });
        }
    };
};
