//import Action Types
import {
  GET_CUSTOMER_CLASSIFICATION_TYPES,
  GET_CUSTOMER_CLASSIFICATION_TYPES_ERROR,
} from "../../constants";

//Write down the initial state of the reducer
interface CurrencyState {
  customerClassifcationTypes: Array<object>;
  error: string;
}

const INITIAL_STATE: CurrencyState = {
  customerClassifcationTypes: [],
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER_CLASSIFICATION_TYPES:
      return { ...state, customerClassifcationTypes: action.payload };
    case GET_CUSTOMER_CLASSIFICATION_TYPES_ERROR:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
