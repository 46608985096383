import { Button } from "antd";
require("../Button/Button.scss");
const ButtonReuse = (props: {
  value?: string;
  type?: any;
  htmlType?: any;
  className?: string;
  children?: any;
  iconRender?: any;
  size?: any;
}) => {
  return (
    <Button
      type={props.type}
      htmlType={props.htmlType}
      className={props.className}
      size={props.size ?? "default"}
    >
      {props.value}
    </Button>
  );
};

export default ButtonReuse;
