import { Table, Row, Col, Button, 
  notification,
  Typography
 } from "antd";
import type { ColumnsType } from "antd/es/table";
import { enumForCreditNote } from "../../../utils/enums";
import { useEffect, useRef, useState } from "react";
import AddOverlay from "./AddCreditNote";
import EditOverlay from "./EditCreditNote";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useSelector } from "react-redux";
import { EditTwoTone } from "@ant-design/icons";
import Loader from "../../../components/Loader/Loader";
require("./AddCreditNote.scss");
import {
  convertCurrency
} from "../../../../src/utils/util";
export interface CreditNoteProps {
  id: number;
  credit_note: string;
  credit_amount: number;
  description?: string;
  customer_id: number;
  currency_id: number;
  creditnote_attachments: any[];
  creditnote_pos: CreditnotePo[];
  customer: Customer;
  mastercurrency: Mastercurrency;
  issue_date: string;
  credit_amount_base: number;
  pending_amount_base: number;
  adjusted_amount_base: number;
}

export interface CreditnotePo {
  id: number;
  amount: number;
  po_id: number;
  creditnote_id: number;
}

export interface Customer {
  name: string;
}
export interface Mastercurrency {
  currency_code: string;
  currency_symbol: string;
}

const { Text } = Typography;
const CreditNote = () => {
  const [editVisible, setEditVisible] = useState(false);
  const [creditNoteDetail, setCreditNoteDetail] = useState<CreditNoteProps>();
  const [visible, setVisible] = useState<boolean>(false);
  const [customerName, setCustomerName] = useState<
    { text: string; value: string }[]
  >([]);
  const [data, setData] = useState<Array<DataType>>([]);
  const [masterCurrency, setMasterCurrency] = useState<Array<any>>([]);
  const currencyRate = useRef<{ [key: string]: number }>({});
  const newMonthId = useRef<string | null>(null);
  const currencyName = useRef<{ [key: string]: string }>({});
  const currencySymbol = useRef<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalUnaccountedAmount, setTotalUnaccountedAmount] = useState(0);

  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
 const currencyRates = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);
 let cur_type = localStorage.getItem("cur_type");
 let isEditable = localStorage.getItem("isEditable");
 
 

  const token = getToken();

  useEffect(() => {
    getCreditNoteData();
  }, []);

  useEffect(() => {
    const total = data.reduce((acc, item) => acc + item.credit_amount_base, 0);
    setTotalAmount(total);
  }, [data]);
  useEffect(() => {
    const unaccountedTotal = data.reduce(
      (acc, item) => acc + item.pending_amount_base,
      0
    );
    setTotalUnaccountedAmount(unaccountedTotal);
  }, [data]);

  const visibleHandler = (pageType?: string) => {
    if(isEditable == "true"){
      if (pageType === "edit") {
        setEditVisible(true);
      } else {
        setVisible(true);
      }
   }else{
    notification.open({
      message: "",
      description: "To edit/add values, please switch to base currency editable mode",
      style: { color: "red" },
    });
   }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (currencySymb?.currency_code) {
        await getMonthsData();
        await getCurrency();
        setIsLoading(false);
      }
    };
    fetchData();
  }, [currencySymb?.currency_code]);

  const closeHandler = (pageType?: string) => {
    if (pageType === "edit") {
      setEditVisible(false);
    } else {
      setVisible(false);
    }
  };
  const formatCurrencyAmount = (amount: number, currencyId: number) => {
    const currency = masterCurrency.find(
      (currency) => currency.currency_symbol === currencyId
    );
    const currencySymbol = currency ? currency.currency_symbol : "";

    const formatter = new Intl.NumberFormat(
      currencySymbol === "₹" ? "en-IN" : "en-US",
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );

    return formatter.format(amount);
  };

 

  const getMonthsData = async () => {
    try {
      const res = await AxiosConfig.get("financialmonths", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const currentMonth: any = res?.data?.data?.months?.find(
        (month: any) => month.status === "1"
      );
      if (currentMonth) {
        newMonthId.current = currentMonth.id;
      }
    } catch (error) {
      console.error("Error fetching financial months:", error);
    }
  };

  const getCurrency = async () => {
    try {
      const res = await AxiosConfig.get(`rates/${newMonthId.current}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const mastercurrencies = res?.data?.monthRates?.mastercurrencies;

      setMasterCurrency(res.data?.monthRates?.mastercurrencies);
      const tempCurrencyName = {};
      const tempCurrencySymb = {};
      mastercurrencies.map((currency) => {
        tempCurrencyName[currency?.currencyrates?.currency_id] =
          currency?.currency_code;
        tempCurrencySymb[currency?.currencyrates?.currency_id] =
          currency?.currency_symbol;
        currencyRate.current[currency?.currencyrates?.currency_id] =
          currency?.currencyrates?.rate;
      });

      currencyName.current = tempCurrencyName;
      currencySymbol.current = tempCurrencySymb;
    } catch (error) {
      console.error(error);
    }
  };

  const getCreditNoteDetail = (creditNote) => {
    setCreditNoteDetail(creditNote);
  };
  const checkCurrencyType = (cur_type,record) => {
    if(isEditable == "true" ){
      getCreditNoteDetail(record);
      visibleHandler("edit");
    }else{
      notification.open({
        message: "",
        description: "To edit/add values, please switch to base currency editable mode",
        style: { color: "red" },
      });
    }
  };
  

  const getCreditNoteData = async () => {
    try {
      const res = await AxiosConfig.get("creditnote", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const creditNotes = res.data.data.creditNotes;
      setData(
        creditNotes.map(
          (row: {
            adjusted_amount: number;
            credit_amount: number;
            credit_note: string;
            currency_id: number;
            customer_id: number;
            description: string;
            customer: any;
            id: number;
            pending_amt: number;
            creditnote_attachments: any;
            issue_date: string;
            pending_amount: number;
            pending_amount_base: number;
            adjusted_amount_base: number;
            credit_amount_base: number;
          }) => {
            const date = new Date(row.issue_date);
            const formattedDate = date.toISOString().split("T")[0];
            return {
              creditnote_attachments: row.creditnote_attachments,
              adjusted_amount: row.adjusted_amount,
              credit_amount: row.credit_amount,
              credit_note: row.credit_note,
              customer: row.customer.name,
              currency_id: row.currency_id,
              customer_id: row.customer_id,
              description: row.description,
              id: row.id,
              pending_amt: row.credit_amount - row.adjusted_amount,
              issue_date: formattedDate,
              pending_amount_base: row.pending_amount_base,
              adjusted_amount_base: row.adjusted_amount_base,
              credit_amount_base: row.credit_amount_base,
            };
          }
        )
      );
      const uniqueCustomers = new Set<string>();
      creditNotes.forEach((row) => {
        uniqueCustomers.add(row.customer.name);
      });
      const tempUniqueCustomers = Array.from(uniqueCustomers).map((customer) => ({
        text: customer,
        value: customer,
      }))
      const sortedUniqueCustomers = tempUniqueCustomers.sort((a, b) => a.text.localeCompare(b.text));
      setCustomerName(sortedUniqueCustomers);
    } catch (error) {
      console.error("Error fetching credit note data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  interface DataType {
    key: string;
    credit_note: string;
    customer: string;
    Customer_name: string;
    credit_amount: number;
    adjusted_amount: number;
    pending_amt: number;
    description: string;
    issue_date: string;
    credit_amount_base: number;
    pending_amount_base: number;
    adjusted_amount_base: number;
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "Credit Note #",
      fixed: "left",
      dataIndex: "credit_note",
      key: "creditNote",
      sorter: (a: DataType, b: DataType) =>
        a.credit_note.localeCompare(b.credit_note),
    },
    {
      title: "Customer Name",
      dataIndex: "customer",
      filters: customerName,
      onFilter: (value, record) => {
        if (typeof value === "string") {
          return record.customer.includes(value);
        }
        return record.customer.includes(value.toString());
      },
      sorter: (a: DataType, b: DataType) =>
        a.customer.localeCompare(b.customer),
    },
    {
      title: "Credit Note Amount",
      dataIndex: "credit_amount",
      key: "credit_amount",
      className: "text-end",
      sorter: (a, b) => a.credit_amount_base - b.credit_amount_base,
      render: (creditAmount: number, record: any) => {
        const currency = record.currency_id
        ? masterCurrency.find(
            (currency) => currency.id === record.currency_id
          )
        : null;

      const currencySymbol = currency ? currency.currency_symbol : "";
        
        const baseCreditAmount = record.credit_amount_base;

        return (
          <div>
            <div>
              <span>
                
                {isEditable == "false" ? convertCurrency(baseCreditAmount,currencyRates) :
                `${currencySymbol} ${formatCurrencyAmount(
                  creditAmount,
                  currencySymbol
                )}`}
              </span>
            </div>{
              
            }
            { isEditable == "true" ?
            currencySymbol === "₹" ? null : (
              <div>
                <Text type="secondary">
                  {`₹ ${formatCurrencyAmount(
                    baseCreditAmount,
                    currencySymbol
                  )}`}
                </Text>
              </div>
            ):""
            }
          </div>
        );
      },
    },

    {
      title: "Adjusted Amount",
      dataIndex: "adjusted_amount",
      key: "adjusted_amount",
      className: "text-end",
      sorter: (a, b) => a.adjusted_amount_base - b.adjusted_amount_base,
      render: (adjustedAmount: number, record: any) => {
        const currency = record.currency_id
          ? masterCurrency.find(
              (currency) => currency.id === record.currency_id
            )
          : null;

        const currencySymbol = currency ? currency.currency_symbol : "";
        
        const baseAdjustedAmount = record.adjusted_amount_base;

        return (
          <div>
            <div>
              <span>
                { isEditable == "false" ? convertCurrency(baseAdjustedAmount,currencyRates) : `${currencySymbol} ${formatCurrencyAmount(
                adjustedAmount,
                currencySymbol
              )}`}
              </span>
            </div>
            { isEditable == "true" ? currencySymbol === "₹" ? null : (
              <div>
                <Text type="secondary">
                  {`₹ ${formatCurrencyAmount(
                    baseAdjustedAmount,
                    currencySymbol
                  )}`
                  }
                </Text>
              </div>
            ):"" }            
          </div>
        );
      },
    },
    {
      title: "Pending Amount",
      dataIndex: "pending_amt",
      key: "pending_amt",
      className: "text-end",
      sorter: (a, b) => a.pending_amount_base - b.pending_amount_base,
      render: (pendingAmount: number, record: any) => {
        const currency = record.currency_id
          ? masterCurrency.find(
              (currency) => currency.id === record.currency_id
            )
          : null;

        const currencySymbol = currency ? currency.currency_symbol : "";
        if (Math.abs(pendingAmount) < 0.001) {
          pendingAmount = 0;
        }

        const basePendingAmount = record.pending_amount_base;

        return (
          <div>
            <div>
              <span>
                {isEditable == "false" ? convertCurrency(basePendingAmount,currencyRates) :
                `${currencySymbol} ${formatCurrencyAmount(
                pendingAmount,
                currencySymbol
              )}`}
              </span>
            </div>
            {isEditable == "true" ? currencySymbol === "₹" ? null : (
              <div>
                <Text type="secondary">
                  {`₹ ${formatCurrencyAmount(
                    basePendingAmount,
                    currencySymbol
                  )}`}
                </Text>
              </div>
            ):""}
          </div>
        );
      },
    },
    {
      title: "Issue Date",
      dataIndex: "issue_date",
      key: "issue_date",
      sorter: (a, b) => a.issue_date.localeCompare(b.issue_date),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Edit",
      className: "text-end",
      render: (record: any) => (
        <>
          <div className="actionBtns" style={{ cursor: "pointer" }}>
            <EditTwoTone
              onClick={() => {
                checkCurrencyType(cur_type,record)
               
              }}
            />
          </div>
        </>
      ),
    },
  ];

  const TotalAmountComponent = ({ text, amount }) => (
    <div>
      <strong>{text}</strong>
      {isEditable == "false" ? convertCurrency(amount,currencyRates) :
        new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
        })
          .format(amount)
          .replace("₹", " ₹ ")
        }
      
    </div>
  );

  return (
    <div className="table-card">
      <Row>
        <Col
          className="AddCreditNoteButton"
          md={24}
          xs={24}
          style={{ textAlign: "right" }}
        >
          <Button
            type="primary"
            className="primary-btn"
            onClick={() => visibleHandler()}
            style={{ marginBottom: "10px" }}
          >
            {enumForCreditNote.buttonForAddCreditNote}
          </Button>
        </Col>
      </Row>

      {visible && 
      <AddOverlay
        visible={visible}
        onClose={closeHandler}
        onCancelButton={closeHandler}
        mastercurrencies={masterCurrency}
      ></AddOverlay>
      }
      {editVisible && creditNoteDetail && (
        <EditOverlay
          visible={editVisible}
          onClose={() => closeHandler("edit")}
          onCancelButton={() => closeHandler("edit")}
          creditNoteDetail={creditNoteDetail}
        ></EditOverlay>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="tableOut noWordBreak">
          <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            className="creditNoteTable"
            bordered
            pagination={{ pageSize: 10 }}
            scroll={{ x: 300, y: 600 }}
            footer={() => (
              <div>
                <TotalAmountComponent
                  text={enumForCreditNote.textForTotalAmount}
                  amount={totalAmount}
                />

                <div className="totalCreditNote">
                  <TotalAmountComponent
                    text={enumForCreditNote.textForPendingAmount}
                    amount={totalUnaccountedAmount}
                  />
                </div>
              </div>
            )}
          ></Table>
        </div>
      )}
    </div>
  );
};

export default CreditNote;
