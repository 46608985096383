import { Card, Spin } from "antd";
import "./Loader.scss";

const Loader = (props) => {
  return (
    <div>
      <Card
        className={`loader_Card ${props.className}`}
        style={{
          ...props.customStyle,
        }}
      >
        <h3> Loading content please wait... </h3>
        <br />
        <Spin className="loader_Spin" />
      </Card>
    </div>
  );
};

export default Loader;
