import { GET_CUSTOMER_CLASSIFICATION_TYPES, GET_CUSTOMER_CLASSIFICATION_TYPES_ERROR } from "../../constants";
import { Axios } from "../../utils/axios";

export const getCustomerClassificationTypes = () => {
  return async (dispatch) => {
    try {
      const response = await Axios.get(`/customerClassificationTypes`);

      if (response.status == 200) {
        dispatch({
          type: GET_CUSTOMER_CLASSIFICATION_TYPES,
          payload: response?.data?.data?.classifications,
        });
      } else {
        dispatch({
          type: GET_CUSTOMER_CLASSIFICATION_TYPES_ERROR,
          payload: "Error: Data not found.",
        });
      }
    } catch (error: any) {
      dispatch({
        type: GET_CUSTOMER_CLASSIFICATION_TYPES_ERROR,
        payload: error,
      });
    }
  };
};
