import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Bar } from "react-chartjs-2";
import { useState, useEffect } from "react";
import moment from "moment";

const StackedAxisChart = (props: {
  selectedDate?: any;
  selectedSkills?: any;
  selectedBu?: any;
}) => {
  const dateFormat = "YYYY-MM-DD";
  const selected_date = moment(props.selectedDate).format(dateFormat);
  const inputValue = {
    selected_date: selected_date,
    department_id: props.selectedBu.map((bu: any) => bu.value),
    skill_id: props.selectedSkills.map((skill: any) => skill.value),
  };
  const token = getToken();
  const [bandLabel, setBandLabel] = useState([]);
  const [totalNumber, setTotalNumber] = useState([]);

  useEffect(() => {
    getData();
  }, [props]);

  const getData = async () => {
    await AxiosConfig.post("/talents/bandbulge", inputValue, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      let data = res.data.data.bandData;
      let bandArray: any = [];
      let numberArray: any = [];
      data.map((ele: any) => {
        bandArray.unshift(ele.band);
        numberArray.unshift(ele.talentCount);
      });
      setBandLabel(bandArray);
      setTotalNumber(numberArray);
    });
  };
  const arrayOne = totalNumber;
  const arrayTwo: any = [];
  arrayOne.forEach((element: any) => {
    arrayTwo.push(element * -1);
  });
  const data = {
    labels: bandLabel,
    datasets: [
      {
        label: "No of People",
        scaleShowLabels: true,
        data: arrayOne,
        backgroundColor: [
          "#00E09A",
          "#FF7A37",
          "#B1DC4D",
          "#00BF79",
          "#F8C053",
          "#36CCED",
          "#5389F8",
          "#5C67E5",
        ],
        borderWidth: 1,
      },
      {
        // label: "One",
        // scaleShowLabels: true,
        data: arrayTwo,
        backgroundColor: [
          "#00E09A",
          "#FF7A37",
          "#B1DC4D",
          "#00BF79",
          "#F8C053",
          "#36CCED",
          "#5389F8",
          "#5C67E5",
        ],
        borderWidth: 1,
        pointHitRadius: 0,
      },
    ],
  };

  const tooltip = {
    callbacks: {
      label: function (context) {
        if (context.datasetIndex == 0) {
          return `${context.dataset.label} : ${context.formattedValue}`;
        }
      },
    },
  };

  const config = {
    type: "bar",
    data,
    options: {
      plugins: {
        legend: false,
        title: {
          display: true,
          align: "start",
          padding: 10,
          font: { weight: "normal", size: "22px"},
          color: "#1E2225",
          text: "Band Bulge Mix",
        },
        tooltip: tooltip,
        responsive: true,
      },
      indexAxis: "y",
      categoryPercentage: 1.0,
      barPercentage: 1.0,
      scales: {
        x: {
          stacked: true,
          display: false,
          categoryPercentage: 1.0,
          barPercentage: 1.0,
        },
        y: {
          stacked: true,
          // display: false,
          beginAtZero: true,
          categoryPercentage: 1.0,
          barPercentage: 1.0,
        },
      },
    },
  };

  return (
    <>
      <Bar data={data} options={config.options} height={200} />
    </>
  );
};

export default StackedAxisChart;
