import React, { useState, useEffect } from "react";
import { Table, Button, Row, Col, Input, Select } from "antd";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { enumForAudit } from "../../../utils/enums";
import { AxiosConfig } from "../../../ApiConfig";
import { errorNotification } from "../../../utils/util";
import { getToken } from "../../../auth";
import SessionAudit from "../../../components/AuditModal/SessionAudit";
import { useParams, useLocation, useHistory } from "react-router-dom";
import "antd/dist/antd.css";
require("./Audits.scss");
import arrow from "../../../assets/images/arrow.png";
import { app } from "../../../utils/enums";
import Loader from "../../../components/Loader/Loader";
import { CSVLink } from "react-csv";
import { ExportOutlined } from "@ant-design/icons";
// Quarterly Audit
const AuditsCSAT = () => {
  let history = useHistory();
  const { id }: any = useParams();
  const location = useLocation();
  const passedData = location.state;

  const { entity }: any = passedData;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [quarterly_type, setQuarterly] = useState();
  const [customer_id, setCustomerId] = useState();
  const [customer_name, setCustomerName] = useState();
  const [project_id, setProjectId] = useState();
  const [response_group_id, setResGroupId] = useState();
  const [func_type, setFuncType] = useState();

  const token = getToken();
  const { Option } = Select;
  const [nameSearch, setNameSearch] = useState("");
  const [userId, setUserId] = useState();
  const [auditCsrData, setAuditCsrData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterTotalCount, setFilterTotalCount] = useState(0);
  const [financialYears, setFinancialYears] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [customerNames, setCustomerNames]: any = useState([]);
  const [projectCode, setProjectCode] = useState("");
  const currentYear = new Date().getFullYear();
  const [accessToAll, setAccesToAll] = useState(0);
  const [loading, setLoading] = useState(true);
  const [customerNameFilter, setCustomerNameFilter] = useState([]);
  const fixedColumns = ["project_code", "id", "customer.name", "name"];
  const optionsQuarter = [
    { label: "JFM", value: "quarter1" },
    { label: "AMJ", value: "quarter2" },
    { label: "JAS", value: "quarter3" },
    { label: "OND", value: "quarter4" },
  ];
  const fixedQuarterColumns = ["quarter1", "quarter2", "quarter3", "quarter4"];
  const [filterQuarter, setFilterQuarter] = useState("");
  const [filteredColumns, setFilteredColumns]: any = useState([
    ...fixedQuarterColumns,
    ...fixedColumns,
  ]);
  const [tableColumns, setTableColumns]: any = useState([]);
  const [allColumns, setAllColumns]: any = useState([]);

  const handleCustomerSelect = (customer: any) => {
    if (customer?.length > 0) {
      setCustomerNameFilter(customer);
      const selectedCustomerName = customer?.map((cust) => {
        return cust.label;
      });
      if (entity === "Customer") {
        const data = auditCsrData?.filter((checkData: any) =>
          selectedCustomerName.includes(checkData.name)
        );
        setFilterData(data);
      } else {
        const data = auditCsrData?.filter((checkData: any) =>
          selectedCustomerName.includes(checkData.customer.name)
        );
        setFilterData(data);
      }
    } else {
      setCustomerNameFilter([]);
      setFilterData(auditCsrData);
    }
  };

  //Total count change according to data
  useEffect(() => {
    setFilterTotalCount(filterData?.length);
  }, [filterData]);

  const generateColumns = () => {
    const commonColumns = [
      ...Array.from({ length: 4 }) // Assuming there are 4 quarters in a year
        .map((_, index) => {
          const quarterInterval = quarters[index]?.quarterInterval
            .replace(/\d+/g, "")
            .replace(/\s/g, "");
          const quarterNumber = index + 1;
          const quarterName = quarters[index]?.quarterInterval;
          const startDate = quarters[index]?.startDate;
          const endDate = quarters[index]?.endDate;
          return {
            title: (
              <div className="header-div">
                <div className="header-div-child">{`${quarterName}`}</div>
                <div className="header-week-range">{`${startDate} - ${endDate}`}</div>
              </div>
            ),
            dataIndex: `quarter${quarterNumber}`,
            key: `quarter${quarterNumber}`,
            width: "200px",
            onCell: (record) => ({
              onClick: () => {
                const quarterData = record?.quarterly_data?.find(
                  (option) =>
                    option.quarterly === quarterInterval && option.year === year
                );
                quarterData
                  ? handleCellClick(
                      quarterData.quarterly,
                      record.id,
                      quarterData.year,
                      "update",
                      record.name,
                      quarterData.group_id,
                      record.project_code,
                      record.id,
                      record.name,
                      quarterData.recorded_by.firstName,
                      quarterData.recorded_by.lastName
                    )
                  : handleCellClick(
                      quarterInterval,
                      record.id,
                      year,
                      "add",
                      record.name,
                      null,
                      record.project_code,
                      record.id,
                      record.name,
                      null,
                      "Not yet Recorded"
                    );
              },
            }),
            render: (_, record) => {
              const quarterData = record?.quarterly_data?.find(
                (option) =>
                  option.quarterly === quarterInterval && option.year === year
              );
              if (quarterData) {
                const colorData =
                  quarterData.color_code != null
                    ? quarterData.color_code
                    : "#B0B0B0";
                const textColor =
                  quarterData.color_code != null ? "#000000" : "#000000";
                return quarterData ? (
                  <Button
                    style={{
                      backgroundColor: `${colorData}`,
                      width: "100%",
                      borderRadius: "20px",
                      height: "40px",
                      color: `${textColor}`,
                    }}
                  >
                    Total Score :{" "}
                    {quarterData
                      ? !isNaN(quarterData.average_score)
                        ? quarterData.average_score.toFixed(2)
                        : quarterData.average_score
                      : null}
                  </Button>
                ) : null;
              }
            },
          };
        }),
    ];

    if (entity === "Project") {
      return [
        {
          title: (
            <div className="project-code-column-header">
              <div style={{ fontSize: "16px" }}>
                {enumForAudit.labelForProjectCode}
              </div>
            </div>
          ),
          dataIndex: "project_code",
          key: "project_code",
          width: "200px",
          className: "project-code-column",
          sorter: (a: any, b: any) =>
            a.project_code.replace("STP_", "") -
            b.project_code.replace("STP_", ""),
        },
        {
          title: (
            <div className="project-code-column-header">
              <div style={{ fontSize: "16px" }}>
                {enumForAudit.labelForProjectName}
              </div>
            </div>
          ),
          dataIndex: "name",
          key: "id",
          width: "400px",
          className: "project-code-column",
          sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        },
        {
          title: (
            <div className="project-code-column-header">
              <div style={{ fontSize: "16px" }}>
                {enumForAudit.labelForCustomerName}
              </div>
            </div>
          ),
          dataIndex: "customer.name",
          key: "customer.name",
          width: "400px",
          className: "project-code-column",
          sorter: (a: any, b: any) =>
            a.customer.name.localeCompare(b.customer.name),
          render: (text, record) => {
            return record.customer ? record.customer.name : null;
          },
        },
        ...commonColumns,
      ];
    } else if (entity === "Customer") {
      return [
        {
          title: (
            <div className="project-code-column-header">
              <div style={{ fontSize: "16px" }}>
                {enumForAudit.labelForCustomerName}
              </div>
            </div>
          ),
          dataIndex: "name",
          key: "name",
          width: "400px",
          className: "project-code-column",
          sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        },
        ...commonColumns,
      ];
    } else {
      return commonColumns;
    }
  };
  let twoDigitsOfYear =
    year !== null && year !== undefined ? year.toString().slice(-2) : "";
  const quarters = [
    {
      quarterInterval: `JFM ${twoDigitsOfYear}`,
      startDate: "01 Jan",
      endDate: "31 Mar",
    },
    {
      quarterInterval: `AMJ ${twoDigitsOfYear}`,
      startDate: "01 Apr",
      endDate: "30 June",
    },
    {
      quarterInterval: `JAS ${twoDigitsOfYear}`,
      startDate: "01 July",
      endDate: "31 Sep",
    },
    {
      quarterInterval: `OND ${twoDigitsOfYear}`,
      startDate: "01 Oct",
      endDate: "31 Dec",
    },
  ];

  useEffect(() => {
    getUser();
    getFinancialYearData();
  }, [userId]);

  useEffect(() => {
    if (auditCsrData) {
      const results: any = auditCsrData?.filter((item: any) =>
        item.name?.toLowerCase()?.includes(nameSearch?.toLowerCase())
      );
      setFilterData(results);
      setFilterTotalCount(results?.length);
    }
  }, [nameSearch, auditCsrData]);
  const getUser = async () => {
    try {
      const response = await AxiosConfig.get("users/current", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.data && response.data.data.user) {
        const userId = response.data.data.user.id;
        setUserId(userId);
        getCsrAuditData(userId);
      } else {
        console.error("User data not found in the API response");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const getFinancialYearData = async () => {
    try {
      const response = await AxiosConfig.get("/financialYears", {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data && response.data.data && response.data.data.years) {
        const finYearsData = response.data.data.years.map((finyears: any) =>
          new Date(finyears.start_date).getFullYear()
        );

        setFinancialYears(finYearsData);
      } else {
        console.error("User data not found in the API response");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const [description, setDescription] = useState("");
  useEffect(() => {
    getCsrAuditData(userId);
  }, []);

  const getCsrAuditData = async (userId: any) => {
    try {
      setLoading(true);
      const res = await AxiosConfig.get(
        `audit/${userId}?group_id=${id}&entity=${entity}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const tableData = res?.data?.data?.projects;
      // We need unique Customer Names to be used in Customer Name filter as there can be multiple projects with same customer name
      if (entity === "Customer") {
        const arrayUniqueByKey = [
          ...new Map(
            res?.data?.data?.projects?.map((item) => [item.name, item])
          ).values(),
        ];
        const allCustomerName = arrayUniqueByKey?.map((proj: any) => {
          return { value: proj.id, label: proj.name };
        });
        setCustomerNames(allCustomerName);
      } else {
        const arrayUniqueByKey = [
          ...new Map(
            res?.data?.data?.projects?.map((item) => [item.customer.name, item])
          ).values(),
        ];
        const allCustomerName = arrayUniqueByKey?.map((proj: any) => {
          return { value: proj.id, label: proj.customer.name };
        });
        setCustomerNames(allCustomerName);
      }
      setAuditCsrData(tableData);
      if (customerNameFilter.length) {
        handleCustomerSelect(customerNameFilter);
      } else {
        setFilterData(tableData);
        setFilterTotalCount(tableData?.length);
      }
      const reviewObj: any = await AxiosConfig.get(`reviewSettings/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const reviewDataArray = reviewObj.data.data.reviewData;
      reviewDataArray != null && reviewDataArray != undefined
        ? setAccesToAll(reviewDataArray[0].access_to_all == true ? 1 : 0)
        : 0;
      const description = reviewDataArray[0].description;
      setDescription(description);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error fetching Audit WSR data:", error);
    }
  };
  function isCurrentQuarter(quarterIdentifier) {
    const currentMonth = new Date().getMonth() + 1;

    if (currentMonth >= 1 && currentMonth <= 3) {
      return quarterIdentifier == "JFM";
    } else if (currentMonth >= 4 && currentMonth <= 6) {
      return quarterIdentifier == "JFM" || quarterIdentifier == "AMJ";
    } else if (currentMonth >= 7 && currentMonth <= 9) {
      return (
        quarterIdentifier == "JFM" ||
        quarterIdentifier == "AMJ" ||
        quarterIdentifier == "JAS"
      );
    } else if (currentMonth >= 10 && currentMonth <= 12) {
      return (
        quarterIdentifier == "JFM" ||
        quarterIdentifier == "AMJ" ||
        quarterIdentifier == "JAS" ||
        quarterIdentifier == "OND"
      );
    }
  }

  const [recordedByInfo, setRecordedByInfo] = useState<{
    recorded_by_firstName: string | null;
    recorded_by_lastName: string | null;
  }>({ recorded_by_firstName: null, recorded_by_lastName: null });

  const handleCellClick = (
    quarterly_type: any,
    id: any,
    year: any,
    func_type: any,
    project_name: any,
    group_id: any,
    project_code: any,
    customer_id: any,
    customer_name: any,
    recorded_by_firstName: any,
    recorded_by_lastName: any
  ) => {
    setYear(year);
    setQuarterly(quarterly_type);
    if (entity == "Project") {
      setProjectId(id);
      setProjectCode(project_code);
    } else if (entity == "Customer") {
      setCustomerId(customer_id);
      setCustomerName(customer_name);
    }

    setResGroupId(group_id);

    if (
      (year === currentYear && !isCurrentQuarter(quarterly_type)) ||
      year > currentYear
    ) {
      errorNotification(
        "Sorry, data entry for the upcoming quarter is not allowed"
      );
    } else {
      setIsModalOpen(true);
    }
    setFuncType(func_type);
    setProjectName(project_name);
    setProjectCode(project_code);

    setRecordedByInfo({
      recorded_by_firstName,
      recorded_by_lastName,
    });
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    getCsrAuditData(userId);
  };

  const handleOnChange = (event) => {
    setNameSearch(event.target.value);
  };

  const quarterLabels = ["JFM", "AMJ", "JAS", "OND"];
  const selectedYear = year.toString();

  const csvData = filterData.map((entity: any) => {
    const rowData: any = {};

    if ("project_code" in entity) {
      rowData.project_code = entity.project_code;
      rowData["Project name"] = `"${entity.name}"`;
      rowData["Customer name"] = `"${entity.customer.name}"`;
    } else {
      rowData["Customer name"] = `"${entity.name}"`;
    }

    if (entity.quarterly_data) {
      quarterLabels.forEach((quarterLabel) => {
        const quarterData = entity.quarterly_data.find(
          (data: any) =>
            data.quarterly.includes(quarterLabel) &&
            data.year.toString() === selectedYear
        );
        rowData[`${quarterLabel}_${selectedYear}`] = quarterData
          ? quarterData.average_score
          : "";
      });
    } else {
      quarterLabels.forEach((quarterLabel) => {
        rowData[`${quarterLabel}_${selectedYear}`] = "";
      });
    }

    return rowData;
  });

  let csvHeader: string[] = [];

  if (filterData.length > 0) {
    const entity = filterData[0] as { quarterly_data?: any[] };

    if ("project_code" in entity) {
      csvHeader = [
        "project_code",
        "Project name",
        "Customer name",
        ...quarterLabels.map((label) => `${label}_${selectedYear}`),
      ];
    } else {
      csvHeader = [
        "Customer name",
        ...quarterLabels.map((label) => `${label}_${selectedYear}`),
      ];
    }
  }

  let csvContent = csvHeader.join(",") + "\n";
  csvData.forEach((row) => {
    const values = csvHeader.map((header) => {
      if (header in row) {
        return row[header] !== undefined ? row[header] : "";
      } else {
        return "";
      }
    });
    csvContent += values.join(",") + "\n";
  });

  const handleQuarterSelect = (quarter) => {
    if (quarter) {
      setFilteredColumns([quarter, ...fixedColumns]);
    } else {
      setFilteredColumns([...fixedQuarterColumns, ...fixedColumns]);
    }
  };

  //It will filter out the quarter columns from all the columns
  useEffect(() => {
    if (filteredColumns) {
      if (filteredColumns.length && financialYears.length > 0) {
        const temp = allColumns.filter((f) => filteredColumns.includes(f.key));
        if (financialYears.length > 0) {
          setTableColumns(temp);
        }
      } else {
        if (financialYears.length > 0) {
          setTableColumns(generateColumns() || []);
        }
      }
    }
    return () => {};
  }, [filteredColumns]);

  useEffect(() => {
    //Condition 1 : If quarter filter is not enabled and we have changed the year
    //Condition 2 (else condition): If quarter filter is enabled and we have changed the year so we need to filter out the quarter's data from the new selected year
    if (!filterQuarter && financialYears.length > 0) {
      setTableColumns(generateColumns() || []);
      setAllColumns(generateColumns() || []);
    } else {
      financialYears.length > 0 ? setAllColumns(generateColumns() || []) : [];
      const newYearColumns = financialYears.length > 0 ? generateColumns() : [];
      const temp =
        (newYearColumns as { key: string }[])?.filter((f) =>
          filteredColumns.includes(f.key)
        ) || [];
      setTableColumns(temp);
      setAllColumns(generateColumns() || []);
    }
  }, [year, financialYears]);

  return (
    <div>
      <Row gutter={24}>
        <Col xl={6} xs={24}>
          <span className="back-button" onClick={() => history.push("/audits")}>
            <img src={arrow} />
            {app.AuditstextForBack}
          </span>
        </Col>
      </Row>
      <Row className="audit-wsr-search">
        <Col>
          <div className="searchForm" style={{ width: "200px" }}>
            <Input.Search
              allowClear
              onChange={handleOnChange}
              placeholder="Search"
            />
          </div>
        </Col>
        <Col className="column2-style z-11 filter-checkbox-width">
          <ReactMultiSelectCheckboxes
            options={customerNames}
            onChange={(e: any) => {
              handleCustomerSelect(e);
            }}
            value={customerNameFilter}
            placeholderButtonLabel="Customer Name"
          />
        </Col>
        <Col>
          <div>
            <span className="div-padding">Year : </span>
            <Select
              value={year}
              className="year-dropdown"
              onChange={(value: any) => {
                setYear(value);
              }}
            >
              {financialYears.map((year: any, index: any) => (
                <Option key={index} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col>
          <div>
            <span className="div-padding1">Quarter : </span>
            <Select
              className="week-dropdown"
              onChange={(value) => {
                setFilterQuarter(value);
                handleQuarterSelect(value);
              }}
              options={optionsQuarter}
              allowClear
              value={filterQuarter}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="description">
            <label>Description: {description}</label>
          </div>
        </Col>
      </Row>
      <Row className="mt-audit-12 mb-10">
        <Col xs={20} className="pos-relative">
          <div className="total-project-container-psat-csat">
            <label>
              {entity == "Project"
                ? enumForAudit?.labelForPojectCount
                : enumForAudit?.labelForCustomersCount}{" "}
              : {filterTotalCount}
            </label>
          </div>
        </Col>
        <Col xs={4}>
          <div className="float-right">
            <CSVLink
              data={csvContent}
              filename="audit_quaterly_data.csv"
              className="exportTableButton-audit"
              title="Export Table"
            >
              <ExportOutlined className="exportOultineIcon" />
            </CSVLink>
          </div>
        </Col>
      </Row>

      {loading ? (
        <Loader />
      ) : (
        <Table
          className={"custom-table fixed-header"}
          dataSource={filterData.sort((a: any, b: any) =>
            a.project_code.localeCompare(b.project_code)
          )}
          columns={tableColumns}
          loading={loading}
          bordered
          pagination={false}
          style={{ tableLayout: "fixed", overflow: "auto" }}
          scroll={{ y: `calc(100vh - 135px)` }}
        />
      )}
      <div>
        {isModalOpen && (
          <SessionAudit
            response_group_id={response_group_id}
            group_id={id}
            project_id={project_id || null}
            is_month_report={false}
            week_no={null}
            month_no={null}
            year={year}
            func_type={func_type}
            project_name={projectName}
            recorded_by={`${recordedByInfo.recorded_by_firstName ?? ""} ${
              recordedByInfo.recorded_by_lastName ?? ""
            }`}
            onClose={handleModalCancel}
            accessToAll={accessToAll}
            month_name={""}
            is_quarterly_report={true}
            quarterly_type={quarterly_type}
            customer_id={customer_id || null}
            entity={entity}
            project_code={projectCode}
            customer_name={customer_name}
          />
        )}
      </div>
    </div>
  );
};
export default AuditsCSAT;
