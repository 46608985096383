import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Card, Spin } from "antd";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableElement from "./dragableElement";
import { getFinYear } from "../../stores/actions/getFinYearAction";
import { getCustomerClassification } from "../../stores/actions/getCustomerClassificationAction";
import { getCustomerClassificationTypes } from "../../stores/actions/getCustomerClassificationTypesAction";
import { getAllCustomers } from "../../stores/actions/getAllCustomerAction";
import ClassificationPopup from "../ClassificationPopup/ClassificationPopup";
import PasswordPopup from "../../components/ClassificationPopup/ClassificationPopupForEditAccess";
import { errorNotification } from "../../utils/util";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Select, Col } from "antd";
import ButtonReuse from "../Buttons/Button";
import { getEditCustomerClassification } from "../../stores/actions/getEditCustomerClassificationAction";
import "./dragList.scss";
import { currentFyYear } from "../../utils/util";
import { Axios } from "../../utils/axios";
import { dragList } from "../../utils/enums";
const DragDropContextContainer = styled.div`
  padding: 40px;
  border-radius: 6px;
`;

const ListGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
`;

const removeFromList = (list, index) => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

const addToList = (list, index, element) => {
  list = list || [];
  const result = Array.from(list);
  result.splice(index, 0, element);
  return result;
};

const lists = ["New Logo", "Sales Managed", "Account Managed"];

function DragList(props: {
  index: any;
  visible?: any;
  onClose?: any;
  onCancelButton?: any;
  onEdit?: any;
}) {
  const { Option } = Select;
  const selectedMonth: any = useRef();
  const [customer, setCustomer] = useState([]);
  const [finYearData, setFinYearData] = useState([]);
  const [finYearId, setFinYearId] = useState([]);
  const [reducedData, setReducedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [SelectedFyId, setSelectedFyId] = useState<number | string>(0);
  const [popupData, setPopupData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  const dispatch: any = useDispatch();
  const finYear = useSelector((store: any) => store?.finYear?.finYear);
  const customerClassification = useSelector(
    (store: any) => store?.customerClassification
  );

  const allCustomers = useSelector((store: any) => store?.allCustomers);

  const index = props.index;

  useEffect(() => {
    dispatch(getFinYear());
    dispatch(getCustomerClassificationTypes());
    dispatch(getAllCustomers());
  }, [dispatch]);

  useEffect(() => {
    if (finYear) {
      setFinYearData(finYear);
      finYear.map((item: any) => {
        if (index == props.index) {
          setFinYearId(item?.id);
        }
      });
    }
  }, [finYear]);

  useEffect(() => {
    if (finYear && finYearId && SelectedFyId === 0)
      dispatch(getCustomerClassification(finYearId));
  }, [dispatch, finYear, SelectedFyId, finYearId]);

  useEffect(() => {
    if (SelectedFyId) {
      dispatch(getCustomerClassification(SelectedFyId));
    }
  }, [dispatch, SelectedFyId]);

  useEffect(() => {
    if (customerClassification) {
      setCustomer(customerClassification?.customerClassification);
    }
  }, [customerClassification]);

  useEffect(() => {
    if (customer.length !== 0) {
      let newArray: any = [...customer];

      newArray = newArray.reduce(function (r, a) {
        r[a?.customer_classification_type?.title] =
          r[a?.customer_classification_type?.title] || [];

        r[a?.customer_classification_type?.title].push(a);
        return r;
      }, {});

      setReducedData(newArray);
    } else if (allCustomers?.length !== 0) {
      setReducedData({
        "New Logo": allCustomers?.allCustomers,
        "Sales Managed": [],
        "Account Managed": [],
      });
    }
  }, [customer, allCustomers]);

  const handleSave = () => {
    if (reducedData && Object.keys(reducedData).length !== 0) {
      let copyOfReducedData = { ...reducedData };
      let data: any = { finYearId: null, classification: [] };

      data.finYearId = SelectedFyId ? SelectedFyId : finYearId;
      if (copyOfReducedData && Object.keys(copyOfReducedData).length !== 0) {
        const NewLogoclassification2 = copyOfReducedData["New Logo"]?.map(
          (item: any) => {
            return item?.customer && item?.customer?.id
              ? item?.customer?.id
              : item?.id;
          }
        );
        data.classification = [
          ...data.classification,
          {
            id: 1,
            customer_id:
              NewLogoclassification2 && NewLogoclassification2.length !== 0
                ? NewLogoclassification2
                : [],
          },
        ];

        const SalesManagedclassification2 = copyOfReducedData[
          "Sales Managed"
        ]?.map((item: any) => {
          return item?.customer && item?.customer?.id
            ? item?.customer?.id
            : item?.id;
        });
        data.classification = [
          ...data.classification,
          {
            id: 2,
            customer_id:
              SalesManagedclassification2 &&
              SalesManagedclassification2.length !== 0
                ? SalesManagedclassification2
                : [],
          },
        ];

        const AccountManagedclassification2 = copyOfReducedData[
          "Account Managed"
        ]?.map((item: any) => {
          return item?.customer && item?.customer?.id
            ? item?.customer?.id
            : item?.id;
        });
        data.classification = [
          ...data.classification,
          {
            id: 3,
            customer_id:
              AccountManagedclassification2 &&
              AccountManagedclassification2.length !== 0
                ? AccountManagedclassification2
                : [],
          },
        ];
      }

      if (data && data?.length != 0) {
        setLoading(true);
        setPopupData(data);
        setShowPopup(true);
        setLoading(false);
      }
    }
  };

  const handleConfirmClick = async (password) => {
    try {
      const response = await Axios.put("auth/verifySuperSecretPassword", {
        supersec_pwd: password,
      });
      if (response && response.status === 200) {
        setIsSave(true);
        notification.success({
          message: "Successful",
          description: `Please add customer classification`,
        });
        setShowPopup1(false);
      } else {
        errorNotification("");
      }
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: `Invalid Super secret password`,
      });
      setShowPopup1(true);
    }
  };

  const handleCancelClick = () => {
    setShowPopup1(false);
  };

  const handlePopupYesClick = () => {
    if (popupData) {
      dispatch(getEditCustomerClassification(popupData));
      notification.success({
        message: "Successful",
        description: `Successfully updated customer classification`,
      });
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    }
    setShowPopup(false);
  };
  const handlePopupNoClick = () => {
    setShowPopup(false);
    setPopupData(null);
  };

  const onDragEnd = (result) => {
    if (!result?.destination) {
      return;
    }
    const listCopy = { ...reducedData };

    const sourceList = listCopy[result?.source?.droppableId];
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      result?.source?.index
    );
    listCopy[result?.source?.droppableId] = newSourceList;
    const destinationList = listCopy[result?.destination?.droppableId];
    listCopy[result?.destination?.droppableId] = addToList(
      destinationList,
      result?.destination?.index,
      removedElement
    );

    setReducedData(listCopy);
  };

  const handleEditClick = () => {
    setIsEditModeEnabled(true);
  };

  const handleClick = () => {
    handleEditClick();
    setShowPopup1(!showPopup);
  };

  return reducedData && Object.keys(reducedData)?.length !== 0 ? (
    <>
      <div>
        {loading ? (
          <Card className="card-Details">
            <h1> Loading content please wait... </h1>
            <br></br>
            <div className="loader-container">
              <Spin size="large" className="spin-Details" />
            </div>
          </Card>
        ) : (
          showPopup && (
            <ClassificationPopup
              onYesClick={handlePopupYesClick}
              onNoClick={handlePopupNoClick}
            />
          )
        )}
        <Row>
          <Col md={3} xs={18} lg={5} xl={5}>
            <div className="label">
              <label className="year_text" htmlFor="financial-year">
                {dragList.labelForFinancialYear}
              </label>
            </div>
          </Col>
          <Col md={15} xs={12}>
            <Select
              id="financial-year"
              placeholder={"Select a year"}
              onChange={(value: any) => {
                setSelectedFyId(value);
                dispatch(getCustomerClassification(SelectedFyId));
              }}
              defaultValue={currentFyYear()}
            >
              {finYearData &&
                finYearData.map(function (finYear: any, index: any) {
                  return (
                    <>
                      <Option
                        key={index}
                        value={finYear?.id}
                        selected={selectedMonth?.current}
                      >
                        {finYear?.fy_title}{" "}
                      </Option>
                    </>
                  );
                })}
            </Select>
          </Col>
          <Col md={2} xs={18}>
            {isEditModeEnabled && isSave == true && (
              <ButtonReuse
                type="primary"
                className="primary-btn"
                value="Save"
                onClick={handleSave}
              ></ButtonReuse>
            )}
          </Col>
          <div>
            {isEditModeEnabled && isSave == true && (
              <ButtonReuse
                type="primary"
                className="cancel--btn"
                value="Cancel"
                onClick={() => {
                  window.location.reload();
                }}
              ></ButtonReuse>
            )}{" "}
            <Col md={2} xs={0}>
              {!isEditModeEnabled && showPopup1 == false && (
                <Button
                  className="Classification_Edit"
                  type="primary"
                  onClick={handleClick}
                >
                  Edit
                </Button>
              )}
            </Col>
          </div>
        </Row>
        <DragDropContextContainer>
          <DragDropContext onDragEnd={onDragEnd}>
            <ListGrid>
              {reducedData &&
                Object.keys(reducedData).length !== 0 &&
                lists.map((listKey) => (
                  <DraggableElement
                    elements={reducedData[listKey]}
                    key={listKey}
                    prefix={listKey}
                    isEditModeEnabled={isEditModeEnabled}
                  />
                ))}
            </ListGrid>
          </DragDropContext>
        </DragDropContextContainer>
      </div>
      {showPopup1 && (
        <PasswordPopup
          onConfirm={handleConfirmClick}
          onCancel={handleCancelClick}
        />
      )}
    </>
  ) : (
    <>
      <h1>{dragList.labelForCustomerClassHeading}</h1>
      <Row className="classification_years">
        <label htmlFor="financial-year">{dragList.labelForFinancialYear}</label>
        <Select
          id="financial-year"
          placeholder={"Select a year"}
          onChange={(value: any) => {
            setSelectedFyId(value);
            dispatch(getCustomerClassification(SelectedFyId));
          }}
          defaultValue={currentFyYear()}
        >
          {finYearData &&
            finYearData.map(function (finYear: any) {
              return (
                <>
                  <Option value={finYear?.id} selected={currentFyYear()}>
                    {finYear?.fy_title}{" "}
                  </Option>
                </>
              );
            })}
        </Select>

        <Button
          className="Classification_Edit"
          type="primary"
          onClick={handleClick}
        >
          Edit
        </Button>
      </Row>
    </>
  );
}

export default DragList;
