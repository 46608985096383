//import Action Types
import {
    GET_INVOICE,
    GET_INVOICE_ERROR,
  } from "../../constants";
  
  //Write down the initial state of the reducer
  interface CurrencyState {
    allInvoice: Array<object>;
    error: string;
  }
  
  const INITIAL_STATE: CurrencyState = {
    allInvoice: [],
    error: "",
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_INVOICE:
        return { ...state, allInvoice: action.payload };
      case GET_INVOICE_ERROR:
        return { ...state, error: action.payload };
  
      default:
        return state;
    }
  };
  