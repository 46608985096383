import { Upload, Modal, notification, Row } from "antd";
import ButtonReuse from "../../../components/Buttons/Button";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Formik } from "formik";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";

const UploadProjectAttachment = (props: { projectId: any }) => {
  const token = getToken();
  const FormData = require("form-data");
  const data = new FormData();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [previewTitle, setPreviewTitle] = useState();
  const [files, setFiles] = useState<any>([]);

  const handlePreview = async (file: any) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancelModal = () => {
    setPreviewVisible(false);
  };

  const handleAttachmentChange = ({ fileList }: any) => {
    setFiles(fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleFormSubmit = () => {
    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i].originFileObj);
    }

    if (files.length === 0) {
      const notify = () => {
        notification.open({
          message: "No Files added",
        });
      };
      notify();
    } else {
      AxiosConfig.post("projects/attachments/upload/" + props.projectId, data, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          if (response.status === 200) {
            const notify = () => {
              notification.open({
                message: response.data.status.message,
              });
            };
            notify();
          }
        })
        .catch(function (error) {
          const notify = () => {
            notification.open({
              message: "",
              description: error.response.data.status.message,
              style: { color: "red" },
            });
          };
          notify();
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          files: [],
        }}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="add_po_earning_form">
            <Upload
              listType="picture-card"
              fileList={files}
              onPreview={handlePreview}
              onChange={handleAttachmentChange}
              beforeUpload={() => false}
            >
              {uploadButton}
            </Upload>
            <Modal
              visible={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancelModal}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
            <Row style={{ justifyContent: "center" }}>
              <ButtonReuse
                type="submit"
                className="upload-attachment-button"
                htmlType="submit"
                value="Upload"
              ></ButtonReuse>
            </Row>
          </form>
        )}
      </Formik>
    </>
  );
};

export default UploadProjectAttachment;
