import React, { useState, useEffect } from "react";
import { Table, Button, Space, Row, Col, Input, Select } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { enumForAudit } from "../../../utils/enums";
import { AxiosConfig } from "../../../ApiConfig";
import { errorNotification } from "../../../utils/util";
import { getToken } from "../../../auth";
import SessionAudit from "../../../components/AuditModal/SessionAudit";
import { format, getWeek } from "date-fns";
import { useParams, useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import "antd/dist/antd.css";
require("./Audits.scss");
import arrow from "../../../assets/images/arrow.png";
import { app } from "../../../utils/enums";
import Loader from "../../../components/Loader/Loader";
import { CSVLink } from "react-csv";
import { ExportOutlined } from "@ant-design/icons";
const AuditsWSR = () => {
  let history = useHistory();

  const { id }: any = useParams();
  const location = useLocation();
  const passedData = location.state;
  const { entity }: any = passedData;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [filterWeek, setFilterWeek] = useState("");
  const [project_id, setProjectId] = useState();
  const [response_group_id, setResGroupId] = useState();
  const [func_type, setFuncType] = useState();
  const [week_no, setWeekNo] = useState(53);
  const [projectOwners, setProjectOwners] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = getToken();
  const currentWeek = getWeek(new Date(year, 0));
  const todayWeek = getWeek(new Date());
  const { Option } = Select;

  const [isFilter, setIsFilter] = useState(false); // eslint-disable-line
  const fixedColumns = ["project_code", "name"];
  const fixedOptionsWeeks = [
    { label: "Week 1", value: "week1" },
    { label: "Week 2", value: "week2" },
    { label: "Week 3", value: "week3" },
    { label: "Week 4", value: "week4" },
    { label: "Week 5", value: "week5" },
    { label: "Week 6", value: "week6" },
    { label: "Week 7", value: "week7" },
    { label: "Week 8", value: "week8" },
    { label: "Week 9", value: "week9" },
    { label: "Week 10", value: "week10" },
    { label: "Week 11", value: "week11" },
    { label: "Week 12", value: "week12" },
    { label: "Week 13", value: "week13" },
    { label: "Week 14", value: "week14" },
    { label: "Week 15", value: "week15" },
    { label: "Week 16", value: "week16" },
    { label: "Week 17", value: "week17" },
    { label: "Week 18", value: "week18" },
    { label: "Week 19", value: "week19" },
    { label: "Week 20", value: "week20" },
    { label: "Week 21", value: "week21" },
    { label: "Week 22", value: "week22" },
    { label: "Week 23", value: "week23" },
    { label: "Week 24", value: "week24" },
    { label: "Week 25", value: "week25" },
    { label: "Week 26", value: "week26" },
    { label: "Week 27", value: "week27" },
    { label: "Week 28", value: "week28" },
    { label: "Week 29", value: "week29" },
    { label: "Week 30", value: "week30" },
    { label: "Week 31", value: "week31" },
    { label: "Week 32", value: "week32" },
    { label: "Week 33", value: "week33" },
    { label: "Week 34", value: "week34" },
    { label: "Week 35", value: "week35" },
    { label: "Week 36", value: "week36" },
    { label: "Week 37", value: "week37" },
    { label: "Week 38", value: "week38" },
    { label: "Week 39", value: "week39" },
    { label: "Week 40", value: "week40" },
    { label: "Week 41", value: "week41" },
    { label: "Week 42", value: "week42" },
    { label: "Week 43", value: "week43" },
    { label: "Week 44", value: "week44" },
    { label: "Week 45", value: "week45" },
    { label: "Week 46", value: "week46" },
    { label: "Week 47", value: "week47" },
    { label: "Week 48", value: "week48" },
    { label: "Week 49", value: "week49" },
    { label: "Week 50", value: "week50" },
    { label: "Week 51", value: "week51" },
    { label: "Week 52", value: "week52" },
  ];
  const fixedWeeksColumns = [
    "week1",
    "week2",
    "week3",
    "week4",
    "week5",
    "week6",
    "week7",
    "week8",
    "week9",
    "week10",
    "week11",
    "week12",
    "week13",
    "week14",
    "week15",
    "week16",
    "week17",
    "week18",
    "week19",
    "week20",
    "week21",
    "week22",
    "week23",
    "week24",
    "week25",
    "week26",
    "week27",
    "week28",
    "week29",
    "week30",
    "week31",
    "week32",
    "week33",
    "week34",
    "week35",
    "week36",
    "week37",
    "week38",
    "week39",
    "week40",
    "week41",
    "week42",
    "week43",
    "week44",
    "week45",
    "week46",
    "week47",
    "week48",
    "week49",
    "week50",
    "week51",
    "week52",
  ];

  const [weeksColumns, setWeeksColumns] = useState(
    isLeapYear(new Date().getFullYear())
      ? [...fixedWeeksColumns, "week53"]
      : fixedWeeksColumns
  );
  const [optionsWeeks, setOptionsWeeks] = useState(
    isLeapYear(new Date().getFullYear())
      ? [...fixedOptionsWeeks, { label: "Week 53", value: "week53" }]
      : fixedOptionsWeeks
  );

  const [nameSearch, setNameSearch] = useState("");
  const [userId, setUserId] = useState();
  const [auditWsrData, setAuditWsrData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterTotalCount, setFilterTotalCount] = useState(0);
  const [currentDisplayedWeek, setCurrentDisplayedWeek] = useState(currentWeek);
  const [currentFirstWeek, setCurrentfirstWeek] = useState(currentWeek);
  const [currentLastWeek, setCurrentlastWeek] = useState(currentWeek + 4);
  const [financialYears, setFinancialYears] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectCode, setProjectCode] = useState("");
  const [accessToAll, setAccesToAll] = useState(0);
  const [customer_id, setCustomerId] = useState();
  const [customer_name, setCustomerName] = useState();
  const [customerNames, setCustomerNames]: any = useState([]);

  const [tableColumns, setTableColumns]: any = useState([]);
  const [allColumns, setAllColumns]: any = useState([]);
  const [filteredColumns, setFilteredColumns]: any = useState([
    ...weeksColumns,
    ...fixedColumns,
  ]);
  const [projectCustomerFilter, setProjectCustomerFilter] = useState([]);
  const [projectOwnersFilter, setProjectOwnersFilter] = useState([]);
  const [yearObj, setYearObj]: any = useState({});

  // tableColumns state have data with all the columns, used for table
  // allColumns state have data with all the columns, used for filtering
  // filteredColumns state contains all the shown columns
  function getWeekRangeDates(weekNumber, year) {
    const allweekData = yearObj?.[year];
    const weekData = allweekData.find((obj) => obj.weeknumber === weekNumber);
    const { startDate, endDate } = weekData;
    return {
      startDate: format(new Date(startDate), "dd MMM yy"),
      endDate: format(new Date(endDate), "dd MMM yy"),
    };
  }

  const updateDisplayedWeek = (offset) => {
    let weekchk: any;
    const newWeek = currentDisplayedWeek + offset;
    if (offset == -4) {
      weekchk = currentFirstWeek - 4;
      if (weekchk >= 1) {
        setCurrentDisplayedWeek(newWeek);
        setCurrentfirstWeek(weekchk);
        setCurrentlastWeek(weekchk + 4);
      }
    } else {
      weekchk = currentLastWeek + 4;
      setCurrentDisplayedWeek(newWeek);
      setCurrentfirstWeek(weekchk - 4);
      setCurrentlastWeek(weekchk);
    }
  };

  const handleWeekSelect = (week) => {
    if (week) {
      setFilteredColumns([week, ...fixedColumns]);
    } else {
      setFilteredColumns(["week1", "week2", "week3", "week4", ...fixedColumns]);
    }
  };

  //Total count change according to data
  useEffect(() => {
    setFilterTotalCount(filterData?.length);
  }, [filterData]);

  useEffect(() => {
    //Condition 1 : If weeks filter is not enabled and we have changed the year
    //Condition 2 (else condition): If weeks filter is enabled and we have changed the year so we need to filter out the week's data from the new selected year
    if (!filterWeek && financialYears.length > 0) {
      setTableColumns(getColumnsData(4) || []);
      setAllColumns(getColumnsData(isLeapYear(year) ? 53 : 52) || []);
    } else {
      financialYears.length > 0
        ? setAllColumns(getColumnsData(isLeapYear(year) ? 53 : 52) || [])
        : [];
      //If selected weeks filter selected week 53 (which is leap year) now year is changed to non-leap year
      if (
        filteredColumns?.includes("week53") &&
        !isLeapYear(year) &&
        financialYears.length > 0
      ) {
        setFilteredColumns([
          "week1",
          "week2",
          "week3",
          "week4",
          ...fixedColumns,
        ]);
        setFilterWeek("");
        setTableColumns(getColumnsData(4) || []);
        errorNotification("Week 53 is not present in " + year);
      } else {
        const newYearColumns =
          financialYears.length > 0
            ? getColumnsData(isLeapYear(year) ? 53 : 52)
            : [];
        const temp =
          (newYearColumns as { key: string }[])?.filter((f) =>
            filteredColumns.includes(f.key)
          ) || [];
        setTableColumns(temp);
        setTableColumns(temp);
      }
    }
    setWeeksColumns(
      isLeapYear(year) ? [...fixedWeeksColumns, "week53"] : fixedWeeksColumns
    );
    setOptionsWeeks(
      isLeapYear(year)
        ? [...fixedOptionsWeeks, { label: "Week 53", value: "week53" }]
        : fixedOptionsWeeks
    );
  }, [year, currentDisplayedWeek, financialYears]);

  useEffect(() => {
    console.log("financialYears =>", financialYears);
    // Example usage:
    const startDate = `${
      financialYears.length ? financialYears[0] : 2018
    }-01-01`; // Specify the start date (YYYY-MM-DD format)
    const endDate = `${
      financialYears.length ? financialYears[financialYears.length - 1] : year
    }-12-31`; // Specify the end date (YYYY-MM-DD format)
    const weekdaysBetween = getWeekdaysBetween(startDate, endDate);

    if (Object.keys(weekdaysBetween).length > 0) {
      let yearobj: any = {};
      financialYears.forEach(async (year) => {
        const daterecord = getAndRemoveRecords(weekdaysBetween, year);
        yearobj[year] = daterecord;
      });
      setYearObj(yearobj);
    }
  }, [financialYears]);

  function isLeapYear(year) {
    const leap = new Date(year, 1, 29).getDate() === 29;
    return leap ? true : false;
  }

  // Function to get and remove five records from the main object
  function getAndRemoveRecords(weekdaysBetween, year) {
    const keys = Object.keys(weekdaysBetween);

    const Records: any = [];
    for (let i = 0; i < (isLeapYear(year) ? 53 : 52); i++) {
      const key = keys[i];
      if (key) {
        Records.push({
          weeknumber: i + 1,
          ...weekdaysBetween[key], // Spread the existing properties
        });
        delete weekdaysBetween[key];
      }
    }
    return Records;
  }

  const getColumnsData = (weeksLength: number) => {
    let columns: any = [];
    if (Object.keys(yearObj).length > 0) {
      columns = [
        ...Array.from({ length: weeksLength }).map((_, index) => {
          let weekNumber = currentDisplayedWeek + index;
          if (!isLeapYear(year) && weekNumber == 53) {
            weekNumber = 52;
          }
          const { startDate, endDate } =
            weekNumber <= 53
              ? getWeekRangeDates(weekNumber, year)
              : getWeekRangeDates(isLeapYear(year) ? 53 : 52, year);
          return {
            title: (
              <div className="header-div">
                <div className="header-div-child">{`Week ${weekNumber}`}</div>
                <div className="header-week-range">{`${startDate} - ${endDate}`}</div>
              </div>
            ),
            dataIndex: `week${weekNumber}`,
            key: `week${weekNumber}`,
            width: 200,
            onCell: (record) => ({
              onClick: () => {
                const weekData = record.week_data.find(
                  (option: any) =>
                    option.week_no == weekNumber && option.year == year
                );
                weekData
                  ? handleCellClick(
                      weekData.week_no,
                      record.id,
                      weekData.year,
                      "update",
                      record.name,
                      weekData.id,
                      record.project_code,
                      record.id,
                      record.name,
                      weekData.recorded_by.firstName,
                      weekData.recorded_by.lastName
                    )
                  : handleCellClick(
                      weekNumber,
                      record.id,
                      year,
                      "add",
                      record.name,
                      null,
                      record.project_code,
                      record.id,
                      record.name,
                      null,
                      "Not yet Recorded"
                    );
              },
            }),
            render: (_, record) => {
              const weekData = record.week_data.find(
                (option: any) =>
                  option.week_no == weekNumber && option.year == year
              );
              if (weekData) {
                const colorData =
                  weekData.color_code != null ? weekData.color_code : "#B0B0B0";
                const textColor =
                  weekData.color_code != null ? "#000000" : "#000000";
                return weekData ? (
                  <Button
                    style={{
                      backgroundColor: `${colorData}`,
                      width: "100%",
                      borderRadius: "20px",
                      height: "40px",
                      color: `${textColor}`,
                    }}
                  >
                    Total Score :{" "}
                    {weekData
                      ? !isNaN(weekData.average_score)
                        ? weekData.average_score.toFixed(2)
                        : weekData.average_score
                      : null}
                  </Button>
                ) : null;
              }
            },
          };
        }),
      ];
    }
    if (entity === "Project") {
      return [
        {
          title: (
            <div className="project-code-column-header">
              <div style={{ fontSize: "16px" }}>
                {enumForAudit.labelForProjectCode}
              </div>
            </div>
          ),
          dataIndex: "project_code",
          key: "project_code",
          width: 200,
          className: "project-code-column",
          sorter: (a: any, b: any) =>
            a.project_code.replace("STP_", "") -
            b.project_code.replace("STP_", ""),
        },
        {
          title: (
            <div className="project-code-column-header">
              <div style={{ fontSize: "16px" }}>
                {enumForAudit.labelForProjectName}
              </div>
            </div>
          ),
          dataIndex: "name",
          key: "name",
          width: 400,
          className: "project-code-column",
          sorter: (a: any, b: any) => {
            const trimAndSanitize = (input: string) =>
              input.trim().replace(/\s+/g, " ");
            const trimmedA = trimAndSanitize(a.name);
            const trimmedB = trimAndSanitize(b.name);
            return trimmedA.localeCompare(trimmedB, "en", {
              sensitivity: "base",
            });
          },
        },
        ...columns,
      ];
    } else if (entity === "Customer") {
      return [
        {
          title: (
            <div className="project-code-column-header">
              <div style={{ fontSize: "16px" }}>
                {enumForAudit.labelForCustomerName}
              </div>
            </div>
          ),
          dataIndex: "name",
          key: "name",
          width: 400,
          className: "project-code-column",
          sorter: (a: any, b: any) => a.name.localeCompare(b.name),
          render: (text, record) => {
            return record ? record.name : null;
          },
        },
        ...columns,
      ];
    } else {
      return columns;
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getFinancialYearData();
  }, [userId]);
  useEffect(() => {
    if (auditWsrData) {
      const results: any = auditWsrData.filter(
        (item: any) =>
          item?.name?.toLowerCase()?.includes(nameSearch.toLowerCase()) ||
          item?.project_code?.toLowerCase()?.includes(nameSearch.toLowerCase())
      );
      setFilterData(results);
      setFilterTotalCount(results?.length);
    }
  }, [nameSearch]);
  const getUser = async () => {
    try {
      const response = await AxiosConfig.get("users/current", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.data && response.data.data.user) {
        const userId = response.data.data.user.id;
        setUserId(userId);
        getWsrAuditData(userId);
      } else {
        console.error("User data not found in the API response");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const getFinancialYearData = async () => {
    try {
      const response = await AxiosConfig.get("/financialYears", {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data && response.data.data && response.data.data.years) {
        const finYearsData = response.data.data.years.map((finyears: any) =>
          new Date(finyears.start_date).getFullYear()
        );

        setFinancialYears(finYearsData);
      } else {
        console.error("User data not found in the API response");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const [description, setDescription] = useState("");
  useEffect(() => {
    getWsrAuditData(userId);
  }, []);

  const getWsrAuditData = async (userId: any) => {
    try {
      if (userId) {
        setLoading(true);
        const res = await AxiosConfig.get(
          `audit/${userId}?group_id=${id}&entity=${entity}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        const tableData = res.data.data.projects;
        // We need unique Customer Names to be used in Customer Name filter as there can be multiple projects with same customer name
        if (entity === "Customer") {
          const arrayUniqueByKey = [
            ...new Map(
              res?.data?.data?.projects?.map((item) => [item.name, item])
            ).values(),
          ];
          const allCustomerName = arrayUniqueByKey?.map((proj: any) => {
            return { value: proj.id, label: proj.name };
          });
          setCustomerNames(allCustomerName);
        } else {
          const arrayUniqueByKey = [
            ...new Map(
              res?.data?.data?.projects?.map((item) => [
                item.customer.name,
                item,
              ])
            ).values(),
          ];
          const allCustomerName = arrayUniqueByKey?.map((proj: any) => {
            return { value: proj.id, label: proj.customer.name };
          });
          setCustomerNames(allCustomerName);
        }
        if (entity == "Customer") {
          setAuditWsrData(tableData);
          if (projectCustomerFilter.length || projectOwnersFilter.length) {
            if (projectCustomerFilter.length) {
              handleCustomerSelect(projectCustomerFilter);
            }
            if (projectOwnersFilter.length) {
              handleOwnerSelect(projectOwnersFilter);
            }
          } else {
            setFilterData(tableData);
            setFilterTotalCount(tableData?.length);
          }
          const reviewObj: any = await AxiosConfig.get(`reviewSettings/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const reviewDataArray = reviewObj.data.data.reviewData;
          reviewDataArray != null && reviewDataArray != undefined
            ? setAccesToAll(reviewDataArray[0].access_to_all == true ? 1 : 0)
            : 0;
          const description = reviewDataArray[0].description;
          setDescription(description);
        } else {
          const dataWithProjectOwner = tableData?.map((data: any) => {
            return {
              ...data,
              projectOwner:
                data.ProjectOwners[0]?.user?.firstName +
                " " +
                data.ProjectOwners[0]?.user?.lastName,
            };
          });
          setAuditWsrData(dataWithProjectOwner);
          if (projectCustomerFilter.length || projectOwnersFilter.length) {
            if (projectCustomerFilter.length) {
              handleCustomerSelect(projectCustomerFilter);
            }
            if (projectOwnersFilter.length) {
              handleOwnerSelect(projectOwnersFilter);
            }
          } else {
            setFilterData(dataWithProjectOwner);
            setFilterTotalCount(dataWithProjectOwner?.length);
          }

          const projectOwnerList = tableData?.map((data: any) => {
            return {
              value: data.ProjectOwners[0]?.owner_id,
              label:
                data.ProjectOwners[0]?.user?.firstName +
                " " +
                data.ProjectOwners[0]?.user?.lastName,
            };
          });
          const uniqueProjectOwnersList = projectOwnerList.filter(
            (obj, index) => {
              return (
                index ===
                projectOwnerList.findIndex(
                  (projectOwner) => obj.label === projectOwner.label
                )
              );
            }
          );

          setProjectOwners(uniqueProjectOwnersList);

          const reviewObj: any = await AxiosConfig.get(`reviewSettings/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const reviewDataArray = reviewObj.data.data.reviewData;
          const description = reviewDataArray[0].description;
          setDescription(description);
          reviewDataArray != null && reviewDataArray != undefined
            ? setAccesToAll(reviewDataArray[0].access_to_all == true ? 1 : 0)
            : 0;
        }

        //Currenly we are showing only 4 weeks data in the table
        //We need all the week's column and data for filtering out the columns
        if (financialYears.length > 0) {
          setAllColumns(getColumnsData(isLeapYear(year) ? 53 : 52) || []);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("Error fetching Audit WSR data:", error);
    }
  };
  const [recordedByInfo, setRecordedByInfo] = useState<{
    recorded_by_firstName: string | null;
    recorded_by_lastName: string | null;
  }>({ recorded_by_firstName: null, recorded_by_lastName: null });

  const handleCellClick = (
    week_no: any,
    project_id: any,
    year: any,
    func_type: any,
    project_name: any,
    group_id: any,
    project_code: any,
    customer_id: any,
    customer_name: any,
    recorded_by_firstName: any,
    recorded_by_lastName: any
  ) => {
    setYear(year);
    setWeekNo(week_no);
    setResGroupId(group_id);

    if (year > new Date().getFullYear()) {
      errorNotification(
        "Sorry, data entry for the upcoming year is not allowed"
      );
    } else if (week_no > todayWeek && year == new Date().getFullYear()) {
      errorNotification(
        "Sorry, data entry for the upcoming week is not allowed"
      );
    } else {
      setIsModalOpen(true);
    }
    setFuncType(func_type);
    if (entity == "Project") {
      setProjectId(project_id);
      setProjectName(project_name);
      setProjectCode(project_code);
    } else if (entity == "Customer") {
      setCustomerId(customer_id);
      setCustomerName(customer_name);
    }

    setRecordedByInfo({
      recorded_by_firstName,
      recorded_by_lastName,
    });
  };

  const handleModalCancel = () => {
    getWsrAuditData(userId);
    setIsModalOpen(false);
    setNameSearch("");
  };

  const handleOnChange = (event) => {
    setNameSearch(event.target.value);
  };

  const handleOwnerSelect = (owner: any) => {
    owner?.length == 0
      ? setProjectOwnersFilter([])
      : setProjectOwnersFilter(owner);
    if (owner?.length > 0) {
      setIsFilter(true);
      setProjectOwnersFilter(owner);
      setProjectCustomerFilter(projectCustomerFilter);

      const projectOwner = owner?.map((proj) => {
        return proj.label;
      });
      const data = auditWsrData?.filter((checkData: any) =>
        projectOwner.includes(checkData.projectOwner)
      );
      setFilterData(data);
    } else if (projectCustomerFilter.length) {
      setProjectOwnersFilter([]);
      const selectedCustomerName = projectCustomerFilter?.map((cust: any) => {
        return cust.label;
      });
      const data = auditWsrData?.filter((checkData: any) =>
        selectedCustomerName.includes(checkData.name)
      );
      setFilterData(data);
    } else {
      setFilterData(auditWsrData);
      setIsFilter(false);
    }
  };

  //It will filter out the weeks columns from all the columns
  useEffect(() => {
    if (filteredColumns) {
      if (filteredColumns.length && financialYears.length > 0) {
        const temp = allColumns.filter((f) => filteredColumns.includes(f.key));
        if (financialYears.length > 0) {
          setTableColumns(temp);
        }
      } else {
        if (financialYears.length > 0) {
          setTableColumns(getColumnsData(4) || []);
        }
      }
    }
    return () => {};
  }, [filteredColumns]);

  const handleCustomerSelect = (customer: any) => {
    customer?.length == 0
      ? setProjectCustomerFilter([])
      : setProjectCustomerFilter(customer);
    if (customer?.length > 0) {
      setIsFilter(true);
      setProjectCustomerFilter(customer);
      setProjectOwnersFilter(projectOwnersFilter);
      const selectedCustomerName = customer?.map((cust) => {
        return cust.label;
      });
      if (entity === "Customer") {
        const data = auditWsrData?.filter((checkData: any) =>
          selectedCustomerName.includes(checkData.name)
        );
        setFilterData(data);
      } else {
        const data = auditWsrData?.filter((checkData: any) =>
          selectedCustomerName.includes(checkData.customer.name)
        );
        setFilterData(data);
      }
    } else if (projectOwnersFilter.length) {
      setProjectCustomerFilter([]);
      const projectOwner = projectOwnersFilter?.map((proj: any) => {
        return proj.label;
      });
      const data = auditWsrData?.filter((checkData: any) =>
        projectOwner.includes(checkData.projectOwner)
      );
      setFilterData(data);
    } else {
      setFilterData(auditWsrData);
      setIsFilter(false);
    }
  };

  // Define a custom type for start and end dates of a week
  type WeekdayRange = {
    [week: string]: { startDate: string; endDate: string; year: string };
  };

  function getWeekdaysBetween(
    startDate: string,
    endDate: string
  ): WeekdayRange {
    const weekdays: WeekdayRange = {};
    let currentDate = moment(startDate).startOf("isoWeek"); // Start from the Monday of the first week
    const lastDate = moment(endDate).endOf("isoWeek"); // End on the Friday of the last week
    let weekCount = 1;

    // Loop through each week from start date to end date
    while (currentDate.isSameOrBefore(lastDate, "day")) {
      const startOfWeek = currentDate.clone();
      const endOfWeek = currentDate.clone().add(4, "days");
      // Add the current week's Monday to weekdays
      weekdays[`week_${weekCount}`] = {
        startDate: startOfWeek.format("YYYY-MM-DD"),
        endDate: endOfWeek.format("YYYY-MM-DD"),
        year: endOfWeek.format("YYYY"),
      };

      // Move to the next Monday and increment week count
      currentDate.add(1, "weeks");
      weekCount++;
    }
    return weekdays;
  }

  const selectedYear = year.toString();

  const csvData = filterData.map((entity: any) => {
    const rowData: any = {};

    if ("project_code" in entity) {
      rowData.project_code = entity.project_code;
      rowData["Project name"] = `"${entity.name}"`;
    } else {
      rowData["Customer name"] = `"${entity.name}"`;
    }

    if (filterWeek) {
      const filterWeekNumber = parseInt(filterWeek.replace("week", ""));

      const weekData = entity.week_data.find(
        (week: any) =>
          week.week_no === filterWeekNumber &&
          week.year.toString() === selectedYear
      );
      if (weekData) {
        rowData[`${filterWeek}`] = weekData.average_score;
      } else {
        rowData[`week_${filterWeekNumber}`] = "";
      }
    } else {
      for (let i = 1; i <= 53; i++) {
        const weekData = entity.week_data.find(
          (week: any) =>
            week.week_no === i && week.year.toString() === selectedYear
        );
        rowData[`week_${i}`] = weekData ? weekData.average_score : "";
      }
    }

    return rowData;
  });

  let csvHeader: string[] = [];

  if (filterData.length > 0) {
    const entity = filterData[0];
    if ("project_code" in entity) {
      csvHeader = ["project_code", "Project name"];
    } else {
      csvHeader = ["Customer name"];
    }
  }

  if (filterWeek) {
    csvHeader.push(`${filterWeek}`);
  } else {
    for (let i = 1; i <= 53; i++) {
      csvHeader.push(`week_${i}`);
    }
  }

  let csvContent = csvHeader.join(",") + "\n";
  csvData.forEach((row) => {
    const values = csvHeader.map((header) => {
      return row[header] !== undefined
        ? row[header]
        : row[header] === 0
        ? 0
        : "";
    });
    csvContent += values.join(",") + "\n";
  });

  return (
    <div>
      <Row gutter={24}>
        <Col xl={6} xs={24}>
          <span className="back-button" onClick={() => history.push("/audits")}>
            <img src={arrow} />
            {app.AuditstextForBack}
          </span>
        </Col>
      </Row>
      <Row className="audit-wsr-search">
        <Col>
          <div className="searchForm" style={{ width: "200px" }}>
            <Input.Search
              allowClear
              value={nameSearch}
              onChange={handleOnChange}
              placeholder="Search"
            />
          </div>
        </Col>
        <Col className="column2-style z-11 filter-checkbox-width">
          <ReactMultiSelectCheckboxes
            options={customerNames}
            onChange={(e: any) => {
              handleCustomerSelect(e);
            }}
            value={projectCustomerFilter}
            placeholderButtonLabel="Customer Name"
          />
        </Col>
        {entity !== "Customer" && (
          <Col className="column2-style z-11 filter-checkbox-width">
            <ReactMultiSelectCheckboxes
              options={projectOwners}
              onChange={(e: any) => {
                handleOwnerSelect(e);
              }}
              value={projectOwnersFilter}
              placeholderButtonLabel="Project Owner"
            />
          </Col>
        )}
        <Col>
          <div>
            <span className="div-padding1">Year : </span>
            <Select
              value={year}
              className="year-dropdown"
              onChange={(value: any) => {
                setYear(value);
              }}
            >
              {financialYears.map((year: any, index: any) => (
                <Option key={index} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col>
          <div>
            <span className="div-padding1">Week : </span>
            <Select
              className="week-dropdown"
              onChange={(value) => {
                setFilterWeek(value);
                handleWeekSelect(value);
              }}
              options={optionsWeeks}
              allowClear
              value={filterWeek}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="description">
            <label className="description-label1">
              Description: {description}
            </label>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={20} className="pos-relative">
          <div className="total-project-container">
            <label>
              {entity == "Project"
                ? enumForAudit?.labelForPojectCount
                : enumForAudit?.labelForCustomersCount}{" "}
              : {filterTotalCount}
            </label>
          </div>
        </Col>
        <Col xs={4}>
          <div className="float-right">
            <Space className="left-right-navigation-button">
              <Button
                icon={<LeftOutlined />}
                onClick={() => updateDisplayedWeek(-4)}
                disabled={
                  currentFirstWeek <= 1 || filteredColumns?.length === 3
                }
              />
              {/* We don't require the buttons when the filter is applied so other disabled condition is to check whether weeks filter is enabled */}
              <Button
                icon={<RightOutlined />}
                onClick={() => updateDisplayedWeek(4)}
                disabled={
                  currentLastWeek >= 53 || filteredColumns?.length === 3
                }
              />
            </Space>
            <CSVLink
              data={csvContent}
              filename="audit_wsr_data.csv"
              className="exportTableButton-audit"
              title="Export Table"
            >
              <ExportOutlined className="exportOultineIcon" />
            </CSVLink>
          </div>
        </Col>
      </Row>

      {loading ? (
        <Loader />
      ) : (
        <Table
          className={"custom-table fixed-header"}
          dataSource={filterData.sort((a: any, b: any) =>
            a.project_code.localeCompare(b.project_code)
          )}
          columns={tableColumns}
          loading={loading}
          bordered
          pagination={false}
          style={{ tableLayout: "fixed", overflow: "auto" }}
          scroll={{ y: `calc(100vh - 135px)` }}
        />
      )}
      <div>
        {isModalOpen && (
          <SessionAudit
            response_group_id={response_group_id}
            group_id={id}
            project_id={project_id}
            week_no={week_no}
            year={year}
            func_type={func_type}
            project_name={projectName}
            project_code={projectCode}
            recorded_by={`${recordedByInfo.recorded_by_firstName ?? ""} ${
              recordedByInfo.recorded_by_lastName ?? ""
            }`}
            onClose={handleModalCancel}
            is_month_report={false}
            month_no={null}
            accessToAll={accessToAll}
            month_name={null}
            is_quarterly_report={false}
            quarterly_type={null}
            customer_id={customer_id || null}
            entity={entity}
            customer_name={customer_name}
          />
        )}
      </div>
    </div>
  );
};
export default AuditsWSR;
