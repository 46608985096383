//import Action Types 
import { GET_BASE_CURRENCY, GET_BASE_CURRENCY_ERROR} from "../../constants";


//Write down the initial state of the reducer
interface CurrencyState {
    baseCurrency: Array<object>;
    error:string;
  }

const INITIAL_STATE: CurrencyState={
    baseCurrency: [],
    error:"",
};

export default (state=INITIAL_STATE, action)=>{
    switch(action.type){
        case GET_BASE_CURRENCY: return{...state,baseCurrency:action.payload};
        case GET_BASE_CURRENCY_ERROR: return{...state,error:action.payload};
        default:return state;
    }
};