//Define your Action Types here
export const GET_SNAPSHOT_LIST = "GET_SNAPSHOT_LIST";
export const GET_SNAPSHOT_ERROR = "GET_SNAPSHOT_ERROR";

//  For Add invoice and edit invoice
export const GET_CURRENCY = "GET_CURRENCY";
export const GET_CURRENCY_ERROR = "GET_CURRENCY_ERROR";

export const GET_CURRENCY_RATES = "GET_CURRENCY_RATES";
export const GET_CURRENCY_RATES_ERROR = "GET_CURRENCY_RATES_ERROR";

//For Geo Location

export const GET_GEO_LOCATION = "GET_GEO_LOCATION";
export const GET_GEO_LOCATION_ERROR = "GET_GEO_LOCATION_ERROR";

//For Customer Category

export const GET_CUSTOMER_CATEGORY = "GET_CUSTOMER_CATEGORY";
export const GET_CUSTOMER_CATEGORY_ERROR = "GET_CUSTOMER_CATEGORY_ERROR";

//For Base Currency Symbol

export const GET_BASE_CURRENCY = "GET_BASE_CURRENCY";
export const GET_BASE_CURRENCY_ERROR = "GET_BASE_CURRENCY_ERROR";

//For Customer classification - get customer classification board

export const GET_CUSTOMER_CLASSIFICATION = "GET_CUSTOMER_CLASSIFICATION";
export const GET_CUSTOMER_CLASSIFICATION_ERROR =
  "GET_CUSTOMER_CLASSIFICATION_ERROR";

//For FinYear - Customer classification board

export const GET_FIN_YEAR = "GET_FIN_YEAR";
export const GET_FIN_YEAR_ERROR = "GET_FIN_YEAR_ERROR";

//For customers - Get all customers

export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_ERROR = "GET_CUSTOMERS_ERROR";

//For editing customer classification - If FYData is present

export const GET_EDIT_CUSTOMER_CLASSIFICATION =
  "GET_EDIT_CUSTOMER_CLASSIFICATION";
export const GET_EDIT_CUSTOMER_CLASSIFICATION_ERROR =
  "GET_EDIT_CUSTOMER_CLASSIFICATION_ERROR";

//For titles - Customer classification types

export const GET_CUSTOMER_CLASSIFICATION_TYPES =
  "GET_CUSTOMER_CLASSIFICATION_TYPES";
export const GET_CUSTOMER_CLASSIFICATION_TYPES_ERROR =
  "GET_CUSTOMER_CLASSIFICATION_TYPES_ERROR";

//For Super sceret password

export const GET_SCERET_PASSWORD = "GET_SCERET_PASSWORD";
export const GET_SCERET_PASSWORD_ERROR = "GET_SCERET_PASSWORD_ERROR";

//For Sales Allocation

export const GET_SALES_ALLOCATION = "GET_SALES_ALLOCATION";
export const GET_SALES_ALLOCATION_ERROR = "GET_SALES_ALLOCATION_ERROR";

//For Invoice

export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_ERROR = "GET_INVOICE_ERROR";

export const GET_DEPARTMENT = "GET_DEPARTMENT";
export const GET_DEPARTMENT_ERROR = "GET_DEPARTMENT_ERROR";

export const GET_PRACTICES = "GET_PRACTICES";
export const GET_PRACTICES_ERROR = "GET_PRACTICES_ERROR";

export const GET_SECONDARY_SKILLS = "GET_SECONDARY_SKILLS";
export const GET_SECONDARY_SKILLS_ERROR = "GET_SECONDARY_SKILLS_ERROR";

export const GET_BANDS = "GET_BANDS";
export const GET_BANDS_ERROR = "GET_BANDS_ERROR";

export const GET_SKILLS = "GET_SKILLS";
export const GET_SKILLS_ERROR = "GET_SKILLS_ERROR";

export const GET_CURRENCY_RATE = "GET_CURRENCY_RATE";
export const GET_CURRENCY_RATE_ERROR = "GET_CURRENCY_RATE_ERROR";
