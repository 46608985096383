import { useState, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Table, Card, Row, Col, Select } from "antd";
import moment from "moment";
import { topUtilised } from "../../../utils/enums";

const TopUtilised = (props: {
  selectedDate?: any;
  selectedSkills?: any;
  selectedBu?: any;
}) => {
  const [topTen, setTopTen] = useState([]);
  const token = getToken();
  const [noOfDays, setNoOfDays] = useState(30);
  const { Option } = Select;
  const dateFormat = "YYYY-MM-DD";
  const selected_date = moment(props.selectedDate).format(dateFormat);
  const inputData = {
    selected_date: selected_date,
    no_of_days: noOfDays,
    department_id_array: props.selectedBu.map((bu: any) => bu.value),
    skill_id_array: props.selectedSkills.map((skill: any) => skill.value),
  };

  useEffect(() => {
    getData();
  }, [noOfDays, props]);

  const convertNumber = (number: any) => {
    if (number === null) {
      return "0%";
    } else {
      return (
        number
          .toFixed(2)
          .toString()
          .replace(/\B(?=(?:\d{3})+(?!\d))/g, ",") + "%"
      );
    }
  };

  const getData = async () => {
    await AxiosConfig.post("talents/performance", inputData, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setTopTen(
        res.data.data.topTen.map(
          (row: { firstName: any; lastName: any; utilisation: any }) => ({
            Full_name: row.firstName + " " + row.lastName,
            Utilization: convertNumber(row.utilisation),
          })
        )
      );
    });
  };

  const topColumns = [
    {
      title: "FULL NAME",
      dataIndex: "Full_name",
      key: "full_name",
    },
    {
      title: "UTLIZATION %",
      dataIndex: "Utilization",
      key: "utilization",
    },
  ];

  const handleChange = (event: any) => {
    setNoOfDays(Number(event));
  };

  return (
    <>
      <Card className="performanceCardTop">
        <Row>
          <Col span={12}>
            <h2>{topUtilised.headingForPerformanceCard}</h2>
          </Col>
          <Col span={12}>
            <Select
              defaultValue="30"
              style={{ width: "100%" }}
              onChange={handleChange}
            >
              <Option value="30">{topUtilised.optionFor30}</Option>
              <Option value="90">{topUtilised.optionFor90}</Option>
              <Option value="365">{topUtilised.optionFor365}</Option>
            </Select>
          </Col>
        </Row>
        <Table
          columns={topColumns}
          dataSource={topTen}
          className="performanceTable"
          pagination={false}
        />
      </Card>
    </>
  );
};

export default TopUtilised;
