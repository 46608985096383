import React, { useEffect, useState, useRef } from "react";
import { Input, notification, DatePicker, Row, Modal, Button } from "antd";
import { Formik } from "formik";
import TextArea from "antd/lib/input/TextArea";
import ButtonReuse from "../../../components/Buttons/Button";
import { preventMinus } from "../../../utils/util";
import UploadExpenseAttachment from "./UploadAttacmentExpense";
import DeleteExpenseAttachment from "./DeleteAttachmentExpense";
import moment from "moment";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Image } from "antd";
import { InputField } from "../../../components";
import ViewAttachment from "../ProjectDetails/ViewAttachment";
import { DownloadOutlined } from "@ant-design/icons";
import pdfPlaceholderImage from "../../../assets/images/pdfPlaceHolder.png";
import xlsxPlaceholderImage from "../../../assets/images/xlsx_thumbnail.png";
import docxPlaceholderImage from "../../../assets/images/docximage.png";
import { saveAs } from "file-saver";
import * as Yup from "yup";

require("../Customer/AddCreditNote.scss");

type ExpenseItem = {
  id: number;
  reference_no: string;
  vendor_name: string;
  amount: number;
  unaccounted_amount: number;
  notes: string;
  bill_date: string;
  pending_amount: number;
};

const EditExpensesSection = (props) => {
  const {
    onClose,
    onCancelHandler,
    showEditModal,
    isModalVisibleEdit,
    setIsModalVisibleEdit,
    editImage,
    expenseData,
    expenseIds,
    allExpenseData,
    expenseIdDetails,
    attachmentData
  } = props;
  const [isModalVisibleImage, setIsModalVisibleImage] = useState(false);
  const [image, setImage] = useState<any>([]);
  const [expensesData, setExpensesData] = useState<ExpenseItem[]>([]);

  let fileData: any = useRef({});
  let image_arr: any = [];
  let edit_image_arr: any = [];
  const token = getToken();

  const validationSchema = Yup.object().shape({
    reference_no: Yup.string().required("Reference number is required"),
    vendor_name: Yup.string().required("Vendor is required"),
    amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be a positive number"),
    bill_date: Yup.date().nullable().required("Bill date is required"),
    notes: Yup.string().required("Notes are required"),
    expense_type: Yup.string().required("Expense Type is required"),
  });

  const openNotification = () => {
    notification.open({
      message: "Expense Edited Successfully",
    });
  };

  const showViewModalImage = () => {
    setIsModalVisibleImage(true);
  };
  const handleCancelModalEdit = () => {
    setIsModalVisibleEdit(false);
  };
  const handleCancelModalImage = () => {
    setIsModalVisibleImage(false);
  };

  useEffect(() => {
    getExpenseDetailsApiCall();
  }, []);

  const handleDownload = (file_id: any) => {
    saveAs(
      fileData["current"][file_id]["data"],
      fileData["current"][file_id]["attachment_id"].split("^").pop()
    );
  };
  const getAttachments = async (attachment_id: any) => {
    // try {
    //   const res = await AxiosConfig.get(
    //     "creditnote/attachment/" + attachment_id,
    //     {
    //       headers: { Authorization: `Bearer ${token}` },
    //       responseType: "blob",
    //     }
    //   );
      let image_obj: any = {};
      const url = URL.createObjectURL(attachmentData);
      image_obj["id"] = attachment_id;
      image_obj["url"] = url;
      edit_image_arr.push(image_obj);
      image_arr.push(url);
      fileData["current"][url] = { data: attachmentData, attachment_id };
      setImage(image_arr);
    // } catch (error) {
    //   console.error("Error fetching attachments:", error);
    // }
  };

  const getExpenseDetailsApiCall = async () => {
    // try {
    //   const res = await AxiosConfig.get(`expense/${expenseIds}`, {
    //     headers: { Authorization: `Bearer ${token}` },
    //   });
    //   if (res.status === 200) {
    //     const expensesData = res?.data?.data?.expense;
    //     if (expensesData) {         
    //       if (res?.data?.data?.expense?.expense_attachments?.length) {
      expenseIdDetails.expense_attachments.forEach(function (
          row: any
        ) {
          const attachment_id = row.attachment_id;
          getAttachments(attachment_id);
        });
      }
    //     } else {
    //       console.error("No expenses found in the response.");
    //     }
    //   } else {
    //     console.error("Failed to fetch expenses. Status code: ", res.status);
    //   }
    // } catch (error) {
    //   console.error("Error fetching expenses: ", error);
    // }
  // };

    const images = image.map((img_url: any, index: any) => {
    const fileNameWithExtension = fileData["current"][img_url]["attachment_id"];
    const fileName = fileNameWithExtension.split("_").slice(0, -1).join("_");

    const fileType = fileNameWithExtension.split(".").pop();

    return (
      <div className="attachment" key={index}>
        {fileType.toLowerCase() === "pdf" ? (
          <div className="pdf-placeholder">
            <img
              src={pdfPlaceholderImage}
              alt="PDF Placeholder"
              className="pdf-placeholder-image"
            />
          </div>
        ) : fileType.toLowerCase() === "xlsx" ? (
          <div className="xlsx-placeholder">
            <img
              src={xlsxPlaceholderImage}
              alt="XLSX Placeholder"
              className="xlsx-placeholder-image"
            />
          </div>
        ) : fileType.toLowerCase() === "docx" ? (
          <div className="docx-placeholder">
            <img
              src={docxPlaceholderImage}
              alt="DOCX Placeholder"
              className="docx-placeholder-image"
            />
          </div>
        ) : (
          <Image
            className="attachment-img"
            width={62}
            height={62}
            src={img_url}
            alt="Attachment"
          />
        )}
        <Button
          type="primary"
          shape="round"
          icon={<DownloadOutlined />}
          size="small"
          onClick={() => handleDownload(img_url)}
        />
        <text>{fileName}</text>
      </div>
    );
  });

  useEffect(() => {
    fetchExpensesData();
  }, []);

  const fetchExpensesData = async () => {
    // try {
    //   const response = await AxiosConfig.get("expense", {
    //     headers: { Authorization: `Bearer ${token}` },
    //   });
      const expenseData1 = allExpenseData.map((row: any) => {
          return {
            id: row.id,
            unaccounted_amount: row.unaccounted_amount,
          };
        })
      setExpensesData(expenseData1);
    // } catch (error) {
    //   console.error("Error fetching expenses data:", error);
    // }
  };

  return (
    <>
      <Formik
        initialValues={{
          reference_no: expenseData?.reference_no,
          vendor_name: expenseData?.vendor_name,
          expense_type: expenseData?.expense_type,
          amount: expenseData?.amount,
          notes: expenseData?.notes,
          bill_date: moment(expenseData?.bill_date),
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          const matchingExpense = expensesData.find(
            (expense) => expense.id === expenseIds
          );

          if (matchingExpense) {
            const unaccountedAmount = matchingExpense.unaccounted_amount;
            if (values?.amount < unaccountedAmount) {
              notification.error({
                message: "Error",
                description:
                  "Expense amount is lesser than the total adjusted amount.",
                style: { color: "red" },
              });
            } else {
              const selectedDate = values.bill_date;
              const formattedDate = selectedDate
                ? moment(selectedDate).format("YYYY-MM-DD")
                : null;
              const payload = {
                reference_no: values?.reference_no,
                vendor_name: values.vendor_name,
                expense_type: values?.expense_type,
                amount: values.amount,
                notes: values.notes,
                bill_date: formattedDate,
              };
              try {
                const response = await AxiosConfig.put(
                  `expense/${matchingExpense.id}`,
                  payload,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  }
                );

                if (response.status === 200) {
                  openNotification();
                  onClose();
                  setTimeout(function () {
                    window.location.reload();
                  }, 1000);
                }
              } catch (error) {
                console.error("Error updating expense:", error);
                const notify = () => {
                  notification.open({
                    message: "Invalid Data",
                  });
                };
                notify();
              }
            }
          } else {
            console.error("No matching expense found.");
          }
          resetForm();
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          setFieldValue,
         }) => {
          return (
            <form onSubmit={handleSubmit} className="add_project_form">
              <label>{"Reference No."} </label>
              <InputField
                placeholder="Enter the Reference Number"
                name="reference_no"
                onChange={handleChange}
                value={values.reference_no}
              />
              <p className="display_error">{errors.reference_no}</p>

              <div className="input_container">
                <label>{"Vendor"}</label>
                <Input
                  placeholder="Enter the Vendor"
                  name="vendor_name"
                  onChange={handleChange}
                  value={values.vendor_name}
                  disabled={true}
                />
                <p className="display_error">{errors.vendor_name}</p>
              </div>

              <div className="input_container">
                <label>{"Bill Date"}</label>
                <DatePicker
                  className="date-picker-input"
                  value={values.bill_date}
                  onChange={(date) => setFieldValue("bill_date", date)}
                />
                <p className="display_error">{errors.bill_date}</p>
              </div>

              <div>
                <label>{"Amount (In ₹)"}</label>
                <Input
                className="amount_input"
                  placeholder="Enter the Expense Amount"
                  name="amount"
                  onKeyPress={preventMinus}
                  min={0}
                  value={values.amount}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const twoDecimalRegex = /^\d+(\.\d{0,2})?$/;
                    if (twoDecimalRegex.test(inputValue) || inputValue === "") {
                      setFieldValue("amount", inputValue);
                    }
                  }}
                />
                <p className="display_error">{errors.amount}</p>
              </div>

              <div className="input_container">
                <label>{"Type of Expense"}</label>
                <Input
                  placeholder="Select Expense Type"
                  defaultValue={values?.expense_type}
                  disabled={true}
                />
              </div>

              <div>
                <label>{"Notes"}</label>
                <TextArea
                  placeholder="Enter the Notes"
                  rows={5}
                  name="notes"
                  onChange={handleChange}
                  value={values.notes}
                  className="textarea-input"
                />
                <p className="display_error">{errors.notes}</p>
              </div>

              <Row>
                <ButtonReuse
                  value="View"
                  type="button"
                  onClick={showViewModalImage}
                  className="edit-image-button-expense"
                />
                <ButtonReuse
                  value="Edit"
                  type="submit"
                  className="edit-image-button-expense"
                  onClick={showEditModal}
                />
                <Modal
                  title="Attachments"
                  className="AttachmentsModal"
                  visible={isModalVisibleImage}
                  onCancel={handleCancelModalImage}
                >
                  {images.length > 0 ? (
                    <ViewAttachment images={images} />
                  ) : (
                    <p>No Attachments To Display</p>
                  )}
                </Modal>
                <Modal
                  title="Attachments"
                  className="AttachmentsModal"
                  visible={isModalVisibleEdit}
                  onCancel={handleCancelModalEdit}
                >
                  <UploadExpenseAttachment ExpenseID={expenseData?.id} />
                  <DeleteExpenseAttachment images={editImage} />
                </Modal>
              </Row>

              <div className="button-container">
                <ButtonReuse
                  type="primary"
                  className="primary-btn"
                  htmlType="submit"
                  value="Save"
                />
                <ButtonReuse
                  type="primary"
                  className="primary-btn cancel--btn"
                  value="Cancel"
                  onClick={onCancelHandler}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditExpensesSection;
