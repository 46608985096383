import React, { useState, useEffect } from "react";
import { EditCustomer } from "../../../utils/enums";
import "antd/dist/antd.css";
import { Drawer, notification, Input } from "antd";
import Switch from "react-switch";
import { Formik } from "formik";
import * as yup from "yup";
import { AxiosConfig } from "../../../ApiConfig";
import { InputField } from "../../../components";
import ButtonReuse from "../../../components/Buttons/Button";
import { getToken } from "../../../auth";
import Select from "react-select";

import { useSelector } from "react-redux";
require("./EditCustomer.scss");

const { TextArea } = Input;

const EditOverlay = (props: {
  visible?: any;
  onClose?: any;
  recordHandler?: any;
  onCancelButton?: any;
  customerDetails?: any;
}) => {
  let customerId = props.recordHandler;
  const token = getToken();

  const initialFormState = {
    customer_name: "",
    desc: "",
    category_id: {
      id: "",
      label: "",
    },
    MSA_reference: "",
    is_MSA_available: yup.boolean,
  };
  const [newitems, setNewItems] = useState(initialFormState);
  const customerCategoryData = useSelector(
    (store: any) => store.categories.customerTypes
  );
  const [MSA, setMSA] = useState(false);

  useEffect(() => {
    const categoryOptions = customerCategoryData.map(function (customer: {
      name: any;
      id: any;
    }) {
      return {
        value: customer.id,
        label: customer.name,
      };
    });
    const sortedCategoryOptions = categoryOptions?.sort((a, b) => a.label.localeCompare(b.label));

    setCustomersCategory(sortedCategoryOptions);
  }, [customerCategoryData]);

  useEffect(() => {
    const initialFormState = {
      customer_name: props.customerDetails.name,
      desc: props.customerDetails.desc,
      category_id: {
        id: props.customerDetails?.customerType?.id,
        label: props.customerDetails?.customerType?.name,
      },
      MSA_reference: props.customerDetails.MSA_reference,
      is_MSA_available: props.customerDetails?.is_MSA_available ?? false,
    };
    setMSA(props.customerDetails?.is_MSA_available ?? false);
    setNewItems(initialFormState);
  }, [props]);

  const validationSchema = yup.object({
    customer_name: yup
      .string()
      .trim()
      .required("Please enter the Customer Name")
      .max(100, "Length must be less than 100 characters"),
    desc: yup.string().trim().required("Please enter the description"),
  });

  const onCancelHandler = () => {
    props.onCancelButton();
  };

  const openNotification = () => {
    notification.open({
      message: "Customer Edited Successfully",
    });
  };

  const [customersCategory, setCustomersCategory] = useState([]);

  const handleSwitchChange = () => {
    setMSA(!MSA);
  };

  const handleInput = (event) => {
    setNewItems({ ...newitems, [event.target.name]: event.target.value });
  };

  const handleDropDown = (event, name) => {
    setNewItems({
      ...newitems,
      [name]: {
        id: event.value,
        label: event.label,
      },
    });
  };

  return (
    <>
      <Drawer
        title="Edit Customer"
        width={500}
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="add_project"
      >
        <Formik
          enableReinitialize={true}
          initialValues={{
            customer_name: newitems.customer_name,
            desc: newitems.desc,
            category_id: {
              id: newitems?.category_id?.id,
              label: newitems?.category_id?.label,
            },
            MSA_reference: newitems?.MSA_reference,
            is_MSA_available: MSA,
          }}
          validationSchema={validationSchema}
          onSubmit={(values: any) => {
            AxiosConfig.put(
              "customers/" + customerId,
              { ...values, customerTypeId: values.category_id?.id },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            )
              .then((response) => {
                if (response.status == 200) {
                  {
                    openNotification();
                    props.onClose();
                    setTimeout(function () {
                      window.location.reload();
                    }, 1000);
                  }
                }
              })
              .catch(function () {
                if (MSA) {
                  const notify = () => {
                    notification.open({
                      message: "Invalid Input",
                      description: "MSA File References can not be empty",
                      style: { color: "red" },
                    });
                  };
                  notify();
                } else {
                  openNotification();
                  props.onClose();
                  setTimeout(function () {
                    window.location.reload();
                  }, 1000);
                }
              });
          }}
        >
          {({ handleSubmit, values, errors }) => {
            return (
              <form onSubmit={handleSubmit} className="add_project_form">
                <label>{EditCustomer.labelForCustomerName}</label>
                <InputField
                  placeholder="Enter the Customer Name"
                  name="customer_name"
                  onChange={handleInput}
                  value={values.customer_name}
                  className="input--field"
                ></InputField>
                <p className="display_error">{errors.customer_name}</p>

                <label>{EditCustomer.labelForCustomerCategory}</label>
                <Select
                  placeholder="Select Customer Category"
                  options={customersCategory}
                  className="select--category"
                  onChange={(event) => handleDropDown(event, "category_id")}
                  value={{
                    label: values?.category_id?.label,
                    id: values?.category_id?.id,
                  }}
                />
                <p className="display_error">{errors.category_id?.id}</p>

                <label>{EditCustomer.labelForDescription} </label>
                <TextArea
                  placeholder="Enter the Description"
                  rows={5}
                  name="desc"
                  onChange={handleInput}
                  value={values.desc}
                  className="textarea-input"
                />
                <p className="display_error">{errors.desc}</p>
                <label> {EditCustomer.labelForMSA}</label>
                <div>
                  <Switch
                    className="MSA"
                    onChange={handleSwitchChange}
                    checked={MSA}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#5389F8"
                    offColor="#BFCDDA"
                  />
                </div>

                <div>
                  <label> {EditCustomer.labelForMSA_Desc}</label>
                  <InputField
                    placeholder="Enter the MSA file references"
                    name="MSA_reference"
                    onChange={handleInput}
                    disabled={!MSA}
                    value={values?.MSA_reference}
                    className="textarea-input"
                  />
                </div>

                <div className="button-container">
                  <ButtonReuse
                    type="primary"
                    className="primary-btn"
                    htmlType="submit"
                    value="Save"
                  ></ButtonReuse>
                  <ButtonReuse
                    type="primary"
                    className="primary-btn cancel--btn"
                    value="Cancel"
                    onClick={onCancelHandler}
                  ></ButtonReuse>
                </div>
              </form>
            );
          }}
        </Formik>
      </Drawer>
    </>
  );
};

export default EditOverlay;
