//import Action Types 
import { GET_SNAPSHOT_LIST, GET_SNAPSHOT_ERROR} from "../../constants";


//Write down the initial state of the reducer
interface UserState {
    snapShotList: Array<object>;
    error:string;
  }

const INITIAL_STATE:UserState={
    snapShotList: [],
    error:''
}

export default (state=INITIAL_STATE, action)=>{
    switch(action.type){
        case GET_SNAPSHOT_LIST: return{...state,snapShotList:action.payload};
        case GET_SNAPSHOT_ERROR: return{...state,error:action.payload};
        default:return state;
    }
}