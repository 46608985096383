import "antd/dist/antd.css";
import { Drawer, Tabs } from "antd";
import { useState, useEffect } from "react";
require("./PurchaseOrderDetails.scss");
import Invoice from "./Invoice";
import Recognition from "./Recognition";
import PO from "./PO";
import TableInfo2 from "./poServicesTableInfo";
import SalesAttribution from "./SalesAttribution";
import ExpensesInfo from "./ExpensesDrawer";

const PoDetailsOverlay = (props: {
  visible?: boolean;
  onClose: () => void;
  onCancelButton: () => void;
  recordHandler?: any;
  poDetails?: any;
  newitems?: any;
  poAttachmentIds?: any;
  filteredMonths?: any;
  newMonth?: any;
  newMonthId?: any;
  newYear?: any;
  mastercurrencies?: any;
  currencyRate?: any;
  currencyName?: any;
  currencySymbol?: any;
  archive_status?: any;
  rolesEditAccess: any;
}) => {
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState<string>("1");
  function callback(key: any) {
    setActiveKey(key);
  }
  let id = props.poDetails.id;
  let individualPoType = props.poDetails.po_type_id;
  const [newitems, setNewItems] = useState({});
  localStorage.setItem("PoType", individualPoType);
  const refreshPage = () => {
    setActiveKey("1");
    props.onClose();
  };

  useEffect(() => {
    if (props.poDetails.currency_change_status) {
      const initialFormState = {
        po_number: props.poDetails.number,
        po_name: props.poDetails.name,
        po_status: props.poDetails.po_status,
        desc: props.poDetails.desc,
        value: props.poDetails.value,
        value_in_base: props.poDetails.value_in_base,
        stus_sold: props.poDetails.stus_sold,
        sale_date: props.poDetails.sale_date,
        po_type_id: props.poDetails.po_type_id,
        currency_id: props.poDetails.currency_id,
        currency_rate: props.poDetails.currency_rate,
        direct_expense: props.poDetails.direct_expense,
        sales_allocation: props.poDetails.salesAllocations,
        customer_name: props.poDetails.customerName,
        customer_relation: props.poDetails.customer_classification_type,
        projected_expenses: props.poDetails.projected_expenses,
      };
      setNewItems(initialFormState);
    } else {
      const initialFormState = {
        po_number: props.poDetails.number,
        po_name: props.poDetails.name,
        po_status: props.poDetails.po_status,
        desc: props.poDetails.desc,
        value: props.poDetails.value,
        value_in_base: props.poDetails.value_in_base,
        stus_sold: props.poDetails.stus_sold,
        sale_date: props.poDetails.sale_date,
        po_type_id: props.poDetails.po_type_id,
        direct_expense: props.poDetails.direct_expense,
        sales_allocation: props.poDetails.salesAllocations,
        customer_name: props.poDetails.customerName,
        customer_relation: props.poDetails.customer_classification_type,
        projected_expenses: props.poDetails.projected_expenses,
      };
      setNewItems(initialFormState);
    }
  }, [props]);
  return (
    <>
      <Drawer
        title="Purchase Order "
        width={1100}
        onClose={refreshPage}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="edit_po"
        maskClosable={false}
        destroyOnClose={true}
      >
        <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={callback}>
          <TabPane tab="PO" key="1">
            <PO
              poDetails={props.poDetails}
              newitems={newitems}
              setNewItems={setNewItems}
              POid={id}
              poAttachmentIds={props.poAttachmentIds}
              onClose={props.onClose}
              mastercurrencies={props.mastercurrencies}
              currencyName={props.currencyName}
              currencySymbol={props.currencySymbol}
              currencyRate={props.currencyRate}
              archiveStatus={props.archive_status}
              addEditAccess={props.rolesEditAccess}
            />
          </TabPane>
          <TabPane tab="PO services" key="2">
            <TableInfo2
              poDetails={props.poDetails}
              onClose={props.onClose}
              visible={props.visible}
              tabClick={callback}
              addEditAccess={props.rolesEditAccess}
            />
          </TabPane>
          <TabPane tab="Invoice" key="3">
            <Invoice
              newMonthId={props.newMonthId}
              POid={id}
              poDetails={props.poDetails}
              mastercurrencies={props.mastercurrencies}
              currencyName={props.currencyName}
              currencySymbol={props.currencySymbol}
              archiveStatus={props.archive_status}
              activeKey={activeKey}
              addEditAccess={props.rolesEditAccess}
            />
          </TabPane>
          <TabPane tab="Recognition" key="4">
            <Recognition
              individualPoType={individualPoType}
              RecognitionId={id}
              newMonth={props.newMonth}
              newMonthId={props.newMonthId}
              newYear={props.newYear}
              onClose={props.onClose}
              newitems={newitems}
              filteredMonths={props.filteredMonths}
              currencyName={props.currencyName}
              currencySymbol={props.currencySymbol}
              selectedCurrencyId={props.poDetails.currency_id}
              currency_rate={props.poDetails.currency_rate}
              archiveStatus={props.archive_status}
              directExpense={props.poDetails.projected_expenses}
              rolesEditAccess={props.rolesEditAccess}
              poDetails={props.poDetails}
            />
          </TabPane>
          <TabPane tab="Sales Attribution" key="5">
            <SalesAttribution
              newitems={newitems}
              poDetails={props.poDetails}
              onClose={props.onClose}
              setNewItems={setNewItems}
              addEditAccess={props.rolesEditAccess}
            />
          </TabPane>
          <TabPane tab="Expenses" key="6">
            <ExpensesInfo poDetails={props.poDetails} activeKey={activeKey} />
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default PoDetailsOverlay;
