require("./Logo.scss");

const Logo = (props: { src: string; className: string }) => {
  return (
    <div className={props.className}>
      <img src={props.src} alt="logo" />
    </div>
  );
};
export default Logo;
