import {
  GET_CURRENCY,
  GET_CURRENCY_ERROR,
  GET_CURRENCY_RATES,
  GET_CURRENCY_RATES_ERROR,
} from "../../constants";
import { Axios } from "../../utils/axios";

export const getCurrencyRates = (month: any, year: any) => {
  return async (dispatch) => {
    try {
      const response = await Axios.get(
        `financialmonths/monthID?month=${month}&&year=${year}`
      );

      if (response.status == 200) {
        dispatch({
          type: GET_CURRENCY,
          payload: response.data.monthId,
        });
      } else {
        dispatch({
          type: GET_CURRENCY_ERROR,
          payload: "Error: Data not found.",
        });
      }
    } catch (error: any) {
      dispatch({
        type: GET_CURRENCY_ERROR,
        payload: error,
      });
    }
  };
};

export const getRates = (monthId: any) => {
  return async (dispatch) => {
    try {
      const response = await Axios.get(`rates/${monthId}`);

      if (response.status == 200) {
        dispatch({
          type: GET_CURRENCY_RATES,
          payload: response?.data?.monthRates,
        });
      } else {
        dispatch({
          type: GET_CURRENCY_RATES_ERROR,
          payload: "Error: Data not found.",
        });
      }
    } catch (error: any) {
      dispatch({
        type: GET_CURRENCY_ERROR,
        payload: error,
      });
    }
  };
};
