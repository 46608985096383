import { Row } from "antd";
import React from "react";

const ViewAttachment = (props: { images: any }) => {
  return (
    <>
      <Row>
        {props.images.map((image: any, index: number) => (
          <React.Fragment key={index}>{image}</React.Fragment>
        ))}
      </Row>
    </>
  );
}; 

export default ViewAttachment;
