//import Action Types
import { GET_SKILLS, GET_SKILLS_ERROR } from "../../constants";

//Write down the initial state of the reducer
interface SkillsState {
  skills: Array<object>;
  error: string;
}

const INITIAL_STATE: SkillsState = {
    skills: [],
    error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SKILLS:
      return { ...state, skills: action.payload };
    case GET_SKILLS_ERROR:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
