import { Upload, Modal, notification, Row } from "antd";
import ButtonReuse from "../../../components/Buttons/Button";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Formik } from "formik";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";

const UploadExpenseAttachment = (props: { ExpenseID: any }) => {
  const token = getToken();
  const FormData = require("form-data");
  const data = new FormData();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();
  const [previewTitle, setPreviewTitle] = useState();
  const [files, setFiles] = useState<any>([]);

  const handlePreview = async (file: any) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleCancelModal = () => {
    setPreviewVisible(false);
  };

  const handleAttachmentChange = ({ fileList }: any) => {
    setFiles(fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const notify = (message: string, description?: string) => {
    notification.open({
      message,
      description,
      style: { color: "red" },
    });
  };

  const handleSubmit = async () => {
    files.map((file) => data.append("files", file.originFileObj));

    if (files.length === 0) {
      notify("No Files added");
    } else {
      try {
        const response = await AxiosConfig.post(
          "expense/attachments/upload/" + props.ExpenseID,
          data,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.status === 200) {
          notify(response.data.status.message);
        }
      } catch (error) {
        notify(
          "",
          (error as { response?: { data?: { status?: { message?: string } } } })
            ?.response?.data?.status?.message || "Unknown error occurred"
        );
      }
    }
  };

  return (
    <Formik initialValues={{ files: [] }} onSubmit={handleSubmit}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="add_po_earning_form">
          <Upload
            listType="picture-card"
            fileList={files}
            onPreview={handlePreview}
            onChange={handleAttachmentChange}
            beforeUpload={() => false}
          >
            {uploadButton}
          </Upload>
          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancelModal}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
          <Row style={{ justifyContent: "center" }}>
            <ButtonReuse
              type="submit"
              className="upload-attachment-button"
              htmlType="submit"
              value="Upload"
            ></ButtonReuse>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default UploadExpenseAttachment;
