import {
  GET_CUSTOMER_CLASSIFICATION,
  GET_CUSTOMER_CLASSIFICATION_ERROR,
} from "../../constants";
import { Axios } from "../../utils/axios";

export const getCustomerClassification = (FinYear: any) => {
  return async (dispatch) => {
    try {
      if (FinYear && !Array.isArray(FinYear)) {
        const response = await Axios.get(`/customerClassification/${FinYear}`);

        if (response.status == 200) {
          dispatch({
            type: GET_CUSTOMER_CLASSIFICATION,
            payload: response.data.customers,
          });
        } else {
          dispatch({
            type: GET_CUSTOMER_CLASSIFICATION_ERROR,
            payload: "Error: Data not found.",
          });
        }
      }
    } catch (error: any) {
      dispatch({
        type: GET_CUSTOMER_CLASSIFICATION_ERROR,
        payload: error,
      });
    }
  };
};
