import { useState , useEffect} from "react";
import { Row, Col, Typography, Modal, Image, Button } from "antd";
import moment from "moment";
import ViewAttachment from "./ViewAttachment";
import ButtonReuse from "../../../components/Buttons/Button";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import { saveAs } from "file-saver";
import { renderMarkasSent, } from "../../../utils/enums";
import UploadSentInvoiceAttachment from "./uploadSentInvoiceAttachment";
import pdfPlaceholderImage from "../../../assets/images/pdfPlaceHolder.png";
import docxPlaceholderImage from "../../../assets/images/docximage.png";
import "./RenderMarkAsSent.scss";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import xlsxPlaceholderImage from "../../../assets/images/xlsx_thumbnail.png";
import { useSelector } from "react-redux";
import { convertCurrency } from "../../../utils/util";

const RenderMarkAsSent = (props: {
  invoiceId?: number;
  currencyName?: string;
  currencySymbol?: any;
  poDetails: any;
  Poid: number;
  finalInvoice: any;
}) => {
  const { finalInvoice,
     currencySymbol, poDetails
     } = props;
  const { Text } = Typography;
  const dateFormat = "YYYY/MM/DD";
  const token = getToken();
  const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);
  const isEditableString = localStorage.getItem("isEditable");
  const isEditable = isEditableString === 'true'; 
  const images = finalInvoice.projectattachments.map((file, index: number) => {
    if (file.file !== "file corrupted") {
      const fileName = file?.attachment_id.split("^").pop().split(".")[0];
      const fileType = file?.attachment_id.split(".").pop();

      if (
        fileType === "pdf" ||
        fileType === "jpg" ||
        fileType === "jpeg" ||
        fileType === "png" ||
        fileType === "xlsx" ||
        fileType === "docx"
      ) {
        return (
          <div className="attachment" key={index}>
            {fileType === "pdf" ? (
              <div className="pdf-placeholder">
                <img
                  src={pdfPlaceholderImage}
                  alt="PDF Placeholder"
                  className="pdf-placeholder-image"
                />
              </div>
            ) : fileType === "xlsx" ? (
              <div className="xlsx-placeholder">
                <img
                  src={xlsxPlaceholderImage}
                  alt="XLSX Placeholder"
                  className="xlsx-placeholder-image"
                />
              </div>
            ) : fileType === "docx" ? (
              <div className="docx-placeholder">
                <img
                  src={docxPlaceholderImage}
                  alt="DOCX Placeholder"
                  className="docx-placeholder-image"
                />
              </div>
            ) : (
              <Image
                className="render-Image"
                width={62}
                height={62}
                src={`data:image/png;base64,${file.file}`}
                alt="Attachment"
              />
            )}
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              size="small"
              onClick={() =>
                handleDownload(
                  `data:image/png;base64,${file.file}`,
                  file.attachment_id
                )
              }
            />
            <text>{fileName}</text>
          </div>
        );
      } else {
        return (
          <div className="attachment boxStyle" key={index}>
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              size="small"
              onClick={() =>
                handleDownload(
                  `data:application/octet-stream;base64,${file.file}`,
                  file.attachment_id
                )
              }
            />
          </div>
        );
      }
    }
  });

  const [isModalVisibleImage, setIsModalVisibleImage] = useState(false);

  const showViewModalImage = () => {
    setIsModalVisibleImage(true);
  };
  const handleCancelModalImage = () => {
    setIsModalVisibleImage(false);
  };
  const handleDownload = (url: string, attachmentId: string) => {
    saveAs(url, attachmentId.split("^").pop());
  };

  const [currencySymbols, setCurrencySymbols] =useState<Currency[]>([]);
interface Currency {
  symbol: string;
  rate: number;
}

const getCurrencyList = async () => {
  if (token) {
    await AxiosConfig.get(`/rates/country/currencyList`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      const currenciesList = res.data.currenciesList || [];
      const currencies = currenciesList.map(currency => ({
        symbol: currency.currency_symbol,
        rate: currency.rate
      }));
      setCurrencySymbols(currencies);
    }).catch((error) => {
      console.error('Error fetching currency list:', error);
    });
  }
};

    useEffect(() => {
      getCurrencyList();
    }, []); 

    const convertToINR = (amount: number | string, rate: number): string => {
      
      const amountStr = amount.toString();
    
      const cleanedAmount = parseFloat(amountStr.replace(/,/g, ''));
      if (isNaN(cleanedAmount)) {
        console.error('Invalid amount provided');
        return 'NaN';
      }

      const inrAmount = cleanedAmount * rate;
      return inrAmount.toFixed(2); 
    };

function checkAndConvertCurrency(value, currSymb) {
  const localStorageCurrencySymb = localStorage.getItem('cur_symbol');

  if (currSymb === localStorageCurrencySymb) {
   
    return `${currSymb} ${value}`;
  } else {
    const currency = currencySymbols.find(c => c.symbol === currSymb);
      if (currency) {
        const convertedValue = convertToINR(value, currency.rate);
    
        const newconvertedValue = Number(convertedValue)
        
        return convertCurrency(newconvertedValue, currencyRate);
        
      }
  }
}

  return (
    <>
      <Row
        gutter={16}
        style={{ marginInlineStart: "0.2rem", marginBlock: "0.5rem" }}
      >
        <Col md={8} xs={24} style={{ display: "grid" }}>
          <label className="mt-0">
            {renderMarkasSent.labelForFinalInvoiceAmt}
          </label>
          <Text type="secondary">
              {isEditable ? (
                <>
                  {currencySymbol[poDetails.currency_id]}
                  {currencySymbol[poDetails.currency_id] === "₹"
                    ? Intl.NumberFormat("en-IN").format(finalInvoice.final_amt)
                    : Intl.NumberFormat("en-US").format(finalInvoice.final_amt)}
                </>
              ) : (
                checkAndConvertCurrency(finalInvoice.final_amt, currencySymbol[poDetails.currency_id])
              )}

          </Text>
        </Col>
        <Col md={8} xs={24} style={{ display: "grid" }}>
          <label className="mt-0">{renderMarkasSent.labelForInvoiceDate}</label>
          <Text type="secondary">
            {moment(finalInvoice.recorded_date).format(dateFormat)}
          </Text>
        </Col>
        <Col md={8} xs={24} style={{ display: "grid" }}>
          <label className="mt-0">
            {renderMarkasSent.labelForInvoiceNumber}
          </label>
          <Text type="secondary">{finalInvoice.invoice_number}</Text>
        </Col>
      </Row>
      <Row
        gutter={16}
        style={{ marginInlineStart: "0.2rem", marginBlock: "0.5rem" }}
      >
        <Col md={8} xs={24} style={{ display: "grid" }}>
          <label>{renderMarkasSent.labelForAttachments}</label>
          <ButtonReuse
            value="Attachments"
            type="submit"
            className="edit-image-button"
            onClick={showViewModalImage}
          />
          <Modal
            title="Attachments"
            className="AttachmentsModal"
            visible={isModalVisibleImage}
            onCancel={handleCancelModalImage}
          >
            <ViewAttachment images={images} />
            <UploadSentInvoiceAttachment invoiceId={props.finalInvoice.id} />
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default RenderMarkAsSent;
