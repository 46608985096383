import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useState, useEffect } from "react";
import { Progress } from "antd";
import { Divider } from "antd";
import { Row, Col } from "antd";
import { attrition } from "../../../utils/enums";
import moment from "moment";
require("./Attrition.scss");

const CircularProgressChart = (props: {
  selectedDate?: any;
  selectedSkills?: any;
  selectedBu?: any;
}) => {
  const dateFormat = "YYYY-MM-DD";
  const selected_date = moment(props.selectedDate).format(dateFormat);
  const inputValue = {
    department_id: props.selectedBu.map((bu: any) => bu.value),
    skill_id: props.selectedSkills.map((skill: any) => skill.value),
    selected_date: selected_date,
  };
  const token = getToken();
  const [annualAttrition, setAnnualAttrition] = useState<any | String>();
  const [ninetyDaysAttrition, setNinetyDaysAttrition] = useState<
    any | String
  >();
  const [thirtyDaysAttrition, setThirtyDaysAttrition] = useState<
    any | String
  >();
  const [annualGrowthRate, setAnnualGrowthRate] = useState<any | String>();
  const [ninetyDaysGrowthRate, setNinetyDaysGrowthRate] = useState<
    any | String
  >();
  const [thirtyDaysGrowthRate, setThirtyDaysGrowthRate] = useState<
    any | String
  >();

  useEffect(() => {
    getData();
  }, [props]);

  const convertNumber = (number: any) => {
    if (number === null || 0) {
      return "0";
    } else {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 1,
      }).format(number);
    }
  };

  const getData = async () => {
    await AxiosConfig.post("/talents/attrition", inputValue, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setAnnualAttrition(convertNumber(res.data.data.annualAttrition));
      setNinetyDaysAttrition(convertNumber(res.data.data.ninetyDaysAttrition));
      setThirtyDaysAttrition(convertNumber(res.data.data.thirtyDaysAttrition));
      setAnnualGrowthRate(convertNumber(res.data.data.annualGrowth));
      setNinetyDaysGrowthRate(convertNumber(res.data.data.ninetyDaysGrowth));
      setThirtyDaysGrowthRate(convertNumber(res.data.data.thirtyDaysGrowth));
    });
  };

  return (
    <>
      <Row>
        <h2 className="displayDate">{attrition.headingForAttrition}</h2>
      </Row>
      <Row className="circles">
        <Col span={8}>
          <Row>
            <Progress
              type="circle"
              percent={annualAttrition}
              strokeColor="#4952B9"
              trailColor="#D0DAE6"
            />
          </Row>
          <Row className="labels">
            <p>{attrition.labelForAnnual}</p>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Progress
              type="circle"
              percent={ninetyDaysAttrition}
              strokeColor="#4952B9"
              trailColor="#D0DAE6"
            />
          </Row>
          <Row className="labels">
            <p>{attrition.labelFor90}</p>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Progress
              type="circle"
              percent={thirtyDaysAttrition}
              strokeColor="#4952B9"
              trailColor="#D0DAE6"
            />
          </Row>
          <Row className="labels">
            <p>{attrition.labelFor30}</p>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <h2 className="displayDate">{attrition.headingForGrowth}</h2>
      </Row>
      <Row className="circles">
        <Col span={8}>
          <Row>
            <Progress
              type="circle"
              percent={annualGrowthRate}
              strokeColor="#00BF79"
              trailColor="#D0DAE6"
            />
          </Row>
          <Row className="labels">
            <p>{attrition.labelForAnnual}</p>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Progress
              type="circle"
              percent={ninetyDaysGrowthRate}
              strokeColor="#00BF79"
              trailColor="#D0DAE6"
            />
          </Row>
          <Row className="labels">
            <p> {attrition.labelFor90}</p>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Progress
              type="circle"
              percent={thirtyDaysGrowthRate}
              strokeColor="#00BF79"
              trailColor="#D0DAE6"
            />
          </Row>
          <Row className="labels">
            <p>{attrition.labelFor30}</p>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CircularProgressChart;
