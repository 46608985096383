import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { SupersetEmbedComponent } from "../../../components";
import BuDashboardInvoiceDetailView from "./BuDashboardInvoiceDetailView";
import { getInvoiceTabsActions } from "../../../stores/actions/dashboardInvoiceAction";

const buDashboardInvoice = () => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const dashboar = useSelector((store:any)=>store?.dashboard)
  return (
    <Tabs className="BoldStyling"
      defaultActiveKey={dashboar?.tabIndexInvoice}
      onChange={(tabsKey: string) => dispatch(getInvoiceTabsActions(tabsKey))}
    >
      <TabPane tab="Summary" key="1">
        <SupersetEmbedComponent pageType="invoice" isDepartmentEnabled={true}
         isSingleProject={false}
         projectId= {'null'} />
      </TabPane>

      <TabPane tab="Detail View" key="2">
        <BuDashboardInvoiceDetailView />
      </TabPane>
    </Tabs>
  );
};

export default buDashboardInvoice;
