//import Action Types
import { GET_CURRENCY_RATE, GET_CURRENCY_RATE_ERROR } from "../../constants";

//Write down the initial state of the reducer
interface CurrencyRateState {
    data: object;
    error: string;
}

const INITIAL_STATE: CurrencyRateState = {
    data: {
        currencyRate: 1,
        symbol: '₹',
        
    },
    error: "",

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CURRENCY_RATE:
            return { ...state, data: action.payload };
        case GET_CURRENCY_RATE_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};
