import "./Modal.scss";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { notification } from "antd";
import "antd/dist/antd.css";
import { addModal } from "../../../utils/enums";

const AddModal = (props: {
  onClose: () => void;
  show: any;
  rowData: any;
  action: any;
  archive_status: any;
  monthid: any;
}) => {
  var token = getToken();
  if (props.action === "delete") {
    const deleteHandler = () => {
      let id = props.rowData;
      AxiosConfig.delete("projects/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          console.log(res);
          if (res.data.status.code === 200) {
            const notify = () => {
              notification.open({
                message: res.data.status.message,
              });
            };
            {
              notify();
            }
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }
        })
        .catch(function (error) {
          console.log(error.response.data);
          const notify = () => {
            notification.open({
              message: "",
              description: error.response.data.status.message,
              style: { color: "red" },
            });
          };
          {
            notify();
          }
        });
    };

    if (!props.show) {
      return null;
    }
    return (
      <div className="modal-container">
        <div className="modal-content">
          <div className="modal-body">
            <p className="modal-text">{addModal.warningMsgForDelete}</p>
            <div className="button-div">
              <button className="modal-body__button" onClick={deleteHandler}>
                {addModal.buttonForDelete}
              </button>
              <button
                className="modal-body__button cancel--button"
                onClick={props.onClose}
              >
                {addModal.buttonForCancel}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    let archive;
    if (props.archive_status) {
      archive = "unarchive";
    } else {
      archive = "archive";
    }

    const archiveHandler = () => {
      let monthid = props.monthid;
      let id = props.rowData;


      AxiosConfig.put(
        "projects/" + archive + "/" + id + "?month_id=" + monthid,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then((res) => {
          console.log(res);
          if (res.data.status.code === 200) {
            const notify = () => {
              notification.open({
                message: res.data.status.message,
              });
            };
            {
              notify();
            }
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }
        })
        .catch(function (error) {
          console.log(error.response.data);
          const notify = () => {
            notification.open({
              message: "",
              description: error.response.data.status.message,
              style: { color: "red" },
            });
          };
          {
            notify();
          }
        });
    };

    if (!props.show) {
      return null;
    }
    return (
      <div className="modal-container">
        <div className="modal-content">
          <div className="modal-body">
            {props.archive_status ? (
              <p className="modal-text">{addModal.warningMsgFOrUnarchive}</p>
            ) : (
              <p className="modal-text">{addModal.warningMsgForArchive}</p>
            )}

            <div className="button-div">
              {props.archive_status ? (
                <button className="modal-body__button" onClick={archiveHandler}>
                  {addModal.buttonForUnarchive}
                </button>
              ) : (
                <button className="modal-body__button" onClick={archiveHandler}>
                  {addModal.buttonForArchive}
                </button>
              )}

              <button
                className="modal-body__button cancel--button"
                onClick={props.onClose}
              >
                {addModal.buttonForCancel}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // const [refresh, setRefresh] = useState(false);
};

export default AddModal;
