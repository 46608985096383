import { Row, Col, notification } from "antd";
import ButtonReuse from "../../../components/Buttons/Button";
import { InputField } from "../../../components";
import { ChangePassword } from "../../../utils/enums";
import { Formik } from "formik";
import * as yup from "yup";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useHistory } from "react-router-dom";
import "./ChangePass.scss";

const ChangePass = () => {
  const validationSchema = yup.object({
    oldPassword: yup.string().required("Please Enter the Old Password"),
    newPassword: yup
      .string()
      .required("Please Enter the New Password")
      .min(8, "New Password must be at least 8 characters")
      .notOneOf(
        [yup.ref("oldPassword")],
        "New Password should not be same as Old Password"
      ),
    confirmNewPassword: yup
      .string()
      .required("Please Re-Enter the new password")
      .min(8, "New Password must be at least 8 characters")
      .oneOf([yup.ref("newPassword")], "Passwords do not match"),
  });

  const token = getToken();
  const userId = localStorage.getItem("userId");
  console.log(userId);

  const onCancelHandler = () => {
    window.location.reload();
  };

  const history = useHistory();

  const logoutHandler = () => {
    history.push("/login");
    window.location.reload();
  };

  return (
    <>
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          AxiosConfig.patch("/users/password/" + userId, values, {
            headers: { Authorization: `Bearer ${token}` },
          })
            .then((response) => {
              if (response.data.status.code == 200) {
                console.log(response.data.status);
                const notify = () => {
                  notification.open({
                    message: response.data.status.message,
                  });
                };
                {
                  notify();
                }
                setTimeout(function () {
                  logoutHandler();
                }, 1000);
              }
            })
            .catch(function (error) {
              const notify = () => {
                notification.open({
                  message: "",
                  description: error.response.data.status.message,
                  style: { color: "red" },
                });
              };
              {
                notify();
              }
            });
        }}
      >
        {({ handleChange, handleSubmit, errors, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="changePassword">
              <div className="white-bg-widget mb20">
                <div className="tab-contents">
                  <Row>
                    <Col span={8}>
                      <label>{ChangePassword.labelForOldPassword}</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <InputField
                        placeholder="Enter Old Password"
                        name="oldPassword"
                        onChange={handleChange}
                        value={values.oldPassword}
                        className="password--field"
                        type="password"
                      ></InputField>
                      <p className="display_error">{errors.oldPassword}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <label>{ChangePassword.labelForNewPassword}</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <InputField
                        placeholder="Enter New Password"
                        name="newPassword"
                        onChange={handleChange}
                        value={values.newPassword}
                        className="password--field"
                        type="password"
                      ></InputField>
                      <p className="display_error">{errors.newPassword}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <label>{ChangePassword.labelForConfirmNewPassword}</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <InputField
                        placeholder="Re-Type New Password"
                        name="confirmNewPassword"
                        onChange={handleChange}
                        value={values.confirmNewPassword}
                        className="password--field"
                        type="password"
                      ></InputField>
                      <p className="display_error">
                        {errors.confirmNewPassword}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <div className="PasswordBtn">
                        <ButtonReuse
                          type="primary"
                          className="primary-btn passwordAdd-btn"
                          htmlType="submit"
                          value="Save"
                        ></ButtonReuse>
                        <ButtonReuse
                          type="primary"
                          className="primary-btn cancel--btn"
                          value="Cancel"
                          onClick={onCancelHandler}
                        ></ButtonReuse>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default ChangePass;
