const initialState = {
  tabIndexSales: "1",
  tabIndexDelivery: "1",
  tabIndexInvoice: "1",
};

const getDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TABS_SALES":
      return {
        ...state,
        tabIndexSales: action.payload,
      };
    case "GET_TABS_INVOICE":
      return {
        ...state,
        tabIndexInvoice: action.payload,
      };
    case "GET_TABS_DELIVERY":
      return {
        ...state,
        tabIndexDelivery: action.payload,
      };

    default:
      return state;
  }
};

export { getDashboardReducer };
