import {
  GET_EDIT_CUSTOMER_CLASSIFICATION,
  GET_EDIT_CUSTOMER_CLASSIFICATION_ERROR,
} from "../../constants";
import { Axios } from "../../utils/axios";

export const getEditCustomerClassification = (filters: any) => {
  return async (dispatch) => {
    try {
      const response = await Axios.post(`/customerClassification`, filters);

      if (response.status == 200) {
        dispatch({
          type: GET_EDIT_CUSTOMER_CLASSIFICATION,
          payload: response?.data?.data,
        });
      } else {
        dispatch({
          type: GET_EDIT_CUSTOMER_CLASSIFICATION_ERROR,
          payload: "Error: Data not found.",
        });
      }
    } catch (error: any) {
      dispatch({
        type: GET_EDIT_CUSTOMER_CLASSIFICATION_ERROR,
        payload: error,
      });
    }
  };
};
