import { AxiosConfig } from "../../../ApiConfig";
import { useState, useEffect } from "react";
import { Row, Col, DatePicker, Input, notification } from "antd";
import * as yup from "yup";
import { useFormik } from "formik";
import ButtonReuse from "../../../components/Buttons/Button";
import Select from "react-select";
import { getToken } from "../../../auth";
import moment from "moment";
import { practiceRecord } from "../../../utils/enums";
require("./RecordData.scss");
import { useSelector } from "react-redux";

const PracticeRecord = (props: {
  selectedRecord?: any;
  onCancelHandler?: any;
  practiceOptions?: any;
  currentDate: any;
}) => {
  const token = getToken();
  const { TextArea } = Input;
  const dateFormat = "YYYY/MM/DD";
  let talentData: any = localStorage.getItem("talentid");
  talentData = JSON.parse(talentData);
  const currentUserId = localStorage.getItem("userId");
  const user: any = localStorage.getItem("user");
  const users = JSON.parse(user);

  const validationSchema: any = yup.object({
    notes: yup.string().required("Please enter some notes"),
    date_of_change: yup
      .mixed()
      .required("Please enter the date of change")
      .nullable(false),
    new_value: yup.string().required("Please select the new practice"),
  });

  const notify = (message: string, description?: string, color?: string) => {
    notification.open({
      message,
      description,
      style: { color },
    });
  };

  const [practicesOptions, setpracticesOptions] = useState([]);
  let practicesData = useSelector((state: any) => state.practices.practices);

  const uniquepracticesData = practicesData
    .filter((item) => item.id) // Ensuring id is mandatory
    .filter(
      (item, index, self) =>
        index ===
        self.findIndex((t) => t.name === item.name && t.id === item.id)
    )
    .filter((item) => item.name !== talentData.Practice);

  useEffect(() => {
    if (uniquepracticesData.length) {
      setpracticesOptions(uniquepracticesData);
    }
  }, [practicesData]);

  const practices = practicesOptions.map(function (associatedpractice: {
    name: any;
    label: any;
    id: any;
  }) {
    return {
      value: associatedpractice.name,
      label: associatedpractice.name,
      key: associatedpractice.id,
    };
  });

  // Adding a new value with key -1 and name "No practice"
  if (
    talentData.Practice &&
    talentData?.Practice?.toLowerCase().trim() !== "no practice"
  ) {
    practices.push({
      value: "No Practice",
      label: "No Practice",
      key: -1,
    });
  }

  // Sort the array based on the name (value)
  practices.sort((a, b) => a.value.localeCompare(b.value));

  const formik: any = useFormik({
    initialValues: {
      old_value: "",
      new_value: "",
      practice_id: "",
      date_of_change: "",
      change_type_id: "",
      user_id: "",
      talent_id: "",
      notes: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      const isValid = await formik.validateForm();

      if (Object.keys(isValid).length === 0) {
      const selectedDate = moment(values.date_of_change);
      const currentDate = new Date();

      if (selectedDate.isBefore(currentDate, "day")) {
        notification.open({
          message: "",
          description: "Cannot make changes for the past dates.",
          style: { color: "red" },
        });
      } else {
        values = {
          ...values,
          old_value: talentData.Practice,
          date_of_change: moment(values.date_of_change).format(dateFormat),
          change_type_id: props.selectedRecord["key"],
          user_id: currentUserId,
          talent_id: talentData["Talent_id"],
        };
        AxiosConfig.post("talents/changes/practice", values, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((response) => {
            talentData = { ...talentData, Practice: values.new_value };
            localStorage.setItem("talentid", JSON.stringify(talentData));
            if (response.status === 200) {
              notify(response.data.status.message);
              formik.resetForm();
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch(function (error) {
            for (const key in values) {
              delete values[key];
            }
            notify("", error.response.data.status.message, "red");
            });
        }
      } else {
        formik.setTouched({
          new_value: true,
          date_of_change: true,
          notes: true,
        });
      }
    },
  });

  return (
    <div className="record-data-form">
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col span={24}>
            <label>{practiceRecord.labelForExistingPractice}</label>
            <Input
              className="input--field"
              value={talentData.Practice ? talentData.Practice : "No Practice"}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label>{practiceRecord.labelForNewPractice}</label>
            {users.superAdmin ? (
              <Select
                placeholder="Select the Practice"
                options={practices}
                name="practice_id"
                className="select--category"
                value={
                  formik.values.practice_id
                    ? {
                        key: formik.values.practice_id,
                        label: formik.values.new_value,
                      }
                    : null
                }
                onChange={(value: any) => {
                  formik.setFieldValue("practice_id", value.key);
                  formik.setFieldValue("new_value", value.label);
                }}
              />
            ) : null}
            {users.superAdmin ? null : (
              <Select
                options={props.practiceOptions?.filter(
                  (option) => option.value !== talentData.Practice
                )}
                name="practice_id"
                placeholder="Select the practice"
                className="select--field"
                value={{
                  key: formik.values.practice_id,
                  label: formik.values.new_value,
                }}
                onChange={(value: any) => {
                  formik.setFieldValue("practice_id", value.key);
                  formik.setFieldValue("new_value", value.label);
                }}
              />
            )}
            <p className="display_error">{formik.touched.new_value && formik.errors.new_value}</p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label style={{ display: "flex" }}>Date</label>
            <DatePicker
              defaultValue={undefined}
              format={dateFormat}
              value={formik.values.date_of_change}
              onChange={(val) => {
                formik.setFieldValue("date_of_change", val);
              }}
              placeholder="Select the Date"
              name="date_of_change"
              className="input--field"
            />
            <p className="display_error">{formik.touched.date_of_change && formik.errors.date_of_change}</p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label>{practiceRecord.labelForNotes}</label>
            <TextArea
              rows={4}
              placeholder="Enter the notes"
              className="input--field textarea--field"
              value={formik.values.notes}
              name="notes"
              onChange={formik.handleChange}
            />
            <p className="display_error">{formik.touched.notes && formik.errors.notes}</p>
          </Col>
        </Row>
        <div className="button-container">
          <ButtonReuse
            type="primary"
            className="primary-btn"
            htmlType="submit"
            value="Record"
          ></ButtonReuse>
          <ButtonReuse
            type="primary"
            className="primary-btn cancel--btn"
            value="Cancel"
            onClick={() => {
              formik.resetForm();
              props.onCancelHandler();
            }}
          ></ButtonReuse>
        </div>
      </form>
    </div>
  );
};

export default PracticeRecord;
