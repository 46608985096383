import { GET_DEPARTMENT, GET_DEPARTMENT_ERROR } from "../../constants";
import { Axios } from "../../utils/axios";

export const getDepartment = () => {
    return async (dispatch) => {
        try {
            const response = await Axios.get(`departments`);

            if (response.status == 200) {
                dispatch({
                    type: GET_DEPARTMENT,
                    payload: response.data.data.departments,
                });
            } else {
                dispatch({
                    type: GET_DEPARTMENT_ERROR,
                    payload: "Error: Data not found.",
                });
            }
        } catch (error: any) {
            dispatch({
                type: GET_DEPARTMENT_ERROR,
                payload: error,
            });
        }
    };
};
