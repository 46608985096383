//import Action Types
import { GET_SCERET_PASSWORD, GET_SCERET_PASSWORD_ERROR } from "../../constants";

//Write down the initial state of the reducer
interface secretePassword {
  data: Array<object>;
  error: string;
}

const INITIAL_STATE: secretePassword = {
  data: [],
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SCERET_PASSWORD:
      return { ...state, data: action.payload };
    case GET_SCERET_PASSWORD_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
