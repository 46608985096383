import React from "react";
import { Col, Image, Table, notification } from "antd";
import deleteImage from "../../../assets/images/delete.png";
import { AxiosConfig } from "../../../ApiConfig";
import pdfPlaceholderImage from "../../../assets/images/pdfPlaceHolder.png";
import xlsxPlaceholderImage from "../../../assets/images/xlsx_thumbnail.png";
import docxPlaceholderImage from "../../../assets/images/docximage.png";
import { getToken } from "../../../auth";
import "../ProjectDetails/EditAttachments.scss";

const EditProjectAttachments = (props) => {
  const token = getToken();

  const deleteHandler = (record) => {
    AxiosConfig.delete(`projects/attachments/${record}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.status.code === 200) {
          const notify = () => {
            notification.open({
              message: res.data.status.message,
            });
          };
          notify();
        }
      })
      .catch((error) => {
        const notify = () => {
          notification.open({
            message: "",
            description: error.response.data.status.message,
            style: { color: "red" },
          });
        };
        notify();
      });
  };

  const POImageData = props.images.map((row) => ({
    image_id: row.id,
    image_url: row.url,
    key: row.id,
  }));

  const renderAttachment = (record, row) => {
    const fileType = row?.image_id.split(".").pop();
    const fileName = row?.image_id.split("^").pop().split(".")[0];

    let attachmentComponent;

    if (fileType === "pdf") {
      attachmentComponent = (
        <div className="pdf-placeholder">
          <img
            src={pdfPlaceholderImage}
            alt="PDF Placeholder"
            className="pdf-placeholder-image"
          />
        </div>
      );
    } else if (fileType === "xlsx") {
      attachmentComponent = (
        <div className="xlsx-placeholder">
          <img
            src={xlsxPlaceholderImage}
            alt="XLSX Placeholder"
            className="xlsx-placeholder-image"
          />
        </div>
      );
    } else if (fileType === "docx") {
      attachmentComponent = (
        <div className="docx-placeholder">
          <img
            src={docxPlaceholderImage}
            alt="DOCX Placeholder"
            className="docx-placeholder-image"
          />
        </div>
      );
    } else if (
      fileType === "jpg" ||
      fileType === "jpeg" ||
      fileType === "png"
    ) {
      attachmentComponent = (
        <Col span={12}>
          <Image
            style={{ border: "1px solid #989898", borderRadius: "4px" }}
            width={62}
            height={62}
            src={record}
            alt="Attachment"
          />
        </Col>
      );
    }

    return (
      <div className="attachment" key={row.key}>
        {attachmentComponent}
        <text>{fileName}</text>
      </div>
    );
  };

  const columns = [
    {
      title: "Attachments",
      dataIndex: "image_url",
      key: "image_url",
      render: renderAttachment,
    },
    {
      title: "Action",
      dataIndex: "image_id",
      key: "image_id",
      render: (record) => (
        <div className="actionBtnAttachment">
          <span>
            <img src={deleteImage} onClick={() => deleteHandler(record)} />
          </span>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        className="AttachmentsTable"
        columns={columns}
        dataSource={POImageData}
        pagination={{ pageSize: 2 }}
      />
    </>
  );
};

export default EditProjectAttachments;
