import React, { useState, useEffect, useRef } from "react";
import edit from "../../../assets/images/edit.png";
import { Table } from "antd";
import { AxiosConfig } from "../../../ApiConfig";
import "./poServicesTableInfo.scss";
import ButtonReuse from "../../../components/Buttons/Button";
import { getToken } from "../../../auth";
import { notification } from "antd";
import { checkNull } from "../../../utils/util";
import { poServiceTableInfo } from "../../../utils/enums";
const TableInfo2 = (props: any) => {
  const [serviceType, setServiceType] = useState([]);
  const [services, setServices] = useState({});
  const [poServiceData, setpoServiceData] = useState([]);
  const [mergData, setTomergeData] = useState([]);
  const [disable, setDisable] = useState(true);
  const [totalAllocation, setTotalAllocation] = useState(0);
  const [checkempty, setempty] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const { addEditAccess } = props;

  const formRef: any = useRef();

  var token = getToken();

  useEffect(() => {
    if (props.visible) {
      setDisable(true);
      setIsLoad(false);
      getData();
    }
    formRef.current.reset();
  }, []);

  useEffect(() => {
    if (serviceType.length > 0) {
      getPoData();
    }
  }, [serviceType]);

  useEffect(() => {
    toMergeData(serviceType);
  }, [poServiceData]);

  const onCancelHandler = () => {
    setDisable(true);
    props.tabClick("1");
    props.onClose();
  };
  const handleEdit = () => {
    setDisable(!disable);
  };
  const openNotification = () => {
    notification.open({
      message: "Updated Successfully",
    });
    setDisable(true);
    props.tabClick("1");
    props.onClose();
  };
  const openErrNotification = () => {
    notification.error({
      message: "Invalid Allocation",
      description:
        "please make sure that total service allocation should be equal to 100%",
      style: { color: "red" },
    });
  };
  const notify = () => {
    notification.error({
      message: "Notification",
      description: `No values have been updated`,
      style: { color: "red" },
    });
  };
  const notifyEmptyValue = () => {
    notification.error({
      message: "Notification",
      description: `Input feilds are empty`,
      style: { color: "red" },
    });
  };

  const getData = async () => {
    await AxiosConfig.get("skills/", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      if (res.data.data.skills && Array.isArray(res.data.data.skills)) {
        setServiceType(
          res.data.data.skills
            .sort((a, b) => a.name.localeCompare(b.name)) // sort by name
            .map((row: { id: any; name: any }) => ({
              id: row.id,
              name: row.name,
            }))
        );
      }
    });
  };

  const getPoData = async () => {
    await AxiosConfig.get("poServices/" + props.poDetails.id, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setpoServiceData(
        res.data.data.map(
          (row: {
            skill_id: any;
            service_percentage: any;
            // serviceType: any;
          }) => ({
            skill_id: row.skill_id,
            service_percentage: row.service_percentage,
            // serviceType: row.serviceType.service,
          })
        )
      );
      setIsLoad(!isLoad);
    });
  };
  const submitFunction = async (data, e: any) => {
    try {
      await AxiosConfig.post("poServices/", data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      openNotification();
      e.target.reset();
    } catch (error) {
      openErrNotification();
    }
  };

  const updateFunction = async (data, e: any) => {
    try {
      await AxiosConfig.put("poServices/", data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      openNotification();
      e.target.reset();
    } catch (error) {
      console.error(error);
      openErrNotification();
    }
  };

  const handleInput = async (e) => {
    if (e.target.value == "") {
      notifyEmptyValue();
      setempty(true);
    } else {
      setempty(false);
    }
  };

  let totalVal = 0;
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const totalObj = e.target.elements;

    for (const key in totalObj) {
      if (parseInt(key) < 20) {
        totalVal += checkNull(parseInt(totalObj[key].value));
      }
    }
    setTotalAllocation(totalVal);

    const data = {
      po_id: props.poDetails.id,
      project_id: props.poDetails.project_id,
      services: services,
    };
    if (Object.keys(services).length > 0) {
      if (poServiceData.length > 0) {
        updateFunction(data, e);
      } else {
        submitFunction(data, e);
      }
    } else {
      notify();
    }
  };
  const toMergeData = async (skills: any) => {
    if (poServiceData.length > 0) {
      let tot: number = 0;

      setTomergeData(
        skills.map((service: any) => {
          const poService: any = poServiceData.find(
            (poService: any) => poService.skill_id === service.id
          );

          const allocationPercentage: number = poService
            ? poService.service_percentage
            : 0;

          tot += checkNull(allocationPercentage);

          return {
            ...service,
            allocationPercentage,
          };
        })
      );
      setTotalAllocation(tot);
    } else {
      setTotalAllocation(0);
      setTomergeData(
        skills.map((skills: any) => {
          return {
            ...skills,
            allocationPercentage: 0,
          };
        })
      );
    }
  };

  const columnsMonth = [
    {
      title: "SKILLS",
      dataIndex: "name",
      key: "name",
      width: "40%",
      render: (text, record) => <p>{record["name"]}</p>,
      sorter: (a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      },
    },

    {
      title: "ALLOCATION %",
      dataIndex: "allocationPercentage",
      key: "allocationPercentage",
      width: "40%",
      onCell: (record) => ({
        onBlur: (e) => {
          setServices((prevState) => ({
            ...prevState,
            [record.id]: parseInt(e.target.value),
          }));
        },
      }),
      render: (text, record) => (
        <>
          <input
            className="PoServices_input"
            type="number"
            defaultValue={record["allocationPercentage"]}
            disabled={disable}
            onWheel={(event) => event.currentTarget.blur()}
            min="0"
            onBlur={(e) => handleInput(e)}
          />
        </>
      ),
      sorter: (a: any, b: any) =>
        a.allocationPercentage - b.allocationPercentage,
    },
  ];

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <div>
        <br />
        <h1
          style={{
            fontSize: "18px",
            color: "#4952B9",
            textTransform: "uppercase",
          }}
        ></h1>
        <div className="Po_services_total">
          {poServiceTableInfo.textFortotalper} {totalAllocation}%/100%
          {addEditAccess && <img src={edit} onClick={handleEdit} className="edit_PoServices" />}
        </div>

        {serviceType.length > 0 && mergData && (
          <Table
            columns={columnsMonth}
            dataSource={mergData}
            rowKey="id"
            bordered
            className="ThisMonthTable"
            scroll={{ x: 500 }}
            pagination={false}
          />
        )}

        <br />
        <div className="button-container btn-con">
          {poServiceData.length > 0 && addEditAccess && (
            <button
              type="submit"
              className="primary-btn-poServices"
              value="Update"
              name="action"
              disabled={checkempty}
            >
              {poServiceTableInfo.buttonForUpdate}
            </button>
          )}
          {poServiceData.length == 0  && addEditAccess && (
            <button
              type="submit"
              className="primary-btn-poServices"
              value="Submit"
              name="action"
              disabled={checkempty}
            >
              {poServiceTableInfo.buttonForSubmit}
            </button>
          )}
          {addEditAccess && <ButtonReuse
            type="primary"
            className="services_primary-btn"
            htmlType="button"
            value="Cancel"
            onClick={onCancelHandler}
          ></ButtonReuse>}
        </div>
      </div>
    </form>
  );
};

export default TableInfo2;
