import { useState, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Progress } from "antd";
import moment from "moment";

const UtilizationGraph = (props: {
  daysGap?: any;
  selectedDate?: any;
  selectedSkills?: any;
  selectedBu?: any;
}) => {
  const token = getToken();
  const dateFormat = "YYYY-MM-DD";
  const selected_date = moment(props.selectedDate).format(dateFormat);
  const percentFormatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const [utilization, setUtilization] = useState(0);
  useEffect(() => {
    const data = {
      selected_date: selected_date,
      department_id: props.selectedBu.map((bu: any) => bu.value),
      skill_id: props.selectedSkills.map((skill: any) => skill.value),
      days: props.daysGap,
    };
    AxiosConfig.post("talents/talentutilization", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const stusCapacity: number = res.data.data.stusCapacity ?? 0;
        const stusExpended: number = res.data.data.stusExpended ?? 0;
        const uti =
          stusCapacity != 0
            ? Number(
                percentFormatter
                  .format(stusExpended / stusCapacity)
                  .replace(/[%]/g, "")
              )
            : 0;
        setUtilization(uti);
      })
      .catch((err) => console.log(err));
  }, [props.selectedSkills, props.selectedBu, props.selectedDate]);
  return (
    <>
      <Progress type="circle" percent={utilization} width={80} />
    </>
  );
};

export default UtilizationGraph;
