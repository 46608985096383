//import Action Types
import { GET_CURRENCY, GET_CURRENCY_ERROR } from "../../constants";

//Write down the initial state of the reducer
interface CurrencyState {
  data: object;
  rates: Array<object>;
  error: string;
}

const INITIAL_STATE: CurrencyState = {
  data: {},
  rates: [],
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CURRENCY:
      return { ...state, data: action.payload };
    case GET_CURRENCY_ERROR:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
