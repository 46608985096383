import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Row,
  Col,
  Progress,
  Typography,
  Modal,
  notification,
  Input,
} from "antd";
import { enumForSalesAttribution } from "../../../utils/enums";
import { Formik } from "formik";
import { preventMinus } from "../../../utils/util";
import ButtonReuse from "../../../components/Buttons/Button";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import edit from "../../../assets/images/edit.png";
import { Axios } from "../../../utils/axios";
import { DeleteTwoTone } from "@ant-design/icons";
import Select from "react-select";
import "./PO.scss";

const { Text } = Typography;

const SalesAttribution = (props: {
  newitems?: any;
  poDetails?: any;
  onClose: () => void;
  setNewItems?: any;
  addEditAccess:any
}) => {
  var token = getToken();

    const [isModalVisibleUpdate, setisModalVisibleUpdate] = useState(false);
    const [isDisabledIput, setIsDisabledInput] = useState(true);
    const [salesPersons, setSalesPersons] = useState([]);
    const [isModalVisibleDelete, setisModalVisibleDelete] = useState(false);
    const { addEditAccess }= props;

    useEffect(() => {
      const fetchSalesPersons = async () => {
        try {
          const response = await Axios.get("/salesAllocation/salesPerson");

          const data = response.data;
          const options = data?.data?.salesPersons?.map((person: any) => ({
            value: person.id,
            label: `${person.firstName} ${person.lastName}`,
          }));
          options.push({ value: 0, label: "Not assigned" });
          setSalesPersons(options);
        } catch (error) {
          console.error("Error fetching sales persons:", error);
        }
      };

      fetchSalesPersons();
    }, []);

    const showModalUpdate = () => {
      setisModalVisibleUpdate(true);
    };
    const handleCancelUpdateModal = () => {
      setisModalVisibleUpdate(false);
    };

    const handleCancel = () => {
      setIsDisabledInput(true);
    };

    const handleCancelDeleteModal = () => {
      setisModalVisibleDelete(false);
    };

    const handleClick = () => {
      setIsDisabledInput(false);
    };

    const updateData = async () => {
      try {
        let data: any = props.newitems;
    
        const salesAllocation = data.sales_allocation.map((eachAllocation) => ({
          talent_id: eachAllocation.talent_id,
          sales_percentage: eachAllocation.sales_percentage,
        }));
    
        const payload = {
          sales_allocation: salesAllocation,
        };
    
        const response = await AxiosConfig.put(
          `salesAllocation/po/${props.poDetails.id}/${props.poDetails.project_id}`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
    
        if (response.status === 200) {
          setIsDisabledInput(true);
          notification.open({
            message: (
              <span style={{ color: "green" }}>
                {response.data.status.message}
              </span>
            ),
          });
        }
      } catch (error) {
        notification.open({
          message: 
            (error as { response?: { data?: { status?: { message?: string } } } })?.response?.data?.status?.message || "Unknown error occurred",         
        });
      }
    
      props.onClose();
    };
    const handleChangeSalesAllocationException = (
      event: ChangeEvent<HTMLInputElement>,
      index: number,
      key: string,
      talent: { label: string; value: number }
    ) => {
      const updatedSalesAllocation = [
        ...props.newitems.sales_allocation.slice(0, index),
        {
          ...props.newitems.sales_allocation[index],
          [key]: event.target.value,
          talent_id: talent.value,
          talent: {
            firstName: talent.label.split(" ")[0],
            lastName: talent.label.split(" ")[1],
          },
        },
        ...props.newitems.sales_allocation.slice(index + 1),
      ];
      props.setNewItems({
        ...props.newitems,
        sales_allocation: updatedSalesAllocation,
      });
    };
    const handleChangeSalesAllocation = (
      event: any,
      index: number,
      key: string
    ) => {
      const updatedSalesAllocation =
        key != "talent_id"
          ? [
              ...props.newitems.sales_allocation.slice(0, index),
              {
                ...props.newitems.sales_allocation[index],
                [key]: event.target.value,
              },
              ...props.newitems.sales_allocation.slice(index + 1),
            ]
          : [
              ...props.newitems.sales_allocation.slice(0, index),
              {
                ...props.newitems.sales_allocation[index],
                [key]: event.value,
                sales_percentage:
                  props.newitems.sales_allocation[index]?.sales_percentage ?? 0,
                talent: {
                  firstName: event.label.split(" ")[0],
                  lastName: event.label.split(" ")[1],
                },
              },
              ...props.newitems.sales_allocation.slice(index + 1),
            ];

      props.setNewItems({
        ...props.newitems,
        sales_allocation: updatedSalesAllocation,
      });
    };

    const totalPercentage = props.newitems?.sales_allocation?.length
      ? props.newitems?.sales_allocation?.reduce((total: number, current) => {
          return total + parseFloat(current.sales_percentage || 0);
        }, 0)
      : 0;

  return (
    <>
      {addEditAccess && <img src={edit} onClick={handleClick} />}
      <Formik
        initialValues={{
          sales_allocation: props.poDetails.salesAllocations,
        }}
        onSubmit={() => {
          showModalUpdate();
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {isDisabledIput && (
              <div className="sales-attribution-summary">
                <Text strong style={{ fontSize: "1rem" }}>
                  {enumForSalesAttribution.textForSalesAttribution}
                </Text>
                <div className="sales-attribution-summary__total">
                  <Row>
                    <Col md={4} sm={6} xs={12}>
                      <Text disabled>
                        {enumForSalesAttribution.labelForTotalper}
                      </Text>
                    </Col>
                    <Col md={4} sm={6} xs={12} style={{ textAlign: "right" }}>
                      <label>
                        <span style={{ color: "#5C67E5", fontWeight: "bold" }}>
                          {totalPercentage}%
                        </span>
                        /100%
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8} sm={12} xs={24}>
                      <Progress
                        style={{ marginBottom: "8%" }}
                        percent={totalPercentage}
                        strokeWidth={8}
                        showInfo={false}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="sales-attribution-summary__details">
                  <Row gutter={16} style={{ marginBlock: "0.5rem" }}>
                    <Col md={4} sm={12}>
                      <Text disabled>
                        {enumForSalesAttribution.textForSalesPerson}
                      </Text>
                    </Col>
                    <Col md={4} sm={12} style={{ textAlign: "right" }}>
                      <Text disabled>
                        {enumForSalesAttribution.textForPercentage}
                      </Text>
                    </Col>
                  </Row>
                  {props.newitems.sales_allocation.length ? (
                    props.newitems.sales_allocation.map((allocation) => (
                      <Row
                        gutter={16}
                        key={allocation.id}
                        style={{ marginBlock: "0.5rem" }}
                      >
                        <Col md={4} sm={12}>
                          {`${allocation.talent.firstName}  ${allocation.talent.lastName}`}
                        </Col>
                        <Col md={4} sm={12} style={{ textAlign: "right" }}>
                          {allocation.sales_percentage}%
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <Row gutter={16} key={0} style={{ marginBlock: "0.5rem" }}>
                      <Col md={4} sm={12}>
                        {enumForSalesAttribution.labelForNotAssigned}
                      </Col>
                      <Col md={4} sm={12} style={{ textAlign: "right" }}>
                        0%
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            )}
            <div className="sales-allocation-form">
              {!isDisabledIput && (
                <>
                  <Text strong style={{ fontSize: "1rem" }}>
                    {enumForSalesAttribution.textForSalesAttribution}
                  </Text>
                  <Row gutter={24}>
                    <Col span={24}>
                      {props.newitems.sales_allocation.length &&
                      salesPersons.length ? (
                        props.newitems.sales_allocation.map(
                          (allocation, index) => (
                            <Row
                              gutter={24}
                              key={allocation?.id}
                              style={{ marginBlock: "0.5rem" }}
                            >
                              <Col span={12}>
                                <label>
                                  {enumForSalesAttribution.textForSalesPerson}
                                </label>
                                <Select
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                  menuPosition="fixed" 
                                  placeholder="Select Sales Person"
                                  value={{
                                    value: allocation.talent_id,
                                    label: `${allocation.talent.firstName} ${allocation.talent.lastName}`,
                                  }}
                                  onChange={(event) =>
                                    handleChangeSalesAllocation(
                                      event,
                                      index,
                                      "talent_id"
                                    )
                                  }
                                  className="input--field"
                                  options={salesPersons}
                                />
                              </Col>
                              <Col span={8}>
                                <label>
                                  {
                                    enumForSalesAttribution.textForSalesPercentage
                                  }
                                </label>
                                <Input
                                  className="Input_salesPercentage"
                                  type="number"
                                  placeholder="Enter Percentage"
                                  value={allocation.sales_percentage}
                                  onChange={(event) =>
                                    handleChangeSalesAllocation(
                                      event,
                                      index,
                                      "sales_percentage"
                                    )
                                  }
                                  min={0}
                                  max={100}
                                  onKeyPress={preventMinus}
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  defaultValue={0}
                                />
                              </Col>
                              <Col span={4} className="display-vertical-center">
                                {addEditAccess&& <DeleteTwoTone
                                  onClick={() => {
                                    props.setNewItems({
                                      ...props.newitems,
                                      sales_allocation: [
                                        ...props.newitems.sales_allocation.slice(
                                          0,
                                          index
                                        ),
                                        ...props.newitems.sales_allocation.slice(
                                          index + 1
                                        ),
                                      ],
                                    });
                                  }}
                                  twoToneColor="#F95734"
                                  className="icon-hover"
                                  style={{ fontSize: "24px" }} 
                                />}
                              </Col>
                            </Row>
                          )
                        )
                      ) : (
                        <Row
                          gutter={16}
                          key={0}
                          style={{ marginBlock: "0.5rem" }}
                        >
                          <Col span={12}>
                            <label>
                              {enumForSalesAttribution.textForSalesPerson}
                            </label>
                            <Select
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              menuPosition="fixed"
                              placeholder="Select Sales Person"
                              value={{
                                value: 0,
                                label: "Not assigned",
                              }}
                              onChange={(event) =>
                                handleChangeSalesAllocation(
                                  event,
                                  0,
                                  "talent_id"
                                )
                              }
                              className="input--field"
                              options={salesPersons}
                            />
                          </Col>
                          <Col span={8}>
                            <label>
                              {enumForSalesAttribution.textForPercentage}
                            </label>
                            <Input
                              type="number"
                              placeholder="Enter Percentage"
                              value={0}
                              onChange={(event) =>
                                handleChangeSalesAllocationException(
                                  event,
                                  0,
                                  "sales_percentage",
                                  {
                                    value: 0,
                                    label: "Not assigned",
                                  }
                                )
                              }
                              min={0}
                              onKeyPress={preventMinus}
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col md={8} sm={9} xs={12}>
                      <h3>{enumForSalesAttribution.labelForTotalper}</h3>
                    </Col>
                    <Col md={4} sm={6} xs={12} style={{ textAlign: "right" }}>
                      <label>
                        <span style={{ color: "#5C67E5", fontStyle: "bold" }}>
                          {isNaN(parseFloat(totalPercentage))
                            ? 0
                            : parseFloat(totalPercentage)}
                          %
                        </span>{" "}
                        / 100%
                      </label>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col md={8} sm={12} xs={24}>
                      <Progress
                        style={{ marginBottom: "8%" }}
                        percent={totalPercentage}
                        strokeWidth={8}
                        showInfo={false}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={29}>
                      {addEditAccess &&<ButtonReuse
                        type="primary"
                        className="sales-AddMore-btn"
                        onClick={() =>
                          props.setNewItems({
                            ...props.newitems,
                            sales_allocation: [
                              ...props.newitems.sales_allocation,
                              {
                                talent_id: "",
                                sales_percentage: "",
                                talent: { firstName: "", lastName: "" },
                              },
                            ],
                          })
                        }
                        value="Add More"
                      ></ButtonReuse>}
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8} />
                    <Col span={8}>
                      <div className="edit_button_div">
                        {addEditAccess && <ButtonReuse
                          value="Update"
                          type="primary"
                          htmlType="submit"
                          className="update-button-sales"
                        ></ButtonReuse>}
                        <Modal
                          title="Update Purchase Order"
                          visible={isModalVisibleUpdate}
                          onCancel={handleCancelUpdateModal}
                          cancelText="Cancel"
                          footer={false}
                          className="modal-deleteCancelBtn"
                        >
                          <p className="text-Note">
                            {enumForSalesAttribution.textForNote}
                          </p>
                          <Row style={{ margin: " auto 100px" }}>
                            <Col span={12}>
                              <ButtonReuse
                                value="Update"
                                type="primary"
                                htmlType="submit"
                                className="modal-deleteCancelBtn"
                                onClick={updateData}
                              />
                            </Col>
                            <Col span={12}>
                              <ButtonReuse
                                value="Cancel"
                                type="submit"
                                className="modal-deleteCancelBtn"
                                onClick={handleCancelUpdateModal}
                              />
                            </Col>
                          </Row>
                        </Modal>
                        {addEditAccess && <ButtonReuse
                          value="Cancel"
                          type="primary"
                          onClick={handleCancel}
                          className="cancel-button"
                        ></ButtonReuse>}

                        <Modal
                          title="Delete Purchase Order"
                          visible={isModalVisibleDelete}
                          onCancel={handleCancelDeleteModal}
                          cancelText="Cancel"
                          footer={false}
                          className="deleteModalMain"
                        >
                          <p>{enumForSalesAttribution.textForDeleteModal}</p>
                          <Row>
                            <Col span={12}>
                              <ButtonReuse
                                value="Cancel"
                                type="submit"
                                className="modal-deleteCancelBtn"
                                onClick={handleCancelDeleteModal}
                              />
                            </Col>
                          </Row>
                        </Modal>
                      </div>
                    </Col>
                    <Col span={8} />
                  </Row>
                </>
              )}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SalesAttribution;
