import { useEffect, useState } from "react";
import { Drawer, Typography, Table, Input, Row, Col } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { ExportOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";

const StuDetail = (props: {
  drawerTitle?: any;
  drawerDesc?: any;
  closeDrawer?: any;
  visible?: any;
  earningsInfo?: any;
}) => {
  const token = getToken();
  const { Text } = Typography;
  const [stuInfo, setStuInfo] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [nameSearch, setNameSearch] = useState("");

  let columns: any = [];
  const { Search } = Input;
  let stuDetailsHeader: any = [];

  if (props.drawerTitle === "Utilized STUs") {
    stuDetailsHeader = [
      { label: "EMPLOYEE ID", key: "emp_id" },
      { label: "FIRST NAME", key: "firstName" },
      { label: "LAST NAME", key: "lastName" },
      { label: "STU's", key: "stus_expended" },
      { label: "PROJECTS", key: "project_name" },
      { label: "BAND", key: "band_name" },
      { label: "HOURS", key: "hours" },
    ];
  } else if (props.drawerTitle === "Available STUs") {
    stuDetailsHeader = [
      { label: "EMPLOYEE ID", key: "emp_id" },
      { label: "FIRST NAME", key: "firstName" },
      { label: "LAST NAME", key: "lastName" },
      { label: "STU's", key: "total_stu" },
      { label: "BAND", key: "band" },
      { label: "HOURS", key: "hours" },
    ];
  }

  const checkNumber = (number: any) => {
    if (number === null) {
      return "0";
    } else {
      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
      }).format(number);
    }
  };

  const handleOnChange = (event: any) => {
    setNameSearch(event.target.value);
  };

  useEffect(() => {
    if (props.drawerTitle === "Utilized STUs") {
      if (stuInfo) {
        const results: any = stuInfo.filter(
          (item: any) =>
            item.emp_id.toLowerCase().includes(nameSearch.toLowerCase()) ||
            item.firstName
              .toLowerCase()
              .concat(" " + item.lastName.toLowerCase())
              .includes(nameSearch.toLowerCase()) ||
            item.project_name
              .toLowerCase()
              .includes(nameSearch.toLowerCase()) ||
            item.band_name.toLowerCase().includes(nameSearch.toLowerCase())
        );
        setFilterData(results);
      }
    } else if (props.drawerTitle === "Available STUs") {
      if (stuInfo) {
        const results: any = stuInfo.filter(
          (item: any) =>
            item.emp_id.toLowerCase().includes(nameSearch.toLowerCase()) ||
            item.firstName
              .toLowerCase()
              .concat(" " + item.lastName.toLowerCase())
              .includes(nameSearch.toLowerCase()) ||
            item.band.toLowerCase().includes(nameSearch.toLowerCase())
        );
        setFilterData(results);
      }
    }
  }, [nameSearch]);
  useEffect(() => {
    let earningInfo: any = props.earningsInfo;
    if (props.drawerTitle === "Utilized STUs") {
      AxiosConfig.post("/monthlyexpended", earningInfo, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          setStuInfo(res.data.data.monthlyExpended);
          setFilterData(res.data.data.monthlyExpended);
        })
        .catch((err) => console.log(err));
    } else if (props.drawerTitle === "Available STUs") {
      console.log(earningInfo);
      AxiosConfig.post("/monthlyinfo", earningInfo, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          console.log(res.data.data.monthlyStuInfo);
          setStuInfo(res.data.data.monthlyStuInfo);
          setFilterData(res.data.data.monthlyStuInfo);
        })
        .catch((err) => console.log(err));
    }
  }, []);
  if (props.drawerTitle === "Utilized STUs") {
    columns = [
      {
        title: "EMPLOYEE ID",
        dataIndex: "emp_id",
        key: "emp_id",
        sorter: (a: any, b: any) => a.emp_id.localeCompare(b.emp_id),
      },
      {
        title: "FULL NAME",
        dataIndex: ["firstName", "lastName"],
        key: "firstName",
        sorter: (a: any, b: any) => a.firstName.localeCompare(b.firstName),
        render: (text: any, record: any) => (
          <div>
            {record["firstName"]} {record["lastName"]}
          </div>
        ),
      },
      {
        title: "STU's",
        dataIndex: "stus_expended",
        defaultSortOrder: "descend" as any,
        key: "stus_expended",
        align: "right",
        sorter: (a: any, b: any) => a.stus_expended - b.stus_expended,
        render: (text: any, record: any) => (
          <div>{checkNumber(record["stus_expended"])}</div>
        ),
      },
      {
        title: "PROJECTS",
        dataIndex: "project_name",
        key: "project_name",
        sorter: (a: any, b: any) =>
          a.project_name.localeCompare(b.project_name),
      },
      {
        title: "BAND",
        dataIndex: "band_name",
        key: "band_name",
        sorter: (a: any, b: any) => a.band_name.localeCompare(b.band_name),
      },
      {
        title: "HOURS",
        dataIndex: "hours",
        align: "right",
        key: "hours",
        sorter: (a: any, b: any) => a.hours - b.hours,
        render: (text: any, record: any) => <>{checkNumber(record["hours"])}</>,
      },
    ];
  } else if (props.drawerTitle === "Available STUs") {
    columns = [
      {
        title: "EMPLOYEE ID",
        dataIndex: "emp_id",
        key: "emp_id",
        sorter: (a: any, b: any) => a.emp_id.localeCompare(b.emp_id),
      },
      {
        title: "FULL NAME",
        dataIndex: ["firstName", "lastName"],
        key: "firstName",
        sorter: (a: any, b: any) => a.firstName.localeCompare(b.firstName),
        render: (text: any, record: any) => (
          <div>
            {record["firstName"]} {record["lastName"]}
          </div>
        ),
      },
      {
        title: "STU's",
        dataIndex: "total_stu",
        defaultSortOrder: "descend" as any,
        key: "total_stu",
        align: "right",
        sorter: (a: any, b: any) => a.total_stu - b.total_stu,
        render: (text: any, record: any) => (
          <div>{checkNumber(record["total_stu"])}</div>
        ),
      },
      {
        title: "BAND",
        dataIndex: "band",
        key: "band",
        sorter: (a: any, b: any) => a.band.localeCompare(b.band),
      },
      {
        title: "HOURS",
        dataIndex: "hours",
        align: "right",
        key: "hours",
        sorter: (a: any, b: any) => a.hours - b.hours,
        render: (text: any, record: any) => <>{checkNumber(record["hours"])}</>,
      },
    ];
  }

  return (
    <Drawer
      title={props.drawerTitle}
      placement="right"
      onClose={props.closeDrawer}
      visible={props.visible}
      width={700}
    >
      <Row>
        <Col span={24}>
          <InfoCircleTwoTone style={{ paddingInline: "0.3rem" }} />
          <Text style={{ paddingInline: 0, display: "inline" }}>
            {props.drawerDesc}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col span={20}>
          {props.drawerTitle === "Utilized STUs" ? (
            <div className="searchForm">
              <form>
                <Search
                  allowClear
                  onChange={handleOnChange}
                  placeholder="Search by Employee Id/Name/Project Name/Band"
                />
              </form>
            </div>
          ) : (
            <div className="searchForm">
              <form>
                <Search
                  allowClear
                  onChange={handleOnChange}
                  placeholder="Search by Employee Id/Name/Band"
                />
              </form>
            </div>
          )}
        </Col>
        <Col span={4}>
          {props.drawerTitle === "Utilized STUs" ? (
            <CSVLink
              data={filterData}
              target="_blank"
              filename={"expended_stu.csv"}
              headers={stuDetailsHeader}
              className="exportTableButton mb-5"
              title="Export Table"
            >
              <ExportOutlined className="exportOultineIcon" />
            </CSVLink>
          ) : (
            <CSVLink
              data={filterData}
              target="_blank"
              filename={"available_stu.csv"}
              headers={stuDetailsHeader}
              className="exportTableButton mb-5"
              title="Export Table"
            >
              <ExportOutlined className="exportOultineIcon" />
            </CSVLink>
          )}
        </Col>
      </Row>
      <div className="tableOut noWordBreak">
        <Table
          columns={columns}
          dataSource={filterData}
          bordered
          className="talentTable"
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "40", "50"],
          }}
        />
      </div>
    </Drawer>
  );
};

export default StuDetail;
