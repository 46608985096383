import React,{ useRef, useEffect, useState } from "react";
import { Row, Col, Select, Card } from "antd";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import EfficiencyList from "./EfficiencyList";
import {
  convertFinancialYearToStartAndEnd,
  currentFinancialYears,
  getFinancialYear,
  isAdmin,
  isBuHead,
} from "../../../utils/util";
import { getSnapShot } from "../../../stores/actions/getSnapShotAction";
import { useDispatch } from "react-redux";
import { AxiosConfig } from "../../../ApiConfig";
import Loader from "../../../components/Loader/Loader";
import { getToken } from "../../../auth";
import axios from "axios";
require("./SnapshotProject.scss");

const SnapshotProjectDetailView = () => {
  var token = getToken();
  const { Option } = Select;
  const selectedMonth: any = useRef();
  const [managerOptions, setManagerOptions] = useState([]);
  const [monthOptions, setMonthOptions] = useState();
  const selectedManager = useRef([]);
  const [managersId, setManagersId] = useState([]);
  const dispatch: any = useDispatch();

  const [startYear, setStartYear] = useState<number | string>(0);
  const [endYear, setEndYear] = useState<number | string>(0);
  const [isLoading, setIsLoading] = useState(true);

  let departmentId: any = [];
  let associatedBuList: any = localStorage.getItem("associatedBus");
  associatedBuList = JSON.parse(associatedBuList);
  if (associatedBuList) {
    for (const bu of associatedBuList) {
      departmentId.push(bu?.id);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      selectedMonth.current = currentFinancialYears();
  
      try {
        await getMonths();
        await finStartAndEndYear(selectedMonth.current);
        await getManagers();
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    const cancelToken = axios.CancelToken.source();
    dispatch(
      getSnapShot(
        {
          fin_year_start: startYear,
          fin_year_end: endYear,
          departments_id_array: departmentId,
          manager_arr: managersId,
        },
        cancelToken
      )
    );
  }, [dispatch, departmentId, managersId]);

  const finStartAndEndYear = (value) => {
    selectedMonth.current = value;
    const finYears = convertFinancialYearToStartAndEnd(selectedMonth.current);
    setStartYear(finYears[0]);
    setEndYear(finYears[1]);
  };

  const getMonths = async () => {
    const tempMonth: any = (
      <Select
        defaultValue={currentFinancialYears()}
        onChange={(value: any) => {
          finStartAndEndYear(value);
        }}
        className="select-box"
      >
        {getFinancialYear().map((month: any, index: any) => (
          <Option
            className="select-box"
            key={index}
            selected={currentFinancialYears()}
            value={`${month}`}
          >{`${month}`}</Option>
        ))}
      </Select>
    );
    setMonthOptions(tempMonth);
  };

  const getManagers = async () => {
    let mId: any = [];
    try {
      const res = await AxiosConfig.get("users", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const tempManagerArr: any = [];
      let mangerList: any[] = res?.data;
      if (mangerList.length > 0) {
        mangerList.forEach((manager: any) => {
          tempManagerArr.push({
            label: manager.firstName + " " + manager.lastName,
            value: manager.id,
          });
          mId.push(manager.id);
        });
      }
      setManagerOptions(tempManagerArr);
      selectedManager.current = tempManagerArr;
    } catch (e: any) {
      console.log(e);
    }
  };
  const handleChangeManager = (val: any) => {
    selectedManager.current = val;
    let mId: any = [];
    selectedManager.current.forEach((row: any) => {
      mId.push(row.value);
    });
    setManagersId(mId);
  };

  return (
    <>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <Row gutter={16}>
              <Col
                className="displayDate"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                <p>{selectedMonth.current}</p>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Row>
                  <Col
                    className="column-style"
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    {monthOptions}
                  </Col>
                  {(isAdmin() || isBuHead()) && (
                    <Col
                      className="column2-style"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                    >
                      <ReactMultiSelectCheckboxes
                        options={managerOptions}
                        onChange={handleChangeManager}
                        placeholderButtonLabel="Project Owner"
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginBottom: 20 }}></Row>
            <Card title="Efficiency List" className="EfficinecyList">
              <Row style={{ width: "100%" }}>
                <Col span={24}>{<EfficiencyList managerId={managersId} />}</Col>
              </Row>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

export default SnapshotProjectDetailView;
