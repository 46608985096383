import { useState, useEffect, useRef } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { Drawer, Row, Col } from "antd";
import Select from "react-select";
import { getToken } from "../../../auth";
import ResignationRecord from "./ResignationRecord";
import SkillRecord from "./SkillRecord";
import DepartmentRecord from "./DepartmentRecord";
import BandRecord from "./BandRecord";
import UnavailableRecord from "./UnavailableRecord";
import { recordData } from "../../../utils/enums";
import PracticeRecord from "./PracticeRecord";
require("./RecordData.scss");

const RecordData = (props: {
  visible?: any;
  selectedData?: any;
  onClose?: any;
  onCancelButton?: any;
  changeType?: any;
  month?: any;
  isEdit?: boolean;
  currentDate: any;
  existingSecondarySkills: any;
}) => {
  const [selectedRecord, setSelectedRecord] = useState({
    value: "",
    label: "",
    key: "",
  });
  const [bandOptions, setBandOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [recordOptions, setRecordOptions] = useState([]);
  const [existingSecondarySkills, setExistingSecondarySkills] = useState([]);
  const recordOptionsId = useRef();
  const token = getToken();
  const [refreshKey, setRefreshKey] = useState(0);

  // Function to refresh the component
  const refreshDrawer = () => {
    setRefreshKey((prevKey) => prevKey + 1);
    props.onClose();
  };

  useEffect(() => {
    if (props.isEdit && props.selectedData) {
      setSelectedRecord({
        value: props.selectedData.change_type,
        label: props.selectedData.change_type,
        key: props.selectedData.change_type_id,
      });
      recordOptionsId.current = props.selectedData.change_type_id;
    }
  }, [props.selectedData]);
  useEffect(() => {
    if (props.existingSecondarySkills) {
      setExistingSecondarySkills(props.existingSecondarySkills);
    }
  }, [props.existingSecondarySkills]);

  const getRecordData = async () => {
    await AxiosConfig.get("changes", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempArr: any = res.data.data.changes.map((change: any) => ({
          value: change.change_type,
          label: change.change_type,
          key: change.id,
        }));
        setRecordOptions(tempArr);
      })
      .catch((err) => console.log(err));
  };
  const getBandData = async () => {
    await AxiosConfig.get("bands", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempArr: any = res.data.data.bands.map((band: any) => ({
          value: band.name,
          label: band.name,
          key: band.id,
        }));
        setBandOptions(tempArr);
      })
      .catch((err) => console.log(err));
  };
  const getSkillsData = async () => {
    await AxiosConfig.get("skills", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempArr: any = res.data.data.skills.map((skill: any) => ({
          value: skill.name,
          label: skill.name,
          key: skill.id,
        }));
        setSkillOptions(tempArr);
      })
      .catch((err) => console.log(err));
  };
  const getDepartmentData = async () => {
    let departmentList: any = localStorage.getItem("Departments");
    departmentList = JSON.parse(departmentList);
    const tempArr: any = departmentList.map((department: any) => ({
      value: department.name,
      label: department.name,
      key: department.id,
    }));
    setDepartmentOptions(tempArr);
  };

  useEffect(() => {
    getRecordData();
    getBandData();
    getSkillsData();
    getDepartmentData();
  }, []);

  const renderRecordDetail = () => {
    if (!selectedRecord.value) {
      return <h1>{recordData.headingForSelectingRecord}</h1>;
    } else if (selectedRecord.value === "Resignation") {
      return (
        <ResignationRecord
          selectedData={props.selectedData}
          selectedRecord={selectedRecord}
          onCancelHandler={onCancelHandler}
        />
      );
    } else if (selectedRecord.value === "Band") {
      return (
        <BandRecord
          selectedRecord={selectedRecord}
          onCancelHandler={onCancelHandler}
          bandOptions={bandOptions}
        />
      );
    } else if (selectedRecord.value === "Department") {
      return (
        <DepartmentRecord
          selectedRecord={selectedRecord}
          onCancelHandler={onCancelHandler}
          departmentOptions={departmentOptions}
          currentDate={props.currentDate}
        />
      );
    } else if (selectedRecord.value === "Skill") {
      return (
        <SkillRecord
          existingSecondarySkills={existingSecondarySkills}
          selectedRecord={selectedRecord}
          onCancelHandler={onCancelHandler}
          skillOptions={skillOptions}
        />
      );
    } else if (selectedRecord.value === "Unavailability") {
      return (
        <UnavailableRecord
          onCancelHandler={onCancelHandler}
          change_type={recordOptionsId.current}
          selectedData={props.selectedData}
          isEdit={props.isEdit}
        />
      );
    } else if (selectedRecord.value === "Practice") {
      return (
        <PracticeRecord
          selectedRecord={selectedRecord}
          onCancelHandler={onCancelHandler}
          currentDate={props.currentDate}
        />
      );
    } else {
      return <h1>{recordData.headingForComingSoon}</h1>;
    }
  };
  const onCancelHandler = () => {
    props.onCancelButton();
  };

  return (
    <>
      <Drawer
        title="Record Talent Data"
        width={500}
        key={refreshKey}
        onClose={refreshDrawer}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="add_talent"
        maskClosable={true}
      >
        <Row>
          <Col md={24} xs={24}>
            <Select
              isDisabled={props.isEdit ? true : false}
              options={recordOptions}
              value={selectedRecord}
              onChange={(value: any) => {
                recordOptionsId.current = value.key;
                setSelectedRecord(value);
              }}
              name="record_options"
              placeholder="Select the Record"
              className="select--category"
            />
          </Col>
        </Row>
        {renderRecordDetail()}
      </Drawer>
    </>
  );
};
export default RecordData;
