import React, { useEffect, useState } from "react";
import {
    Drawer, 
    Row, 
    Col,  
    Switch,
    notification,
    Spin
  } from "antd";
import DatePicker from "react-datepicker";
import { Formik } from "formik";
import Select from "react-select";
import ButtonReuse from "../../../components/Buttons/Button";
import { getToken } from "../../../auth";
import { AxiosConfig } from "../../../ApiConfig";
import * as Yup from "yup";
import DeleteConfirmation from "../../../components/DeleteConfirmation/DeleteConfirmation";
import { rolesEnums } from "../../../utils/enums";
import "./AddRoles.scss";

interface addRolesPropsType{
    visible?: boolean;
    handleAddRolesClose: () => void; 
    submitType:string;
    addRolesFormValues:any;
    rolesData:any;
    currentRoleHistory:any;
    getCurrentRoleHistoryData: any;
    setCurrentRoleHistory:any;
    currentSelectedHistoryData:any;
    projectAccessRolesData:any;
    allUsersData:any;
    setSumbitType:any;
}
const token = getToken();

const rolesValidationSchema = Yup.object().shape({
    role_name: Yup.string().required("Roles is required"),
    user_id: Yup.number().required("Role Name is required"),
    user_name: Yup.string().required("Role Name is required"),
    from_date: Yup.string().when('role_name', {
      is: (roleName) => roleName !== 'Project Access',
      then: Yup.string().required("Date From is required"),
      otherwise: Yup.string().nullable()
    }),
    to_date: Yup.string().when('role_name', {
      is: (roleName) => roleName !== 'Project Access',
      then: Yup.string().required("Date To is required"),
      otherwise: Yup.string().nullable()
    })
  });

const AddRoles = (props:addRolesPropsType) => {

    const {submitType, handleAddRolesClose, addRolesFormValues, rolesData, currentRoleHistory, getCurrentRoleHistoryData, setCurrentRoleHistory,projectAccessRolesData,allUsersData, setSumbitType} = props;
    const [allRoles,setRoles] = useState([]);
    const [allUsers,setAllUsers] = useState([]);
    const [filteredUser,setFilteredUsers] = useState([]);
    const [visibleDeleteConfirmation, setVisibleDeleteConfirmation] = useState(false);
    const [visibleField, setVisibleField] = useState({
        visibleSelectRole: submitType === "edit",
        visibleDateSelect: submitType === "edit" && addRolesFormValues.role_name !== "Project Access",
        visibleHistory: submitType === "edit",
        visibleButtons: submitType === "edit"
      });    
    const monthFormat = "MM/yyyy";
    const [rangeSelected,setRangeSelected]:any = useState({to_date:"",from_date:""});
    const [loading,setLoading] = useState(false);
    const [showLoader,setShowLoader]= useState(true);
    
    useEffect(() =>{
        if(rangeSelected.to_date){
            const newData = {...visibleField,visibleButtons :true};
            setVisibleField(newData);
        }
    },[rangeSelected])

    /* Validation to check if date range is overlapping with history roles */
    const validateData = (data) =>{
        let isValidationSuccess = true;
        if(data?.role_name !== "Project Access"){
            const isOverlappingData = currentRoleHistory?.find(rol =>((data.from_date >= rol.from_date && data.from_date <= rol.to_date) ||(data.to_date >= rol.from_date && data.to_date <= rol.to_date)));
            isOverlappingData?.id && notification.error({ message:`Data already exists updated successfully!`});
            isValidationSuccess = isOverlappingData?.id ? false : true ;
        }
        return isValidationSuccess;
    }

    const closeAndReload = () =>{
        handleAddRolesClose();
        setTimeout(function () {
            window.location.reload();
          }, 1000);
    }

    const handleSubmit = (data) =>{
        if(!data.is_active){
            submitType === "add" ? addHistory(data, "reloadRequired","add") : updateDisabledRole(data);
        }
        else
        {
            //Edit access will be provided to Project Owner role only
            submitType === "add" ? addRoles({...data, is_read_only: data?.role_name ===" Project Owner" ? true : false},"reloadRequired") : updateRoles(data,"reloadRequired");
        }
    }
    const updateDisabledRole = (data) =>{
        deleteRole("edit");
        addHistory({...data,is_active:false},"reloadRequired","edit");
    }

    const addRoles = async (data, isReloadRequired?:string) =>{
        try {
            const response = await AxiosConfig.post("/roles", data, {
            headers: { Authorization: `Bearer ${token}` },
            });
            if (response.status === 200) {
                notification.success({ message:`${data.role_name} added successfully!`});
                isReloadRequired && closeAndReload();
            }
        } catch (error :any) {
            console.error("Audit submission error:", error);
            if (error.response && error.response.data?.status?.message) {
                // Handle 409 Conflict error
                const errorMessage = error.response.data?.status?.message || "Conflict error occurred";
                notification.error({ message: errorMessage });
              } else {
                // Handle other errors
                notification.error({ message: "Error occurred!" });
              }
        }
        finally {
            setLoading(false); // Set loading back to false after API call
        }
    }

    const updateRoles = async (data, isReloadRequired?:string) =>{
        try {
            const response = await AxiosConfig.put(`/roles/${data.id}`, {...data , from_date: data.from_date, to_date: data.to_date}, {
              headers: { Authorization: `Bearer ${token}` },
            });
            if (response.status === 200) {
              notification.success({ message:`${data.role_name} updated successfully!`});
              isReloadRequired && closeAndReload();
            }
          } catch (error:any) {
            console.error("Audit submission error:", error);
            if (error.response && error.response.data?.status?.message) {
                // Handle 409 Conflict error
                const errorMessage = error.response.data?.status?.message || "Conflict error occurred";
                notification.error({ message: errorMessage });
              } else {
                // Handle other errors
                notification.error({ message: "Error occurred!" });
              }
          }
          finally {
            setLoading(false); // Set loading back to false after API call
        }
    }

    const addHistory = async (data:any, isReloadRequired?:string, type?:string) =>{
        try {
            const response = await AxiosConfig.post("/roleHistory/", data, {
              headers: { Authorization: `Bearer ${token}` },
            });
            if (response.status === 200) {
                type ==="add" && notification.success({message:`${data.role_name} added successfully!`});
                type ==="edit" && notification.success({ message:`${data.role_name} updated successfully!`});
                isReloadRequired && closeAndReload();
            }
          } catch (error) {
            console.error("Role History submission error:", error);
          }
    }


    const onSubmitAddRoles = async (data) =>{
        setLoading(true);
        const isNoOverlappingData = validateData(data);
        isNoOverlappingData && handleSubmit(data);
    }

    useEffect(() =>{
        initAll()
    },[])

    const initAll = async () =>{
        getAllRoles();
        getAllUsers();
    }

    const getAllRoles = async () =>{
        try {
            const response = await AxiosConfig.get("/roles/user/roles",{
              headers: { Authorization: `Bearer ${token}` },
            });
            if (response.status === 200) {
                    setRoles(response?.data?.data?.userRoles || []);
                    setShowLoader(false);
            }
          } catch (error) {
            console.error("Dropdown roles data fetch error", error);
          }
    }

    const getAllUsers = async () =>{
        try {
            if(allUsersData.length){
                setAllUsers(allUsersData);
                setFilteredUsers(allUsersData)
            }
          } catch (error) {
            console.error("Dropdown users data fetch error", error);
          }
    }

    const deleteRole = async (type?:string) =>{
        setLoading(true);
        const queryParams = {
            // Add your query parameters here
            project_id:addRolesFormValues.project_id,
            user_id:addRolesFormValues.user_id
        };
        AxiosConfig.delete("roles/" + addRolesFormValues?.id, {headers: { Authorization: `Bearer ${token}` },params: queryParams
          }).then((res) => {
            if (res.data.status.code === 200 && res.data.status.success) {
                if(type !== "edit"){
                    notification.success({message: `${addRolesFormValues?.role_name} Deleted Successfully`});
                    closeAndReload();
                } 
            }
            else{
                console.error("Delete role error");
            }
            setLoading(false);
          });
    }

    const onDeleteClose = () =>{
        setVisibleDeleteConfirmation(false);
    }
    const setType = async(type,data)=>{
        try {
           await handleAddRolesClose();
           await setSumbitType(type,data)
          } catch (error) {
            console.error("Dropdown users data fetch error", error);
          }
    }

    return (
            <Drawer
                title={submitType === "add" ? "Add new role" : "Edit Role"}
                width={500}
                onClose={handleAddRolesClose}
                visible={props.visible}
                bodyStyle={{ paddingBottom: 80 }}
                className="add_creditNote"
            >
                {!showLoader &&
                    <Formik
                        initialValues={addRolesFormValues}
                        onSubmit={(data)=>{!loading && onSubmitAddRoles(data)}}
                        validationSchema={rolesValidationSchema}
                    >
                        {({handleSubmit, setFieldValue, errors, values}) =>(
                            <form onSubmit={handleSubmit}>
                                <Row>
                                    <Col span={23}>
                                        <div className="role-component">
                                            <label>{"Select Role"}</label>
                                            <Select
                                                name="role_name"
                                                placeholder="Select Role"
                                                options={allRoles}
                                                onChange={(selectedOption) => {
                                                    let roleExist =  rolesData?.filter((role) => (role.role_name == selectedOption.label))|| [];
                                                    if(selectedOption.label != 'Project Access' && roleExist.length ){
                                                     setType('edit',roleExist?.[0] || {})
                                                    }
                                                setFieldValue("role_name", selectedOption?.label);
                                                setCurrentRoleHistory(getCurrentRoleHistoryData(selectedOption?.label) || []);
                                                setVisibleField({...visibleField,visibleSelectRole:true,visibleHistory:true});
                                                selectedOption?.label === "Project Access" && values.user_id && setVisibleField({...visibleField,visibleDateSelect:false,visibleButtons:true});
                                                selectedOption?.label !== "Project Access" && values.user_id && setVisibleField({...visibleField,visibleDateSelect:true, visibleButtons: values.to_date ? true : false})
                                                }}
                                                value={allRoles.find((option:any) => option.label === values.role_name)}
                                                isDisabled={submitType === "edit"}
                                            />
                                            <p className="display_error">{errors.role_name}</p>
                                        </div>
                                    </Col>
                                </Row>
                                {visibleField.visibleSelectRole && <label className="ml-10">{`Select ${values.role_name}`}</label>}
                                {visibleField.visibleSelectRole &&
                                    <Row className="mt-5">
                                        <Col span={3} className="y-center">
                                            <div className="role-component">
                                                <Switch
                                                    defaultChecked={values?.is_active}
                                                    onChange={(data) => {
                                                        setFieldValue("is_active", data)
                                                        setFieldValue("to_date",new Date());
                                                        setRangeSelected({...rangeSelected , to_date: new Date()})
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col span={20}>
                                            <div className="role-component">
                                                <Select
                                                    name="user_id"
                                                    placeholder={`Select ${values.role_name}`}
                                                    options={filteredUser}
                                                    onChange={(selectedOption) => {
                                                        let roleExist =  projectAccessRolesData?.filter((role) => (role.user_name == selectedOption?.label && role.role_name == values.role_name)) || [];
                                                        if(values.role_name == 'Project Access' && roleExist?.length){
                                                            setType('edit',roleExist?.[0] || {})
                                                        }
                                                        setFieldValue("user_id", selectedOption?.value);
                                                        setFieldValue("user_name", selectedOption?.label);
                                                        values.role_name === "Project Access" ? setVisibleField({...visibleField,visibleButtons:true, visibleHistory:true}):setVisibleField({...visibleField,visibleDateSelect:true, visibleHistory:true})
                                                    }}
                                                    value={allUsers.find((option:any) => option.value === values.user_id)}
                                                    isDisabled={submitType === "edit"}
                                                />
                                                <p className="display_error">{errors.user_id || errors.user_name}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                                {visibleField.visibleDateSelect &&
                                    <Row className="roles-date-picker-container mt-5" >
                                            <Col span={11}>
                                                <div className="role-component">
                                                    <label>{`From`}</label>
                                                    <DatePicker
                                                        showMonthYearPicker
                                                        showFullMonthYearPicker
                                                        showTwoColumnMonthYearPicker
                                                        maxDate={values.to_date}
                                                        selected={values.from_date}
                                                        onChange={() => {}}
                                                        dateFormat={monthFormat}
                                                        className="date-picker-width"
                                                        disabled={true}
                                                    />
                                                    <p className="display_error">{errors.from_date}</p>
                                                </div>
                                            </Col>
                                            <Col span={11}>
                                                <label className="ml-8">{`To`}</label>
                                                <div className="role-component">
                                                    <DatePicker
                                                        showMonthYearPicker
                                                        showFullMonthYearPicker
                                                        showTwoColumnMonthYearPicker
                                                        minDate={values.from_date}
                                                        selected={values.to_date}
                                                        onChange={(date) => {
                                                            if (date) {
                                                                const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                                                                setFieldValue("to_date", lastDayOfMonth);
                                                                setRangeSelected({...rangeSelected , to_date:lastDayOfMonth})
                                                            }

                                                        }}
                                                        dateFormat={monthFormat}
                                                        className="date-picker-width"
                                                    />
                                                    <p className="display_error">{errors.to_date}</p>
                                                </div>
                                            </Col>
                                    </Row> 
                                }
                                {visibleField.visibleButtons  &&
                                    <Row className="add-roles-btn-container mb-40">
                                        <Col span={7}>
                                            <ButtonReuse
                                                type="primary"
                                                className="primary-btn"
                                                disabled={loading}
                                                htmlType="submit"
                                                style={{minWidth:"115px"}}
                                                value={submitType === "add" ? "Add" : "Update"}
                                            />
                                        </Col>
                                        <Col span={7}>
                                            <ButtonReuse
                                                type="primary"
                                                className="cancel-button"
                                                value="Cancel"
                                                onClick={handleAddRolesClose}
                                            />
                                        </Col>
                                        {submitType === "edit" && values?.is_active &&
                                            <Col span={7}>
                                                <ButtonReuse
                                                    type="button"
                                                    className="delete-btn"
                                                    value="Delete"
                                                    onClick={() => setVisibleDeleteConfirmation(true)}
                                                    disabled={loading}
                                                />
                                            </Col>
                                        }
                                    </Row>
                                }

                                {visibleField.visibleHistory && currentRoleHistory?.length > 0 && currentRoleHistory?.map((roleData,index) => { return(
                                    <div key={"role-history-"+index}>
                                        {/* History */}
                                        <Row  className="mt-5">
                                            <Col span={3} className="y-center">
                                                <div className="role-component">
                                                    <Switch
                                                        checked={roleData?.is_active}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </Col>
                                            <Col span={20}>
                                                <div className="role-component">
                                                    <Select
                                                        isDisabled={true}
                                                        options={allUsers}
                                                        value={allUsers.find((option:any) => option.value === roleData.user_id)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        {values?.role_name !=="Project Access" &&
                                            <Row className="roles-date-picker-container mt-5" >
                                                    <Col span={11}>
                                                        <div className="role-component">
                                                            {/* <label>{`From`}</label> */}
                                                            <DatePicker
                                                                disabled={true}
                                                                showMonthYearPicker
                                                                showFullMonthYearPicker
                                                                showTwoColumnMonthYearPicker
                                                                maxDate={values.to_date}
                                                                selected={new Date(roleData.from_date)}
                                                                className="date-picker-width"
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        setFieldValue("from_date", date);
                                                                    }
                                                                }}
                                                                dateFormat={monthFormat}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col span={11}>
                                                        {/* <label className="ml-8">{`To`}</label> */}
                                                        <div className="role-component">
                                                            <DatePicker
                                                                disabled={true}
                                                                showMonthYearPicker
                                                                showFullMonthYearPicker
                                                                showTwoColumnMonthYearPicker
                                                                minDate={values.from_date}
                                                                selected={new Date(roleData.to_date)}
                                                                className="date-picker-width float-right"
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        setFieldValue("to_date", date);
                                                                    }
                                                                    
                                                                }}
                                                                dateFormat={monthFormat}
                                                            />
                                                        </div>
                                                    </Col>
                                            </Row> 
                                        }
                                        {/* History end */}
                                    </div>
                                )})
                                }
    
                            </form>
                        )}
                    </Formik>
                }
                {visibleDeleteConfirmation && <DeleteConfirmation onClose={onDeleteClose} onConfirm={() =>{!loading && deleteRole()}} customDeleteConfirmationMsg={rolesEnums.warningMsgForDelete}/>}
                {(showLoader ||loading) && 
                    <Row>
                        <Col span={24}>
                            <Spin size="large" className="add-roles-loader"/>
                        </Col>
                    </Row>
                }
            </Drawer>
    );
};
export default AddRoles;