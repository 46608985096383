import { useState, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { DatePicker, Input, notification } from "antd";
import * as yup from "yup";
// import { useFormik, Formik } from "formik";
import { useFormik } from "formik";
import ButtonReuse from "../../../components/Buttons/Button";
import Select from "react-select";
import { getToken } from "../../../auth";
import moment from "moment";
import { skillRecord } from "../../../utils/enums";
require("./RecordData.scss");

const SkillRecord = (props: {
  selectedRecord?: any;
  onCancelHandler?: any;
  skillOptions?: any;
  existingSecondarySkills?: any;
}) => {
  const token = getToken();
  const { TextArea } = Input;
  const dateFormat = "YYYY/MM/DD";
  let talentData: any = localStorage.getItem("talentid");
  talentData = JSON.parse(talentData);

  const currentUserId = localStorage.getItem("userId");
  const old_skill_id = props.skillOptions.find(
    (obj) => obj.value === talentData.Skill_set
  )?.key;

  const [secondarySkillOptions, setSecondarySkillOptions] = useState([]);
  const [isSecondarySkillchaneged, setIsSecondarySkillchaneged] =
    useState(false);

  const initialValues = {
    old_value: talentData.Skill_set,
    new_value: "",
    new_skill_id: "",
    date_of_change: "",
    change_type_id: props.selectedRecord["key"],
    user_id: currentUserId,
    talent_id: talentData["Talent_id"],
    notes: "",
    secondary_skills:
      props.existingSecondarySkills?.map((skill: any) => ({
        value: skill.id,
        label: skill.name,
        key: skill.id,
      })) || [],
  };
  const validationSchema = yup.object({
    notes: yup.string().required("Please enter some notes"),
    date_of_change: yup
      .mixed()
      .required("Please enter the date of change")
      .nullable(false),
    new_value: yup.string(),
    secondary_skills: yup.array(),
  });

  const getSecondarySkillsData = async () => {
    await AxiosConfig.get("skills/secondarySkills", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempArr: any = res.data.data.secondarySkills.map(
          (skill: any) => ({
            value: skill.name,
            label: skill.name,
            key: skill.id,
          })
        );
        setSecondarySkillOptions(tempArr);
      })
      .catch((err) => {
        throw err;
      });
  };

  useEffect(() => {
    getSecondarySkillsData();
  }, []);

  const handleSubmit = async (values: any) => {
    
    const isValid = await formik.validateForm();
  
    if (Object.keys(isValid).length === 0) {
    let secondarySkillsArray = [];
    if (isSecondarySkillchaneged == true) {
      secondarySkillsArray = values.secondary_skills.map((skill: any) => ({
        id: skill.value,
        name: skill.label,
      }));
    } else {
      secondarySkillsArray = props.existingSecondarySkills;
    }
    let oldSecondarySkills = String(
      props.existingSecondarySkills
        .map((secondarySkill: any) => secondarySkill.name)
        .join(", ")
    );
    let newSecondarySkills = String(
      secondarySkillsArray
        .map((secondarySkill: any) => secondarySkill.name)
        .join(", ")
    );

    const primarySkill = values.new_value || values.old_value;
    values.old_value =
      "primary_skill:" +
      values.old_value +
      ",secondary_skill:" +
      oldSecondarySkills;

    values.new_value =
      "primary_skill:" +
      primarySkill +
      ",secondary_skill:" +
      String(newSecondarySkills);

    values = {
      ...values,
      old_skill_id: old_skill_id,
      date_of_change: moment(values.date_of_change).format(dateFormat),
      secondary_skills: secondarySkillsArray,
    };

    if (!values.new_value) values.new_value = values.old_value;
    AxiosConfig.post("talents/changes/skills", values, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.status === 200) {
          talentData = { ...talentData, Skill_set: primarySkill };
          localStorage.setItem("talentid", JSON.stringify(talentData));
          const notify = () => {
            notification.open({
              message: response.data.status.message,
            });
          };
          notify();
          formik.resetForm();

          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
      })
      .catch(function (error) {
        const notify = () => {
          notification.open({
            message: "",
            description: error.response.data.status.message,
            style: { color: "red" },
          });
        };
        notify();
      });
    } else {
      formik.setTouched(
        Object.keys(isValid).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {})
      );
      formik.setErrors(isValid);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  // Filter out selected skills from the options list
  const availableOptions = secondarySkillOptions.filter((option: any) => {
    const isSelected = formik.values.secondary_skills.some(
      (selected: any) => selected.key === option.key
    );
    return !isSelected;
  });
  const handleSelectChange = (value: any) => {
    formik.setFieldValue("new_skill_id", value.key);
    formik.setFieldValue("new_value", value.label);
  };

  const handleSecondarySkillsChange = (selectedOptions: any) => {
    formik.setFieldValue(
      "secondary_skills",
      selectedOptions.map((option: any) => ({
        value: option.key,
        label: option.label,
        key: option.key,
      }))
    );

    setIsSecondarySkillchaneged(true);
  };

  return (
    <div className="record-data-form">
      <form onSubmit={formik.handleSubmit}>
        <label>{skillRecord.labelForExistingSkill}</label>
        <Input className="input--field" value={talentData.Skill_set} disabled />
        <p className="display_error">{formik.errors.new_value}</p>
        <label>{skillRecord.labelForNewSkills}</label>
        <Select
          options={props.skillOptions?.filter(
            (option) => option.value !== talentData.Skill_set
          )}
          name="new_skill_id"
          placeholder="Select the Skill"
          className="select--category"
          defaultValue={undefined}
          value={{
            key: formik.values.new_skill_id,
            label: formik.values.new_value,
          }}
          onChange={handleSelectChange}
        />
        <p className="display_error">{formik.touched.new_value && formik.errors.new_value}</p>

        <label>Secondary Skills</label>
        <Select
          isMulti
          isClearable
          options={availableOptions}
          name="secondary_skills"
          placeholder="Select secondary skills"
          value={formik.values.secondary_skills}
          onChange={handleSecondarySkillsChange}
          className="select--category secondarySkillsSelect"
        />
        <p className="display_error">{/*validation errors*/}</p>

        <div className="input_container">
          <label style={{ display: "flex" }}>{skillRecord.labelForDate}</label>
        </div>
        <DatePicker
          defaultValue={undefined}
          format={dateFormat}
          value={
            formik.values.date_of_change
              ? moment(formik.values.date_of_change)
              : null
          }
          onChange={(val) => {
            formik.setFieldValue("date_of_change", val);
          }}
          placeholder="Select the Date"
          name="date_of_change"
          className="input--field"
        />
        <p className="display_error">{formik.touched.date_of_change && formik.errors.date_of_change}</p>
        <label>{skillRecord.labelForNotes}</label>
        <TextArea
          rows={4}
          placeholder="Enter the Notes"
          className="input--field textarea--field"
          value={formik.values.notes}
          name="notes"
          onChange={formik.handleChange}
        />
        <p className="display_error">{formik.touched.notes && formik.errors.notes}</p>

        <div className="button-container">
          <ButtonReuse
            type="primary"
            className="primary-btn"
            htmlType="submit"
            value="Record"
            disabled={!formik.dirty} // Disable button if form is not dirty
            // disabled={!isChanged}
          ></ButtonReuse>
          <ButtonReuse
            type="primary"
            className="primary-btn cancel--btn"
            value="Cancel"
            onClick={() => {
              formik.resetForm();
              props.onCancelHandler();
            }}
          ></ButtonReuse>
        </div>
      </form>
    </div>
  );
};

export default SkillRecord;
