import { Col, Row, Button, Table } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { AxiosConfig } from "../../../ApiConfig";
import { useState, useEffect } from "react";
import arrow from "../../../assets/images/arrow.png";
import { getToken } from "../../../auth";
import TalentDetailsCard from "./TalentDetailsCard";
import RecordData from "./RecordData";
import moment from "moment";
import ProjectByHours from "./ProjectByHours";
import ApprovedHours from "./ApprovedHours";
import editImage from "../../../assets/images/edit.png";
import { checkTalentStatus } from "../../../utils/util";
import { Buttons } from "../../../utils/enums";
require("./TalentDetails.scss");

type SkillsProps = {
  label: string;
  skillsList: string[];
};

const TalentDetails = () => {
  var token = getToken();
  const dateFormat: string = "YYYY/MM/DD";
  let { id }: any = useParams();
  let history = useHistory();
  const [profileFirstName, setProfileFirstName] = useState();
  const [talent, setTalent] = useState({ id: 0, isSalesEnabled: false });
  const [profileLastName, setProfileLastName] = useState();
  const [designation, setDesignation] = useState();
  const [band, setBand] = useState();
  const [lifeTimeStus, setLifeTimeStus] = useState();
  const [assignedBu, setAssignedBu] = useState();
  const [joiningDate, setJoiningDate] = useState();
  const [releivingDate, setReleivingDate] = useState();
  const [skills, setSkills] = useState();
  const [ExistingSecondarySkills, setExistingSecondarySkills] = useState();
  const [isVisible, setVisible] = useState(false);
  const [changesData, setChangesData] = useState([]);
  const [email, setEmail] = useState(" ");
  const [editData, setEditData] = useState(false);
  const [dateOfSep, setDateOfSep] = useState("");
  const [dateOfChange, setDateOfChange] = useState("");

  const getData = () => {
    AxiosConfig.get(`talents/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempArr: any = [];

        const isoDate: any = res.data.data.talent.date_of_seperation
          ? new Date(res.data.data.talent.date_of_seperation).toString()
          : "-";
        const { id, isSalesEnabled } = res.data.data.talent;

        const records = res.data.data.talent.records;
        const mostRecentDate = records.reduce((latestDate, record) => {
          const recordDate = new Date(record.date_of_change);
          return recordDate > latestDate ? recordDate : latestDate;
        }, new Date(0));

        setProfileFirstName(res.data.data.talent.firstName);
        setProfileLastName(res.data.data.talent.lastName);
        setDesignation(res.data.data.talent.skill.name);
        setBand(res.data.data.talent.band.name);
        setLifeTimeStus(res.data.data.talent.lifeTimeStus);
        setAssignedBu(res.data.data.talent.department.name);
        setJoiningDate(res.data.data.talent.date_of_joining);
        setTalent({ id, isSalesEnabled });
        setReleivingDate(isoDate);
        setSkills(res.data.data.talent.skill.code);
        let existsecondaryskills = res.data.data.talent.talent_skills || [];
        setExistingSecondarySkills(existsecondaryskills);
        setEmail(res.data.data.talent.email);
        setDateOfChange(mostRecentDate);
        setDateOfSep(res.data.data.talent.date_of_seperation);
        res.data.data.talent.records.map((value: any, index: any) => {
          let detailValueMonth =
            value?.talent_unavailable_history?.financialmonth?.month ?? "";
          let detailValueYear =
            value?.talent_unavailable_history?.financialmonth?.year ?? "";
          let detail: any;
          if (value.change.change_type === "Unavailability") {
            detail = `This person is unavailable for ${detailValueMonth} ${detailValueYear}`;
          } else if (value.change.change_type === "Resignation") {
            detail = `Last Working Day is ${res.data.data.talent.date_of_seperation
              .slice(0, 10)
              .replace(/-/gi, "/")}`;
          } else if (value.change.change_type === "Band") {
            detail = `Band changed to ${value.new_value}`;
          } else if (value.change.change_type === "Department") {
            detail = `Department changed to ${value.new_value}`;
          } else if (value.change.change_type === "Skill") {
            detail = getDetailsColumnData(value.new_value, value.old_value);
          } else if (value.change.change_type === "Practice") {
            detail = `Practice changed to ${value.new_value} `;
          } else {
            detail = "";
          }

          tempArr.push({
            key: index,
            recordId: value?.id,
            date_of_change: moment(value.date_of_change).format(dateFormat),
            recorded_by: `${value.user.firstName} ${value.user.lastName}`,
            change_type: value.change.change_type,
            change_type_id: value.change.id,
            old_value: value.old_value,
            new_value: value.new_value,
            notes: value.notes,
            details: detail,
            monthId: value?.talent_unavailable_history?.monthId,
            month: value?.talent_unavailable_history?.financialmonth?.month,
            year: value?.talent_unavailable_history?.financialmonth?.year,
            department: value?.talent_unavailable_history?.department,
            skill: value?.talent_unavailable_history?.skill,
            band: value?.talent_unavailable_history?.band,
            unavailableId: value?.talent_unavailable_history?.id,
            otherDetails: value,
          });
        });
        setChangesData(tempArr);
      })
      .catch((err) => console.log(err));
  };

  const visibleHandler = () => {
    setVisible(true);
  };
  const closeHandler = () => {
    setVisible(false);
  };

  const [selectedData, setSelectedData] = useState<any>({});
  const handleEditClick = (record: any) => {
    setSelectedData(record);
    setEditData(true);
  };

  const columns: any = [
    {
      title: "ACTION DATE",
      dataIndex: "date_of_change",
      key: "date_of_change",
    },
    {
      title: "RECORDED BY",
      dataIndex: "recorded_by",
      key: "recorded_by",
    },
    {
      title: "CHANGE TYPE",
      dataIndex: "change_type",
      key: "change_type",
    },
    {
      title: "DETAILS",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "OLD VALUE",
      dataIndex: "old_value",
      key: "old_value",
      render: (text: any, record: any) => {
        if (record.otherDetails?.change?.change_type === "Skill") {
          const formattedSkills = formatSkills(text);
          return (
            <div className="skills-column-cell">
              <SkillsColumnCell
                label={formattedSkills.primarySkillLabel}
                skillsList={formattedSkills.primarySkillsList}
              />
              <SkillsColumnCell
                label={formattedSkills.secondarySkillLabel}
                skillsList={formattedSkills.secondarySkillsList}
              />
            </div>
          );
        } else {
          return text;
        }
      },
      width: 200,
    },
    {
      title: "NEW VALUE",
      dataIndex: "new_value",
      key: "new_value",
      render: (text: any, record: any) => {
        if (record.otherDetails?.change?.change_type === "Skill") {
          const formattedSkills = formatSkills(text);
          return (
            <div className="skills-column-cell">
              <SkillsColumnCell
                label={formattedSkills.primarySkillLabel}
                skillsList={formattedSkills.primarySkillsList}
              />
              <SkillsColumnCell
                label={formattedSkills.secondarySkillLabel}
                skillsList={formattedSkills.secondarySkillsList}
              />
            </div>
          );
        } else {
          return text;
        }
      },
      width: 200,
    },
    {
      title: "EDIT/DELETE",
      dataIndex: ["change_type", "date_of_change"],
      render: (text: any, record: any) => (
        <>
          {record.change_type == "Unavailability" ||
          record.change_type === "Resignation" ? (
            <>
              <div className="edit_Delete">
                <span onClick={() => handleEditClick(record)}>
                  {" "}
                  <img
                    className="editImage"
                    src={editImage}
                    style={{ cursor: "pointer" }}
                  />
                </span>
                {editData}
              </div>
            </>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  /**
   * below component is used to show the formatted skills in OLD 'VALUE' and 'NEW VALUE' columns
   */
  const SkillsColumnCell = (skillsProps: SkillsProps) => {
    const { label, skillsList } = skillsProps;
    return (
      <>
        <span className="skill-label">{label}</span>
        {skillsList && skillsList.length > 0 ? (
          <ul>
            {skillsList.map(
              (skill: string, index: number) =>
                skill && <li key={index}>{skill}</li>
            )}
          </ul>
        ) : (
          <p>No Skill</p>
        )}
      </>
    );
  };

  /**
   * As a part of new requirement which includes secondary skills along with primary skills in
   * old_value and new_value
   * Intial old_value and new_value contains only primary skill in string format
   * example: old_value: "MERN/MEAN", new_value: "Devops"
   * new old_value and new_value format example: "primary_skill: MEARN/MEAN, secondary_skill: devops, QA"
   * differentiator: primary_skill and secondary_skill
   */
  const getDetailsColumnData = (newSkills: string, oldSkills: string) => {
    if (newSkills !== oldSkills) {
      let newPrimarySkill = newSkills;
      let oldPrimarySkill = oldSkills;

      if (newSkills?.includes("primary_skill")) {
        /**We can have only one primary skill and below function returns array of skills */
        newPrimarySkill = extractSkills(newSkills, "primary_skill")?.[0];
        if (!newPrimarySkill) return;
        oldPrimarySkill = extractSkills(oldSkills, "primary_skill")?.[0];
      }

      if (
        newPrimarySkill &&
        oldPrimarySkill &&
        newPrimarySkill !== oldPrimarySkill
      ) {
        return `Primary skill has been changed from ${oldPrimarySkill} to ${newPrimarySkill}`;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  /**extract the skills based on the skill label 'primary_skill' or 'secondary_skill' */
  function extractSkills(
    skillString: string,
    skillLabel: string,
    nextSkillLabel?: string | null,
    strictMatch: boolean = false
  ) {
    if (skillString?.includes(skillLabel)) {
      const skillPart = skillString?.split(`${skillLabel}:`)?.[1];
      if (skillPart) {
        const cleanPart = nextSkillLabel
          ? skillPart.split(`${nextSkillLabel}:`)?.[0]?.trim()
          : skillPart;
        return cleanPart?.split(",")?.map((skill: string) => skill.trim());
      }
      return [];
    } else {
      return strictMatch ? [] : [skillString];
    }
  }

  function formatSkills(skills: string) {
    return {
      primarySkillLabel: "Primary Skill",
      primarySkillsList: extractSkills(
        skills,
        "primary_skill",
        "secondary_skill"
      ),
      secondarySkillLabel: "Secondary Skill",
      secondarySkillsList: extractSkills(skills, "secondary_skill", null, true),
    };
  }

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <Row className="mb20">
        <Col span={24}>
          <div className="backBtn" onClick={() => history.push("/talent")}>
            <img src={arrow} /> <span>{Buttons.labelForBackButton}</span>
          </div>
          {(checkTalentStatus(dateOfSep) == "Active" ||
            checkTalentStatus(dateOfSep) == "Serving Notice period") && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                onClick={visibleHandler}
                className="primary-btn"
              >
                {Buttons.labelForRecordDataButton}
              </Button>
            </div>
          )}
        </Col>
      </Row>
      <RecordData
        visible={isVisible}
        onClose={closeHandler}
        onCancelButton={closeHandler}
        selectedData={selectedData}
        existingSecondarySkills={ExistingSecondarySkills}
        currentDate={dateOfChange}
      />
      <Row className="mb20">
        <Col span={24}>
          <TalentDetailsCard
            parentProfileFirstName={profileFirstName}
            parentProfileLastName={profileLastName}
            parentDesignation={designation}
            parentBand={band}
            parentLifeTimeStus={lifeTimeStus}
            parentAssignedBu={assignedBu}
            parentJoiningDate={joiningDate}
            parentReleivingDate={releivingDate}
            parentSkills={skills}
            parentEmail={email}
            parentStatus={checkTalentStatus(dateOfSep)}
            talent={talent}
            setTalent={setTalent}
          />
        </Col>
      </Row>
      <Row className="mb20" gutter={16}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <ProjectByHours />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <ApprovedHours />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={changesData}
            expandable={{
              expandedRowRender: (record: any) => (
                <div style={{ paddingLeft: "3rem" }}>
                  <b style={{ color: "#8595A5" }}>NOTES</b>
                  <p style={{ margin: "0" }}>{record.notes}</p>
                </div>
              ),
            }}
            className="talentTable"
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
            }}
            scroll={{ y: 500 }}
          />
        </Col>
      </Row>
      {editData && (
        <RecordData
          visible={editData}
          existingSecondarySkills={ExistingSecondarySkills}
          onClose={() => setEditData(false)}
          onCancelButton={() => setEditData(false)}
          selectedData={selectedData}
          isEdit={true}
          currentDate={dateOfChange}
        />
      )}
    </>
  );
};

export default TalentDetails;
