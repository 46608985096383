import axios from "axios";
import { getToken } from "./../auth";

var token = getToken();
const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: `Bearer ${token}` },
});

export { Axios };
