//import Action Types
import { GET_CUSTOMER_CATEGORY, GET_CUSTOMER_CATEGORY_ERROR } from "../../constants";

//Write down the initial state of the reducer
interface CurrencyState {
  customerTypes: Array<object>;
  error: string;
}

const INITIAL_STATE: CurrencyState = {
  customerTypes: [],
  error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMER_CATEGORY:
      return { ...state, customerTypes: action.payload };
    case GET_CUSTOMER_CATEGORY_ERROR:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
