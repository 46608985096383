import { useState } from "react";
import { Modal, Input,message } from "antd";
import { AxiosConfig } from "../../ApiConfig";

const ForgetPasswordModal = ({ visible, handleOk, handleCancel }) => {
  const [email, setEmail] = useState("");

  const handleChange = (e) => {
    setEmail(e.target.value);

  };
  const handleOkClicked = () => {
    if (validateEmail(email)) {
      handleOk(email);
      userExist(email)
      
    } else {
      message.error("Please enter a valid email address.");
    }
  };

  const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const userExist = async (userEmail) => {
    try {
      let data = {email:userEmail}
      const response = await AxiosConfig.post("users/forgot-password",data, {
      });
      
      if (response?.data && response?.data?.status &&response?.data?.status?.code==200) {
        message.info("Please check your email to reset the password",4);
      } else {
        console.error("User data not found");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      message.error("User data not found! Please contact the administrator");
    }
  };

  return (
    <Modal
      title="Forgot Password"
      visible={visible}
      onOk={handleOkClicked}
      onCancel={handleCancel}
    >
      <p>Please enter your email address:</p>
      <Input type="email" value={email} onChange={handleChange} />
    </Modal>
  );
};

export default ForgetPasswordModal;
