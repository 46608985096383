// import { Layout, Select } from "antd";
// import { SideMenu } from "../../viewgroups/index";
// import { useState } from "react";
// import React from "react";
// import { BrowserRouter as Router } from "react-router-dom";
// import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
// import { Customer, Project, buDashboard } from "../index";
// import logo from "../../assets/images/logo_dashboard.svg";
// import logoSym from "../../assets/images/logo_sym.svg";
// import Logo from "../../components/Logo/Logo";
// import { PrivateRoute } from "../../Routes/index";
// import { ProjectDetails } from "../index";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { useHistory } from "react-router-dom";
// import HeaderContext from "../../store/name-context";
require("./Dashboard.scss");

// const { Header, Sider, Content } = Layout;

const Dashboard = () => {
  // const firstName = localStorage.getItem("firstName");
  // const [collapsed, setCollapsed] = useState(false);
  // const location = useLocation();
  // console.log(location.pathname);
  // const history = useHistory();
  // console.log(location.pathname);

  let location = useLocation();
  useEffect(() => {
    console.log("pageview", location.pathname);
  }, [location]);

  // const ctx = useContext(HeaderContext);
  // console.log(ctx.pageHeading);

  // useEffect(() => {
  //   return history.listen((location) => {
  //     console.log(`You changed the page to: ${location.pathname}`);
  //   });
  // }, [Router]);

  // const current = window.location.pathname;

  // const [heading, setHeading] = useState("");
  // useEffect(() => {
  //   const pathname = window.location.pathname;
  //   returnHeader(pathname);
  //   console.log("use-Effect");
  //   console.log("firstpath", current);
  // }, []);

  // const returnHeader = (path: any) => {
  //   switch (path) {
  //     case "/customers":
  //       return setHeading("Customers");

  //     case "/projects":
  //       return setHeading("Projects");
  //   }
  // };
  // console.log(heading);

  // const toggle = () =>
  //   setCollapsed((prevState: any) => (prevState = !prevState));

  // const { Option } = Select;

  // function handleChange(value: any) {
  //   console.log(`selected ${value}`);
  // }
  return (
    // <Layout className="side-menu-container">
    //   <Sider collapsible collapsed={collapsed}>
    //     <Logo src={logo} className="type1" />
    //     <Logo src={logoSym} className="type2" />
    //     <SideMenu />
    //   </Sider>

    //   <Layout className="site-layout">
    //     <Header className="header">
    //       <Row>
    //         <Col md={1}>
    //           {React.createElement(
    //             collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
    //             {
    //               className: "trigger",
    //               onClick: toggle,
    //             }
    //           )}
    //         </Col>
    //         <Col md={5}>
    //           <h1 className="h1">BU Dashboard</h1>
    //         </Col>
    //         <Col md={5}>
    //           <div className="years">
    //             <Select defaultValue="2021-2022" onChange={handleChange}>
    //               <Option value="2020-2021">2020-2021</Option>
    //               <Option value="2019-2020">2019-2020</Option>
    //             </Select>
    //           </div>
    //         </Col>
    //         <Col md={13}>
    //           <h6>Hello {firstName}</h6>
    //         </Col>
    //       </Row>
    //     </Header>
    //     <Content className="main-content-container">
    //       <PrivateRoute path="/dashboard" component={buDashboard} exact />
    //       <PrivateRoute path="/customers" component={Customer} exact />
    //       <PrivateRoute path="/projects" component={Project} exact />
    //       <PrivateRoute path="/projects/:id" component={ProjectDetails} exact />
    //     </Content>
    //   </Layout>
    // </Layout>
    <div></div>
  );
};

export default Dashboard;
