import { Upload, Modal, notification, Row } from "antd";
import ButtonReuse from "../../../components/Buttons/Button";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Formik } from "formik";
import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Buttons } from "../../../utils/enums";

const UploadSentInvoiceAttachment = (props: { invoiceId: any }) => {
  const token = getToken();
  const FormData = require("form-data");
  const data = new FormData();

  const [previewImage, setPreviewImage] = useState({
    url: "",
    title: "",
    visible: false,
  });

  const handlePreview = async (file: any) => {
    setPreviewImage({
      url: file.url || file.preview,
      title: file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
      visible: true,
    });
  };

  const handleCancelModal = () => {
    setPreviewImage({ ...previewImage, visible: false });
  };

  const [files, setFiles] = useState<any>([]);

  const handleAttachmentChange = ({ fileList }: any) => {
    setFiles(fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{Buttons.labelForUpload}</div>
    </div>
  );

  const handleFileUpload = () => {
    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i].originFileObj);
    }

    if (files.length === 0) {
      notify("No Files added");
    } else {
      AxiosConfig.post(
        "finalinvoice/attachments/upload/" + props.invoiceId,
        data,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            notify(response.data.status.message);
          }
        })
        .catch(function (error) {
          const errorMessage =
            error.response?.data?.status?.message || "Unknown error occurred";
          notify(errorMessage, true);
        });
    }
  };

  const notify = (message: string, isError = false) => {
    notification.open({
      message: isError ? "" : message,
      description: isError ? message : "",
      style: { color: isError ? "red" : undefined },
    });
  };

  return (
    <Formik
      initialValues={{
        files: [],
      }}
      onSubmit={handleFileUpload}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className="add_po_earning_form">
          <Upload
            listType="picture-card"
            fileList={files}
            onPreview={handlePreview}
            onChange={handleAttachmentChange}
            beforeUpload={() => false}
          >
            {uploadButton}
          </Upload>
          <Modal
            visible={previewImage.visible}
            title={previewImage.title}
            footer={null}
            onCancel={handleCancelModal}
          >
            <img
              alt="example"
              style={{ width: "100%" }}
              src={previewImage.url}
            />
          </Modal>
          <Row style={{ justifyContent: "center" }}>
            <ButtonReuse
              type="submit"
              className="upload-attachment-button"
              htmlType="submit"
              value="Upload"
            ></ButtonReuse>
          </Row>
        </form>
      )}
    </Formik>
  );
};

export default UploadSentInvoiceAttachment;
