import {
  Card,
  Col,
  Row,
  Typography,
  Button,
  Table,
  Dropdown,
  Menu,
  Space,
  Checkbox,
  notification,
  Input,
} from "antd";
import BasicInfo from "../../ProjectDetails/BasicInfo";
import "./AllocationDetails.scss";
import AddOverlay from "../AllocationDetails/ManageTalent";
import arrow from "../../../../assets/images/arrow.png";
import { AxiosConfig } from "../../../../ApiConfig";
import { useState, useEffect, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getToken } from "../../../../auth";
import ProjectStuSummary from "./ProjectStuSummary";
import editImage from "../../../../assets/images/edit.png";
import EditAllocation from "../../Project/AllocationDetails/Edit_Allocation";
import Loader from "../../../../components/Loader/Loader";
import {
  convertNumber,
  checkColor,
  convertNumberToAmounts,
  convertAmountToNumberForVariance,
  // formatCurrencyValue,
  isAdmin,
  convertCurrency
} from "../../../../utils/util";
import { useSelector } from "react-redux";
import "../AllocationDetails/AllocationDetails.scss";
import { allocationDetails } from "../../../../utils/enums";
import { FilterFilled , DownOutlined } from "@ant-design/icons";

export interface IProjectOwner {
  id: number;
  is_active: boolean;
  start_date: Date;
  end_date: Date;
  project_id: number;
  owner_id: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
}
interface IBUHeads{
  id: number;
  is_active: boolean;
  project_id: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
}
const AllocationDetails = () => {
  const history = useHistory();
  const [projectMonth, setProjectMonth] = useState({
    ThisMonth: "",
    NextMonth: "",
    FutureMonth: "",
    ThisMonthYear: String,
    NextMonthYear: String,
    FutureMonthYear: String,
    ThisMonthFinancialMonthId: Number,
    NextMonthFinancialMonthId: Number,
    FutureMonthFinancialMonthId: Number,
  });

  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
 const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);

  const [currmonth, setmonth] = useState();
  const [month_id1, setMonthId1] = useState(0);
  const [month_id2, setMonthId2] = useState(0);
  const [month_id3, setMonthId3] = useState(0);
  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState("");

  const [customerName, setCustomerName] = useState("");
  const [associatedBU, setDepartment] = useState("");
  const [isBillable, setIsBillable] = useState("");
  const [locationData, setLocationData] = useState([]);
  const [billabaleGeoOption, setBillableGeoOption] = useState();
  const [projectCode, setProjectCode] = useState("");
  const [managers, setManagers] = useState([]);
  const [stusSold, setStuSold] = useState();
  const [allocatedStus, setallocatedStus] = useState("");
  const [stusExecuted, setStusExecuted] = useState();
  const [getTalentData, setTalentData] = useState();
  const [attachmentIds, setAttachmentIds] = useState([]);
  const [visible, setVisible] = useState(false);
  const [talentAllocation, setTalentAllocation] = useState<{ Name: string, Employee_id: string }[]>([]);
  const [getThismonthTotalStu, setThismonthTotalStu] = useState(0);
  const [getThismonthTotalHours, setThismonthTotalHours] = useState(0);
  const [getNextmonthTotalStu, setNextmonthTotalStu] = useState(0);
  const [getNextmonthTotalHours, setNextmonthTotalHours] = useState(0);
  const [getFuturemonthTotalStu, setFuturemonthTotalStu] = useState(0);
  const [getFuturemonthTotalHours, setFuturemonthTotalHours] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [projectOwners, setProjectOwners] = useState<IProjectOwner[]>([]);
  const [buHeads, setBUHeads] = useState<IBUHeads[]>([]);
  const [projectDeptId, setProjectDeptId] = useState(0);
  const [selectedMonths, setSelectedMonths] = useState<any[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [rolesData,setRolesData] = useState([]);
  const [projectAccessRolesData,setProjectAccessRolesData] = useState([]);
  const [roleHistory,setRolesHistory] = useState({});
  const [rolesEditAccess, setRolesEditAccess] = useState(false);
  const [currentFinacialMonth,setCurrentFinacialMonth] = useState("");
  const [allUsers,setAllUsers] = useState([]);
  const [projectDetailsData,setProjectDetailsData] = useState({});
  const [allocationDetail,setAllocationDetails]:any = useState({});

  let ThismonthTotalStu = 0;
  let ThismonthTotalHours = 0;
  let NextmonthTotalStu = 0;
  let NextmonthTotalHours = 0;
  let FuturemonthTotalStu = 0;
  let FuturemonthTotalHours = 0;

  const [selectedNames, setSelectedNames] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState(" "); 
  const [dropdownShown, setDropdownShown] = useState(false);


const handleDropdownVisibleChange = (visible) => {
  setDropdownShown(visible);
};


const handleNameSelect = (name: string) => {
  setSelectedNames([...selectedNames, name]);
};


const handleNameDeselect = (name) => {
  setSelectedNames(selectedNames.filter((selected) => selected !== name));
};


const handleSearch = (e) => {
  setSearchQuery(e.target.value);
};


  const [dataSource, setDataSource] = useState([
    {
      Name: "Earned STU's",
      thisMonth: 0,
      nextMonth: 0,
      futureMonth: 0,
    },
  ]);

  const filteredNames = (talentAllocation as { Name: string }[])
  .filter((data) => data.Name.toLowerCase().includes(searchQuery.toLowerCase()))
  .map((data) => data.Name);

  const handleReset = (e) => {
    e.stopPropagation(); 
    setSelectedNames([]);
  };
  const handleContainerClick = (e) => {
    e.stopPropagation(); 
  };
  const menuDropdown = (
    <Menu className="menu-dropdown">
     
      <div className="search-box" onClick={handleContainerClick}>
        <Input className="search-input" allowClear={true} placeholder="Search names" onChange={handleSearch}  />
      </div>
   
      <div className="menu-items-container">
        {filteredNames.map((name) => (
          <Menu.Item key={name}>
            <div onClick={handleContainerClick}> 
            <Checkbox
              checked={selectedNames.includes(name)}
              onChange={(e) => {
                if (e.target.checked) {
                  handleNameSelect(name);
                } else {
                  handleNameDeselect(name);
                }
              }}
            >
              {name}
            </Checkbox>
            </div>
          </Menu.Item>
        ))}
      </div>
      <Menu.Item >
      <button
      className="reset-button"
      onClick={handleReset}
      disabled={selectedNames.length === 0} 
    >
     <span className= "reset-text">Reset</span> 
    </button>
    </Menu.Item>


    </Menu>
  );
  

  const [filteredTalentAllocation, setFilteredTalentAllocation] = useState<{ Name: string }[]>([]);



const filterTalentAllocation = () => {
  if (selectedNames.length === 0) {
 
    setFilteredTalentAllocation(talentAllocation);
  } else {

    const filteredData = talentAllocation.filter((data) =>
      selectedNames.includes(data.Name)
    ) as { Name: string }[];
    setFilteredTalentAllocation(filteredData);
  }
};

const filteredTalentAllocationWithoutZeroRows = filteredTalentAllocation.filter(row => {
    const monthsData = Object.keys(row).filter(key => key !== 'Name' && key !== 'Employee_id' && key !== 'Employee_band' && key !== 'Employee_bandName' && key !== 'TalentData');
    return !monthsData.every(month => row[month][0] === "0 %");
});

useEffect(() => {
  filterTalentAllocation();
}, [selectedNames, talentAllocation]);

  const [visibleEdit, setVisibleEdit] = useState(false);

  const [hasFetchedData, setHasFetchedData] = useState(false);
  useEffect(() => {
    if (!hasFetchedData && currencySymb?.currency_code) {
      getMonthsId();
      setHasFetchedData(true);
    }
  }, [currencySymb?.currency_code, hasFetchedData,currencyRate]);

  useEffect(() => {
    let temp = {};

    if(Object.keys(allocationDetail).length !== 0){

    
    allocationDetail?.TalentAllocationDetails.map((row: any) => {
      const {
        talent: {
          emp_id,
          firstName,
          lastName,
          current_band_id,
          band: { name },
        },

        financialmonth: { month },
        allocated_percentage,
        allocated_stus,
        hours,
      } = row;
      if (!temp[emp_id]) {
        temp[emp_id] = {
          Name: `${firstName} ${lastName}`,
          Employee_id: emp_id,
          Employee_band: current_band_id,
          Employee_bandName: name,
          TalentData: {
            First_Name: firstName,
            Last_Name: lastName,
            Talent_id: emp_id,
          },
          [month]: [
            `${convertNumber(allocated_percentage, 2)} %`,
            convertNumber(allocated_stus, 2),
            convertNumber(hours, 2),
          ],
        };

        if (allocationDetail?.months[0]?.ThisMonth === [month][0]) {
          ThismonthTotalStu = ThismonthTotalStu + allocated_stus;
          ThismonthTotalHours = ThismonthTotalHours + hours;
        }
        if (allocationDetail?.months[0]?.NextMonth === [month][0]) {
          NextmonthTotalStu = NextmonthTotalStu + allocated_stus;
          NextmonthTotalHours = NextmonthTotalHours + hours;
        }
        if (allocationDetail?.months[0]?.FutureMonth === [month][0]) {
          FuturemonthTotalStu = FuturemonthTotalStu + allocated_stus;
          FuturemonthTotalHours = FuturemonthTotalHours + hours;
        }
      } else {
        temp[emp_id][month] = [
          `${convertNumber(allocated_percentage, 2)} %`,
          convertNumber(allocated_stus, 2),
          convertNumber(hours, 2),
        ];
        if (allocationDetail?.months[0]?.ThisMonth === [month][0]) {
          ThismonthTotalStu = ThismonthTotalStu + allocated_stus;
          ThismonthTotalHours = ThismonthTotalHours + hours;
        }
        if (allocationDetail?.months[0]?.NextMonth === [month][0]) {
          NextmonthTotalStu = NextmonthTotalStu + allocated_stus;
          NextmonthTotalHours = NextmonthTotalHours + hours;
        }
        if (allocationDetail?.months[0]?.FutureMonth === [month][0]) {
          FuturemonthTotalStu = FuturemonthTotalStu + allocated_stus;
          FuturemonthTotalHours = FuturemonthTotalHours + hours;
        }
      }
    });
    setDataSource([
      {
        Name: "Revenue Earned",
        thisMonth: convertCurrency(
          allocationDetail?.project[0]?.PresentMonthRevenue,
          currencyRate
        ),
        nextMonth: convertCurrency(
          allocationDetail?.project[0]?.NextMonthRevenue,
          currencyRate
        ),
        futureMonth: convertCurrency(
          allocationDetail?.project[0]?.FutureMonthRevenue,
          currencyRate
        ),
      },
      {
        Name: "Earned STU's",
        thisMonth: convertNumberToAmounts(
          allocationDetail?.project[0]?.ThisMonthstus_executed,
          2
        ),
        nextMonth: convertNumberToAmounts(
          allocationDetail?.project[0]?.NextMonthstus_executed,
          2
        ),
        futureMonth: convertNumberToAmounts(
          allocationDetail?.project[0]?.FutureMonthstus_executed,
          2
        ),
      },
      {
        Name: "Planned STU's",
        thisMonth: ThismonthTotalStu.toFixed(2),
        nextMonth: NextmonthTotalStu.toFixed(2),
        futureMonth: FuturemonthTotalStu.toFixed(2),
      },
    ]);
  }
    
  }, [currencyRate]);

  useEffect(() => {
    getAllUsers();
    getAttachments();
  }, []);
  const token = getToken();

  const closeHandler = () => {
    setVisible(false);
  };
  const closeHandler1 = () => {
    setVisibleEdit(false);
  };
  const visibleHandler = () => {
    setVisible(true);
  };
  const getAllUsers = async () =>{
    try {
        const response = await AxiosConfig.get("/users/all-users/details",{
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
            setAllUsers(response?.data?.users);
        }
      } catch (error) {
        console.error("Dropdown users data fetch error", error);
      }
}
  const onClickRow = () => {
    history.push("/projects/" + id);
  };
  const getMonthsId = async () => {
    await AxiosConfig.get("financialmonths", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      const currentMonth: any = res?.data?.data?.months.filter(
        (month: any) => month.status === "1"
      );
      setmonth(currentMonth[0].id);
      setMonthId1(currentMonth[0].id)
      setMonthId2(currentMonth[0].id + 1)
      setMonthId3(currentMonth[0].id + 2)
      getTalentAllocation(currentMonth[0].id);
    });
  };

  const { id }: any = useParams();

  const getAttachments = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if(userId){
      const res = await AxiosConfig.get(`projects/${id}?user_id=${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const projectValue =
        res?.data?.data?.project?.billableentity?.entity_name;
      if (projectValue) {
        setBUHeads(res.data.data.BUHeads);
        setBillableGeoOption(projectValue);
        setAttachmentIds(
          res?.data?.data?.project?.projectattachments?.map(
            (row: { id: number; attachment_id: number }) => ({
              id: row?.id,
              attachment_id: row?.attachment_id,
            })
          ) || []
        );
      }
      if (res.data?.data?.project) {
        setProjectDetailsData(res?.data?.data?.project || {});
        setProjectOwners(res.data.data.project.ProjectOwners);
        setRolesHistory(res.data?.data?.roleHistory || {});
        setRolesData(res.data?.data?.roles);
        setCurrentFinacialMonth(res?.data?.data?.finacialMonth);
        setProjectAccessRolesData(res.data?.data?.projectAccessRoles);
        const isProjectOwnerPresent = res.data?.data?.roles?.find(rol => rol.role_name === "Project Owner");
        const isUserProjectOwner = isProjectOwnerPresent?.user_id === parseInt(userId);
        setRolesEditAccess((isUserProjectOwner || isAdmin() || res.data?.data?.isBUHead) ? true : false);
      }
    }
    } catch (error) {
      console.error("Error fetching attachments:", error);
    }
  };
  const { Text } = Typography;

  const getTalentAllocation = async (monthid: any) => {
    const res = await AxiosConfig.get(`allocations/${id}?month_id=` + monthid, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const projectData = res?.data?.data?.project[0];
    const MonthData = res?.data?.data?.months;
    setAllocationDetails(res?.data?.data)
    
    if (projectData) {
      setProjectDeptId(projectData);
    }
    if (MonthData.length >= 0) {
      setIsLoading(false);
    }
    setProjectMonth({
      ThisMonth: res?.data?.data?.months[0]?.ThisMonth,
      NextMonth: res?.data?.data?.months[0]?.NextMonth,
      FutureMonth: res?.data?.data?.months[0]?.FutureMonth,
      ThisMonthYear: res?.data?.data?.months[0]?.ThisMonthYear,
      NextMonthYear: res?.data?.data?.months[0]?.NextMonthYear,
      FutureMonthYear: res?.data?.data?.months[0]?.FutureMonthYear,
      ThisMonthFinancialMonthId:
        res?.data?.data?.months[0]?.ThisMonthFinancialMonthId,
      NextMonthFinancialMonthId:
        res?.data?.data?.months[0]?.NextMonthFinancialMonthId,
      FutureMonthFinancialMonthId:
        res?.data?.data?.months[0]?.FutureMonthFinancialMonthId,
    });

    const temp = {};

    res?.data?.data?.TalentAllocationDetails.map((row: any) => {
      const {
        talent: {
          emp_id,
          firstName,
          lastName,
          current_band_id,
          band: { name },
        },

        financialmonth: { month },
        allocated_percentage,
        allocated_stus,
        hours,
      } = row;
      if (!temp[emp_id]) {
        temp[emp_id] = {
          Name: `${firstName} ${lastName}`,
          Employee_id: emp_id,
          Employee_band: current_band_id,
          Employee_bandName: name,
          TalentData: {
            First_Name: firstName,
            Last_Name: lastName,
            Talent_id: emp_id,
          },
          [month]: [
            `${convertNumber(allocated_percentage, 2)} %`,
            convertNumber(allocated_stus, 2),
            convertNumber(hours, 2),
          ],
        };

        if (res?.data?.data?.months[0]?.ThisMonth === [month][0]) {
          ThismonthTotalStu = ThismonthTotalStu + allocated_stus;
          ThismonthTotalHours = ThismonthTotalHours + hours;
        }
        if (res?.data?.data?.months[0]?.NextMonth === [month][0]) {
          NextmonthTotalStu = NextmonthTotalStu + allocated_stus;
          NextmonthTotalHours = NextmonthTotalHours + hours;
        }
        if (res?.data?.data?.months[0]?.FutureMonth === [month][0]) {
          FuturemonthTotalStu = FuturemonthTotalStu + allocated_stus;
          FuturemonthTotalHours = FuturemonthTotalHours + hours;
        }
      } else {
        temp[emp_id][month] = [
          `${convertNumber(allocated_percentage, 2)} %`,
          convertNumber(allocated_stus, 2),
          convertNumber(hours, 2),
        ];
        if (res?.data?.data?.months[0]?.ThisMonth === [month][0]) {
          ThismonthTotalStu = ThismonthTotalStu + allocated_stus;
          ThismonthTotalHours = ThismonthTotalHours + hours;
        }
        if (res?.data?.data?.months[0]?.NextMonth === [month][0]) {
          NextmonthTotalStu = NextmonthTotalStu + allocated_stus;
          NextmonthTotalHours = NextmonthTotalHours + hours;
        }
        if (res?.data?.data?.months[0]?.FutureMonth === [month][0]) {
          FuturemonthTotalStu = FuturemonthTotalStu + allocated_stus;
          FuturemonthTotalHours = FuturemonthTotalHours + hours;
        }
      }
    });
    setThismonthTotalStu(ThismonthTotalStu);
    setThismonthTotalHours(ThismonthTotalHours);
    setNextmonthTotalStu(NextmonthTotalStu);
    setNextmonthTotalHours(NextmonthTotalHours);
    setFuturemonthTotalStu(FuturemonthTotalStu);
    setFuturemonthTotalHours(FuturemonthTotalHours);

    Object.values(temp).forEach(() => {});
    setTalentAllocation(Object.values(temp));

    if (
      Array.isArray(res?.data?.data?.project) &&
      res?.data?.data?.project.length
    ) {
      setDataSource([
        {
          Name: "Revenue Earned",
          thisMonth: convertCurrency(
            res?.data?.data?.project[0]?.PresentMonthRevenue,
            currencyRate
          ),
          nextMonth: convertCurrency(
            res?.data?.data?.project[0]?.NextMonthRevenue,
            currencyRate
          ),
          futureMonth: convertCurrency(
            res?.data?.data?.project[0]?.FutureMonthRevenue,
            currencyRate
          ),
        },
        {
          Name: "Earned STU's",
          thisMonth: convertNumberToAmounts(
            res?.data?.data?.project[0]?.ThisMonthstus_executed,
            2
          ),
          nextMonth: convertNumberToAmounts(
            res?.data?.data?.project[0]?.NextMonthstus_executed,
            2
          ),
          futureMonth: convertNumberToAmounts(
            res?.data?.data?.project[0]?.FutureMonthstus_executed,
            2
          ),
        },
        {
          Name: "Planned STU's",
          thisMonth: ThismonthTotalStu.toFixed(2),
          nextMonth: NextmonthTotalStu.toFixed(2),
          futureMonth: FuturemonthTotalStu.toFixed(2),
        },
      ]);
      setProjectName(res?.data?.data?.project[0]?.name);
      setProjectId(res?.data?.data?.project[0]?.id)
      setCustomerName(res?.data?.data?.project[0]?.customer.name);
      setProjectCode(res?.data?.data?.project[0]?.project_code);
      setLocationData(res?.data?.data?.project[0]?.Geography?.location);
      setManagers(res?.data?.data?.project[0]?.users);

      if (res?.data?.data?.project[0]?.billable_option === true) {
        setIsBillable("True");
      } else if (res?.data?.data?.project[0]?.billable_option === false) {
        setIsBillable("False");
      } else {
        setIsBillable("Not Selected");
      }

      setDepartment(res?.data?.data?.project[0]?.department.name);
      setStuSold(
        res?.data?.data?.project[0]?.stus_sold != null
          ? res?.data?.data?.project[0]?.stus_sold.toFixed(2)
          : "0"
      );
      const totalAlloc =
        Number(ThismonthTotalStu) +
        Number(NextmonthTotalStu) +
        Number(FuturemonthTotalStu);
      setallocatedStus(totalAlloc.toFixed(2));
      setStusExecuted(
        convertNumber(res?.data?.data?.project[0]?.stus_executed, 2)
      );
    }
  };
  const handleView = async (record: any) => {
    setTalentData(record);
    setVisibleEdit(true);
  };

 

  const columns1 =
    dataSource.length === 3
      ? [
          {
            title: "STU's",
            dataIndex: "Name",
            className: "recognized",
          },
          {
            title: `${String([projectMonth.ThisMonth]).substring(0, 3)}  ${[
              projectMonth.ThisMonthYear,
            ]}`,
            dataIndex: "thisMonth",
            maximumFractionDigits: 2,
            className: "thisMonthRecognized",
            render: (text: any, record: any) => {
              const color = checkColor(
                dataSource[2].thisMonth,
                dataSource[1].thisMonth,
                record["Name"]
              );
              const thisMonthValue = record["thisMonth"];
              let additionalInfo = "";

              if (color === "orange") {
                additionalInfo = `Under Allocated by ${convertAmountToNumberForVariance(
                  dataSource[2].thisMonth,
                  dataSource[1].thisMonth
                )}%`;
              } else if (color === "red") {
                additionalInfo = `Over Allocated by ${convertAmountToNumberForVariance(
                  dataSource[2].thisMonth,
                  dataSource[1].thisMonth
                )}%`;
              }

              return (
                <Row>
                  <Col md={24} xs={24} style={{ color }}>
                    <p className="showVariancedetailThisMonth">
                      {thisMonthValue}
                    </p>
                    {additionalInfo && <p id="showColor">{additionalInfo}</p>}
                  </Col>
                </Row>
              );
            },
          },
          {
            title: `${String([projectMonth.NextMonth]).substring(0, 3)}  ${[
              projectMonth.NextMonthYear,
            ]}`,
            dataIndex: "nextMonth",
            maximumFractionDigits: 2,
            className: "nextMonthRecognized",
            render: (text: any, record: any) => {
              const color = checkColor(
                dataSource[2].nextMonth,
                dataSource[1].nextMonth,
                record["Name"]
              );
              let content = "";

              if (color === "orange") {
                content = `Under Allocated by ${convertAmountToNumberForVariance(
                  dataSource[2].nextMonth,
                  dataSource[1].nextMonth
                )}%`;
              } else if (color === "red") {
                content = `Over Allocated by ${convertAmountToNumberForVariance(
                  dataSource[2].nextMonth,
                  dataSource[1].nextMonth
                )}%`;
              }

              return (
                <Row>
                  <Col md={24} xs={24} style={{ color }}>
                    <p className="showVariancedetailNextMonth">
                      {record["nextMonth"]}
                    </p>
                    {content && <p id="showColor">{content}</p>}
                  </Col>
                </Row>
              );
            },
          },
          {
            title: `${String([projectMonth.FutureMonth]).substring(0, 3)}  ${[
              projectMonth.FutureMonthYear,
            ]}`,
            dataIndex: "futureMonth",
            maximumFractionDigits: 2,
            className: "futureMonthRecognized",
            render: (text: any, record: any) => {
              const color = checkColor(
                dataSource[2].futureMonth,
                dataSource[1].futureMonth,
                record["Name"]
              );

              const variancePercentage = convertAmountToNumberForVariance(
                dataSource[2].futureMonth,
                dataSource[1].futureMonth
              );

              let varianceText = "";

              if (color === "orange") {
                varianceText = `Under Allocated by ${variancePercentage}%`;
              } else if (color === "red") {
                varianceText = `Over Allocated by ${variancePercentage}%`;
              }

              return (
                <Row>
                  <Col md={24} xs={24} style={{ color }}>
                    <p className="showVariancedetailFutureMonth">
                      {record["futureMonth"]}
                    </p>
                    {varianceText && <p id="showColor">{varianceText}</p>}
                  </Col>
                </Row>
              );
            },
          },
        ]
      : [];
      const sortedTalentAllocation = talentAllocation.sort((a, b) => {
        return a.Name.localeCompare(b.Name); 
      });
      const columns = sortedTalentAllocation.length
    ? [
      {
        title: "Employee Id",
          dataIndex: ["Employee_id"],
        className: "titles",
      },
      {
          title: (
            <div  className="drop-div" >
              <span className="drop-name" >Name</span>
              <div className="drop-button" >
              <Dropdown
                overlay={menuDropdown}
                trigger={['click']}
                visible={dropdownShown}
                overlayClassName="menu-dropdown"
                onVisibleChange={handleDropdownVisibleChange}
              >
                <button
                  className="ant-dropdown-link filter-button"
                  onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                  onMouseEnter={(e) => { e.stopPropagation(); }} 
                  style={{ border: 'none', backgroundColor: 'none'}}

                  title="Click to filter" 
                >
                  <FilterFilled  />
                </button>
              </Dropdown>
            </div>
            </div>
          ),
          dataIndex: ["Name"],
        className: "titles",
        sorter: (a, b) => {
            const nameA = a.Name.toUpperCase();
            const nameB = b.Name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          }
      },
        {
          title: "Band",
          dataIndex: ["Employee_bandName"],
          className: "titles",
          sorter: (a, b) =>
            parseInt(a.Employee_bandName.replace("B", "")) -
            parseInt(b.Employee_bandName.replace("B", "")),
        },
        {
          title: [
            `${String(projectMonth.ThisMonth).substring(0, 3)}  ${
              projectMonth.ThisMonthYear
            }`,
          ],
          className: "thisMonth",

          children: [
            {
              title: "% Age",
              dataIndex: [projectMonth.ThisMonth],
              key: `${projectMonth.ThisMonth}% Age`,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.ThisMonth])
                  ? parseFloat(
                      a[projectMonth.ThisMonth][0].replace("%", "").trim()
                    )
                  : 0;
                const v2 = Array.isArray(b[projectMonth.ThisMonth])
                  ? parseFloat(
                      b[projectMonth.ThisMonth][0].replace("%", "").trim()
                    )
                  : 0;
                return v1 - v2;
              },
              className: "titleThisMonth percentage-column",
              render: (text: any) => <p>{Array.isArray(text) ? text[0] : 0}</p>,
            },
            {
              title: "Hours",
              className: "titleThisMonth",
              key: `${projectMonth.ThisMonth}Hours`,
              dataIndex: [projectMonth.ThisMonth],
              render: (text: any) => <p>{Array.isArray(text) ? text[2] : 0}</p>,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.ThisMonth])
                  ? parseFloat(a[projectMonth.ThisMonth][2])
                  : 0;
                const v2 = Array.isArray(b[projectMonth.ThisMonth])
                  ? parseFloat(b[projectMonth.ThisMonth][2])
                  : 0;
                return v1 - v2;
              },
              maximumFractionDigits: 2,
            },
            {
              title: "STU's",
              className: "titleThisMonth",
              key: `${projectMonth.ThisMonth}STUs`,
              dataIndex: [projectMonth.ThisMonth],
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.ThisMonth])
                  ? parseFloat(a[projectMonth.ThisMonth][1])
                  : 0;
                const v2 = Array.isArray(b[projectMonth.ThisMonth])
                  ? parseFloat(b[projectMonth.ThisMonth][1])
                  : 0;
                return v1 - v2;
              },
              render: (text: any) => <p>{Array.isArray(text) ? text[1] : 0}</p>,
              maximumFractionDigits: 2,
            },
          ],
        },
        {
          title: [
            `${String(projectMonth.NextMonth).substring(0, 3)}  ${
              projectMonth.NextMonthYear
            }`,
          ],
          className: "nextMonth",

          children: [
            {
              title: "% Age",
              className: "titleNextMonth percentage-column",
              key: `${projectMonth.NextMonth}% Age`,
              dataIndex: [projectMonth.NextMonth],
              render: (text: any) => <p>{Array.isArray(text) ? text[0] : 0}</p>,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.NextMonth])
                  ? parseFloat(
                      a[projectMonth.NextMonth][0].replace("%", "").trim()
                    )
                  : 0;
                const v2 = Array.isArray(b[projectMonth.NextMonth])
                  ? parseFloat(
                      b[projectMonth.NextMonth][0].replace("%", "").trim()
                    )
                  : 0;
                return v1 - v2;
              },
            },
            {
              title: "Hours",
              className: "titleNextMonth",
              key: `${projectMonth.NextMonth}Hours`,
              dataIndex: [projectMonth.NextMonth],
              render: (text: any) => <p>{Array.isArray(text) ? text[2] : 0}</p>,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.NextMonth])
                  ? parseFloat(a[projectMonth.NextMonth][2])
                  : 0;
                const v2 = Array.isArray(b[projectMonth.NextMonth])
                  ? parseFloat(b[projectMonth.NextMonth][2])
                  : 0;
                return v1 - v2;
              },
              maximumFractionDigits: 2,
            },
            {
              title: "STU's",
              className: "titleNextMonth",
              key: `${projectMonth.NextMonth}STUs`,
              dataIndex: [projectMonth.NextMonth],
              render: (text: any) => <p>{Array.isArray(text) ? text[1] : 0}</p>,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.NextMonth])
                  ? parseFloat(a[projectMonth.NextMonth][1])
                  : 0;
                const v2 = Array.isArray(b[projectMonth.NextMonth])
                  ? parseFloat(b[projectMonth.NextMonth][1])
                  : 0;
                return v1 - v2;
              },
              maximumFractionDigits: 2,
            },
          ],
        },
        {
          title: [
            `${String(projectMonth.FutureMonth).substring(0, 3)}  ${
              projectMonth.FutureMonthYear
            }`,
          ],
          className: "futureMonth",

          children: [
            {
              title: "% Age",
              className: "titleFutureMonth percentage-column",
              key: `${projectMonth.FutureMonth}% Age`,
              dataIndex: [projectMonth.FutureMonth],
              render: (text: any) => <p>{Array.isArray(text) ? text[0] : 0}</p>,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.FutureMonth])
                  ? parseFloat(
                      a[projectMonth.FutureMonth][0].replace("%", "").trim()
                    )
                  : 0;
                const v2 = Array.isArray(b[projectMonth.FutureMonth])
                  ? parseFloat(
                      b[projectMonth.FutureMonth][0].replace("%", "").trim()
                    )
                  : 0;
                return v1 - v2;
              },
            },
            {
              title: "Hours",
              className: "titleFutureMonth",
              key: `${projectMonth.FutureMonth}Hours`,
              dataIndex: [projectMonth.FutureMonth],
              render: (text: any) => <p>{Array.isArray(text) ? text[2] : 0}</p>,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.FutureMonth])
                  ? parseFloat(a[projectMonth.FutureMonth][2])
                  : 0;
                const v2 = Array.isArray(b[projectMonth.FutureMonth])
                  ? parseFloat(b[projectMonth.FutureMonth][2])
                  : 0;
                return v1 - v2;
              },
              maximumFractionDigits: 2,
            },
            {
              title: "STU's",
              className: "titleFutureMonth",
              key: `${projectMonth.FutureMonth}STUs`,
              dataIndex: [projectMonth.FutureMonth],
              render: (text: any) => <p>{Array.isArray(text) ? text[1] : 0}</p>,
              sorter: (a, b) => {
                const v1 = Array.isArray(a[projectMonth.FutureMonth])
                  ? parseFloat(a[projectMonth.FutureMonth][1])
                  : 0;
                const v2 = Array.isArray(b[projectMonth.FutureMonth])
                  ? parseFloat(b[projectMonth.FutureMonth][1])
                  : 0;
                return v1 - v2;
              },
              maximumFractionDigits: 2,
            },
          ],
        },
          rolesEditAccess ? 
          {
          title: "Edit",
          dataIndex: ["TalentData"],
          render: (record: any) => (
            <>
              <div className="actionBtns">
                  <span onClick={() => handleView(record)}>
                    <img className="Editimage" src={editImage} />
                  </span>
              </div>
            </>
          ),
        }:{},
      ]
    : [];

  const openNotificationForAllocation = () => {
    notification.open({
      message:
        "Resource Allocation cloned successfully for the financial month(s)",
    });
  };

  const handleConfirmClick = async () => {
    try {
      //const projectOwner = projectOwners[0];
     // if (projectOwner) {
     //   const { project_id } = projectOwner;
        const project_id = id;
        if (project_id) {
        const response = await AxiosConfig.post(
          `allocations/clone/${project_id}?from_month_id=${
            projectMonth.ThisMonthFinancialMonthId
          }&to_month_id=${selectedMonths.join(",")}`,
          null,

          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        if (response?.data &&response?.data?.data && response?.data?.data?.MissedAllocationDetails && response?.data?.data?.MissedAllocationDetails?.length > 0) {
          notification.open({
            message: "",
            description: "Allocation for the below resource(s) is not cloned as it is exceeding 100% for the month",
            style: { color: "red" },
          });
        }
  
        if (response.status === 200) {
          openNotificationForAllocation();
          window.setTimeout(function () {
            location.reload();
          }, 5000);
        } else {
          setIsSaving(false);
        }
  
        setSelectedMonths([]);
        setDropdownVisible(false);
      } else {
        console.error("No project found");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelClick = () => {
    setSelectedMonths([]);
    setDropdownVisible(false);
  };

  const menu = (
    <Menu>
      <Menu.Item>
      <div className="cloneToText">{allocationDetails.buttonCloneTo}</div>
        <Checkbox.Group
          onChange={(checkedValues) => setSelectedMonths(checkedValues)}
          value={selectedMonths}
        >
          <Col className="monthOptions" span={12}>
            <Checkbox value={projectMonth.NextMonthFinancialMonthId}>
              {projectMonth.NextMonth}&nbsp;{projectMonth.NextMonthYear}
            </Checkbox>
          </Col>
          <Col span={12}>
            <Checkbox value={projectMonth.FutureMonthFinancialMonthId}>
              {projectMonth.FutureMonth}&nbsp;{projectMonth.FutureMonthYear}
            </Checkbox>
          </Col>
        </Checkbox.Group>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div className="cardClone">
          <Button
            className="buttonStyle"
            type="primary"
            onClick={handleCancelClick}
          >
            {allocationDetails.buttonCancel}
          </Button>
          <Button
            className="buttonStyle"
            type="primary"
            onClick={handleConfirmClick}
            disabled={isSaving}
          >
            {allocationDetails.buttonConfirmClone}
          </Button>
        </div>
      </Menu.Item>
    </Menu>
  );

  const isTableEmpty = talentAllocation.length === 0;

  return (
    <Fragment>
      <Row gutter={24}>
        <Col xl={6} xs={22}>
          <div style={{ marginBottom: 15 }}>
            <span
              className="back-button"
              style={{
                fontSize: 16,
              }}
              onClick={() => history.goBack()}
            >
              <img src={arrow} />
              {allocationDetails.backButton}
            </span>
          </div>
        </Col>
        <Col xl={24} xs={2}>
          <Button id="button1" onClick={onClickRow}>
            {allocationDetails.buttonForProjectDetails}
          </Button>
          {rolesEditAccess && <Button
            style={{
              float: "right",
              borderRadius: "4px",
              color: "#fff",
              border: "none",
              padding: "7px 24px",
              fontSize: "16px",
              height: "40px",
              background: "#5c67e5",
              marginBottom: "20px",
            }}
            onClick={visibleHandler}
          >
            {allocationDetails.buttonForManageTalent}
          </Button>}
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xl={6} xs={22}>
          <Card
            className="basic-info"
            bordered={false}
            style={{ width: "341px" }}
          >
            <BasicInfo
              projectNameParent={projectName}
              billablaeGeoOption={billabaleGeoOption}
              customerNameParent={customerName}
              associatedBUParent={associatedBU}
              projectCodeParent={projectCode}
              managersParent={managers}
              attachmentIds={attachmentIds}
              isBillable={isBillable}
              locationNameParent={locationData}
              editreq={false}
              flag={true}
              projectId={id}
              projectOwners={projectOwners}
              buHeads={buHeads}
              rolesData={rolesData}
              projectAccessRolesData={projectAccessRolesData}
              isNotAllocationPage={true}
              roleHistory={roleHistory}
              rolesEditAccess={rolesEditAccess}
              currentFinacialMonth={currentFinacialMonth}
              allUsers = {allUsers}
              projectDetails={projectDetailsData}
            ></BasicInfo>
          </Card>
          <Row gutter={24}>
            <Col xl={8} xs={24}>
              <Card
                className="ProjectStuSummary-Info"
                bordered={false}
                style={{ width: "341px" }}
              >
                <ProjectStuSummary
                  parentStus={stusSold}
                  parentSales={allocatedStus}
                  exuctedSales={stusExecuted}
                ></ProjectStuSummary>
              </Card>
            </Col>
          </Row>
        </Col>
        {/* Loader implemention */}
        {isLoading === true ? (
          <Loader />
        ) : (
          <Col xl={18} xs={2}>
            <div>
              <Card>
                <Table
                  columns={columns1}
                  dataSource={dataSource}
                  bordered
                  className="TalentDetails"
                  pagination={false}
                />
              </Card>
            </div>
            <br />
            <Card>
              <div className="cardClone">
                <h2>{allocationDetails.headingForTalentAllocation}</h2>
                <div>
                  {isTableEmpty ? (
                    <></>
                  ) : (
                    <Space>
                      {rolesEditAccess && <Dropdown
                        overlay={menu}
                        trigger={["click"]}
                        visible={dropdownVisible}
                        onVisibleChange={(visible) =>
                          setDropdownVisible(visible)
                        }
                        placement="bottomRight"
                      >
                        <Button
                          className="cloneButton"
                          onClick={() => setDropdownVisible(!dropdownVisible)}
                        >
                          {allocationDetails.buttonCloneFrom}{" "}
                          {`${String([projectMonth.ThisMonth]).substring(
                            0,
                            3
                          )}  ${[projectMonth.ThisMonthYear]}`}{" "}
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                      }
                    </Space>
                  )}
                </div>
              </div>
              <div className="overflow-x-scroll">
                <Table
                  columns={columns}
                  dataSource={filteredTalentAllocationWithoutZeroRows}
                  bordered
                  className="TalentDetails"
                  pagination={false}
                  summary={() => (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} className="total">
                        {allocationDetails.textForTotal}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={1}
                        className="Summary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={2}
                        className="Summary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={3}
                        className="Summary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={4} className="Summary">
                        <Text>
                          {getThismonthTotalHours !== 0
                            ? getThismonthTotalHours.toFixed(2)
                            : ""}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5} className="Summary">
                        <Text className="Summary">
                          {getThismonthTotalStu !== 0
                            ? getThismonthTotalStu.toFixed(2)
                            : ""}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={6}
                        className="Summary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={7} className="Summary">
                        <Text className="Summary">
                          {getNextmonthTotalHours !== 0
                            ? getNextmonthTotalHours.toFixed(2)
                            : ""}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8} className="Summary">
                        <Text className="Summary">
                          {getNextmonthTotalStu !== 0
                            ? getNextmonthTotalStu.toFixed(2)
                            : ""}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={9}
                        className="Summary"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={10} className="Summary">
                        <Text>
                          {getFuturemonthTotalHours !== 0
                            ? getFuturemonthTotalHours.toFixed(2)
                            : ""}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={11} className="Summary">
                        <Text>
                          {getFuturemonthTotalStu !== 0
                            ? getFuturemonthTotalStu.toFixed(2)
                            : ""}
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                />
              </div>
            </Card>
          </Col>
        )}
      </Row>
      {projectName && (
        <>
          <AddOverlay
            visible={visible}
            onClose={closeHandler}
            onCancelButton={closeHandler}
            projectName={projectName}
            projectId = {projectId}
            projectCode={projectCode}
            emp_id={getTalentData}
            id={id}
            projectDeptId={projectDeptId}
            projectOwners={projectOwners}
            managersParent={managers}
            projectMonth={projectMonth}
            currentMonthId={currmonth}
            talentData={getTalentData}
            monthid={currmonth}
            names={talentAllocation.map(item => item.Name)} 
            empId= {talentAllocation.map(item => item.Employee_id)}
            monthId1 = {month_id1}
            monthId2 = {month_id2}
            monthId3 = {month_id3}
          ></AddOverlay>
          {visibleEdit && (
            <EditAllocation
              projectDeptId={projectDeptId}
              projectOwners={projectOwners}
              managersParent={managers}
              visible={visibleEdit}
              onClose={closeHandler1}
              onCancelButton={closeHandler1}
              projectName={projectName}
              projectId = {projectId}
              projectCode={projectCode}
              id={id}
              talentData={getTalentData}
              monthid={currmonth}
            ></EditAllocation>
          )}
        </>
      )}
    </Fragment>
  );
};
export default AllocationDetails;