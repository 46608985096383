import { Droppable } from "react-beautiful-dnd";
import ListItem from "./lisitItem";
import React from "react";
import styled from "styled-components";

const ColumnHeader = styled.div`
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const DroppableStyles = styled.div`
  padding: 10px;
  border-radius: 6px;
  background: white;
`;
const DraggableElement: any = ({ prefix, elements, isEditModeEnabled }) => {
  elements = elements?.map((element) => {
    return { ...element, prefix };
  });
  return (
    <DroppableStyles>
      <ColumnHeader>{prefix}</ColumnHeader>
      <Droppable droppableId={`${prefix}`}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {elements?.map((item, index) => (
              <ListItem
                key={item.id}
                item={item}
                index={index}
                isEditModeEnabled={isEditModeEnabled}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DroppableStyles>
  );
};

export default DraggableElement;
