import "./PoweredBy.scss";
import companyLogo from "../../assets/images/Logo.png";

const PoweredBy = () => {
  return (
    <div className="powered-by mt20">
      <span className="powered-by__text">Powered By</span>
      <img src={companyLogo} className="powered-by__logo" alt="company-logo" />
    </div>
  );
};

export default PoweredBy;
