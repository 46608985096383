import { GET_CURRENCY_RATE, GET_CURRENCY_RATE_ERROR } from "../../constants";

export const getCurrencyRate = (result) => {
    return async (dispatch) => {
        try {
            if (result !== null) {
                dispatch({
                    type: GET_CURRENCY_RATE,
                    payload:result,
                });
            } else {
                dispatch({
                    type: GET_CURRENCY_RATE_ERROR,
                    payload: "Error: Data not found.",
                });
            }
        } catch (error: any) {
            dispatch({
                type: GET_CURRENCY_RATE_ERROR,
                payload: error,
            });
        }
    };
};
