import "antd/dist/antd.css";
import {Divider, Drawer, Modal } from "antd";
import Select from "react-select";
import { EditProject } from "../../../utils/enums";
import { Formik } from "formik";
import * as yup from "yup";
import { AxiosConfig } from "../../../ApiConfig";
import { InputField } from "../../../components";
import ButtonReuse from "../../../components/Buttons/Button";
import { useState, useEffect } from "react";
import { getToken } from "../../../auth";
import { notification, Row } from "antd";
import { useParams } from "react-router-dom";
require("./EditProject.scss");
import EditProjectsAttachments from "./EditProjectAttachments";
import UploadProjectsAttachment from "./UploadProjectAttachment";
import { getLocation } from "../../../stores/actions/getLocationAction";
import { useDispatch, useSelector } from "react-redux";


const AddOverlay = (props: {
  visible?: any;
  onClose?: any;
  onCancelButton?: any;
  attachmentIds?: any;
  projectDetails:any
}) => {
  const validationSchema = yup.object({
    project_name: yup.string().trim().required("Please enter the project name"),
    geo_id: yup.object().required("please select the Geo Location"),
  });

  let { id }: any = useParams();
  const { projectDetails } = props;

  let projectAttachmentIds = props.attachmentIds;

  const onCancelHandler = () => {
    props.onCancelButton();
  };
  var token = getToken();

  const [customers, setCustomers] = useState([]);
  const [location, setLocation] = useState([]);
  const [locationId, setLocationId] = useState();
  const [projectCode, setProjectCode] = useState();
  const [projectName, setProjectName] = useState();
  const [customerName, setCustomerName] = useState();
  const [billableEntityData, setBillableEntityData] = useState();
  const [billableEntityId, setBillableEntityId] = useState();
  const [customerId, setCustomerId] = useState();
  const [associatedBU, setAssociatedBU] = useState();
  const [associatedBUId, setAssociatedBUId] = useState();
  const [billableOptionDefault, setBillableOptionDefault] = useState({});
  const [locationData, setLocationData] = useState([]);
  const [billableGeoOption, setBillableGeoOption] = useState([]);

  const dispatch: any = useDispatch();
  const geoLocationData = useSelector((store: any) => store.locations.location);

  useEffect(() => {
    getData();
  }, [projectDetails]);

  useEffect(() => {
    dispatch(getLocation());
  }, [dispatch]);

  useEffect(() => {
    if (geoLocationData) {
      setLocation(geoLocationData);
    }
  }, [geoLocationData]);

  const getData = async () => {
    try {
      const userId = localStorage.getItem("userId");
      if(userId){
      // const res = await AxiosConfig.get(`projects/${id}?user_id=${userId}`, {
      //   headers: { Authorization: `Bearer ${token}` },
      // });
  
      setProjectName(projectDetails?.name);
      setCustomerName(projectDetails?.customer?.name);
      setBillableEntityData(
        projectDetails?.billableentity?.entity_name
      );
      setBillableEntityId(projectDetails?.billable_id);
      setCustomerId(projectDetails?.customer?.id);
      setLocationId(projectDetails?.Geography?.id);
      setAssociatedBU(projectDetails?.department?.name);
      setAssociatedBUId(projectDetails?.department?.id);
      setProjectCode(projectDetails?.project_code);
      if (projectDetails?.billable_option == true) {
        setBillableOptionDefault({ label: "Billable", value: 1 });
      } else if (projectDetails?.billable_option == false) {
        setBillableOptionDefault({ label: "Non-Billable", value: 1 });
      } else {
        setBillableOptionDefault({});
      }
      setLocationData(projectDetails?.Geography?.location);
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    getBillableGeo();
  }, []);

  const getCustomers = async () => {
    await AxiosConfig.get("customers", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setCustomers(res.data.data.customers);
    });
  };

  let obj: any = localStorage.getItem("Departments");
  let departments = JSON.parse(obj);
  let filteredBu = [];
  if (departments === null) {
    filteredBu = [];
  } else {
    filteredBu = departments;
  }

  const getBillableGeo = async () => {
    try {
      const response = await AxiosConfig.get("billableentity", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const billableOptions = response.data.data.billableEntity.map(
        (billable_option) => ({
          value: billable_option.entity_name,
          label: billable_option.entity_name,
          key: billable_option.id,
        })
      );

      setBillableGeoOption(billableOptions);
    } catch (error) {
      console.error("Error fetching billable options:", error);
    }
  };

  const customerOptions = customers.map(function (customer: {
    name: string;
    label: string;
    id: number;
  }) {
    return {
      value: customer.name,
      label: customer.name,
      key: customer.id,
    };
  });

  const locationOptions = location.map(function (Locations: {
    location: any;
    id: any;
  }) {
    return {
      value: Locations.id,
      label: Locations.location,
    };
  });

  const associatedBuOptions = filteredBu.map(function (associatedBus: {
    name: any;
    label: any;
    id: any;
  }) {
    return {
      value: associatedBus.name,
      label: associatedBus.name,
      key: associatedBus.id,
    };
  });

  const billableValues = [
    { key: 1, name: "Billable" },
    { key: 0, name: "Non-Billable" },
  ];
  const billableOptions = billableValues.map(function (billableBU: {
    name: any;
    key: any;
  }) {
    return {
      value: billableBU.name,
      label: billableBU.name,
      key: billableBU.key,
    };
  });

  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const showEditModal = () => {
    setIsModalVisibleEdit(true);
  };
  const handleCancelModalEdit = () => {
    setIsModalVisibleEdit(false);
  };
  const [editImage, setEditImage] = useState<any>([]);
  let image_arr: any = [];
  let edit_image_arr: any = [];

  useEffect(() => {
    projectAttachmentIds.forEach(function (row: any) {
      const attachment_id = row.attachment_id;
      getAttachments(attachment_id);
    });
  }, [projectAttachmentIds]);

  const getAttachments = (attachment_id: any) => {
    AxiosConfig.get("projects/attachments/" + attachment_id, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    }).then((res) => {
      let image_obj: any = {};
      const url = URL.createObjectURL(res.data);
      image_obj["id"] = attachment_id;
      image_obj["url"] = url;
      edit_image_arr.push(image_obj);
      image_arr.push(url);
      setEditImage(edit_image_arr);
    });
  };

  return (
    <>
      <Drawer
        title="Edit Project"
        width={600}
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="edit_project_form"
      >
        <Formik
          initialValues={{
            customer_id: { label: customerName, value: customerId },
            geo_id: { label: locationData, value: locationId },
            project_name: projectName,
            billable_option: billableOptionDefault,
            billable_id: { lable: billableEntityData, value: billableEntityId },
            associated_bu_id: { label: associatedBU, value: associatedBUId },
            project_code: projectCode,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
              let diffValues: any = {};
              let id_array: any = [];
              for (const element in values) {
                if (values[element].value && !Array.isArray(values[element])) {
                  diffValues[element] = values[element].value;
                }
                else if(Array.isArray(values[element])) {
                  values[element].forEach((item: any) => {
                    id_array.push(item.value);
                  });
                  diffValues[element] = id_array;
                } else {
                  diffValues[element] = values[element];
                }
              }
              AxiosConfig.put(`projects/${id}`, diffValues, {
                headers: { Authorization: `Bearer ${token}` },
              })
                .then((response) => {
                  if (response.status == 200) {
                    const notify = () => {
                      notification.open({
                        message: response.data.status.message,
                      });
                    };
                    {
                      notify();
                    }
                    setTimeout(function () {
                      window.location.reload();
                    }, 1000);
                  }
                })
                .catch(function (error) {
                  const notify = () => {
                    notification.open({
                      message: "",
                      description: error.response?.data?.status?.message
                        ? typeof error.response.data.status.message === "string"
                          ? error.response.data.status.message
                          : error.response.data.status.message.details[0].message.replaceAll(
                              '"',
                              ""
                            )
                        : "Unknown error occurred",
                      style: { color: "red" },
                    });
                  };
                  {
                    notify();
                  }
                });
          }}
        >
          {({ handleSubmit, handleChange, errors, values, setFieldValue }) => (
            <form onSubmit={handleSubmit} className="add_project_form">
              <label>{EditProject.labelForCustomerName} </label>
              <Select
                options={customerOptions}
                onChange={(value: { key: any }) =>
                  setFieldValue("customer_id", {
                    label: "Selected Value",
                    value: value.key,
                  })
                }
                defaultValue={{ label: customerName, value: customerId }}
                name="customer_id"
                placeholder="Select customer name"
              />

              <p className="display_error">{errors.customer_id}</p>

              <label>{EditProject.labelForProjectName}</label>

              <InputField
                placeholder="Enter Project Name"
                name="project_name"
                onChange={handleChange}
                value={values.project_name}
                className="input--field--edit"
              ></InputField>
              <p className="display_error">{errors.project_name}</p>

              <label>{EditProject.labelForProjectCode}</label>

              <InputField
                name="project_code"
                value={projectCode}
                className="input--field--edit"
                disabled={true}
              ></InputField>
              <label>{EditProject.labelForAssociatedBU}</label>
              <Select
                placeholder="Select Associated BU"
                options={associatedBuOptions}
                onChange={(value: { key: any }) =>
                  setFieldValue("associated_bu_id", {
                    label: "Selected Value",
                    value: value.key,
                  })
                }
                name="associated_bu_id"
                defaultValue={{ label: associatedBU, value: associatedBUId }}
              />
              <p className="display_error">{errors.associated_bu_id}</p>

              <label>{EditProject.labelForBillableorNonBillable}</label>
              <Select
                placeholder="Billable/Non-Billable"
                options={billableOptions}
                onChange={(value: { key: any }) =>
                  setFieldValue("billable_option", value.key)
                }
                name="billable_option"
                defaultValue={billableOptionDefault}
              />
              <p className="display_error">{errors.billable_option}</p>
              <label>{EditProject.labelForBillableOptions}</label>
              <Select
                placeholder="Billable Entity"
                options={billableGeoOption}
                onChange={(value: { key: number }) => {
                  setFieldValue("billable_id", value.key);
                }}
                name="billableGeoOption"
                defaultValue={{
                  label: billableEntityData,
                }}
              />
              <label>{EditProject.labelForGeoLocation}</label>
              <Select
                placeholder="Select location"
                options={locationOptions.filter(
                  (option) => option.value !== locationId
                )}
                onChange={(value: { value: number }) =>
                  setFieldValue("geo_id", {
                    label: "Selected Value",
                    value: value.value,
                  })
                }
                name="geo_id"
                defaultValue={{ label: locationData, value: locationId }}
              />
              <p className="display_error">{errors.geo_id}</p>

              <label>{EditProject.labelForAttachments}</label>
              <Row>
                <ButtonReuse
                  value="Edit"
                  type="submit"
                  className="edit-image-button"
                  onClick={showEditModal}
                />
                <Modal
                  title="Attachments"
                  className="AttachmentsModal"
                  visible={isModalVisibleEdit}
                  onCancel={handleCancelModalEdit}
                >
                  <UploadProjectsAttachment projectId={id} />
                  <EditProjectsAttachments images={editImage} />
                </Modal>
              </Row>
              <Divider />
              <div className="button-container">
                <ButtonReuse
                  type="primary"
                  className="primary-btn"
                  htmlType="submit"
                  value="Update"
                ></ButtonReuse>
                <ButtonReuse
                  type="primary"
                  className="primary-btn cancel--btn"
                  value="Cancel"
                  onClick={onCancelHandler}
                ></ButtonReuse>
              </div>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export default AddOverlay;
