import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getToken } from "../auth";

const PublicRoute = ({ component: Component, restricted, ...rest }: any) => {
  var token = getToken();
  return (
    <Route
      {...rest}
      render={(props) =>
        token && restricted ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
};

export default PublicRoute;
