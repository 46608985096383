import { GET_BASE_CURRENCY, GET_BASE_CURRENCY_ERROR } from "../../constants";
import { Axios } from "../../utils/axios";

export const getBaseCurrency =()=>{
    return (async(dispatch)=>{
            try{
                const response = await Axios.get("currency/baseCurrency")

                if ( response.status == 200 ) {
                    dispatch({
                        type:GET_BASE_CURRENCY,
                        payload: response.data.data.baseCurrency
                    });
                } else {
                    dispatch({
                        type:GET_BASE_CURRENCY_ERROR,
                        payload: "Error: Data not found."
                    });
                }
            } catch(error:any){
                dispatch({
                    type:GET_BASE_CURRENCY_ERROR,
                    payload: error
                });
            } 
    });
};