import "./Basicinfo.scss";
import { Row, Col, Modal, Button, Typography, Image } from "antd";
import EditOverlay from "../Project/EditProject";
import { useState, useEffect, useRef } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import ViewAttachment from "./ViewAttachment";
import ButtonReuse from "../../../components/Buttons/Button";
import { DownloadOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import moment from "moment";
import { basicInfo } from "../../../utils/enums";
import pdfPlaceholderImage from "../../../assets/images/pdfPlaceHolder.png";
import xlsxPlaceholderImage from "../../../assets/images/xlsx_thumbnail.png";
import docxPlaceholderImage from "../../../assets/images/docximage.png";
import AddRoles from "./AddRoles";
import { useParams } from "react-router";
import editIcon from "../../../assets/images/edit.png";

interface IProjectOwner {
  id: number;
  is_active: boolean;
  start_date: Date;
  end_date: Date;
  project_id: number;
  owner_id: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
}
interface IBUHeads{
  id: number;
  is_active: boolean;
  project_id: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
}

const BasicInfo = (props: {
  projectNameParent?: any;
  customerNameParent?: any;
  associatedBUParent?: any;
  projectCodeParent?: any;
  managersParent?: any;
  attachmentIds?: any;
  isBillable?: any;
  locationNameParent?: any;
  billablaeGeoOption?: string;
  editreq?: any;
  flag?: boolean;
  projectId?: number;
  projectOwners: IProjectOwner[];
  buHeads: IBUHeads[];
  rolesData?: any;
  projectAccessRolesData?:any;
  isNotAllocationPage?:boolean;
  roleHistory?:any;
  rolesEditAccess:boolean;
  currentFinacialMonth:any;
  allUsers:any;
  projectDetails:any;
  
}) => {
  const { rolesData, projectAccessRolesData, isNotAllocationPage, roleHistory, rolesEditAccess, currentFinacialMonth,allUsers, projectDetails} = props;
  const { Text } = Typography;
  const params:any = useParams();
  const monthFormat = "MM/YYYY";
  let attachmentIds = props.attachmentIds;

  var token = getToken();
  let fileData: any = useRef({});

  const [visible, setVisible] = useState(false);
  const [visibleAddRoles, setVisibleAddRoles] = useState<boolean>(false);
  const [submitType,setSubmitType] = useState("add");
  const [addRolesFormValues,setAddRolesFormValues]:any = useState([]);
  const [currentRoleHistory,setCurrentRoleHistory]:any = useState([]);
  const [currentSelectedHistoryData,setCurrentSelectedHistoryData] = useState({});

  const visibleHandler = () => {
    setVisible(true);
  };
  const closeHandler = () => {
    setVisible(false);
  };
  const closeAddRoles = () => {
    setVisibleAddRoles(false);
  };

  const [image, setImage] = useState<any>([]);
  const [isModalVisibleImage, setIsModalVisibleImage] = useState(false);

  let image_arr: any[] = [];

  const showViewModalImage = () => {
    setIsModalVisibleImage(true);
  };
  const handleCancelModalImage = () => {
    setIsModalVisibleImage(false);
  };

  const handleDownload = (file_id: any) => {
    saveAs(
      fileData["current"][file_id]["data"],
      fileData["current"][file_id]["attachment_id"].split("^").pop()
    );
  };

  useEffect(() => {
    attachmentIds.forEach(function (row: any) {
      const attachment_id = row.attachment_id;
      getAttachments(attachment_id);
    });
  }, [attachmentIds]);

  const getAttachments = async (attachment_id: any) => {
    AxiosConfig.get("projects/attachments/" + attachment_id, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    }).then((res) => {
      const url = URL.createObjectURL(res.data);
      fileData["current"][url] = { data: res.data, attachment_id };
      image_arr.push(url);
      setImage(image_arr);
    });
  };
  const images = image.map((img_url: any, index: any) => {
    const fileNameWithExtension = fileData["current"][img_url]["attachment_id"];
    const fileName = fileNameWithExtension.split("^").pop().split(".")[0];

    const fileType = fileNameWithExtension.split(".").pop();
    { 
      return (
        <div className="attachment" key={index}>
          {fileType.toLowerCase() === "pdf" ? (
            <div className="pdf-placeholder">
              <img
                src={pdfPlaceholderImage}
                alt="PDF Placeholder"
                className="pdf-placeholder-image"
              />
            </div>
          ) : fileType.toLowerCase() === "xlsx" ? (
            <div className="xlsx-placeholder">
              <img
                src={xlsxPlaceholderImage}
                alt="XLSX Placeholder"
                className="xlsx-placeholder-image"
              />
            </div>
          ) : fileType.toLowerCase() === "docx" ? (
            <div className="docx-placeholder">
              <img
                src={docxPlaceholderImage}
                alt="DOCX Placeholder"
                className="docx-placeholder-image"
              />
            </div>
          ) : (
            <Image
              className="attachment-img"
              width={62}
              height={62}
              src={img_url}
              alt="Attachment"
            />
          )}
          <Button
            type="primary"
            shape="round"
            icon={<DownloadOutlined />}
            size="small"
            onClick={() => handleDownload(img_url)}
          />
          <text>
            {fileName}
          </text>
        </div>
      );
    }
  });

  const openAddRoles = (type:string,data:any) =>{
    let formData = {
      role_name: data?.role_name || "",
      user_name: data?.user_name || "",
      user_id: data?.user_id || 0,
      from_date: data?.from_date ? new Date(data?.from_date) : new Date(currentFinacialMonth),
      to_date: data?.to_date ? new Date(data?.to_date) : "",
      is_read_only: data?.is_read_only || false,
      project_id: parseInt(params.id),
      id: data?.id || undefined,
      is_active: true
  }
    const currentTempRoleHistory = getCurrentRoleHistoryData(data?.role_name || "");
    const otherFilteredData = currentTempRoleHistory?.filter(rol =>rol.user_id !== data.user_id);
    type ==="add" ? setCurrentRoleHistory([]) : setCurrentRoleHistory(otherFilteredData || []);
    const historyData = type ==="edit" ? currentTempRoleHistory?.find(rol =>rol.user_id === data.user_id) : {};
    setCurrentSelectedHistoryData(historyData || {});
    setAddRolesFormValues(formData);
    setSubmitType(type);
    setVisibleAddRoles(true);
  }

  const getCurrentRoleHistoryData = (selectedRole:string) =>{
    const existingProjectAccessRolesActiveData = projectAccessRolesData?.map(rol => {return {...rol, is_active:true}}) || [] ;
    switch (selectedRole) {
      case "Project Owner":
        return roleHistory?.project_owner;
      case "Project Access":
        return [...roleHistory?.project_access, ...existingProjectAccessRolesActiveData];
      case "Team Lead":
        return roleHistory?.team_lead;
      case "QA Lead":
        return roleHistory?.qa_lead;
      case "Architect":
        return roleHistory?.architect;
      default:
        return [];
    }
  }


  return (
    <div className="basic-info">
      <Row className="BasinInfoHeader">
        <Col span={20} className="break-word">
          <h1>{props.projectNameParent}</h1>
        </Col>
        <Col span={4} className="imgColumn">
          {rolesEditAccess &&
            <img
              src={editIcon}
              onClick={visibleHandler}
              className="editProjectIcon"
            />
          }
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <p>{basicInfo.textForProjectCode}</p>
        </Col>
        <Col span={12}>
          <p>{props.projectCodeParent}</p>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <p>{basicInfo.textForCustomer}</p>
        </Col>
        <Col span={12}>
          <p>{props.customerNameParent}</p>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <p>{basicInfo.textForDepartment}</p>
        </Col>
        <Col span={12}>
          <p>{props.associatedBUParent}</p>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <p>{basicInfo.textForBillable}</p>
        </Col>
        <Col span={12}>
          <p>{props.isBillable}</p>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <p>{basicInfo.textForBillableOption} </p>
        </Col>
        <Col>
          <p>{props?.billablaeGeoOption}</p>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <p>{basicInfo.textForGeography}</p>
        </Col>
        <Col span={12}>
          <p>{props.locationNameParent}</p>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <label>{basicInfo.labelForAttachment}</label>
        </Col>
        <Col span={12}>
          <ButtonReuse
            value="View"
            type="submit"
            className="view-button"
            onClick={showViewModalImage}
          />
        </Col>
      </Row>
      
        <Row>
          <Col xl={6}>
            <label>{basicInfo.textForRoles}</label>
          </Col>
          <Col xl={18}>
          {isNotAllocationPage && (rolesEditAccess) &&
            <ButtonReuse
              value="Add new role"
              type={"primary"}
              className="add-new-role-btn"
              onClick={() =>{openAddRoles("add",{})}}
            />
          }
          </Col>
        </Row>
      {isNotAllocationPage && rolesData?.map((role,index) =>{
        return(
        <Row className="mt-26" key={`role-${index+1}`}>
          <Col xl={8}>
            {role.role_name}
          </Col>
          <Col xl={13}>
            <div>
              {role.user_name}
            </div>
            <div className="assigned-date-container">
              <Text type="secondary">
                {`${moment(
                 role.from_date
                ).format(monthFormat)} - ${moment(
                  role.to_date
                ).format(monthFormat)}`}
              </Text>
            </div>
          </Col>
          <Col xl={3}>
            <div className="roles-edit-container">
              {(rolesEditAccess) &&
                <img
                  src={editIcon}
                  onClick={()=>{openAddRoles("edit",role)}}
                  className="editProjectIcon roles-edit-ico"
                />
              }  
            </div>
          </Col>
        </Row>
      )})
      }       
      {projectAccessRolesData?.length > 0 && isNotAllocationPage &&
          <Row className="mt-26">
            <Col xl={8} xs={24}>
              {basicInfo.textForProjectAccess}
            </Col>
            <Col xl={16} xs={24}>
            {projectAccessRolesData?.map((role,index) =>{
              return(
                <div key={"role-"+index} className="mt-12">
                  <div className="inline">
                    {role.user_name}
                  </div>
                  <div className="access-edit-ico">
                    {(rolesEditAccess ) &&
                      <img
                        src={editIcon}
                        onClick={()=>{openAddRoles("edit",role)}}
                        className="editProjectIcon roles-edit-ico"
                      />
                    } 
                  </div>
                </div>
              )
            })}
            </Col>
          </Row>    
      }

      <Row>
        <div className="attachments-container">
          <Modal
            title="Attachments"
            className="AttachmentsModal"
            visible={isModalVisibleImage}
            onCancel={handleCancelModalImage}
          >
            <ViewAttachment images={images} />
          </Modal>
        </div>
      </Row>

      {projectDetails && 
        <EditOverlay
          visible={visible}
          onClose={closeHandler}
          onCancelButton={closeHandler}
          attachmentIds={attachmentIds}
          projectDetails={projectDetails}
        />
      }

      {visibleAddRoles &&
        <AddRoles 
          visible={visibleAddRoles}
          handleAddRolesClose={closeAddRoles}
          submitType={submitType}
          addRolesFormValues={addRolesFormValues}
          rolesData={rolesData}
          currentRoleHistory={currentRoleHistory}
          getCurrentRoleHistoryData={getCurrentRoleHistoryData}
          setCurrentRoleHistory={setCurrentRoleHistory}
          currentSelectedHistoryData={currentSelectedHistoryData}
          projectAccessRolesData = {projectAccessRolesData}
          allUsersData = {allUsers}
          setSumbitType={openAddRoles}
        />
      }
    </div>
  );
};
export default BasicInfo;
