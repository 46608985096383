import { useState, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import { getRandomColorForTeam } from "../../../utils/util";

const TeamSize = (props: {
  selectedDate?: any;
  selectedSkills?: any;
  selectedBu?: any;
}) => {
  const token = getToken();
  const dateFormat = "YYYY-MM-DD";
  const selected_date = moment(props.selectedDate).format(dateFormat);
  const [teamSize, setTeamSize] = useState([]);
  const [totalTeamSize, setTotalTeamSize] = useState(0);

  const getTeamSize = () => {
    const data = {
      selected_date: selected_date,
      department_id: props.selectedBu.map((bu: any) => bu.value),
      skill_id: props.selectedSkills.map((skill: any) => skill.value),
    };
    AxiosConfig.post("talents/talentsizebydept", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempDataArr: any = [];
        let total = 0;
        res.data.data.talentSize.forEach((team: any) => {
          tempDataArr.push({
            label: team.name,
            data: [team.talent],
            backgroundColor: getRandomColorForTeam(),
            hidden: false,
          });
          total += team.talent;
        });
        setTeamSize(tempDataArr);
        setTotalTeamSize(total);
      })
      .catch((err) => console.log(err));
  };
  const teamSizeDatasets: any = {
    labels: ["Team Size"],
    datasets: teamSize,
  };
  const teamSizeConfig = {
    type: "bar",
    teamSizeDatasets,
    options: {
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            boxHeight: 15,
            boxWidth: 15,
            generateLabels: (chart: any) => {
              const datasets = chart.data.datasets;
              return datasets.map((v: any) => ({
                text: `${v.label}- ${v.data[0]}`,
                fillStyle: v.backgroundColor,
              }));
            },
          },
        },
        title: {
          display: true,
          align: "start",
          padding: 10,
          font: { weight: "normal", size: "22px"},
          color: "#1E2225",
          text: ["Total Team Size", totalTeamSize],
        },
      },
      indexAxis: "y",
      barPercentage: 0.2,
      scales: {
        x: {
          stacked: true,
          display: false,
        },
        y: {
          stacked: true,
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    getTeamSize();
  }, [props.selectedSkills, props.selectedBu, props.selectedDate]);

  return (
    <>
      <Bar data={teamSizeDatasets} options={teamSizeConfig.options} />
    </>
  );
};

export default TeamSize;
