import { Table, Modal } from "antd";
import { Buttons, getMonthlySlots } from "../../../utils/enums";
import { useEffect, useState } from "react";
import Select from "react-select";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
// import * as yup from "yup";
import ButtonReuse from "../../../components/Buttons/Button";
import { Row, Col } from "antd";
import { InputField } from "../../../components";
import { notification } from "antd";
// import ChangeStatus from "./ChangeStatus";
require("./getMonthlySlots.scss");
import blueEye from "../../../assets/images/blue_eye.png";
import edit from "../../../assets/images/edit.png";
import ViewOverlay from "./viewMonthsData";
import EditOverlay from "./editMonthsData";
import { decimalCheck } from "../../../utils/util";

const MonthlySlots = () => {
  //   const [show, setShow] = useState(false);
  // const [rowId, setRowId] = useState();
  const [monthlyData, setMonthlyData] = useState([]);
  //const [isLoad, setLoad] = useState(false);
  var token = getToken();
  //   let { id }: any = useParams();

  const isActive = (status: any) => {
    if (status == 0) {
      return "Future"; //dropdown
    } else if (status == 1) {
      return "Active"; //text
    } else {
      return "Past";
    }
  };
  const statusValidation = (status: number) => {
    if (status == 0 || status == 1) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    //if (isLoad) {
    getData();
    //}
  }, []);

  const getData = async () => {
    AxiosConfig.get("financialmonths", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setMonthlyData(
        res.data.data.months.map(
          (row: {
            id: any;
            month: any;
            year: any;
            status: any;
            cost_per_stu: any;
          }) => ({
            id: row.id,
            month: row.month,
            year: row.year,
            status: isActive(row.status),
            Status: row.status,
            key: row.id,
            cost: decimalCheck(row.cost_per_stu, 2),
            action: statusValidation(row.status),
          })
        )
      );
      //setLoad(true);
    });
  };

  var filteredMonths = monthlyData.filter(function (month: {
    Status: any;
    // financialmonth: any;
  }) {
    return month.Status == 0;
  });

  var currentActiveMonth = monthlyData.filter(function (month: {
    Status: any;
    // financialmonth: any;
  }) {
    return month.Status == 1;
  });
  console.log("Filtered months", currentActiveMonth);

  const monthOptions = filteredMonths.map(function (month: {
    month: any;
    year: any;
    // status: any;
    id: any;
  }) {
    return {
      value: month.id,
      label: `${month.month} (${month.year})`,
      // status: month.status
      key: month.id,
    };
  });

  const statusOptions = [{ value: "1", label: "Active" }];

  const openNotification = () => {
    notification.open({
      message: "Status changed",
    });
  };

  const [monthId, setMonthId] = useState<any>([]);
  const [newitems, setNewItems] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMonthSelected, setIsMonthSelected] = useState(false);
  const [isStatusSelected, setIsStatusSelected] = useState(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    AxiosConfig.put("financialmonths/" + monthId, newitems, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.status == 200) {
          {
            openNotification();
          }
          setIsModalVisible(false);
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
      })
      .catch(function (error) {
        const notify = () => {
          notification.open({
            message: error.response.data.status.message,
          });
        };
        {
          notify();
        }
      });
  };

  useEffect(() => {
    const initialFormState = {
      supersec_pwd: "",
      status: "",
    };
    setNewItems(initialFormState);
  }, []);

  // const validationSchema = yup.object({
  //   status: yup.string().required("Please select the status"),
  //   month: yup.string().required("Please select the month"),
  //   supersec_pwd: yup.string().required("Please enter the password"),
  // });

  const handleChangeEvent = () => {
    setIsModalVisible(true);
    setNewItems({
      ...newitems,
    });
  };
  const [viewvisible, setViewVisible] = useState(false);
  const [rateDetails, setRateDetails] = useState([]);
  const [rateId, setRateId] = useState();
  const [editvisible, setEditVisible] = useState(false);

  const viewVisibleHandler = (id: any) => {
    setViewVisible(true);
    setRateId(id);
    AxiosConfig.get("rates/" + id.id, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setRateDetails(res.data?.monthRates?.mastercurrencies);
    });
  };
  const editVisibleHandler = (id: any) => {
    setEditVisible(true);
    setRateId(id);
    AxiosConfig.get("rates/" + id.id, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setRateDetails(res?.data?.monthRates?.mastercurrencies);
    });
  };
  const editcloseHandler = () => {
    setEditVisible(false);
  };
  const viewcloseHandler = () => {
    setViewVisible(false);
  };
  const columns = [
    {
      title: "MONTH",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "YEAR",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render(text: any) {
        return {
          props: {
            style: {
              color: text === "Active" ? "green" : "red",
            },
          },
          children: <div>{text}</div>,
          //   children: <ChangeStatus />,
        };
      },
    },
    {
      title: "COST/STU",
      dataIndex: "cost",
      key: "cost",
      className: "text-end",
      width: "12%",
    },
    {
      dataIndex: ["status"],
      key: "id",
      className: "text-end",
      width: "20%",
      render(status: any, record: any) {
        let actionButon;
        if (status == "Past") {
          actionButon = (
            <>
              <span>
                <img src={blueEye} onClick={() => viewVisibleHandler(record)} />
              </span>
            </>
          );
        } else {
          actionButon = (
            <span>
              <img src={edit} onClick={() => editVisibleHandler(record)} />
            </span>
          );
        }
        return <div>{actionButon}</div>;
      },
    },
  ];

  return (
    <>
      <ViewOverlay
        visible={viewvisible}
        onClose={viewcloseHandler}
        recordHandler={rateId}
        rateDetails={rateDetails}
      ></ViewOverlay>

      <EditOverlay
        visible={editvisible}
        onClose={editcloseHandler}
        recordHandler={rateId}
        rateDetails={rateDetails}
      ></EditOverlay>
      <Row>
        <Col span={8}>
          <h1 className="purchaseOrderTitle">{Buttons.headingForMonthSlots}</h1>
        </Col>
        <Col span={16}>
          <Row className="text-end">
            <Col span={8}>
              {/* <label>Select Month </label> */}
              <Select
                // defaultValue={monthOptions}
                options={monthOptions}
                onChange={(value: { value: any }) => {
                  // setFieldValue("po_type_id", value.value);
                  setMonthId(value.value);
                  setIsMonthSelected(true);
                }}
                name="month"
                placeholder="Select month"
                className="select-option"
              />
            </Col>

            <Col span={8}>
              {/* <label>Select Status </label> */}
              <Select
                options={statusOptions}
                onChange={(value: { value: any }) => {
                  setNewItems({
                    ...newitems,
                    status: value.value,
                  });
                  setIsStatusSelected(true);
                }}
                name="status"
                placeholder="Select status"
                className="select-option"
              />
            </Col>

            <Col span={8}>
              <ButtonReuse
                value="Change Status"
                type="primary"
                onClick={handleChangeEvent}
                className="update-button"
                disabled={!isMonthSelected || !isStatusSelected}
              ></ButtonReuse>
              <Modal
                title=""
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                className="password-modal"
              >
                <label>{getMonthlySlots.labelForSuperSecretPassword}</label>
                <p>{getMonthlySlots.confirmationNote}</p>
                <InputField
                  placeholder="Enter password"
                  name="supersec_pwd"
                  onChange={(event: any) => {
                    setNewItems({
                      ...newitems,
                      supersec_pwd: event.target.value,
                    });
                  }}
                  className="input--field"
                  type="password"
                ></InputField>
              </Modal>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="table-card">
        <Row>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={monthlyData}
              bordered
              pagination={{
                defaultPageSize: 12,
                showSizeChanger: true,
                pageSizeOptions: ["12", "24", "36", "48", "60"],
              }}
              onRow={() => ({
                onClick: (event: any) => console.log("event", event),
              })}
              className="projectsTable"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MonthlySlots;
