import { Table, notification, Tooltip } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons'; 
import ButtonReuse from "../../../../components/Buttons/Button";
import { InputField } from "../../../../components";
import { useState, useEffect } from "react";
import { AxiosConfig } from "../../../../ApiConfig";
import { getToken } from "../../../../auth";
import {
  convertNumberToPer,
  convertPerToNumber,
} from "../../../../utils/util";


const TableInfo = (props: {
  monthDetails?: any;
  monthName?: string;
  projectName?: string;
  monthId?: number;
  projectId?: number;
  empId?: string;
  allocatedPercent?: string;
  allocatedHour?: any;
  monthtotalPercentage?: string;
  totalpercentage?: any;
  allocationId?:any;
  TotalHour?: any;
  Hour?: any;
  temparr1?:any;
  temparr2?:any;
  temparr3?:any;
  monthId1 ? : any;
  monthId2 ? : any;
  monthId3 ? : any;
  TallocationId: any;
  updateIdsAfterSave: () => void; 
 
}) => {
  const openNotification = () => {
    notification.open({
      message: "Updated Successfully",
    });
  };
 
  const Tid = props.TallocationId

  const token = getToken();

  const thisMonth = props.temparr1;

  const nextMonth = props.temparr2;
  const futureMonth = props.temparr3;

  
  const thisMonthTooltipTitle = thisMonth ? thisMonth.map(item => `${item.project_name}: ${item.allocated_percentage}%`).join('\n') : '';
  const nextMonthTooltipTitle = nextMonth ? nextMonth.map(item => `${item.project_name}: ${item.allocated_percentage}%`).join('\n') : '';
  const futureMonthTooltipTitle = futureMonth ? futureMonth.map(item => `${item.project_name}: ${item.allocated_percentage}%`).join('\n') : '';

  

  let tooltipTitle: string | undefined;

  if (props.monthId === props.monthId1) {
    tooltipTitle = thisMonthTooltipTitle;
  } else if (props.monthId === props.monthId2) {
    tooltipTitle = nextMonthTooltipTitle;
  } else if (props.monthId === props.monthId3) {
    tooltipTitle = futureMonthTooltipTitle;
  }



  
  const openErrNotificationWithMessage = (message: string): void => {
      
    notification.error({
      message: "Invalid Allocation",
      description: message,
      style: { color: "red" },
    });
  };
 
  
  const openErrNotification = () => {
    notification.error({
      message: "Invalid Allocation",
      description: "Please enter valid number",
      style: { color: "red" },
    });
  };
  const notify = () => {
    notification.error({
      message: "Notification",
      description: `Total allocation cannot be greater than 100%`,
      style: { color: "red" },
    });
  };
   
   const [projectAllocations, setProjectAllocations] = useState<{
    [key: number]: { percentage: string; hour: string };
  }>({});


  useEffect(() => {
    if (props.monthDetails) {
      const initialAllocations: { [key: number]: { percentage: string; hour: string } } = {};
      props.monthDetails.forEach((item: any) => {
        initialAllocations[item.project_id] = {
          percentage: item.allocated_percentage || '', 
          hour: item.allocated_hours || '',
        };
      });
      setProjectAllocations(prevState => {
        return {
          ...prevState,
          ...initialAllocations,
        };
      });
    }
  }, [props.monthDetails]);
 
  const allowOnlyNumbers = (e) => {
    const value = e.target.value;
    const re = /^[0-9]*(\.[0-9]{0,2})?$/;
    
    if (value === "") {
      return "";
    }
  
    const formattedInput = value.startsWith('.') ? `0${value}` : value;
    
    if (re.test(formattedInput)) {
      return formattedInput;
    }
   
    e.preventDefault();
    return false;
  };
  
  const handlePercentageChange = (projectId: number, e) => {
    const val = allowOnlyNumbers(e);
    if (val !== false) {
      const percentageNumber = parseFloat(val);
      if (!isNaN(percentageNumber)) {
        const totalHours = convertPerToNumber(val, props.TotalHour);
        const updatedAllocations = {
          ...projectAllocations,
          [projectId]: { ...projectAllocations[projectId], percentage: val, hour: totalHours?.toString() },
        };
        setProjectAllocations(updatedAllocations);
      } else {
        setProjectAllocations({ ...projectAllocations, [projectId]: { percentage: '', hour: '' } });
      }
    } else {
      setProjectAllocations({ ...projectAllocations, [projectId]: { percentage: '', hour: '' } });
    }
  };

  const handleHourChange = (projectId: number, e) => {
    const val = allowOnlyNumbers(e); 
    if (val !== false) {
     
      const hourNumber = parseFloat(val);
  
      if (!isNaN(hourNumber)) {
        const percentage = convertNumberToPer(val, props.TotalHour);
        const updatedAllocations = {
          ...projectAllocations,
          [projectId]: { ...projectAllocations[projectId], percentage: percentage?.toString(), hour: val },
        };
        setProjectAllocations(updatedAllocations);
      } else {
       
        setProjectAllocations({ ...projectAllocations, [projectId]: { percentage: '', hour: '' } });
      }
    } else {
      setProjectAllocations({ ...projectAllocations, [projectId]: { percentage: '', hour: '' } });
    }
  };
  const isUpdating = Tid ? true : false; 

  const addAllocattionToProject = async () => {
    const total = Object.values(projectAllocations).reduce((acc, curr) => acc + Number(curr.percentage), 0);
  
    if (total > 100) {
      notify(); 
      return;
    }
  
   
    const indexMap = new Map();
    props.monthDetails.forEach((detail, index) => {
      indexMap.set(detail.project_id, index);
    });
  
    
    const sortedProjectAllocations = Object.entries(projectAllocations)
      .sort((a, b) => indexMap.get(parseInt(a[0])) - indexMap.get(parseInt(b[0])));

  
    const allocationData = sortedProjectAllocations
      .map(([projectId, allocation]) => {
        const matchingTallocation = Tid.find(item => item.project_id === parseInt(projectId) && item.month_id === props.monthId);
        const payload = {
          month_id: props.monthId,
          project_id: projectId,
          emp_id: props.empId,
          year: new Date().getFullYear(),
          isUpdating: isUpdating, 
        };
  
        if (Tid) {
          return {
            ...payload,
            updatedAllocated_percentage: allocation.percentage.toString() || "0",
            updatedAllocated_hours: allocation.hour.toString() || "0",
            id: matchingTallocation ? matchingTallocation.id : null,
          };
        } else {
         
          if (allocation.percentage.toString().trim() === "" && allocation.hour.toString().trim() === "") {
            return null; 
          }
          return {
            ...payload,
            allocated_percentage: allocation.percentage.toString(),
            allocated_hours: allocation.hour.toString(),
          };
        }
      })
      .filter(data => 
        data !== null && data.project_id && !(
          data['id'] === null && 
          data['updatedAllocated_percentage'] === "0" && 
          data['updatedAllocated_hours'] === "0"
        )
      )
      
   
     
    
    if (allocationData.length === 0) {
      openErrNotification();
      return;
    }
  
    try {
      await AxiosConfig.post(`allocations/update/allocation`, allocationData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      props.updateIdsAfterSave();
      openNotification();
    } catch (error: any) {
      props.updateIdsAfterSave();
      console.log("Error:", error);
      const backendMessage = error.response && error.response.data && error.response.data.status ? error.response.data.status.message : "An unexpected error occurred.";
      openErrNotificationWithMessage(backendMessage);
    }
  };
  const columnsMonth = [
    {
      title: "Project Name",
      dataIndex: ["project_name"],
      key: "project_name",
      width: "8%",
    },

    {
      title: "Percentage",
      dataIndex: "project_id",
      key: "allocated_percentage",
      width: "8%",
      render: (projectId: number) => (
        <InputField
          disabled={props.Hour ? true : false}
          placeholder="Enter allocated percentage"
          name={`allocated_percentage_${projectId}`}
          onChange={(e) => handlePercentageChange(projectId, e)}
          value={projectAllocations[projectId]?.percentage || ''}
          className="input--field"
        />
      ),
    },
    {
      title: "Hours",
      dataIndex: "project_id",
      key: "allocated_hours",
      width: "8%",
      render: (projectId: number) => (
        <InputField
          disabled={props.Hour ? false : true}
          placeholder="Enter allocated Hour"
          name={`allocated_Hours_${projectId}`}
          onChange={(e) => handleHourChange(projectId, e)}
          value={projectAllocations[projectId]?.hour || ''}
          className="input--field"
        />
      ),
    },

  ];

  return (
    <div>
      <br />
      <h1
        style={{
          fontSize: "18px",
          color: "#4952B9",
          textTransform: "uppercase",
        }}
      >
        {props.monthName}
      </h1>
      <div style={{ marginTop: -35, textAlign: "center" }}>
      </div>
      <div style={{ marginTop: -20, textAlign: "right" }}>
      <Tooltip title={<pre>{tooltipTitle}</pre>}>
        <InfoCircleOutlined style={{ marginRight: 5 }} />
      </Tooltip>
        {(props.totalpercentage).toFixed(2)}%/100%
      </div>
      <table>
        <Table
          columns={columnsMonth}
          dataSource={props.monthDetails}
          bordered
          className="ThisMonthTable"
          scroll={{ x: 500 }}
          pagination={false}
        />{" "}
      </table>
      <br />
      <ButtonReuse
        type="primary"
        className="primary-btn"
        htmlType="submit"
        value="Update"
        onClick={addAllocattionToProject}
      ></ButtonReuse>
    </div>
  );
};

export default TableInfo;
