import { GET_CUSTOMERS, GET_CUSTOMERS_ERROR } from "../../constants";
import { Axios } from "../../utils/axios";

export const getAllCustomers = () => {
  return async (dispatch) => {
    try {
      const response = await Axios.get("/customers");

      if (response.status == 200) {
        dispatch({
          type: GET_CUSTOMERS,
          payload: response?.data?.data?.customers,
        });
      } else {
        dispatch({
          type: GET_CUSTOMERS_ERROR,
          payload: "Error: Data not found.",
        });
      }
    } catch (error: any) {
      dispatch({
        type: GET_CUSTOMERS_ERROR,
        payload: error,
      });
    }
  };
};
