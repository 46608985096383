import { GET_SECONDARY_SKILLS, GET_SECONDARY_SKILLS_ERROR } from "../../constants";
import { Axios } from "../../utils/axios";

export const getSecondarySkills = () => {
    return async (dispatch) => {
        try {
            const response = await Axios.get(`skills/secondarySkills`);

            if (response.status == 200) {
                dispatch({
                    type: GET_SECONDARY_SKILLS,
                    payload: response.data.data.secondarySkills,
                });
            } else {
                dispatch({
                    type: GET_SECONDARY_SKILLS_ERROR,
                    payload: "Error: Data not found.",
                });
            }
        } catch (error: any) {
            dispatch({
                type: GET_SECONDARY_SKILLS_ERROR,
                payload: error,
            });
        }
    };
};
