import { deleteModal } from "../../utils/enums";
import "./DeleteConfirmation.scss";

const DeleteConfirmation = (props: {  onClose: () => void; onConfirm: any, customDeleteConfirmationMsg?:string }) => {

  const { onClose, onConfirm, customDeleteConfirmationMsg } = props;

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="modal-body">
          <p className="modal-text">{customDeleteConfirmationMsg || deleteModal.warningMsgForDelete}</p>
          <div className="button-div">
            <button className="modal-body__button" onClick={onConfirm}>
              {deleteModal.buttonForDelete}
            </button>
            <button
              className="modal-body__button cancel--button"
              onClick={onClose}
            >
              {deleteModal.buttonForCancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmation;
