import { useRef } from "react";
import "antd/dist/antd.css";
import {
  Drawer,
  DatePicker,
  Row,
  Col,
  Typography,
  Input,
  Select as AntSelect,
  Progress,
} from "antd";
import Select from "react-select";
import moment from "moment";
import { Formik } from "formik";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { AxiosConfig } from "../../../ApiConfig";
import { InputField } from "../../../components";
import ButtonReuse from "../../../components/Buttons/Button";
import { useState, useEffect } from "react";
import { getToken } from "../../../auth";
import { notification } from "antd";
import { Upload, Modal } from "antd";
import { PlusOutlined, DeleteTwoTone } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { preventMinus } from "../../../utils/util";
import { Axios } from "../../../utils/axios";
import { addPurchaseOrder } from "../../../utils/enums";
require("./AddPurchaseOrder.scss");

var FormData = require("form-data");

var data = new FormData();

interface SalesAllocation {
  talent_id: number;
  sales_percentage: number;
}

const AddOverlay = (props: {
  visible?: boolean;
  onClose: () => void;
  onCancelButton: () => void;
  currencyRate?: any;
  mastercurrencies?: any;
  newitems?: any;
  ProjectManagers?: any;
  associatedBuId?: number;
  ProjectName?: string;
  ProjectOwnerId?: any;
}) => {
  const { TextArea } = Input;
  const { Text } = Typography;
  const { Option } = AntSelect;
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [selectedCurrencyRate, setSelectedCurrencyRate] = useState(0);
  const [isDisable, setDisable] = useState(true);
  const [currencyList, setCurrencyList] = useState();
  const [salesPersons, setSalesPersons] = useState([]);

  const ValueAmt = useRef(0);
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  const validationSchema = yup.object({
    po_number: yup.string().trim().required("Please enter the PO number"),
    po_name: yup.string().trim().required("Please enter the PO name"),
    po_status: yup.string().required("Please select the PO status"),
    desc: yup.string().trim().required("Please enter the PO description"),
    value: yup
      .number()
      .required("Please select currency & enter the Value sold"),
    stus_sold: yup.number().required("Please enter the Stu's sold"),
    po_type_id: yup.number().required("Please select the PO Type"),
    sales_allocation: yup
      .array()
      .of(
        yup.object({
          talent_id: yup
            .object({
              value: yup.string().required("value is required"),
              label: yup.string().required(),
            })
            .required("Please enter the Talent ID"),
          sales_percentage: yup
            .number()
            .required("Please enter the Sales Percentage"),
        })
      )
      .required("Please provide the Sales Allocation"),
  });

  const poStatusList = useRef([
    { value: "Verbal commit", label: "Verbal commit" },
    { value: "Email commit", label: "Email commit" },
    { value: "Letter of Intent", label: "Letter of Intent" },
    { value: "PO received", label: "PO received" },
  ]);

  const onCancelHandler = () => {
    props.onCancelButton();
  };
  var token = getToken();

  const [poType, setPoType] = useState([]);

  useEffect(() => {
    getPoType();
    getCurrencyDropdown();
  }, []);

  useEffect(() => {
    // Fetch the options from the API
    const fetchSalesPersons = async () => {
      try {
        const response = await Axios.get("/salesAllocation/salesPerson");

        const data = response.data;

        const options =
          data?.data?.salesPersons?.map((person: any) => ({
            value: person.id,
            label: `${person.firstName} ${person.lastName}`,
          })) ?? [];

        options.push({
          value: 0,
          label: "Not assigned",
        });

        setSalesPersons(options);
      } catch (error) {
        console.error("Error fetching sales persons:", error);
      }
    };

    fetchSalesPersons();
  }, []);

  const getPoType = async () => {
    await AxiosConfig.get("potypes", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setPoType(res.data.data.poTypes);
      console.warn(res);
    });
  };
  const getCurrencyDropdown = async () => {
    const tempList: any = (
      <AntSelect
        className="select-before"
        onChange={(value: any) => {
          setDisable(false);
          setSelectedCurrency(value);
          setSelectedCurrencyRate(props.currencyRate[value]);
        }}
      >
        {props.mastercurrencies.map((currency: any, index: any) => (
          <Option key={index} value={currency?.currencyrates?.currency_id}>
            {currency?.currency_code}
          </Option>
        ))}
      </AntSelect>
    );
    setCurrencyList(tempList);
  };

  const poTypeOptions = poType.map(function (poType: {
    type: any;
    label: any;
    id: any;
  }) {
    return {
      value: poType.id,
      label: poType.type,
    };
  });

  const openNotification = () => {
    notification.open({
      message: "Purchase Order Added Successfully",
    });
  };

  const dateFormat = "YYYY/MM/DD";

  let { id }: any = useParams();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const [previewTitle, setPreviewTitle] = useState();
  const [files, setFiles] = useState<any>([]);

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const handlePreview = async (file: any) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleAttachmentChange = ({ fileList }: any) => {
    setFiles(fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> {addPurchaseOrder?.buttonForUpload}</div>
    </div>
  );

  return (
    <>
      <Drawer
        title="Add Purchase Order"
        width={700}
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="add_project"
      >
        <Formik
          initialValues={{
            po_number: "",
            po_name: "",
            po_status: "",
            desc: "",
            value: "",
            value_in_base: "",
            stus_sold: "",
            sale_date: new Date(),
            project_id: id,
            po_type_id: "",
            currency_id: "",
            currency_rate: "",
            projected_expenses: "",
            sales_allocation: [
              {
                talent_id: { value: 0, label: "Not assigned" },
                sales_percentage: 0,
              },
            ],
            files: [],
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const sales_allocation: SalesAllocation[] = [];

            for (let i = 0; i < files.length; i++) {
              data.append("files", files[i].originFileObj);
            }

            data.append("po_number", values.po_number);
            data.append("po_name", values.po_name);
            data.append("po_status", values.po_status);
            data.append("desc", values.desc);
            data.append("value", values.value);
            data.append(
              "value_in_base",
              Number(values.value) * selectedCurrencyRate
            );
            data.append("stus_sold", values.stus_sold);
            data.append("sale_date", values.sale_date);
            data.append("po_type_id", values.po_type_id);
            data.append("project_id", values.project_id);
            data.append("projected_expenses", values.projected_expenses);
            data.append("currency_id", selectedCurrency);
            data.append("currency_rate", selectedCurrencyRate);

            values.sales_allocation.forEach((allocation) => {
              if (allocation.talent_id.value) {
                sales_allocation.push({
                  talent_id: allocation.talent_id.value,
                  sales_percentage: allocation.sales_percentage,
                });
              }
            });
            data.append("sales_allocation", JSON.stringify(sales_allocation));

            try {
              const response = await AxiosConfig.post("purchaseorders", data, {
                headers: { Authorization: `Bearer ${token}` },
              });
            
              if (response.status === 200) {
                openNotification();
                props.onClose();
            
                for (const key in values) {
                  data.delete(key);
                }
            
                window.location.reload();
              }
            } catch (error) {
              console.log(error);
            
              for (const key in values) {
                console.log("KEYS ", key);
                data.delete(key);
              }
            
              const notify = () => {
                notification.open({
                  message: (error as { response?: { data?: { status?: { message?: string } } } })?.response?.data?.status?.message || "Unknown error occurred",
                });
              };
            
              notify();
            }            
          }}
        >
          {({
            handleSubmit,
            handleChange,
            errors,
            values,
            setFieldValue,
            resetForm,
          }) => {
            const calculateTotalPercentage = () => {
              let total = 0;
              values.sales_allocation.forEach((allocation) => {
                total += Number(allocation.sales_percentage) || 0;
              });
              return total;
            };

            const updateTotalPercentage = () => {
              const totalPercentage = calculateTotalPercentage();
              setFieldValue("totalPercentage", totalPercentage);
            };
            return (
              <form onSubmit={handleSubmit} className="add_po_form">
                <Row>
                  <Col span={12}>
                    <label>{addPurchaseOrder?.labelForPoNumber} </label>
                    <InputField
                      placeholder="Enter the PO Number"
                      name="po_number"
                      onChange={handleChange}
                      value={values.po_number}
                      className="input--field"
                    ></InputField>
                    <p className="display_error">{errors.po_number}</p>
                  </Col>
                  <Col span={12}>
                    <label>{addPurchaseOrder?.labelForPoStatus}</label>
                    <Select
                      placeholder="Select PO Status"
                      options={poStatusList.current}
                      onChange={(value: { value: any }) =>
                        setFieldValue("po_status", value.value)
                      }
                      name="po_status"
                      className="select--field"
                    />
                    <p className="display_error">{errors.po_status}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <label>{addPurchaseOrder?.labelForPoName} </label>
                    <InputField
                      placeholder="Enter the PO Name"
                      name="po_name"
                      onChange={handleChange}
                      value={values.po_name}
                      className="input--field"
                    ></InputField>
                    <p className="display_error">{errors.po_name}</p>
                  </Col>
                  <Col span={12}>
                    <label>{addPurchaseOrder?.labelForSaleDate} </label>
                    <DatePicker
                      defaultValue={moment()}
                      format={dateFormat}
                      placeholder="Enter the Sales Date"
                      name="sale_date"
                      onChange={(val) => {
                        setFieldValue("sale_date", val);
                      }}
                      className="input--field"
                    />
                    <p className="display_error">{errors.sale_date}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <label>{addPurchaseOrder?.labelForPoType} </label>
                    <Select
                      placeholder="Select Project Type"
                      options={poTypeOptions}
                      onChange={(value: { value: any }) =>
                        setFieldValue("po_type_id", value.value)
                      }
                      name="po_type_id"
                      className="select--field"
                    />
                    <p className="display_error">{errors.po_type_id}</p>
                  </Col>
                  <Col span={12}>
                    <label> {addPurchaseOrder?.labelForStuSold}</label>
                    <InputField
                      placeholder="Enter the Stu's Sold"
                      name="stus_sold"
                      onChange={handleChange}
                      value={values.stus_sold}
                      className="input--field"
                    ></InputField>
                    <p className="display_error">{errors.stus_sold}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <label>{addPurchaseOrder?.labelForValueSold} </label>
                    <Input
                      placeholder="Enter the value sold"
                      name="value"
                      onChange={(event: any) => {
                        ValueAmt.current = event.target.value;
                        return handleChange(event);
                      }}
                      disabled={isDisable}
                      value={values.value}
                      addonBefore={currencyList}
                      className="mb--10 input--field"
                    ></Input>
                    <Text type="secondary">
                      {addPurchaseOrder?.labelForConversionRate}{" "}
                      {currencySymb?.currency_symbol}
                      {selectedCurrencyRate} | {currencySymb?.currency_code}:
                      {currencySymb?.currency_symbol}
                      {(selectedCurrencyRate * ValueAmt.current).toFixed(2)}
                    </Text>
                    <p className="display_error">{errors.value}</p>
                  </Col>
                  <Col span={12}>
                    <label>{addPurchaseOrder?.labelForPoDesc} </label>
                    <TextArea
                      className="textarea--field"
                      rows={3}
                      placeholder="Enter the PO Description"
                      name="desc"
                      onChange={handleChange}
                      value={values.desc}
                    />
                    <p className="display_error">{errors.desc}</p>
                  </Col>
                  <Col span={12}>
                    <label>{"Projected Expense"}</label>
                    <Input
                      placeholder="Enter the projected Expense"
                      name="projected_expenses"
                      onChange={handleChange}
                      value={values.projected_expenses}
                      addonBefore={"₹"}
                      className="mb--10 input--field"
                    ></Input>
                  </Col>
                </Row>

                <Upload
                  className="PO-upload"
                  listType="picture-card"
                  fileList={files}
                  onPreview={handlePreview}
                  onChange={handleAttachmentChange}
                  beforeUpload={() => false}
                >
                  {files.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal
                  visible={previewVisible}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>

                <h2 className="sales_title">
                  {addPurchaseOrder?.textForSalesAttribution}
                </h2>
                <Row>
                  <Col md={4} sm={6} xs={12}>
                    <label className="total_sales">
                      {addPurchaseOrder?.headingForTotalPer}
                    </label>
                  </Col>
                  <Col md={4} sm={6} xs={12} style={{ textAlign: "right" }}>
                    <label>
                      <span style={{ color: "#5C67E5", fontWeight: "bold" }}>
                        {calculateTotalPercentage()}%
                      </span>
                      /100%{" "}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md={8} sm={12} xs={24}>
                    <Progress
                      percent={calculateTotalPercentage()}
                      strokeWidth={8}
                      showInfo={false}
                    />
                  </Col>
                </Row>
                <br />
                <br />
                {values.sales_allocation.map((allocation, index) => (
                  <Row key={index}>
                    <Col span={12}>
                      <label>{addPurchaseOrder?.labelForSalesPerson}</label>
                      <Select
                        placeholder="Select Sales Person"
                        value={allocation.talent_id}
                        onChange={(value) =>
                          setFieldValue(
                            `sales_allocation[${index}].talent_id`,
                            value
                          )
                        }
                        className="input--field"
                        options={salesPersons}
                      />
                    </Col>
                    <Col span={9}>
                      <label>{addPurchaseOrder?.labelForPercentage} </label>
                      <Input
                        type="number"
                        placeholder="Enter Percentage"
                        name={`sales_allocation[${index}].sales_percentage`}
                        value={allocation.sales_percentage}
                        onChange={(event) => {
                          handleChange(event);
                          updateTotalPercentage(); // Update total percentage on input change
                        }}
                        className="input--field"
                        min={0}
                        onKeyPress={preventMinus}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </Col>
                    {Boolean(
                      values.sales_allocation.length > 1 ||
                        values.sales_allocation[index].talent_id.value
                    ) && (
                      <Col span={3} className="display-vertical-center">
                        <DeleteTwoTone
                          onClick={() => {
                            if (values.sales_allocation.length === 1) {
                              setFieldValue("sales_allocation", [
                                {
                                  talent_id: {
                                    value: 0,
                                    label: "Not assigned",
                                  },
                                  sales_percentage: 0,
                                },
                              ]);
                            } else {
                              setFieldValue("sales_allocation", [
                                ...values.sales_allocation.slice(0, index),
                                ...values.sales_allocation.slice(index + 1),
                              ]);
                            }
                          }}
                          twoToneColor="#F95734"
                          className="icon-hover"
                        />
                      </Col>
                    )}
                  </Row>
                ))}
                <div>
                  <ButtonReuse
                    type="primary"
                    className="sales-primary-btn"
                    onClick={() =>
                      setFieldValue("sales_allocation", [
                        ...values.sales_allocation,
                        { talent_id: "", sales_percentage: "" },
                      ])
                    }
                    value="Add More"
                  ></ButtonReuse>
                  <div className="button-container">
                    <ButtonReuse
                      type="primary"
                      className="primary-btn"
                      htmlType="submit"
                      value="Add"
                    ></ButtonReuse>
                    <ButtonReuse
                      type="primary"
                      className="primary-btn cancel--btn"
                      value="Cancel"
                      onClick={() => {
                        onCancelHandler();
                        resetForm();
                      }}
                    ></ButtonReuse>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Drawer>
    </>
  );
};

export default AddOverlay;
