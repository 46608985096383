import { projectStuSummary } from "../../../../utils/enums";
import "./ProjectStuSummary.scss";
import { Row, Col } from "antd";

const ProjectStuSummary = (props: {
  parentStus?: any;
  parentSales?: any;
  exuctedSales?: any;
}) => {

  return (
    <div className="ProjectStuSummary-Info">
      <Row className="projectStuSummaryHeader">
        <Col span={24}>
          <h1>{projectStuSummary.headingForProjectStuSummary}</h1>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <p>{projectStuSummary.textForStusSold} </p>
        </Col>
        <Col span={8} className="text-end">
          <p>{props.parentStus}</p>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <p>{projectStuSummary.textForPlanned}</p>
        </Col>
        <Col span={8} className="text-end">
          <p>{props.parentSales}</p>
        </Col>
      </Row>
      <Row>
        <Col span={16}>
          <p>{projectStuSummary.textForEarned}</p>
        </Col>
        <Col span={8} className="text-end">
          <p>{props.exuctedSales}</p>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectStuSummary;
