//import Action Types
import { GET_DEPARTMENT, GET_DEPARTMENT_ERROR } from "../../constants";

//Write down the initial state of the reducer
interface DeparmentState {
  departments: Array<object>;
  error: string;
}

const INITIAL_STATE: DeparmentState = {
    departments: [],
    error: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DEPARTMENT:
      return { ...state, departments: action.payload };
    case GET_DEPARTMENT_ERROR:
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
