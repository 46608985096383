import React from "react";
import "antd/dist/antd.css";
import { Drawer, Tabs } from "antd";
import { useState, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { FormikProps } from "formik";
import { getToken } from "../../../auth";
import moment from "moment";
import EditExpensesSection from "./EditExpensesSection";
import EditExpenseDetailSection from "./EditExpenseDetailSection";
require("../Customer/AddCustomer.scss");
type ExpenseItem = {
  id: number;
  reference_no: string;
  vendor_name: string;
  amount: number;
  unaccounted_amount: number;
  notes: string;
  bill_date: string;
  pending_amount: number;
};


const EditOverlay = (props: {
  visible?: boolean;
  onClose: () => void;
  onCancelButton: () => void;
  expenseId: number;
  expensesDetail: ExpenseItem | undefined;
  allExpenseData:any
}) => {
  var token = getToken();
  const { TabPane } = Tabs;
  const [activeKey, setActiveKey] = useState<string>("1");
  function callback(key: any) {
    setActiveKey(key);
  }

  useEffect(() => {
    getExpenseDetailsApiCall();
  }, []);
  const initialFormState = {
    id: 0,
    vendor_name: "",
    amount: 0,
    notes: "",
    reference_no: "",
    bill_date: "",
  };

  type MyFormValues = {
    id: number;
    reference_no: string;
    vendor_name: string;
    amount: number;
    files: [];
    notes: string;
    bill_date: moment.Moment | null;
  };
  const { allExpenseData } = props;

  const [expenseData, setExpensesDetails] = useState(initialFormState);
  const formikRef = React.useRef<FormikProps<MyFormValues>>(null);
  const [expensePos, setExpensePos] = useState<any[]>([]);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [editable, setEditable] = useState<boolean[]>(
    Array(expensePos.length).fill(false)
  );
  const [expensesId, setExpensesId] = useState();
  const [expenseIdDetails,setExpenseIdDetails] = useState({});
  const [attachmentData,setAttachmentData] = useState({});
  const onCancelHandler = () => {
    props.onCancelButton();
  };

  const showEditModal = () => {
    setIsModalVisibleEdit(true);
  };

  const [editImage, setEditImage] = useState<any>([]);
  let image_arr: any = [];
  let edit_image_arr: any = [];
  const getAttachments = async (attachment_id: any) => {
    try {
      const res = await AxiosConfig.get(
        "creditnote/attachment/" + attachment_id,
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        }
      );
      setAttachmentData(res.data);
      let image_obj: any = {};
      const url = URL.createObjectURL(res.data);
      image_obj["id"] = attachment_id;
      image_obj["url"] = url;
      edit_image_arr.push(image_obj);
      image_arr.push(url);
      setEditImage(edit_image_arr);
    } catch (error) {
      console.error("Error fetching attachments:", error);
    }
  };

  const getExpenseDetailsApiCall = async () => {
    try {
      const res = await AxiosConfig.get(`expense/${props.expenseId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        const expensesData = res?.data?.data?.expense;
        setExpenseIdDetails(expensesData);
        if (expensesData) {
          const firstExpense = expensesData;
          const initialFormState = {
            id: firstExpense.id,
            vendor_name: firstExpense.vendor_name,
            notes: firstExpense.notes,
            expense_type: firstExpense.expense_type,
            amount: firstExpense.amount,
            reference_no: firstExpense.reference_no,
            bill_date: firstExpense.bill_date,
          };
          setExpensesId(
            res.data.data.expense.expense_pos.map((row: { id: any }) => ({
              id: row.id,
            }))
          );
          setExpensesDetails(initialFormState);
          setExpensePos(res?.data?.data?.expense?.expense_pos || []);
          if (res?.data?.data?.expense?.expense_attachments?.length) {
            res.data.data.expense.expense_attachments.forEach(function (
              row: any
            ) {
              const attachment_id = row.attachment_id;
              getAttachments(attachment_id);
            });
          }
        } else {
          console.error("No expenses found in the response.");
        }
      } else {
        console.error("Failed to fetch expenses. Status code: ", res.status);
      }
    } catch (error) {
      console.error("Error fetching expenses: ", error);
    }
  };
  const handleDrawerClose = () => {
    if (formikRef.current) {
      formikRef.current?.resetForm();
    }
    props.onClose();
  };

  return (
    <>
      <Drawer
        title="Expenses"
        width={1500}
        visible={props.visible}
        onClose={handleDrawerClose}
        bodyStyle={{ paddingBottom: 80 }}
        className="add_creditNote"
      >
        <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={callback}>
          <TabPane tab="Expense Details" key="1">
            <EditExpenseDetailSection
              onCancelHandler={onCancelHandler}
              expensePos={expensePos}
              setExpensePos={setExpensePos}
              editable={editable}
              setEditable={setEditable}
              expenseData={expenseData}
              expensesId={expensesId}
            />
          </TabPane>
          <TabPane tab="Edit Expenses" key="2">
            {allExpenseData && expenseIdDetails && 
              <EditExpensesSection
                showEditModal={showEditModal}
                isModalVisibleEdit={isModalVisibleEdit}
                setIsModalVisibleEdit={setIsModalVisibleEdit}
                expenseData={expenseData}
                onCancelHandler={onCancelHandler}
                editImage={editImage}
                onClose={props.onClose}
                expenseIds={props.expenseId}
                allExpenseData={allExpenseData}
                expenseIdDetails={expenseIdDetails}
                attachmentData={attachmentData}
              />
            }
          </TabPane>
        </Tabs>
      </Drawer>
    </>
  );
};

export default EditOverlay;
