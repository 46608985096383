import { Input, Row, Col, Card, Table, Typography, Select } from "antd";
import {
 
  convertCurrency,
  replaceString,
  convertNumber,
} from "../../../utils/util";
import { useHistory, useLocation } from "react-router-dom";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import "./snapshotDetails.scss";
import { MONTH_OPTIONS } from "./../../../constants/months_name";
import {  snapshotDetail } from "../../../utils/enums";

const SnapshotDetails = () => {
  const { Text } = Typography;
  const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);
  const snapData: any = useLocation();
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  const [reconizedData, getRecognisedData] = useState(Number);
  const [stusEarned, getStusEarned] = useState();
  const [gainedData, getGainedData] = useState(Number);
  const [droppedData, getDroppedData] = useState(Number);
  const [plannedData, getPlanneddData] = useState();
  const [utilizedData, getUtilizedData] = useState();
  const [actualEfficiency, getActualEfficiency] = useState();
  const [tableData, setTableData] = useState([]);
  const [table2, settable2] = useState<any[]>([]);
  const [nameSearch, setNameSearch] = useState("");
  const [filterTableData, setFilterTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [plannedEfficiency, getPlannedEfficiency] = useState();
  const [MonthlyData, getMonthlyData] = useState();
  const [yearlyData, getYearlyData] = useState<any>();
  const [selectedMonth, setSelectedMonth] = useState<string>("select a month");
  const [selectedYear, setSelectedYear] = useState("");
  const [earning_Stu, getEarning_Stu] = useState();

  const { Option } = Select;

  const token = getToken();
  let departmentId: any = [];

  let associatedBuList: any = localStorage.getItem("associatedBus");
  associatedBuList = JSON.parse(associatedBuList);
  if (associatedBuList) {
    for (const bu of associatedBuList) {
      departmentId.push(bu?.id);
    }
  }
  const getData = async (month: string, year: string) => {
    try {
     setIsLoading(true)
      const res = await AxiosConfig.post(
        "projectSnapshots/month",
        {
          month,
          year: year,
          departments_id_array: departmentId,
          manager_arr: snapData.state?.managers,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = res?.data?.data?.monthData[0]?.monthlyEarnings;
      const poData = res?.data?.data?.monthData[0]?.monthlyEarnings;
      settable2(poData);

      if (data && data.length >= 0) {
        setIsLoading(false);
      }

      let tempData: any = data.map((value: any, index: any) => {
        return {
          key: index,
          project_code: value?.project_code,
          project_name: value?.name,
          delete_action: value?.id,
          
          earned: convertCurrency(
            
            value?.earned,
            currencyRate
          ),
          stus_earned: convertNumber(value?.stus_earned, 2),
          stu_allocated: convertNumber(value?.stu_allocated, 2),
          stus_utilized: convertNumber(value?.stus_utilized, 2),
          purchaseOrders: { key: index, orderList: [] },
          stusExpended: convertNumber(value?.stus_expended, 2),
          month:
            value.month !== undefined && value.month !== null
              ? value.month
              : "N/A",
          year:
            value.year !== undefined && value.year !== null
              ? value.year
              : "N/A",
        };
      });

      setTableData(tempData);
      setFilterTableData(tempData);
      getRecognisedData(res?.data?.data?.monthData[0]?.earned);
      getGainedData(res?.data?.data?.monthData[0]?.gained);
      getDroppedData(res?.data?.data?.monthData[0]?.dropped);
      getPlanneddData(res?.data?.data?.monthData[0]?.Monthallocated_Stu);
      getStusEarned(res?.data?.data?.monthData[0]?.stus_earned);
      getMonthlyData(res?.data?.data?.monthData[0]?.month.substr(0, 3));
      if (!yearlyData) getYearlyData(res?.data?.data?.monthData[0]?.year);
      getUtilizedData(res?.data?.data?.monthData[0]?.utilized);
      getEarning_Stu(
        convertNumber(
          res?.data?.data?.monthData[0]?.earned /
            res?.data?.data?.monthData[0]?.stus_earned,
          2
        )
      );
      getActualEfficiency(
        convertNumber(
          (res?.data?.data?.monthData[0]?.stus_earned /
            res?.data?.data?.monthData[0]?.utilized) *
            100,
          2
        )
      );
      getPlannedEfficiency(
        convertNumber(
          (res?.data?.data?.monthData[0]?.stus_earned /
            res?.data?.data?.monthData[0]?.Monthallocated_Stu) *
            100,
          2
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currencySymb?.currency_code) {
      if (selectedMonth !== "select a month" && selectedYear) {
        getData(selectedMonth, selectedYear);
      } else {
        getData(snapData.state?.month, snapData.state?.year);
      }
    }
  }, [currencySymb?.currency_code, currencyRate]);
 
  useEffect(() => {
    getData(snapData.state?.month, snapData.state?.year);
  }, [currencySymb?.currency_code]);

  const handleOnChange = (event: any) => {
    setNameSearch(event.target.value);
  };

  useEffect(() => {
    if (tableData) {
      const results: any = tableData.filter(
        (item: any) =>
          item?.project_code.toLowerCase().includes(nameSearch.toLowerCase()) ||
          item?.project_name.toLowerCase().includes(nameSearch.toLowerCase())
      );
      setFilterTableData(results);
    }
  }, [nameSearch]);

  const expandedRow = (row: any) => {
    let dataSource = undefined;
    const purchaseOrders = table2[row.key]?.PurchaseOrders;
    dataSource = purchaseOrders
      ? purchaseOrders.map((obj) => {
          return {
            name: obj.name,
            potype: obj.potype.type,
            earned: convertCurrency(
              
              obj.earned,
              currencyRate
            ),
            stus_earned: convertNumber(obj.stus_earned, 2),
          };
        })
      : [];
    return (
      <Table
        columns={expandedColumns}
        dataSource={dataSource}
        pagination={false}
        bordered
      />
    );
  };

  const history = useHistory();

  const onClickRow = (record: any) => {
    history.push("/projects/" + record);
  };

  const columns: any = [
    {
      title: "PROJECTS",
      width: "24%",
      className: "projects_snapShot",
      dataIndex: ["project_code", "delete_action", "project_name"],
      key: "project_code",
      filters: "",
      onFilter: (value: any, record: any) =>
        record.department_name.includes(value),
      sorter: (a: any, b: any) => a.project_name.localeCompare(b.project_name),
      render: (text: any, record: any) => (
        <Row>
          <Col md={24} xs={24}>
            <a
              onClick={() => {
                onClickRow(record["delete_action"]);
              }}
            >
              {record["project_code"]}
            </a>
          </Col>
          <Col md={24} xs={24}>
            {record["project_name"]}
          </Col>
          <Col md={24} xs={24}>
            {record["department_name"]}
          </Col>
        </Row>
      ),
    },
    {
      title: "EARNED",
      className: "title_snapshot",
      key: "earned",
      dataIndex: "earned",
      sorter: (a: any, b: any) =>
        replaceString(a.earned) - replaceString(b.earned),
      align: "right",
    },
    {
      title: "EARNED STU's",
      className: "title_snapshot",
      key: "margin",
      dataIndex: "stus_earned",
      sorter: (a: any, b: any) =>
        replaceString(a.stus_earned) - replaceString(b.stus_earned),
      align: "right",
    },
    {
      title: "PLANNED STU's",
      className: "title_snapshot",
      key: "stu_allocated",
      dataIndex: "stu_allocated",
      sorter: (a: any, b: any) =>
        replaceString(a.stu_allocated) - replaceString(b.stu_allocated),
      align: "right",
    },
    {
      title: "UTILIZED STU's",
      className: "title_snapshot",
      key: "stus_utilized",
      dataIndex: "stus_utilized",
      sorter: (a: any, b: any) =>
        replaceString(a.stus_utilized) - replaceString(b.stus_utilized),
      align: "right",
    },
  ];

  const expandedColumns: any = [
    {
      title: "Project ORDER",
      dataIndex: "name",
    },
    {
      title: "PO TYPE",
      dataIndex: "potype",
    },
    {
      title: "EARNED VALUE",
      align: "right",
      dataIndex: "earned",
    },
    {
      title: "STU'S RECOGNIZED",
      dataIndex: "stus_earned",
      align: "right",
    },
  ];

  return (
    <div>
      <div className="table-card">
        <div className="mb20">
          <div className="tab-contents">
            <Row>
              <Col md={9} xs={18}>
                <div className="searchForm">
                  <form>
                    <Input.Search
                      allowClear
                      onChange={handleOnChange}
                      placeholder="Search by Project Code/Project Name"
                    />
                  </form>
                </div>
              </Col>
              <Col md={3} xs={6}>
                <label className="totalLabel">
                  Total #: {filterTableData.length}
                </label>
              </Col>
              <Col md={3} xs={6}>
                <Select
                  className="snapShot_dateDropdown"
                  value={`${selectedMonth} ${selectedYear}`}
                  onChange={(value) => {
                    const [month] = value.split(" ");
                    setSelectedMonth(month);
                    if (
                      month === "January" ||
                      month === "February" ||
                      month === "March"
                    ) {
                      setSelectedYear(`${parseInt(yearlyData) + 1}`);
                      getData(month, `${parseInt(yearlyData) + 1}`);
                    } else {
                      setSelectedYear(yearlyData);
                      getData(month, yearlyData);
                    }
                  }}
                >
                  {Object.values(MONTH_OPTIONS).map((option, index: any) =>
                    index <= 8 ? (
                      <Option className="snapShot_dateDropdown" key={option.value} value={option.value}>
                        {`${option.label}, ${yearlyData}`}
                      </Option>
                    ) : (
                      <Option className="snapShot_dateDropdown" key={option.value} value={option.value}>
                        {`${option.label}, ${parseInt(yearlyData) + 1}`}
                      </Option>
                    )
                  )}
                </Select>
              </Col>
              <Col
                className="displayDate"
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
              >
                {selectedYear ? (
                  <p>
                    {MonthlyData} {selectedYear}
                  </p>
                ) : (
                  <p>
                    {MonthlyData} {yearlyData}
                  </p>
                )}
              </Col>

              <Col className="textAlignRight" md={2} xs={6}></Col>
            </Row>
            <Row className="mb20" gutter={18}>
              <Col className="site-card-border-less-wrapper " md={8} xs={24}>
                <Card
                  className="cardHeight"
                  title={"Total Revenue"}
                  bordered={false}
                >
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col md={12} xs={12}>
                      <Text
                        title="Recognized"
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                      >
                        {snapshotDetail.textForRecognized}
                      </Text>
                      <InfoCircleTwoTone title="The revenue earned/executed during this period. Monthly revenue recognition is captured for each active purchase order." />
                    </Col>
                    <Col md={12} xs={12} className="textAlignRight">
                      <b
                        className="textValueRevenue overflowEllipsisValue"
                        style={{ textAlign: "right" }}
                        // title={reconizedData}
                      >
                        {convertCurrency(
                          
                          reconizedData,
                          currencyRate
                        )}
                      </b>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col md={12} xs={12}>
                      <Text
                        title="Gained"
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                      >
                        {snapshotDetail.textForGained}
                      </Text>
                    </Col>
                    <Col md={12} xs={12} className="textAlignRight">
                      <b
                        className="textValueRevenue overflowEllipsisValue"
                        style={{ textAlign: "right" }}
                        // title={gainedData}
                      >
                        {convertCurrency(
                         
                          gainedData,
                          currencyRate
                        )}
                      </b>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col md={12} xs={12}>
                      <Text
                        title="Dropped"
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                      >
                        {snapshotDetail.textForDropped}
                      </Text>
                    </Col>
                    <Col md={12} xs={12} className="textAlignRight">
                      <b
                        className="textValueRevenue overflowEllipsisValue"
                        style={{ textAlign: "right" }}
                        // title={droppedData}
                      >
                        {convertCurrency(
                          
                          droppedData,
                         currencyRate
                        )}
                      </b>
                    </Col>
                  </Row>
                  <Col md={12} xs={12} className="textAlignRight"></Col>
                  <Row>
                    <Col className="" md={13} xs={12}></Col>
                    <Col md={11} xs={12} className="textAlignRight"></Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12}></Col>
                    <Col md={12} xs={12} className="textAlignRight"></Col>
                  </Row>
                </Card>
              </Col>
              <Col className="site-card-border-less-wrapper " md={8} xs={24}>
                <Card
                  className="cardHeight"
                  title={"Total STU's"}
                  bordered={false}
                >
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col md={12} xs={12}>
                      <Text
                        title="Earned STU's"
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                      >
                        {snapshotDetail.textForEarnedStu}
                      </Text>
                    </Col>
                    <Col md={12} xs={12} className="textAlignRight">
                      <b
                        className="textValueRevenue overflowEllipsisValue"
                        style={{ textAlign: "right" }}
                        title={stusEarned}
                      >
                        {convertNumber(stusEarned, 2)}
                      </b>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col md={12} xs={12}>
                      <Text
                        title="Planned STU's"
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                      >
                        {snapshotDetail.textForPlannedStu}
                      </Text>
                    </Col>
                    <Col md={12} xs={12} className="textAlignRight">
                      <b
                        className="textValueRevenue overflowEllipsisValue"
                        style={{ textAlign: "right" }}
                        title={plannedData}
                      >
                        {convertNumber(plannedData, 2)}
                      </b>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col md={12} xs={12}>
                      <Text
                        title="Utilized STU's"
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                      >
                        {snapshotDetail.textForUtilizedStu}
                      </Text>
                    </Col>
                    <Col md={12} xs={12} className="textAlignRight">
                      <b
                        className="textValueRevenue overflowEllipsisValue"
                        style={{ textAlign: "right" }}
                        title={utilizedData}
                      >
                        {convertNumber(utilizedData, 2)}
                      </b>
                    </Col>
                  </Row>
                  <Col md={12} xs={12} className="textAlignRight"></Col>
                  <Row>
                    <Col className="" md={13} xs={12}></Col>
                    <Col md={11} xs={12} className="textAlignRight"></Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12}></Col>
                    <Col md={12} xs={12} className="textAlignRight"></Col>
                  </Row>
                </Card>
              </Col>
              <Col className="site-card-border-less-wrapper " md={8} xs={24}>
                <Card
                  className="cardHeight"
                  title={"Overall Efficiency"}
                  bordered={false}
                >
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col md={12} xs={12}>
                      <Text
                        title="Planned Efficiency"
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                      >
                        {snapshotDetail.textForPlannedEfficiency}
                      </Text>
                      <InfoCircleTwoTone title="Planned Efficiency % = Earned STU's / Allocated STUs " />
                    </Col>
                    <Col md={12} xs={12} className="textAlignRight">
                      <b
                        className="textValueRevenue overflowEllipsisValue"
                        style={{ textAlign: "right" }}
                        title={plannedEfficiency}
                      >
                        {(currencySymb?.currency_code, `${plannedEfficiency}%`)}
                      </b>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col md={12} xs={12}>
                      <Text
                        title="Actual Efficiency"
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                      >
                        {snapshotDetail.textForActualEfficiency}
                      </Text>
                      <InfoCircleTwoTone title="Actual Efficiency % = Earned STU's / Utilized STUs" />
                    </Col>
                    <Col md={12} xs={12} className="textAlignRight">
                      <b
                        className="textValueRevenue overflowEllipsisValue"
                        style={{ textAlign: "right" }}
                        title={actualEfficiency}
                      >
                        {(currencySymb?.currency_code, `${actualEfficiency}%`)}
                      </b>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", alignItems: "center" }}>
                    <Col md={12} xs={12}>
                      <Text
                        title="Earning / Stus"
                        type="secondary"
                        className="textHeadingRevenue pr4 overflowEllipsis"
                      >
                        {snapshotDetail.textForEarningbyStus}
                      </Text>
                    </Col>
                    <Col md={12} xs={12} className="textAlignRight">
                      <b
                        className="textValueRevenue overflowEllipsisValue"
                        style={{ textAlign: "right" }}
                        title={earning_Stu}
                      >
                        {(currencySymb?.currency_code, earning_Stu)}
                      </b>
                    </Col>
                  </Row>
                  <Col md={12} xs={12} className="textAlignRight"></Col>
                  <Row>
                    <Col className="" md={13} xs={12}></Col>
                    <Col md={11} xs={12} className="textAlignRight"></Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={12}></Col>
                    <Col md={12} xs={12} className="textAlignRight"></Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <div className="tableOut noWordBreak">
              {isLoading === true ? (
                <Loader className="" />
              ) : (
                <Table
                  columns={columns}
                  expandedRowRender={expandedRow}
                  dataSource={filterTableData}
                  bordered
                  className="snapshotTable"
                  pagination={{
                    showSizeChanger: true,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnapshotDetails;
