import { Table, Row, Col, Checkbox, Typography } from "antd";
import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { MyCurrencyLoaderContext } from "../../../App";
import { convertCurrency } from "../../../utils/util";
import { Axios } from "../../../utils/axios";
import Loader from "../../../components/Loader/Loader";
require("./buDashboardInvoice.scss");

const BuDashboardInvoiceDetailView = () => {
  let initialEarningsInfo = {
    month: "",
    fin_month_year: "",
    departments_id_array: [],
    po_type_id_array: [],
  };
  const { Text } = Typography;
  const { currencyLoader } = useContext(MyCurrencyLoaderContext);

  const [associatedBu, setAssociatedBu] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [getTotalCount, setTotalCount] = useState(0);
  const [getTotalSentCount, setTotalSentCount] = useState(0);
  const [getTotalPendingCount, setTotalPendingCount] = useState(0);
  const [getTotalVal, setTotalVal] = useState(0);
  const [getTotalPendingVal, setTotalPendingVal] = useState(0);
  const [getTotalSentVal, setToatlSentVal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState<any>({
    departments: [],
    fin_period: "",
  });
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);
  const history = useHistory();
  let selectedYear = localStorage.getItem("selectedFy");

  useEffect(() => {
    if (currencySymb?.currency_code) {
      getAssociatedBu();
    }
  }, [currencySymb?.currency_code,currencyRate]);

  useEffect(() => {
    if (currencySymb?.currency_code) {
      getInvoiceData();
    }
  }, [filter, selectedYear, currencySymb?.currency_code,currencyRate]);

  function onChange(e: any) {
    let associatedBuList: any = localStorage.getItem("associatedBus");
    associatedBuList = JSON.parse(associatedBuList);
    let value = e.target.value;

    if (e.target.checked == true) {
      const selectedBu = associatedBu.filter((bu: any) => bu.id === value);
      associatedBuList.push(...selectedBu);
      localStorage.setItem("associatedBus", JSON.stringify(associatedBuList));
    } else {
      associatedBuList = associatedBuList.filter((bu: any) => bu.id != value);
      localStorage.setItem("associatedBus", JSON.stringify(associatedBuList));
    }

    setFilter({ fin_period: "", departments: associatedBuList });
  }

  let TotalCount = 0;
  let TotalSent = 0;
  let TotalPending = 0;
  let TotalVal = 0;
  let TotalPendingVal = 0;
  let TotalSentVal = 0;

  const getInvoiceData = async () => {
    let deprt: any = [];
    let associatedBuList: any = localStorage.getItem("associatedBus");
    associatedBuList = JSON.parse(associatedBuList);
    if (associatedBuList) {
      for (const bu of associatedBuList) {
        deprt.push(bu.id);
      }
    }

    let selectedYear = localStorage.getItem("selectedFy");

    let url = `invoice/invoiceDashboardData`;
    const res = await Axios.get(url, {
      params: {
        departments: deprt,
        finPeriod: selectedYear,
      },
    });
    saveInvoiceData(res.data.data);
    if (res.data.data.length >= 0) {
      setIsLoading(false);
    }
  };

  const saveInvoiceData = (data) => {
    setIsLoading(true);
    let iData: any = [];
    data.map((row: any) => {
      iData.push({
        Month: row.month,
        Year: row.year,
        Month_Status: row.financialMonthStatus,
        Month_Year: row.month.substr(0, 3) + " " + row.year,
        Invoice_count: row.pendingCount,
        Sent_count: row.sentCount,
        Total_count: row.totalInvoiceCount,
        Pending_val: convertCurrency(
          row?.pendingAmount, currencyRate
        ),
        Sent_val: convertCurrency(
          row?.sentAmount, currencyRate
        ),
        Total_val: convertCurrency(
          row?.totalInvoiceAmount, currencyRate
        ),
      });

      TotalCount = TotalCount + row.totalInvoiceCount;
      TotalSent = TotalSent + row.sentCount;
      TotalPending = TotalPending + row.pendingCount;
      TotalVal = TotalVal + row.totalInvoiceAmount;
      TotalPendingVal = TotalPendingVal + row.pendingAmount;
      TotalSentVal = TotalSentVal + row.sentAmount;
    });

    setInvoiceData(iData);
    setTotalCount(TotalCount);
    setTotalSentCount(TotalSent);
    setTotalPendingCount(TotalPending);
    setTotalVal(TotalVal);
    setTotalPendingVal(TotalPendingVal);
    setToatlSentVal(TotalSentVal);
    setIsLoading(false);
  };

  const isChecked = (id: any) => {
    let associatedBuList: any = localStorage.getItem("associatedBus");
    associatedBuList = JSON.parse(associatedBuList);
    if (associatedBuList) {
      for (const bu of associatedBuList) {
        if (bu.id === id) return true;
      }
      return false;
    } else {
      return true;
    }
  };

  const getAssociatedBu = async () => {
    let associatedBuList: any = localStorage.getItem("fixedAssociatedBu");
    associatedBuList = JSON.parse(associatedBuList);
    setAssociatedBu(
      associatedBuList.filter(function (associatedBu: { flag: any }) {
        return associatedBu.flag === true;
      })
    );
  };

  const onClickRow = (month: any, year: any) => {
    initialEarningsInfo.fin_month_year = year;
    initialEarningsInfo.month = month;
    history.push({
      pathname: "/DashboardInvoice",
      search: `?month=${month}&year=${year}`,
    });
  };

  const columns = [
    {
      title: "MONTH",
      dataIndex: ["Month_Year"],
      key: "Month",
      render: (text: any, record: any) => (
        <a
          className="onClick"
          onClick={() => {
            onClickRow(record["Month"], record["Year"]);
          }}
        >
          {record["Month_Year"]}
        </a>
      ),
    },
    {
      title: "Invoice Count",
      dataIndex: "Total_count",
      key: "Total_count",
      className: "number",
    },
    {
      title: "Sent Count",
      dataIndex: "Sent_count",
      key: "Sent_count",
      className: "number",
    },
    {
      title: "Pending Count",
      dataIndex: "Invoice_count",
      key: "Invoice_count",
      className: "number",
    },
    {
      title: "Invoice Amount",
      dataIndex: "Total_val",
      key: "Total_val",
      className: "number",
    },
    {
      title: "Total Pending Amount",
      dataIndex: "Pending_val",
      key: "Pending_val",
      className: "number",
    },
    {
      title: "Total Sent Amount",
      dataIndex: "Sent_val",
      key: "Sent_val",
      className: "number",
    },
  ];

  return (
    <>
      <div className="dasboardTabsOut">
      {(!isLoading && !currencyLoader) &&
        <div className="tabs-content tab-contenets-top">
          <Row style={{ paddingBlock: "0" }}>
            <Col md={24} xs={24}>
              <ul className="buList">
                {associatedBu.map((row: { name: any; id: any }) => {
                  return (
                    <li key={row.id}>
                      <Checkbox
                        onChange={onChange}
                        name={row.id}
                        value={row.id}
                        defaultChecked={isChecked(row.id)}
                      >
                        {row.name}
                      </Checkbox>
                    </li>
                  );
                })}
              </ul>
            </Col>
          </Row>
          {/* Loader Implementation */}
          <Row>
              <div className="tableOut noWordBreak" style={{ width: "100%" }}>
                <Table
                  rowClassName={(record: any) =>
                    record.Month_Status === "0"
                      ? "PresentStatus"
                      : record.Month_Status === "1"
                      ? "FutureStatus"
                      : ""
                  }
                  columns={columns}
                  dataSource={invoiceData}
                  bordered
                  className="talentTable"
                  pagination={false}
                  summary={() => (
                    <Table.Summary fixed>
                      <Table.Summary.Row className="table-bold-rows">
                        <Table.Summary.Cell index={0} className="title">
                          Total
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} className="total">
                          {" "}
                          <Text>{getTotalCount}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} className="total">
                          <Text>{getTotalSentCount}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={3} className="total">
                          <Text>{getTotalPendingCount}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={4} className="total">
                          <Text>
                            {convertCurrency(getTotalVal,currencyRate)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={5} className="total">
                          <Text>
                            {convertCurrency(getTotalPendingVal,currencyRate)}
                          </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={6} className="total">
                          <Text>
                            {convertCurrency(getTotalSentVal,currencyRate)}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                />
              </div>
          </Row>
        </div>
      }
      {(isLoading || currencyLoader) && <Loader className="edit_Loader_revenue"/>}
      </div>
    </>
  );
};

export default BuDashboardInvoiceDetailView;
