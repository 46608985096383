//import Action Types
import {
    GET_EDIT_CUSTOMER_CLASSIFICATION,
    GET_EDIT_CUSTOMER_CLASSIFICATION_ERROR,
  } from "../../constants";
  
  //Write down the initial state of the reducer
  interface CurrencyState {
    editCustomerClassification: Array<object>;
    error: string;
  }
  
  const INITIAL_STATE: CurrencyState = {
    editCustomerClassification: [],
    error: "",
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_EDIT_CUSTOMER_CLASSIFICATION:
        return { ...state, editCustomerClassification: action.payload };
      case GET_EDIT_CUSTOMER_CLASSIFICATION_ERROR:
        return { ...state, error: action.payload };
  
      default:
        return state;
    }
  };
  