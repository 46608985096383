import { useEffect, useState } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import moment from "moment";
import { Doughnut } from "react-chartjs-2";
import { getRandomColorForTeamSkill } from "../../../utils/util";

const TeamSizeBySkill = (props: {
  selectedDate?: any;
  selectedSkills?: any;
  selectedBu?: any;
}) => {
  const token = getToken();
  const dateFormat = "YYYY-MM-DD";
  const selected_date = moment(props.selectedDate).format(dateFormat);
  const [labels, setLabels] = useState([]);
  const [dataArr, setDataArr] = useState([]);
  const [colors, setColors] = useState([]);



  const getTeamSizeForSkill = () => {
    const data = {
      selected_date: selected_date,
      department_id: props.selectedBu.map((bu: any) => bu.value),
      skill_id: props.selectedSkills.map((skill: any) => skill.value),
    };
    AxiosConfig.post("talents/talentsizebyskill", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempLabels: any = [];
        const tempDataArr: any = [];
        const tempColors: any = [];
        res.data.data.talentSize.forEach((skill: any) => {
          tempLabels.push(skill.name);
          tempDataArr.push(skill.talent);
          tempColors.push(getRandomColorForTeamSkill());
        });
        setLabels(tempLabels);
        setDataArr(tempDataArr);
        setColors(tempColors);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getTeamSizeForSkill();
  }, [props.selectedSkills, props.selectedBu, props.selectedDate]);

  const skillDatasets: any = {
    labels: labels,
    datasets: [
      {
        label: "Skill Size",
        data: dataArr,
        backgroundColor: colors,
      },
    ],
  };

  const config = {
    type: "doughnut",
    data: skillDatasets,
    options: {
      radius: "80%",
      cutout: "60%",
      plugins: {
        legend: {
          position: "right",
          labels: {
            boxHeight: 15,
            boxWidth: 15,
            generateLabels: (chart: any) => {
              const datasets = chart.data.datasets;
              return datasets[0].data.map((data: any, i: any) => ({
                text: `${chart.data.labels[i]}- ${data}`,
                fillStyle: datasets[0].backgroundColor[i],
              }));
            },
          },
        },
        title: {
          display: true,
          align: "start",
          padding: 10,
          font: { weight: "normal", size: "22px" },
          color: "#1E2225",
          text: "Skill Based Talent",
        },
      },
    },
  };

  return (
    <>
      <Doughnut data={skillDatasets} options={config.options} />
    </>
  );
};

export default TeamSizeBySkill;
