import { GET_FIN_YEAR, GET_FIN_YEAR_ERROR } from "../../constants";
import { Axios } from "../../utils/axios";

export const getFinYear = () => {
  return async (dispatch) => {
    try {
      const response = await Axios.get("/financialYears");

      if (response.status == 200) {
        dispatch({
          type: GET_FIN_YEAR,
          payload: response?.data?.data?.years,
        });
      } else {
        dispatch({
          type: GET_FIN_YEAR_ERROR,
          payload: "Error: Data not found.",
        });
      }
    } catch (error: any) {
      dispatch({
        type: GET_FIN_YEAR_ERROR,
        payload: error,
      });
    }
  };
};
