import React from "react";
import { Table, Row, Col, Input, Typography } from "antd";
import { useState, useEffect, useRef } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import Loader from "../../../components/Loader/Loader";
import type { ColumnsType } from "antd/es/table";
import {
  convertNumber,
  convertAmountToNumber,
  checkVarience,
  convertAmountToNumberForVariance,
  convertCurrency,
  replaceCurrencyString
} from "../../../utils/util";
import { useSelector } from "react-redux";
import { allocations } from "../../../utils/enums";

require("./allocation.scss");

const Allocation = () => {
  const [projectMonth, setProjectMonth] = useState({
    ThisMonth: String,
    NextMonth: String,
    FutureMonth: String,
    ThisMonthYear: String,
    NextMonthYear: String,
    FutureMonthYear: String,
  });
  const [filterData, setFilterData] = useState<any>([]);
  const [isDepartment, setIsDepartment] = useState(false);
  const [getdata, setdata] = useState([]);
  const { Text } = Typography;
  const [getTotalThisRev, setTotalThisRev] = useState(0);
  const [getTotalNextRev, setTotalNextRev] = useState(0);
  const [getTotalFutuRev, setTotalFutuRev] = useState(0);
  const [getTotalThisAlloc, setTotalThisAlloc] = useState(0);
  const [getTotalNextAlloc, setTotalNextAlloc] = useState(0);
  const [getTotalFutuAlloc, setTotalFutuAlloc] = useState(0);

  const [getTotalThisExe, setTotalThisExe] = useState(0);
  const [getTotalNextExe, setTotalNextExe] = useState(0);
  const [getTotalFutuExe, setTotalFutuExe] = useState(0);
  const [managerOptions, setManagerOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const selectedManager = useRef([]);
  const [selectedDepartment, setDepartmentFilter] = useState([]);
  const [selectedManagers, setManagersFilter] = useState([]);
  const [isSetAllocationData, setAllocationData] = useState(false);
  const user: any = localStorage.getItem("user");
  const user_id: any = localStorage.getItem("userId");
  const users = JSON.parse(user);
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);
  const [totalExpense, setTotalExpense] = useState({
    thisMonth: 0,
    nextMonth: 0,
    futureMonth: 0,
  });
  const [allocationData, setAllocation]:any = useState([]);

  let history = useHistory();
  useEffect(() => {
    if (currencySymb?.currency_symbol) {
      getMonthsId();
      getManagers();
    }
  }, [currencySymb?.currency_symbol]);
  useEffect(() => {
  const filteredDepartmentString = localStorage.getItem("filtered_department");
  const filteredDepartment = filteredDepartmentString ? JSON.parse(filteredDepartmentString) : [];
  const filteredManagerString = localStorage.getItem("filtered_managers");
  const filteredManagers = filteredManagerString ? JSON.parse(filteredManagerString) : [];
  if(!isLoading &&  filteredDepartment.length){
    setDepartmentFilter(filteredDepartment)
    setFilterArgument((filterArgument: any) => ({
      ...filterArgument,
      departments: filteredDepartment.map((obj: any) => obj.label),
    }));
  }
  if(!isLoading && filteredManagers.length){
      setManagersFilter(filteredManagers)
      setFilterArgument((filterArgument: any) => ({
        ...filterArgument,
        managers: filteredManagers.map((obj: any) => obj.label),
      }));
  }  
  }, [isSetAllocationData,isLoading]);
  
  useEffect(() => {
    setFilterData(
      allocationData.map(
        (row: {
          name: any;
          id: any;
          department: any;
          ThisMonth: any;
          ThisMonthYear: any;
          project_code: any;
          PresentMonthRevenue: any;
          NextMonthRevenue: any;
          FutureMonthRevenue: any;
          PresentMonthAllocatedStu: any;
          NextMonthAllocatedStu: any;
          FutureMonthAllocatedStu: any;
          PresentMonthStus_executed: any;
          NextMonthStus_executed: any;
          FutureMonthStus_executed: any;
          NextMonth: any;
          NextMonthYear: any;
          FutureMonth: any;
          FutureMonthYear: any;
          RevPerStu_ThisMonth: any;
          RevPerAllocation_NextMonth: any;
          RevPerAllocation_FutureMonth: any;
          RevPerStu_NextMonth: any;
          RevPerStu_FutureMonth: any;
          FutureMonthExpense: number;
          NextMonthExpense: number;
          PresentMonthExpense: number;
          users: any;
        }) => ({
          Project_code: {
            code: row.project_code,
            department: row.department.name,
          },
          Project_name: row.name,
          ThisMonth: row.ThisMonth,
          ThisMonthYear: row.ThisMonthYear,
          NextMonth: row.NextMonth,
          NextMonthYear: row.NextMonthYear,
          FutureMonth: row.FutureMonth,
          FutureMonthYear: row.FutureMonthYear,
          Associated_bu: row.department.name,
          PresentMonthRevenue:convertCurrency(row?.PresentMonthRevenue,currencyRate),
          presentMonthRevenue: row.PresentMonthRevenue,
          NextMonthRevenue:convertCurrency( row?.NextMonthRevenue,currencyRate),
          nextMonthRevenue: row.NextMonthRevenue,
          FutureMonthRevenue:convertCurrency(row?.FutureMonthRevenue,currencyRate),
          futureMonthRevenue: row.FutureMonthRevenue,
          id: row.id,
          PresentMonthAllocatedStu: convertNumber(
            row.PresentMonthAllocatedStu,
            2
          ),
          NextMonthAllocatedStu: convertNumber(row.NextMonthAllocatedStu, 2),
          FutureMonthAllocatedStu: convertNumber(
            row.FutureMonthAllocatedStu,
            2
          ),
          RevPerStu_ThisMonth: convertCurrency( (row?.PresentMonthRevenue - row?.PresentMonthExpense) /
          row?.PresentMonthStus_executed,currencyRate),
          RevPerAllocation_ThisMonth:convertCurrency( (row?.PresentMonthRevenue - row?.PresentMonthExpense) /
          row?.PresentMonthAllocatedStu,currencyRate),
          RevPerAllocation_NextMonth:convertCurrency((row?.NextMonthRevenue - row?.NextMonthExpense) /
          row?.NextMonthAllocatedStu,currencyRate),
          RevPerAllocation_FutureMonth: convertCurrency( (row?.FutureMonthRevenue - row?.FutureMonthExpense) /
          row?.FutureMonthAllocatedStu,currencyRate),
          RevPerStu_NextMonth:convertCurrency( (row?.NextMonthRevenue - row?.NextMonthExpense) /
          row?.NextMonthStus_executed,currencyRate),
          RevPerStu_FutureMonth:convertCurrency((row?.FutureMonthRevenue - row?.FutureMonthExpense) /
          row?.FutureMonthStus_executed,currencyRate),
          RevPerStu_FutureMonth_filter:
            row.FutureMonthRevenue / row.FutureMonthStus_executed,

          PresentMonthStus_executed: convertNumber(
            row.PresentMonthStus_executed,
            2
          ),
          NextMonthStus_executed: convertNumber(
            row.NextMonthStus_executed,
            2
          ),
          FutureMonthStus_executed: convertNumber(
            row.FutureMonthStus_executed,
            2
          ),
          users: row.users,
        })
      )
    );

    setdata(
      allocationData.map(
        (row: {
          name: any;
          id: any;
          department: any;
          ThisMonth: any;
          ThisMonthYear: any;
          project_code: any;
          PresentMonthRevenue: any;
          NextMonthRevenue: any;
          FutureMonthRevenue: any;
          PresentMonthAllocatedStu: any;
          NextMonthAllocatedStu: any;
          FutureMonthAllocatedStu: any;
          PresentMonthStus_executed: any;
          NextMonthStus_executed: any;
          FutureMonthStus_executed: any;
          NextMonth: any;
          NextMonthYear: any;
          FutureMonth: any;
          FutureMonthYear: any;
          RevPerStu_ThisMonth: any;
          RevPerAllocation_NextMonth: any;
          RevPerAllocation_FutureMonth: any;
          RevPerStu_NextMonth: any;
          RevPerStu_FutureMonth: any;
          FutureMonthExpense: number;
          NextMonthExpense: number;
          PresentMonthExpense: number;
          users: any;
        }) => ({
          Project_code: {
            code: row.project_code,
            department: row.department.name,
          },
          Project_name: row.name,
          ThisMonth: row.ThisMonth,
          ThisMonthYear: row.ThisMonthYear,
          NextMonth: row.NextMonth,
          NextMonthYear: row.NextMonthYear,
          FutureMonth: row.FutureMonth,
          FutureMonthYear: row.FutureMonthYear,
          Associated_bu: row.department.name,
          PresentMonthRevenue:convertCurrency(row?.PresentMonthRevenue,currencyRate),
          presentMonthRevenue: row.PresentMonthRevenue,
          NextMonthRevenue:convertCurrency( row?.NextMonthRevenue,currencyRate),
          nextMonthRevenue: row.NextMonthRevenue,
          FutureMonthRevenue:convertCurrency(row?.FutureMonthRevenue,currencyRate),
          futureMonthRevenue: row.FutureMonthRevenue,
          id: row.id,
          PresentMonthAllocatedStu: convertNumber(
            row.PresentMonthAllocatedStu,
            2
          ),
          NextMonthAllocatedStu: convertNumber(row.NextMonthAllocatedStu, 2),
          FutureMonthAllocatedStu: convertNumber(
            row.FutureMonthAllocatedStu,
            2
          ),
          RevPerStu_ThisMonth: convertCurrency( (row?.PresentMonthRevenue - row?.PresentMonthExpense) /
          row?.PresentMonthStus_executed,currencyRate),
          
          RevPerAllocation_ThisMonth:convertCurrency( (row?.PresentMonthRevenue - row?.PresentMonthExpense) /
          row?.PresentMonthAllocatedStu,currencyRate),
          RevPerAllocation_NextMonth:convertCurrency((row?.NextMonthRevenue - row?.NextMonthExpense) /
          row?.NextMonthAllocatedStu,currencyRate),
          
          RevPerAllocation_FutureMonth: convertCurrency( (row?.FutureMonthRevenue - row?.FutureMonthExpense) /
          row?.FutureMonthAllocatedStu,currencyRate),
         
          RevPerStu_NextMonth:convertCurrency( (row?.NextMonthRevenue - row?.NextMonthExpense) /
          row?.NextMonthStus_executed,currencyRate),
          RevPerStu_FutureMonth:convertCurrency((row?.FutureMonthRevenue - row?.FutureMonthExpense) /
          row?.FutureMonthStus_executed,currencyRate),
         
          RevPerStu_FutureMonth_filter:
            row.FutureMonthRevenue / row.FutureMonthStus_executed,

          PresentMonthStus_executed: convertNumber(
            row.PresentMonthStus_executed,
            2
          ),
          NextMonthStus_executed: convertNumber(
            row.NextMonthStus_executed,
            2
          ),
          FutureMonthStus_executed: convertNumber(
            row.FutureMonthStus_executed,
            2
          ),
          users: row.users,
        })
      )
    );
   
 }, [currencyRate]);
  
  var token = getToken();
 

  const handleChangeManager = (val: any) => {
    if(val.length > 0){
      setManagersFilter(val)
      localStorage.setItem("filtered_managers", JSON.stringify(val));
    }else{
      setManagersFilter([])
      localStorage.removeItem("filtered_managers");
    }
    const newVal = val.map((obj: any) => obj.label);
    setFilterArgument((filterArgument: any) => ({
      ...filterArgument,
      managers: newVal,
    }));
  };

  const [filterArgument, setFilterArgument] = useState<any>({
    departments: [],
    managers: [],
    search: "",
  });

  useEffect(() => {
    if (currencySymb?.currency_symbol) {
      handleFilter();
    }
  }, [
    filterArgument.departments,
    filterArgument.managers,
    filterArgument.search,
    currencySymb?.currency_symbol,
  ]);

  useEffect(() => {
    subTodataData();
  }, [filterData.length]);

  const handleOnChange = (event: any) => {
    setFilterArgument((filterArgument: any) => ({
      ...filterArgument,
      search: event.target.value,
    }));
  };

  const handleFilter = () => {
    let results: any = [];
    if (filterArgument.managers.length > 0) {
      getdata.forEach((item: any) => {
        if (item.users) {
          item.users.forEach((user: any) => {
            if (
              filterArgument.managers.includes(
                `${user.firstName} ${user.lastName}`
              )
            ) {
              results.push(item);
            }
          });
        }
      });
    }
    if (filterArgument.departments.length > 0) {
      let newData = getdata;
      if (results.length > 0) {
        newData = results;
      }

      results = newData.filter((item: any) =>
        filterArgument.departments.includes(item.Associated_bu)
      );
    }
    if (filterArgument.search) {
      let newData: any = getdata;
      if (results.length > 0) {
        newData = results;
      }
      results = newData.filter(
        (item) =>
          item.Project_name.toLowerCase().includes(
            filterArgument.search.toLowerCase()
          ) ||
          item.Project_code.code
            .toLowerCase()
            .includes(filterArgument.search.toLowerCase())
      );
    }

    if (
      filterArgument.departments.length == 0 &&
      filterArgument.managers.length == 0 &&
      !filterArgument.search
    ) {
      results = getdata;
    }
    setFilterData(results);
  };

  const handleChangeBu = (val: any) => {
    const newVal = val.map((obj: any) => obj.label);
    if(val.length>0){
      setDepartmentFilter(val)
      localStorage.setItem("filtered_department", JSON.stringify(val));
    }else{
      setDepartmentFilter([])
      localStorage.removeItem("filtered_department");
    }

    setFilterArgument((filterArgument: any) => ({
      ...filterArgument,
      departments: newVal,
    }));
    
  };

  const subTodataData = () => {
    let totalThisRev = 0;
    var totalNextRev = 0;
    var totalFutuRev = 0;
    var totalThisAlloc = 0;
    var totalNextAlloc = 0;
    var totalFutuAlloc = 0;
    var totalThisAvgAloc = 0;
    var totalNextAvgAlloc = 0;
    var totalFutuAvgAlloc = 0;
    var totalThisExe = 0;
    var totalNextExe = 0;
    let totalFutuExe = 0;
    let totalThisAvgExe = 0;
    var totalNextAvgExe = 0;
    var totalFutuAvgExe = 0;
    let totalExpThisMonth = 0;
    let totalExpNextMonth = 0;
    let totalExpFutureMonth = 0;
    
    filterData.map((res) => {
      totalThisRev =
        totalThisRev + convertAmountToNumber(res.presentMonthRevenue);
      totalNextRev = totalNextRev + convertAmountToNumber(res.nextMonthRevenue);
      totalFutuRev =
        totalFutuRev + convertAmountToNumber(res.futureMonthRevenue);
      totalExpThisMonth += convertAmountToNumber(
        res.PresentMonthExpense
      );
      totalExpNextMonth += convertAmountToNumber(
        res.NextMonthExpense
      );
      totalExpFutureMonth += convertAmountToNumber(
        res.FutureMonthExpense
      );

      totalThisAlloc =
        convertAmountToNumber(res.PresentMonthAllocatedStu) &&
        convertAmountToNumber(res.PresentMonthAllocatedStu) != 0
          ? totalThisAlloc + convertAmountToNumber(res.PresentMonthAllocatedStu)
          : totalThisAlloc;
      totalNextAlloc =
        convertAmountToNumber(res.NextMonthAllocatedStu) &&
        convertAmountToNumber(res.NextMonthAllocatedStu) != 0
          ? totalNextAlloc + convertAmountToNumber(res.NextMonthAllocatedStu)
          : totalNextAlloc;
      totalFutuAlloc =
        convertAmountToNumber(res.FutureMonthAllocatedStu) &&
        convertAmountToNumber(res.FutureMonthAllocatedStu) != 0
          ? totalFutuAlloc + convertAmountToNumber(res.FutureMonthAllocatedStu)
          : totalFutuAlloc;
      totalThisAvgAloc =
        convertAmountToNumber(res.PresentMonthAllocatedStu) &&
        convertAmountToNumber(res.PresentMonthAllocatedStu) != 0
          ? totalThisAvgAloc +
            convertAmountToNumber(res.PresentMonthRevenue) /
              convertAmountToNumber(res.PresentMonthAllocatedStu)
          : totalThisAvgAloc;
      totalNextAvgAlloc =
        convertAmountToNumber(res.NextMonthAllocatedStu) &&
        convertAmountToNumber(res.NextMonthAllocatedStu) != 0
          ? totalNextAvgAlloc +
            convertAmountToNumber(res.NextMonthRevenue) /
              convertAmountToNumber(res.NextMonthAllocatedStu)
          : totalNextAvgAlloc;
      totalFutuAvgAlloc =
        convertAmountToNumber(res.FutureMonthAllocatedStu) &&
        convertAmountToNumber(res.FutureMonthAllocatedStu) != 0
          ? totalFutuAvgAlloc +
            convertAmountToNumber(res.FutureMonthRevenue) /
              convertAmountToNumber(res.FutureMonthAllocatedStu)
          : totalFutuAvgAlloc;
      totalThisExe =
        totalThisExe + convertAmountToNumber(res.PresentMonthStus_executed);
      totalNextExe =
        totalNextExe + convertAmountToNumber(res.NextMonthStus_executed);
      totalFutuExe =
        totalFutuExe + convertAmountToNumber(res.FutureMonthStus_executed);
      totalThisAvgExe =
        convertAmountToNumber(res.PresentMonthStus_executed) &&
        convertAmountToNumber(res.PresentMonthStus_executed) != 0
          ? totalThisAvgExe +
            convertAmountToNumber(res.PresentMonthRevenue) /
              convertAmountToNumber(res.PresentMonthStus_executed)
          : totalThisAvgExe;
      totalNextAvgExe =
        convertAmountToNumber(res.NextMonthStus_executed) &&
        convertAmountToNumber(res.NextMonthStus_executed) != 0
          ? convertAmountToNumber(totalNextAvgExe) +
            convertAmountToNumber(res.NextMonthRevenue) /
              convertAmountToNumber(res.NextMonthStus_executed)
          : convertAmountToNumber(totalNextAvgExe);
      totalFutuAvgExe =
        convertAmountToNumber(res.FutureMonthStus_executed) &&
        convertAmountToNumber(res.FutureMonthStus_executed) != 0
          ? totalFutuAvgExe +
            convertAmountToNumber(res.FutureMonthRevenue) /
              convertAmountToNumber(res.FutureMonthStus_executed)
          : totalFutuAvgExe;
    });

    setTotalThisRev(totalThisRev);
    setTotalNextRev(totalNextRev);
    setTotalThisAlloc(totalThisAlloc);
    setTotalNextAlloc(totalNextAlloc);
    setTotalFutuAlloc(totalFutuAlloc);
    setTotalThisExe(totalThisExe);
    setTotalNextExe(totalNextExe);
    setTotalFutuExe(totalFutuExe);
    setTotalExpense({
      thisMonth: totalExpThisMonth,
      nextMonth: totalExpNextMonth,
      futureMonth: totalExpFutureMonth,
    });
  };

  const getMonthsId = async () => {
    AxiosConfig.get("financialmonths", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      const currentMonth: any = res.data.data.months.filter(
        (month: any) => month.status === "1"
      );
      getProjectAllocation(currentMonth[0].id);
    });
  };

  const getManagers = async () => {
    AxiosConfig.get("projects/managers", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempManagerArr: any = [];
        res.data.data.managerList.map((manager: any) =>
          tempManagerArr.push({ label: manager.fullName, value: manager.id })
        );
        const sortedTempManagerData = tempManagerArr?.sort((a, b) => a.label.trim().toLowerCase().localeCompare(b.label.trim().toLowerCase()));
        setManagerOptions(sortedTempManagerData);
        selectedManager.current = tempManagerArr;
      })
      .catch((e: any) => {
        throw(e);
      });
  };

  const getProjectAllocation = async (monthid: any) => {
    let totalThisRev = 0;
    var totalNextRev = 0;
    var totalFutuRev = 0;
    var totalThisAlloc = 0;
    var totalNextAlloc = 0;
    var totalFutuAlloc = 0;
    var totalThisAvgAloc = 0;
    var totalNextAvgAlloc = 0;
    var totalFutuAvgAlloc = 0;
    var totalThisExe = 0;
    var totalNextExe = 0;
    let totalFutuExe = 0;
    let totalThisAvgExe = 0;
    var totalNextAvgExe = 0;
    var totalFutuAvgExe = 0;
    let totalExpThisMonth = 0;
    let totalExpNextMonth = 0;
    let totalExpFutureMonth = 0;
    AxiosConfig.get("allocations?month_id=" + monthid+"&user_id="+user_id, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      let proj = res.data.data.projects;
      if (proj.length >= 0) {
        setIsLoading(false);
      }
      proj = proj.sort((a: any, b: any) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        
        return  dateB.getTime() - dateA.getTime() 
    });

      setProjectMonth({
        ThisMonth: res.data.data.months[0].ThisMonth,
        NextMonth: res.data.data.months[0].NextMonth,
        FutureMonth: res.data.data.months[0].FutureMonth,
        ThisMonthYear: res.data.data.months[0].ThisMonthYear,
        FutureMonthYear: res.data.data.months[0].ThisMonthYear,
        NextMonthYear: res.data.data.months[0].ThisMonthYear,
      });
      setAllocation(res.data.data.projects)
      setdata(
        res.data.data.projects.map(
          (row: {
            name: any;
            id: any;
            department: any;
            ThisMonth: any;
            ThisMonthYear: any;
            project_code: any;
            PresentMonthRevenue: any;
            NextMonthRevenue: any;
            FutureMonthRevenue: any;
            PresentMonthAllocatedStu: any;
            NextMonthAllocatedStu: any;
            FutureMonthAllocatedStu: any;
            PresentMonthStus_executed: any;
            NextMonthStus_executed: any;
            FutureMonthStus_executed: any;
            NextMonth: any;
            NextMonthYear: any;
            FutureMonth: any;
            FutureMonthYear: any;
            RevPerStu_ThisMonth: any;
            RevPerAllocation_NextMonth: any;
            RevPerAllocation_FutureMonth: any;
            RevPerStu_NextMonth: any;
            RevPerStu_FutureMonth: any;
            FutureMonthExpense: number;
            NextMonthExpense: number;
            PresentMonthExpense: number;
            users: any;
          }) => ({
            Project_code: {
              code: row.project_code,
              department: row.department.name,
            },
            Project_name: row.name,
            ThisMonth: row.ThisMonth,
            ThisMonthYear: row.ThisMonthYear,
            NextMonth: row.NextMonth,
            NextMonthYear: row.NextMonthYear,
            FutureMonth: row.FutureMonth,
            FutureMonthYear: row.FutureMonthYear,
            Associated_bu: row.department.name,
            PresentMonthRevenue:convertCurrency(row?.PresentMonthRevenue,currencyRate),
            presentMonthRevenue: row.PresentMonthRevenue,
            NextMonthRevenue:convertCurrency( row?.NextMonthRevenue,currencyRate),
            nextMonthRevenue: row.NextMonthRevenue,
            FutureMonthRevenue:convertCurrency(row?.FutureMonthRevenue,currencyRate),
            futureMonthRevenue: row.FutureMonthRevenue,
            id: row.id,
            PresentMonthAllocatedStu: convertNumber(
              row.PresentMonthAllocatedStu,
              2
            ),
            NextMonthAllocatedStu: convertNumber(row.NextMonthAllocatedStu, 2),
            FutureMonthAllocatedStu: convertNumber(
              row.FutureMonthAllocatedStu,
              2
            ),
            RevPerStu_ThisMonth: convertCurrency( (row?.PresentMonthRevenue - row?.PresentMonthExpense) /
            row?.PresentMonthStus_executed,currencyRate),
            
            RevPerAllocation_ThisMonth:convertCurrency( (row?.PresentMonthRevenue - row?.PresentMonthExpense) /
            row?.PresentMonthAllocatedStu,currencyRate),
            RevPerAllocation_NextMonth:convertCurrency((row?.NextMonthRevenue - row?.NextMonthExpense) /
            row?.NextMonthAllocatedStu,currencyRate),
            
            RevPerAllocation_FutureMonth: convertCurrency( (row?.FutureMonthRevenue - row?.FutureMonthExpense) /
            row?.FutureMonthAllocatedStu,currencyRate),
           
            RevPerStu_NextMonth:convertCurrency( (row?.NextMonthRevenue - row?.NextMonthExpense) /
            row?.NextMonthStus_executed,currencyRate),
            RevPerStu_FutureMonth:convertCurrency((row?.FutureMonthRevenue - row?.FutureMonthExpense) /
            row?.FutureMonthStus_executed,currencyRate),
           
            RevPerStu_FutureMonth_filter:
              row.FutureMonthRevenue / row.FutureMonthStus_executed,

            PresentMonthStus_executed: convertNumber(
              row.PresentMonthStus_executed,
              2
            ),
            NextMonthStus_executed: convertNumber(
              row.NextMonthStus_executed,
              2
            ),
            FutureMonthStus_executed: convertNumber(
              row.FutureMonthStus_executed,
              2
            ),
            users: row.users,
          })
        )
      );
      res.data.data.projects.length ? setAllocationData(true):setAllocationData(false)
      res.data.data.projects.map((res) => {
        totalThisRev = totalThisRev + res.presentMonthRevenue;
       totalExpThisMonth += res?.PresentMonthExpense;
       totalExpNextMonth += res?.NextMonthExpense;
       totalExpFutureMonth += res?.FutureMonthExpense;
        totalNextRev = totalNextRev + res.NextMonthRevenue;
        totalFutuRev = totalFutuRev + res.FutureMonthRevenue;
        totalThisAlloc =
          res.PresentMonthAllocatedStu && res.PresentMonthAllocatedStu != 0
            ? totalThisAlloc + res.PresentMonthAllocatedStu
            : totalThisAlloc;
        totalNextAlloc =
          res.NextMonthAllocatedStu && res.NextMonthAllocatedStu != 0
            ? totalNextAlloc + res.NextMonthAllocatedStu
            : totalNextAlloc;
        totalFutuAlloc =
          res.FutureMonthAllocatedStu && res.FutureMonthAllocatedStu != 0
            ? totalFutuAlloc + res.FutureMonthAllocatedStu
            : totalFutuAlloc;
        totalThisAvgAloc =
          res.PresentMonthAllocatedStu && res.PresentMonthAllocatedStu != 0
            ? totalThisAvgAloc +
              res.PresentMonthRevenue / res.PresentMonthAllocatedStu
            : totalThisAvgAloc;
        totalNextAvgAlloc =
          res.NextMonthAllocatedStu && res.NextMonthAllocatedStu != 0
            ? totalNextAvgAlloc +
              res.NextMonthRevenue / res.NextMonthAllocatedStu
            : totalNextAvgAlloc;
        totalFutuAvgAlloc =
          res.FutureMonthAllocatedStu && res.FutureMonthAllocatedStu != 0
            ? totalFutuAvgAlloc +
              res.FutureMonthRevenue / res.FutureMonthAllocatedStu
            : totalFutuAvgAlloc;
        totalThisExe = totalThisExe + res.PresentMonthStus_executed;
        totalNextExe = totalNextExe + res.NextMonthStus_executed;
        totalFutuExe = totalFutuExe + res.FutureMonthStus_executed;
        totalThisAvgExe =
          res.PresentMonthStus_executed && res.PresentMonthStus_executed != 0
            ? totalThisAvgExe +
              res.PresentMonthRevenue / res.PresentMonthStus_executed
            : totalThisAvgExe;
        totalNextAvgExe =
          res.NextMonthStus_executed && res.NextMonthStus_executed != 0
            ? totalNextAvgExe +
              res.NextMonthRevenue / res.NextMonthStus_executed
            : totalNextAvgExe;
        totalFutuAvgExe =
          res.FutureMonthStus_executed && res.FutureMonthStus_executed != 0
            ? totalFutuAvgExe +
              res.FutureMonthRevenue / res.FutureMonthStus_executed
            : totalFutuAvgExe;
      });
      setTotalThisRev(totalThisRev);
      setTotalNextRev(totalNextRev);
      setTotalFutuRev(totalFutuRev);
      setTotalThisAlloc(totalThisAlloc);
      setTotalNextAlloc(totalNextAlloc);
      setTotalFutuAlloc(totalFutuAlloc);
      setTotalThisExe(totalThisExe);
      setTotalNextExe(totalNextExe);
      setTotalFutuExe(totalFutuExe);
      setTotalExpense({
        thisMonth: totalExpThisMonth,
        nextMonth: totalExpNextMonth,
        futureMonth: totalExpFutureMonth,
      });
        const filteredDepartmentString = localStorage.getItem("filtered_department");
        const filteredDepartment = filteredDepartmentString ? JSON.parse(filteredDepartmentString) : [];
        const filteredManagerString = localStorage.getItem("filtered_managers");
        const filteredManagers = filteredManagerString ? JSON.parse(filteredManagerString) : [];
        filteredDepartment.length == 0 && filteredManagers.length == 0 ? setFilterData(
              res.data.data.projects.map(
                (row: {
                  name: any;
                  id: any;
                  department: any;
                  ThisMonth: any;
                  project_code: any;
                  PresentMonthRevenue: any;
                  NextMonthRevenue: any;
                  FutureMonthRevenue: any;
                  PresentMonthAllocatedStu: any;
                  NextMonthAllocatedStu: any;
                  FutureMonthAllocatedStu: any;
                  PresentMonthStus_executed: any;
                  NextMonthStus_executed: any;
                  FutureMonthStus_executed: any;
                  NextMonth: any;
                  FutureMonth: any;
                  RevPerStu_ThisMonth: any;
                  RevPerAllocation_NextMonth: any;
                  RevPerAllocation_FutureMonth: any;
                  RevPerStu_NextMonth: any;
                  RevPerStu_FutureMonth: any;
                  TalentName: any;
                  PresentMonthExpense: number;
                  NextMonthExpense: number;
                  FutureMonthExpense: number;
                }) => ({
                  PresentMonthTotalExpense: row?.PresentMonthExpense,
                  NextMonthTotalExpense: row?.NextMonthExpense,
                  FutureMonthTotalExpense: row?.FutureMonthExpense,
                  Project_code: {
                    code: row.project_code,
                    department: row.department.name,
                  },
                  Project_name: row.name,
                  ThisMonth: row.ThisMonth,
                  NextMonth: row.NextMonth,
                  FutureMonth: row.FutureMonth,
                  Associated_bu: row.department.name,
                  PresentMonthRevenue:convertCurrency(row?.PresentMonthRevenue,currencyRate),
                  presentMonthRevenue: row.PresentMonthRevenue,
                  NextMonthRevenue:convertCurrency(row?.NextMonthRevenue,currencyRate),
                  nextMonthRevenue: row.NextMonthRevenue,
                  FutureMonthRevenue:convertCurrency( row?.FutureMonthRevenue,currencyRate) ,
                  
                  id: row.id,
                  PresentMonthAllocatedStu: convertNumber(
                    row.PresentMonthAllocatedStu,
                    2
                  ),
                  NextMonthAllocatedStu: convertNumber(row.NextMonthAllocatedStu, 2),
                  FutureMonthAllocatedStu: convertNumber(
                    row.FutureMonthAllocatedStu,
                    2
                  ),
                  RevPerStu_ThisMonth:convertCurrency((row?.PresentMonthRevenue - row?.PresentMonthExpense) /
                  row?.PresentMonthStus_executed,currencyRate),
                 
                  RevPerAllocation_ThisMonth: convertCurrency((row?.PresentMonthRevenue - row?.PresentMonthExpense) /
                  row?.PresentMonthAllocatedStu,currencyRate),
                 
                  RevPerAllocation_NextMonth:convertCurrency( (row?.NextMonthRevenue - row?.NextMonthExpense) /
                  row?.NextMonthAllocatedStu,currencyRate),
                 
                  RevPerAllocation_FutureMonth:convertCurrency((row?.FutureMonthRevenue - row?.FutureMonthExpense) /
                  row?.FutureMonthAllocatedStu,currencyRate),
                 
                  RevPerStu_NextMonth:convertCurrency((row?.NextMonthRevenue - row?.NextMonthExpense) /
                  row?.NextMonthStus_executed,currencyRate),
                 
                  RevPerStu_FutureMonth:convertCurrency( (row?.FutureMonthRevenue - row?.FutureMonthExpense) /
                  row?.FutureMonthStus_executed,currencyRate),
                  
                  RevPerStu_FutureMonth_filter:
                    row?.FutureMonthRevenue / row?.FutureMonthStus_executed,

                  PresentMonthStus_executed: convertNumber(
                    row.PresentMonthStus_executed,
                    2
                  ),
                  NextMonthStus_executed: convertNumber(
                    row.NextMonthStus_executed,
                    2
                  ),
                  FutureMonthStus_executed: convertNumber(
                    row.FutureMonthStus_executed,
                    2
                  ),
                })
              )
            ):[]
          });
   
      
    
  };

  let obj: any = localStorage.getItem("Departments");
  let departments = JSON.parse(obj);
  let filteredBu = [];
  if (departments === null) {
    filteredBu = [];
  } else {
    filteredBu = departments;
  }

  const onClickRow = (record: any) => {
    history.push("/allocations/" + record);
    localStorage.setItem("projectsId", record);
  };

  const tempDepartmentOptionsnew  = filteredBu.map(function (associatedBus: {
    name: any;
    id: any;
  }) {
    return {
      label: associatedBus.name,
      value: associatedBus.name,
    };
  });

  const departmentOptionsnew = tempDepartmentOptionsnew?.sort((a, b) => a.label.trim().toLowerCase().localeCompare(b.label.trim().toLowerCase()));

  useEffect(() => {
    if (currencySymb?.currency_symbol) {
      const addProjectAccess = sessionStorage.getItem("isDepartment");
      if (addProjectAccess === "1") {
        setIsDepartment(true);
      }
    }
  }, [currencySymb?.currency_symbol]);

  const projectHeaders = [
    { label: "PROJECT_CODE", key: "Project_code" },
    { label: "PROJECT_NAME", key: "Project_name" },
    { label: "ThisMonth", key: "ThisMonth" },
    { label: "DEPARTMENT", key: "Associated_bu" },
    { label: "REVENUE", key: "PresentMonthRevenue" },
    { label: "SOLD VALUE", key: "Sold_value" },
    { label: "AVG SOLD/STU", key: "Average_stu" },
    { label: "STU’S EXPENDED", key: "Stus_expended" },
    { label: "EARNED", key: "Earned_value" },
    { label: "AVG EARNED/STU", key: "Average_Ext" },
    { label: "PENDING", key: "Pending" },
    { label: "recognized_tilldate", key: "" },
    { label: "this_month_value", key: "this_month_value" },
  ];

  interface DataType {}

  const columns: ColumnsType<DataType> = [
    {
      fixed: "left",
      title: "PROJECT_CODE",
      dataIndex: ["Project_code", "id", "Delete_Action"],
      key: "key",
      width: "7%",
      render: (text: any, record: any) => (
        <div>
          <a
            onClick={() => {
              onClickRow(record["id"]);
            }}
          >
            {record["Project_code"]?.code}
          </a>
          <h1></h1>
          {record["Project_code"]?.department}
        </div>
      ),
      sorter: (a: any, b: any) =>
        a.Project_code.code.replace("STP_", "") -
        b.Project_code.code.replace("STP_", ""),
    },

    {
      title: "PROJECT_NAME",
      dataIndex: "Project_name",
      key: "project_name",
      width: "7%",
      fixed: "left",

      sorter: (a: any, b: any) => a.Project_name.localeCompare(b.Project_name),
    },

    {
      title:
        String(projectMonth.ThisMonth).substring(0, 3) +
        " " +
        projectMonth.ThisMonthYear,
      className: "thisMonthTitle",
      dataIndex: "ThisMonth",
      key: "ThisMonth",
      children: [
        {
          title: "REV",
          className: "thisMonth",
          dataIndex: "PresentMonthRevenue",
          key: "PresentMonthRevenue",
          width: "7%",
          sorter: (a: any, b: any) =>
            a.presentMonthRevenue - b.presentMonthRevenue,
        },
        {
          title: "PLANNED",
          className: "thisMonth",
          children: [
            {
              title: "STU'S",
              className: "thisMonth",
              dataIndex: "PresentMonthAllocatedStu",
              key: "PresentMonthAllocatedStu",
              sorter: (a: any, b: any) =>
                a.PresentMonthAllocatedStu.replace(/[,]/g, "") -
                b.PresentMonthAllocatedStu.replace(/[,]/g, ""),
              render: (text: any, record: any) => (
                <Row>
                  <Col
                    md={24}
                    xs={24}
                    style={{
                      color: checkVarience(
                        record["PresentMonthAllocatedStu"],
                        record["PresentMonthStus_executed"],
                        false
                      ),
                    }}
                  >
                    <p className="showVarienceDetailsThisMonth">
                      {record["PresentMonthAllocatedStu"]}
                    </p>

                    {checkVarience(
                      record["PresentMonthAllocatedStu"],
                      record["PresentMonthStus_executed"],
                      false
                    ) === "orange" ? (
                      <p id="ThisMonthhover">
                        {" "}
                        {`Under Allocated by${convertAmountToNumberForVariance(
                          record["PresentMonthAllocatedStu"],
                          record["PresentMonthStus_executed"]
                        )}%`}{" "}
                      </p>
                    ) : "" ||
                      checkVarience(
                        record["PresentMonthAllocatedStu"],
                        record["PresentMonthStus_executed"],
                        false
                      ) === "red" ? (
                      <p id="ThisMonthhover">
                        {`Over Allocated by ${convertAmountToNumberForVariance(
                          record["PresentMonthAllocatedStu"],
                          record["PresentMonthStus_executed"]
                        )}%`}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              ),
            },
            {
              title: "AVG/STU",
              className: "thisMonth",
              dataIndex: "RevPerAllocation_ThisMonth",
              key: "RevPerAllocation_ThisMonth",
              sorter: (a: any, b: any) =>
                replaceCurrencyString(a.RevPerAllocation_ThisMonth) -
                replaceCurrencyString(b.RevPerAllocation_ThisMonth),
              render: (text: any, record: any) => (
                <Row>
                  <Col md={24} xs={24}>
                    {record["RevPerAllocation_ThisMonth"]}
                    {}{" "}
                  </Col>
                </Row>
              ),
            },
          ],
        },
        {
          title: "EARNED",
          className: "thisMonth",
          children: [
            {
              title: "STU'S",
              className: "thisMonth",
              dataIndex: "PresentMonthStus_executed",
              key: "PresentMonthStus_executed",
              sorter: (a: any, b: any) =>
                a.PresentMonthStus_executed.replace(/[,]/g, "") -
                b.PresentMonthStus_executed.replace(/[,]/g, ""),
            },
            {
              title: "AVG/ STU",
              className: "thisMonth",
              dataIndex: "RevPerStu_ThisMonth",
              key: "RevPerStu_ThisMonth",

              sorter: (a: any, b: any) =>
                replaceCurrencyString(a.RevPerStu_ThisMonth) -
                replaceCurrencyString(b.RevPerStu_ThisMonth),
            },
          ],
        },
      ],
    },
    {
      title:
        String(projectMonth.NextMonth).substring(0, 3) +
        " " +
        projectMonth.NextMonthYear,
      className: "nextMonthTitle",
      dataIndex: "NextMonth",
      key: "NextMonth",
      children: [
        {
          title: "REV",
          className: "nextMonth",
          dataIndex: "NextMonthRevenue",
          key: "NextMonthRevenue",
          width: "7%",
          sorter: (a: any, b: any) =>
            replaceCurrencyString(a.NextMonthRevenue) -
            replaceCurrencyString(b.NextMonthRevenue),
        },
        {
          title: "PLANNED",
          className: "nextMonth",
          children: [
            {
              title: "STU'S",
              className: "nextMonth",
              dataIndex: "NextMonthAllocatedStu",
              key: "NextMonthAllocatedStu",
              sorter: (a: any, b: any) =>
                replaceCurrencyString(a.NextMonthAllocatedStu) -
                replaceCurrencyString(b.NextMonthAllocatedStu),
              render: (text: any, record: any) => (
                <Row>
                  <Col
                    md={24}
                    xs={24}
                    style={{
                      color: checkVarience(
                        record["NextMonthAllocatedStu"],
                        record["NextMonthStus_executed"],
                        false
                      ),
                    }}
                  >
                    <p className="showVarienceDetailsNextMonth">
                      {record["NextMonthAllocatedStu"]}
                    </p>
                    {checkVarience(
                      record["NextMonthAllocatedStu"],
                      record["NextMonthStus_executed"],
                      false
                    ) === "orange" ? (
                      <p id="NextMonthhover">
                        {" "}
                        {`Under Allocated by ${convertAmountToNumberForVariance(
                          record["NextMonthAllocatedStu"],
                          record["NextMonthStus_executed"]
                        )}%`}{" "}
                      </p>
                    ) : "" ||
                      checkVarience(
                        record["NextMonthAllocatedStu"],
                        record["NextMonthStus_executed"],
                        false
                      ) === "red" ? (
                      <p id="NextMonthhover">
                        {`Over Allocated by${convertAmountToNumberForVariance(
                          record["NextMonthAllocatedStu"],
                          record["NextMonthStus_executed"]
                        )}%`}{" "}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              ),
            },
            {
              title: "AVG/ STU",
              className: "nextMonth",
              dataIndex: "RevPerAllocation_NextMonth",
              key: "RevPerAllocation_NextMonth",
              sorter: (a: any, b: any) =>
              replaceCurrencyString(a.RevPerAllocation_NextMonth) -
              replaceCurrencyString(b.RevPerAllocation_NextMonth),
              render: (text: any, record: any) => (
                <Row>
                  <Col md={24} xs={24}>
                    {record["RevPerAllocation_NextMonth"]}
                  </Col>
                </Row>
              ),
            },
          ],
        },
        {
          title: "EARNED",
          className: "nextMonth",
          children: [
            {
              title: "STU'S",
              className: "nextMonth",
              dataIndex: "NextMonthStus_executed",
              key: "NextMonthStus_executed",
              sorter: (a: any, b: any) =>
                a.NextMonthStus_executed.replace(/[,]/g, "") -
                b.NextMonthStus_executed.replace(/[,]/g, ""),
            },
            {
              title: "AVG/ STU",
              className: "nextMonth",
              dataIndex: "RevPerStu_NextMonth",
              key: "RevPerStu_NextMonth",
              sorter: (a: any, b: any) =>
              replaceCurrencyString(a.RevPerStu_NextMonth) -
              replaceCurrencyString(b.RevPerStu_NextMonth),
            },
          ],
        },
      ],
    },
    {
      title:
        String(projectMonth.FutureMonth).substring(0, 3) +
        " " +
        projectMonth.FutureMonthYear,
      className: "futureMonthTitle",
      dataIndex: "FutureMonth",
      key: "FutureMonth",
      children: [
        {
          title: "REV",
          className: "futureMonth",
          dataIndex: "FutureMonthRevenue",
          key: "FutureMonthRevenue",
          width: "7%",
          sorter: (a: any, b: any) =>
            replaceCurrencyString(a.FutureMonthRevenue) -
            replaceCurrencyString(b.FutureMonthRevenue),
        },
        {
          title: "PLANNED",
          className: "futureMonth",
          children: [
            {
              title: "STU'S",
              className: "futureMonth",
              dataIndex: "FutureMonthAllocatedStu",
              key: "FutureMonthAllocatedStu",
              sorter: (a: any, b: any) =>
                a.FutureMonthAllocatedStu.replace(/[,]/g, "") -
                b.FutureMonthAllocatedStu.replace(/[,]/g, ""),
              render: (text: any, record: any) => (
                <Row>
                  <Col
                    md={24}
                    xs={24}
                    style={{
                      color: checkVarience(
                        record["FutureMonthAllocatedStu"],
                        record["FutureMonthStus_executed"],
                        false
                      ),
                    }}
                  >
                    <p className="showVarienceDetailsFutureMonth">
                      {record["FutureMonthAllocatedStu"]}
                    </p>
                    {checkVarience(
                      record["FutureMonthAllocatedStu"],
                      record["FutureMonthStus_executed"],
                      false
                    ) === "orange" ? (
                      <p id="FutureMonthhover">
                        {" "}
                        {`Under Allocated by ${convertAmountToNumberForVariance(
                          record["FutureMonthAllocatedStu"],
                          record["FutureMonthStus_executed"]
                        )}%`}{" "}
                      </p>
                    ) : "" ||
                      checkVarience(
                        record["FutureMonthAllocatedStu"],
                        record["FutureMonthStus_executed"],
                        false
                      ) === "red" ? (
                      <p id="FutureMonthhover">
                        {`Over Allocated by ${convertAmountToNumberForVariance(
                          record["FutureMonthAllocatedStu"],
                          record["FutureMonthStus_executed"]
                        )}%`}{" "}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              ),
            },
            {
              title: "AVG/ STU",
              className: "futureMonth",
              dataIndex: "RevPerAllocation_FutureMonth",
              key: "RevPerAllocation_FutureMonth",
              sorter: (a: any, b: any) =>
                replaceCurrencyString(a.RevPerAllocation_FutureMonth) -
                replaceCurrencyString(b.RevPerAllocation_FutureMonth),
              render: (text: any, record: any) => (
                <Row>
                  <Col md={24} xs={24}>
                    {record["RevPerAllocation_FutureMonth"]}
                  </Col>
                </Row>
              ),
            },
          ],
        },
        {
          title: "EARNED",
          className: "futureMonth",
          children: [
            {
              title: "STU'S",
              className: "futureMonth",
              dataIndex: "FutureMonthStus_executed",
              key: "FutureMonthStus_executed",
              sorter: (a: any, b: any) =>
                a.FutureMonthStus_executed.replace(/[,]/g, "") -
                b.FutureMonthStus_executed.replace(/[,]/g, ""),
            },
            {
              title: "AVG/ STU",
              className: "futureMonth",
              dataIndex: "RevPerStu_FutureMonth",
              key: "RevPerStu_FutureMonth",
              sorter: (a: any, b: any) =>
                a.RevPerStu_FutureMonth_filter - b.RevPerStu_FutureMonth_filter,
            },
          ],
        },
      ],
    },
  ];



  return (
    <>
      <div className="table-card">
        <Row>
          <Col md={8} xs={24}>
            <div className="searchForm">
              <form>
                <Input.Search
                  allowClear
                  onChange={handleOnChange}
                  placeholder="Search by PROJECT CODE/ PROJECT NAME"
                />
              </form>
            </div>
          </Col>
          <Col md={2} xs={4}>
            <label className="totalLabel">
              {allocations.labelForTotal} {filterData.length}
            </label>
          </Col>
          <Col md={3} xs={3} className="departmentDropdown">
            <ReactMultiSelectCheckboxes
              options={departmentOptionsnew}
              placeholderButtonLabel="Department"
              onChange={handleChangeBu}
              value={selectedDepartment}
            />
          </Col>
          {users.superAdmin ? (
            <Col md={2} xs={3} className="departmentDropdown">
              <ReactMultiSelectCheckboxes
                options={managerOptions}
                onChange={handleChangeManager}
                placeholderButtonLabel="Manager"
                value={selectedManagers}
              />
            </Col>
          ) : null}

          <Col md={8} xs={24} className="text-end">
            {isDepartment}
            <CSVLink data={filterData} headers={projectHeaders}></CSVLink>
          </Col>
        </Row>

        {isLoading === true ? (
          <Loader />
        ) : (
          <div className="tableOutnoWordBreak">
            {
              <Table
                columns={columns}
                dataSource={filterData}
                bordered

                className="projectsTable"
                scroll={{ x: 2320, y: 680 }}
                pagination={false}
                summary={() => (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} className="total">
                        {allocations.textForTotal}
                      </Table.Summary.Cell>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={1}></Table.Summary.Cell>
                      </Table.Summary.Row>
                      <Table.Summary.Cell index={2} className="numTotal">
                        <Text>
                          {convertCurrency(
                            getTotalThisRev,currencyRate
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3} className="numTotal">
                        <Text>{convertNumber(getTotalThisAlloc, 0)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} className="numTotal">
                        <Text>
                          {convertCurrency(
                            (getTotalThisRev - totalExpense.thisMonth) /
                              getTotalThisAlloc,currencyRate
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={5} className="numTotal">
                        <Text>{convertNumber(getTotalThisExe, 0)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={6} className="numTotal">
                        <Text>
                          {convertCurrency(
                            (getTotalThisRev - totalExpense.thisMonth) /
                              getTotalThisExe,currencyRate
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={7} className="numTotal">
                        <Text>
                          {convertCurrency(
                            getTotalNextRev,
                            currencyRate
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={8} className="numTotal">
                        <Text>{convertNumber(getTotalNextAlloc, 0)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={9} className="numTotal">
                        <Text>
                          {convertCurrency(
                            (getTotalNextRev - totalExpense.nextMonth) /
                              getTotalNextAlloc,currencyRate
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={10} className="numTotal">
                        <Text>{convertNumber(getTotalNextExe, 0)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={11} className="numTotal">
                        <Text>
                          {convertCurrency(
                            (getTotalNextRev - totalExpense.nextMonth) /
                              getTotalNextExe,currencyRate
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={12} className="numTotal">
                        <Text>
                          {convertCurrency(
                            getTotalFutuRev,
                            currencyRate
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={13} className="numTotal">
                        <Text>{convertNumber(getTotalFutuAlloc, 0)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={14} className="numTotal">
                        <Text>
                          {convertCurrency(
                            (getTotalFutuRev - totalExpense.futureMonth) /
                              getTotalFutuAlloc,currencyRate
                          )}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={15} className="numTotal">
                        <Text>{convertNumber(getTotalFutuExe, 0)}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={16} className="numTotal">
                        <Text>
                          {convertCurrency(
                            (getTotalFutuRev - totalExpense.futureMonth) /
                              getTotalFutuExe,currencyRate
                          )}
                          
                        </Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            }
          </div>
        )}
      </div>
    </>
  );
};
export default Allocation;
