import { Input } from "antd";
import { Fragment } from "react";
import "./InputField.scss";

const PasswordField = (props: {
  name?: string;
  onChange?: any;
  value?: string;
  placeholder?: string;
  onBlur?: any;
  type?: string;
  iconRender?: any;
  className?: any;
}) => {
  const classes = "form-group__input " + props.className;

  return (
    <Fragment>
      <Input.Password
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        type={props.type}
        iconRender={props.iconRender}
        className={classes}
      />
    </Fragment>
  );
};
export default PasswordField;
