//import Action Types
import { GET_SECONDARY_SKILLS, GET_SECONDARY_SKILLS_ERROR } from "../../constants";

//Write down the initial state of the reducer
interface SecondarySkillsState {
    secondary_skills: Array<object>;
    error: string;
}

const INITIAL_STATE: SecondarySkillsState = {
    secondary_skills: [],
    error: "",
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_SECONDARY_SKILLS:
            return { ...state, secondary_skills: action.payload };
        case GET_SECONDARY_SKILLS_ERROR:
            return { ...state, error: action.payload };

        default:
            return state;
    }
};
