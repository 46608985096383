import { CancelTokenSource } from "axios";
import { GET_SNAPSHOT_LIST, GET_SNAPSHOT_ERROR } from "../../constants";
import { Axios } from "../../utils/axios";

export const getSnapShot =(filters:any, cancelToken: CancelTokenSource)=>{
    return (async(dispatch)=>{
            try{
                const response = await Axios.post("projectSnapshots", filters, {cancelToken: cancelToken.token}) 
                console.log("Response", response); 

                if ( response.status == 200 ) {
                    dispatch({
                        type:GET_SNAPSHOT_LIST,
                        payload: response.data.data
                    })
                } else {
                    dispatch({
                        type:GET_SNAPSHOT_ERROR,
                        payload: "Error: Data not found."
                    })
                }
            } catch(error:any){
                dispatch({
                    type:GET_SNAPSHOT_ERROR,
                    payload: error
                })
            } 
    })
}