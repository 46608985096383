import { Drawer, Row, Col, Input, notification } from "antd";
import React, { useState, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { Formik } from "formik";
import ButtonReuse from "../../../components/Buttons/Button";
import { CurrencyInput } from "../../../components/Currency/MasterCurrency";
import * as yup from "yup";
import { getToken } from "../../../auth";
import { useSelector } from "react-redux";
import { viewMonthsData } from "../../../utils/enums";
require("./getMonthlySlots.scss");
require("./viewslots.scss");

const ViewOverlay = (props: {
  visible?: any;
  onClose?: any;
  recordHandler?: any;
  rateDetails?: any;
  id?: any;
}) => {
  const costNotification = () => {
    notification.open({
      message: "Cost Edited Successfully",
    });
  };

  const costId = props.recordHandler?.id;
  var token = getToken();
  const ratesID: any = {};
  const currID: any = {};
  const initialFormState = {};
  const cuError = {};

  props.rateDetails.map((value: any) => {
    const key = `rate_${value.currency_code}`.toLowerCase();
    ratesID[key] = value.currencyrates.id;
    currID[key] = value.id;
    initialFormState[key] = value?.currencyrates?.rate;
    cuError[key] = yup.number().typeError("Please enter a valid amount");
  });

  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );

  const costUpdate = (values: any) => {
    AxiosConfig.patch("financialmonths/" + costId, values, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.status === 200) {
          costNotification();
          props.onClose();
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
      })
      .catch(function (error) {
        console.log(error.response.data);
        const notify = () => {
          notification.open({
            message: error.response.data.status.message,
          });
        };
        notify();
      });
  };

  const initialCost = {
    cost_per_stu: "",
  };

  const [newitems, setNewItems] = useState(initialFormState);
  const [newcost, setCostItem] = useState(initialCost);

  useEffect(() => {
    const initialCost = {
      cost_per_stu: props.recordHandler?.cost.replace("$", ""),
    };
    setCostItem(initialCost);
    setNewItems(initialFormState);
  }, [props]);

  const schema_cost = yup.object().shape({
    ...cuError,
    cost_per_stu: yup.number().typeError("Please enter a valid amount"),
  });

  const handleFormSubmit = (values: any) => {
    const valuesKey: any = Object.keys(values);
    for (const key of valuesKey) {
      const currenecyID = ratesID[key];
      AxiosConfig.patch(
        "rates/" + currenecyID,
        { rate: values[key] },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      ).then((response) => {
        if (response.status === 200) {
          props.onClose();
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
      });
    }
    AxiosConfig.put(
      "purchaseorders/forexValue",
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((response) => {
        notification.open({
          message: response.data.status.message,
        });
      })
      .catch(function (error) {
        notification.open({
          message: error.response.data.status.message,
        });
      });
  };

  const renderForexRatesForm = () => {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={newitems}
          validationSchema={schema_cost}
          onSubmit={handleFormSubmit}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={24}>
                  <h3 className="slots-title mt-20">
                    {viewMonthsData.headingForBaseCurrency}
                    {currencySymb?.currency_code} (
                    {currencySymb?.currency_symbol})
                  </h3>
                </Col>
                <Col span={24}>
                  <h4 className="view">
                    {viewMonthsData.headingForForexRates}
                  </h4>
                </Col>
                <CurrencyInput
                  monthId={costId}
                  type="edit"
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                />
                <Col span={24} className="viewSlotsCol">
                  <ButtonReuse
                    type="primary"
                    className="primary-btn submitBtn"
                    htmlType="submit"
                    value="Save"
                  ></ButtonReuse>
                  <ButtonReuse
                    onClick={props.onClose}
                    value="Cancel"
                    className="closeSlotBtn"
                  ></ButtonReuse>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </>
    );
  };

  const renderAddForexRateForm = () => {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={newitems}
          validationSchema={schema_cost}
          onSubmit={handleFormSubmit}
        >
          {({ handleSubmit, handleChange, values, errors }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={24}>
                  <h4 className="slots-title mt-20">
                    {viewMonthsData.headingForAddForexRate}
                  </h4>
                </Col>
                <CurrencyInput
                  monthId={costId}
                  type="edit"
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                />
                <Col span={24} className="viewSlotsCol">
                  <ButtonReuse
                    type="primary"
                    className="primary-btn submitBtn"
                    htmlType="submit"
                    value="Save"
                  ></ButtonReuse>
                  <ButtonReuse
                    onClick={props.onClose}
                    value="Cancel"
                    className="closeSlotBtn"
                  ></ButtonReuse>
                </Col>
              </Row>
            </form>
          )}
        </Formik>
      </>
    );
  };

  return (
    <>
      <Drawer
        title="View Monthly Slots"
        placement="right"
        onClose={props.onClose}
        visible={props.visible}
        width="500"
        className="monthlySlotsDrawer"
      >
        <Formik
          enableReinitialize={true}
          initialValues={{ cost_per_stu: newcost.cost_per_stu }}
          validationSchema={schema_cost}
          onSubmit={(values) => {
            costUpdate(values);
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={12} className="viewSlotsCol">
                  <h4>{viewMonthsData.headingForMonthName}</h4>
                  <Input value={props.recordHandler?.month} disabled />
                </Col>
                <Col span={12} className="viewSlotsCol">
                  <h4>{viewMonthsData.headingForMonthsYear}</h4>
                  <Input value={props.recordHandler?.year} disabled />
                </Col>
              </Row>
            </form>
          )}
        </Formik>
        {Object.keys(ratesID).length! > 0 && renderForexRatesForm()}
        {Object.keys(ratesID).length! < 1 && renderAddForexRateForm()}
      </Drawer>
    </>
  );
};

export default ViewOverlay;
